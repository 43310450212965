//Customizable Area Start
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepIconProps } from "@material-ui/core/StepIcon";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Tab,
  Modal,
  InputLabel,
  ListSubheader, 
  ListItemText
} from "@material-ui/core";
import { FileDrop } from "react-file-drop";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import {  sortIcon, filterSmIcon, uploadImg, imgLogo, } from "../assets";
import { noInvetoryImg } from "../../../catalogue/src/assets"
import BulkUploadController, { Props,configJSON } from "./BulkUploadController.web";
import Loader from "../../../../components/src/Loader.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import Check from "@material-ui/icons/Check";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CreateIcon from "@material-ui/icons/Create";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import TabPanel from "@material-ui/lab/TabPanel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TabContext, TabList } from "@material-ui/lab";
import TeamMember from "../../../email-account-registration/src/teams-web/TeamMemberTable.web";
import TeamMembersSort from "../../../email-account-registration/src/teams-web/TeamMembersSort.web";
import TeamSort from "../../../email-account-registration/src/teams-web/TeamSort.web";
import ConfirmActionModal from "../../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import WebLoader from "../../../../components/src/WebLoader.web";
import { InventoryTableView } from "../../../../components/src/Inventory/InventoryTableView.web";
import ClearIcon from "@material-ui/icons/Clear";
import { checkBlack, tutorialLogo } from "../../../email-account-registration/src/assets"
import MembersListTable from "../../../../components/src/MembersListTable.web";
import SubscriptionScreenWeb from "../../../email-account-registration/src/teams-web/SubscriptionScreen.web";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    stepperRoot: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      "& .MuiStepConnector-line": {
        borderColor: "transparent",
      },
      "& .stepper-top": {
        padding: "16px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "21px 24px",
        },
        "& .stepper-row": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "0 -10px",
          [theme.breakpoints.down("sm")]: {
            margin: "0 -5px",
          },
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            justifyContent: "space-between",
          },
          "& .stepper-col": {
            flex: "0 0 33.33%",
            maxWidth: "33.33%",
            padding: "0 10px",
            [theme.breakpoints.down("md")]: {
              flex: "0 0 27%",
              maxWidth: "27%",
            },
            [theme.breakpoints.down("sm")]: {
              padding: "0 5px",
              flex: "unset",
              maxWidth: "unset",
            },
            [theme.breakpoints.down("xs")]: {
              padding: "0",
            },
            "&.stepper-middle": {
              [theme.breakpoints.down("md")]: {
                flex: "0 0 46%",
                maxWidth: "46%",
              },
              [theme.breakpoints.down("sm")]: {
                flex: "unset",
                maxWidth: "unset",
              },
              [theme.breakpoints.down("xs")]: {
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              },
            },
            "&.stepper-action": {
              [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
              },
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
            "& .MuiMobileStepper-root": {
              [theme.breakpoints.down("xs")]: {
                padding: "0",
                background: "transparent",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                color: "#94A3B8",
              },
            },
            "& .back-btn-wrap": {
              "& i": {
                display: "block",
              },
            },
            "& .auth-logo": {
              maxWidth: "117px",
              width: "100%",
              "& img": {
                maxWidth: "100%",
              },
            },
            "& .button-wrapper": {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              "& .secondary-btn": {
                minWidth: "116px",
                marginRight: "14px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "90px",
                  marginRight: "10px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  marginRight: "5px",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
              "& .primary-btn": {
                minWidth: "142px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "130px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
            },
          },
        },
      },
      "& .sm-visible": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .stepIcon": {
        backgroundColor: "#fafcfe",
        zIndex: 1,
        color: "#94a3b8",
        width: 48,
        height: 48,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid transparent",
        [theme.breakpoints.down("sm")]: {
          width: 32,
          height: 32,
        },
        "& .MuiSvgIcon-root": {
          width: 20,
          height: 20,
        },
        "&.active": {
          backgroundColor: "#4ba3b7",
          color: "#ffffff",
        },
        "&.completed": {
          backgroundColor: "transparent",
          borderColor: "#00ba88",
          color: "#00ba88",
        },
      },
      "& .MuiStepper-root": {
        padding: "0",
        maxWidth: "490px",
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "unset",
          width: "auto",
        },
        "& .step-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "&.active-step-root": {
            [theme.breakpoints.down("xs")]: {
              display: "block",
              padding: "0",
            },
          },
        },
        "& .MuiStepConnector-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .MuiStepLabel-iconContainer": {
          paddingRight: "13px",
          [theme.breakpoints.down("sm")]: {
            paddingRight: "5px",
          },
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
      "& .MuiStepLabel-label": {
        "&.MuiStepLabel-active": {
          "& .step-no": {
            color: "#4ba3b7",
          },
          "& .step-label": {
            color: "#2b2b2b",
            [theme.breakpoints.down("xs")]: {
              color: "#4BA3B7",
            },
          },
        },
        "&.MuiStepLabel-completed": {
          "& .step-no": {
            color: "#00ba88",
          },
          "& .step-label": {
            color: "#00ba88",
          },
        },
        "& .step-no": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Roboto",
          letterSpacing: "0.4px",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .step-label": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          letterSpacing: "0.15px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
            lineHeight: "17px",
          },
          [theme.breakpoints.down("xs")]: {
            background: "rgba(75, 163, 183, 0.08)",
            borderRadius: "6px",
            padding: "4px 12px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            color: "#4BA3B7",
            fontFamily: "Roboto",
            marginRight: "12px",
          },
        },
      },
      "& .step-content-root": {
        display: "flex",
        padding: "37px 24px",
        width: "100%",
        flex: "1",
        [theme.breakpoints.down("xs")]: {
          padding: "18px 0 24px",
        },
      },
      "& .step-content": {
        width: "100%",
        display: "flex",
        "& .member-upload-content": {
          maxWidth: "100%",
          width: "812px",
          margin: "auto",
          [theme.breakpoints.down("xs")]: {
            padding: "0 24px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
          },
          "& .file-drop": {
            [theme.breakpoints.down("xs")]: {
              marginBottom: "8px",
            },
          },
          "& .heading": {
            fontSize: "24px",
            lineHeight: "30px",
            margin: "0 0 9px",
            color: "#000000",
            letterSpacing: "0",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            [theme.breakpoints.down("xs")]: {
              fontSize: "22px",
              lineHeight: "26px",
              margin: "0 0 9px",
              color: "#2B2B2B",
            },
          },
          "& .sub-txt": {
            fontSize: "14px",
            lineHeight: "24px",
            margin: "0 0 26px",
            color: "#505050",
            fontFamily: "Roboto",
            fontWeight: 400,
            [theme.breakpoints.down("xs")]: {
              fontSize: "12px",
              lineHeight: "20px",
              margin: "0 0 78px",
              color: "#2B2B2B",
            },
          },
          "& .button-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "25px",
            [theme.breakpoints.down("xs")]: {
              marginTop: "auto",
            },
            "& .secondary-btn": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
              },
            },
            "& .MuiButton-root": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
              },
              "&:last-child": {
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "0",
                },
              },
            },
          },
        },
      },
    },
    radioChoose: {
      width: "100%",
      marginBottom: "24px",
      "&.error-show": {
        "& .MuiRadio-colorSecondary ~ span": {
          border: "1px solid #C7263E",
          color: "#C7263E",
        },
      },
      "& .radio-group": {
        flexDirection: "row",
        margin: "0 -5px",
        [theme.breakpoints.down("xs")]: {
          margin: "0",
        },
        "& .MuiFormControlLabel-root": {
          margin: "0",
          flex: "0 0 33.33%",
          maxWidth: "33.33%",
          padding: "0 5px",
          [theme.breakpoints.down("xs")]: {
            flex: "1",
            maxWidth: "33.33%",
            marginRight: "5px",
            padding: "0",
          },
          "&:last-child": {
            marginRight: "0",
          },
          "&:nth-child(3n)": {
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
            },
          },
        },
      },
      "& .MuiRadio-root": {
        display: "none",
      },
      "& .MuiRadio-colorSecondary ~ span": {
        padding: "10px 15px",
        width: "100%",
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "57px",
        transition: "all 0.3s ease-in-out",
      },
      "& .MuiRadio-colorSecondary.Mui-checked ~ span": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        // color: "#C7263E",
        color: "#00BA88",
        // fontFamily: "Expletus Sans",
        fontFamily: "Roboto",
        fontWeight: "400",
        marginLeft: "14px",
        marginRight: "14px",
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    fileUploadWrapper: {
      border: "1px dashed #e8ecf2",
      padding: "62px 30px 70px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        backgroundColor: "#fafcfe",
        borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "30px",
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.4px",
        fontFamily: "Roboto",
        fontWeight: 400,
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    uploadProgress: {
      borderRadius: "12px",
      border: "1px solid #e8ecf2",
      marginTop: "24px",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0",
        marginBottom: "46px",
      },
      "& .progress-status": {
        width: "56%",
        position: "absolute",
        height: "100%",
        opacity: "0.169",
        backgroundImage: "linear-gradient(270deg, #82d0dc 0%, #08748c 100%)",
      },
      "& .progress-row": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "29px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px 16px",
        },
        "& .progress-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          margin: "0 0 8px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
          },
        },
        "& .progress-subtxt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.4px",
          },
          "& .remaining": {
            position: "relative",
            marginLeft: "9px",
            paddingLeft: "14px",
            "&:before": {
              content: "''",
              width: "6px",
              height: "6px",
              borderRadius: "3px",
              backgroundColor: "#2b2b2b",
              position: "absolute",
              left: "0",
              top: "4px",
            },
          },
        },
        "& .right-col": {
          marginLeft: "15px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& >a": {
            marginRight: "23px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.play-pause": {
              color: "#000000",
            },
            "&.close": {
              color: "#bf3e50",
            },
          },
        },
      },
    },
    uploadFileList: {
      marginTop: "24px",
      padding: "0",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
        marginBottom: "10px",
      },
      "& .MuiListItem-root": {
        marginBottom: "24px",
        borderRadius: "12px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        padding: "15px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .file-info": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          "& .file-name": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
            fontFamily: "Roboto",
            fontWeight: 400,
          },
          "& .close": {
            color: "#959595",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    
    teamModuleWrapper: {
      padding: "0",
      textAlign: "left",
      width: "100%",
      "& .stepper-action": {
        [theme.breakpoints.down("xs")]: {
          padding: "46px 24px 0",
        },
        "& .button-wrapper": {
          "& .MuiButton-root": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              marginBottom: "16px",
            },
            "&:last-child": {
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
      },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginBottom: "23px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px",
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: "10px",
          },
        },
        "& .members-count": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2b2b2b",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          marginRight: "14px",
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "28px",
          margin: "0 0 11px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
          },
        },
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#505050",
          fontFamily: "Roboto",
          fontWeight: "400",
          margin: "0",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px",
            color: "#2B2B2B",
            letterSpacing: "0",
            opacity: "0.67",
          },
        },
      },
      "& .search-filter-sm": {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          padding: "0 24px",
          marginBottom: "3px",
        },
        "& .search-wrapper": {
          flex: "1",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              paddingTop: "16px",
              paddingBottom: "16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                width: "20px",
                height: "20px",
              },
            },
          },
        },
        "& .sort-group": {
          marginLeft: "16px",
          cursor: "pointer",
        },
        "& .filter-group": {
          marginLeft: "16px",
          cursor: "pointer",
        },
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
      },
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "55px",

        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          flexWrap: "wrap",
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px",
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px",
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px",
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px",
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px",
          },
          "& .select-control": {
            marginRight: "52px",
            "& fieldset":{
              border:0,
            },
            "& .MuiSelect-select":{
              paddingRight:"50px",
            },
            [theme.breakpoints.down("md")]: {
              marginRight: "50px",
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px",
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px",
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
        "& .search-wrapper": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: {
              width: "308px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "234px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px",
          },
          "& .MuiInput-underline": {
            "&:before, &:after": {
              display: "none",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "#2b2b2b",
            opacity: "0.671",
            fontSize: "22px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px",
            },
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
          },
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none",
            },
            "& .MuiInput-formControl": {
              marginTop: "0",
            },
            "& .MuiSelect-select": {
              paddingRight: "40px",
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "25px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    emptyDataBox: {
      minHeight: "54vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .emptydata-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "830px",
        margin: "25px auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "661px",
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "100%",
          padding: "0 24px",
          margin: "18px auto",
        },
        "& .emptydata-img": {
          maxWidth: "389px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "281px",
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "327px",
          },
        },
      },
      "& .emptydata-content": {
        marginLeft: "98px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "60px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "50px",
          width: "100%",
        },
      },
      "& .heading": {
        fontSize: "20px",
        lineHeight: "24px",
        margin: "0 0 14px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "21px",
          letterSpacing: "0.03em",
          color: "#363636",
          fontFamily: "Roboto",
          fontWeight: 700,
          margin: "0 0 16px",
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 48px",
        color: "#2b2b2b",
        opacity: "0.671",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 32px",
        },
        [theme.breakpoints.down("xs")]: {
          letterSpacing: "0.03em",
          color: "#7A7A7A",
          margin: "0 0 24px",
        },
      },
    },
    tabWrapper: {
      "& .MuiTable-root": {
        "&.bulk-upload-confirm": {
          "& .MuiTableCell-head": {
            "&.MuiTableCell-paddingNone": {
              paddingLeft: "26px",
            },
          },
          "& .MuiTableCell-body": {
            "&.MuiTableCell-paddingNone": {
              paddingLeft: "26px",
            },           
            "&.name-cell": {
              width: "26%",
              [theme.breakpoints.down("xs")]: {
                width: "40%",
              },
            },
            "&.email-cell": {
              width: "74%",
              [theme.breakpoints.down("xs")]: {
                width: "60%",
              },
            },
          },
        },
        "& .MuiTableCell-body": {          
          "&.name-cell": {
            width: "24.5%",
            // [theme.breakpoints.down("md")]: {
            //   width: "15.4%",
            // },
          },
          "&.email-cell": {
            width: "26%",
            [theme.breakpoints.down("md")]: {
              width: "21%",
            },
            "& .table-link": {
              marginLeft: "0",
            },
          },
          "&.team-cell": {
            width: "22.9%",
            [theme.breakpoints.down("md")]: {
              width: "19.9%",
            },
          },
          "&.joindate-cell": {
            width: "14.33%",
            // [theme.breakpoints.down("md")]: {
            //   width: "10.5%",
            // },
          },
          "&:last-child": {
            width: "auto",
          },
          "&.action-link": {
            width: "8.3%",
            [theme.breakpoints.down("md")]: {
              width: "12%",
            },
          },
        },
      },
      "& .MuiTabs-flexContainer": {
        margin: "0 33px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 24px",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#3691a6",
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid #E8ECF2",
        },
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        textTransform: "unset",
        letterSpacing: 0,
        opacity: "0.671",
        [theme.breakpoints.up("sm")]: {
          minWidth: "166px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "50%",
        },
        "&.Mui-selected": {
          opacity: "1",
        },
      },
      "& .MuiTabPanel-root": {
        padding: "0",
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "48%",
            minWidth: "unset",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    selectOutline: {
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(25,25,25,0.32)",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#94a3b8",
        letterSpacing: "0.0075em",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        width: "calc(100% - 28px)",
        "& .MuiInputLabel-asterisk": {
          marginLeft: "auto",
          fontSize: "18px",
          lineHeight: "23px",
          color: "#e86577",
        },
        "&.Mui-error": {
          color: "rgba(43, 43, 43, 0.87)",
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "23px",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b5b5b5",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2b2b2b",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.0075em",
        padding: "20px 16px",
        "&:focus": {
          background: "transparent",
        },
        "&.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0",
        },
      },
      "& .MuiInputLabel-outlined": {
        color: "#94a3b8",
      },
      "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(43,43,43,0.87)",
        letterSpacing: "0.0075em",
        fontFamily: "Roboto",
        fontWeight: "400",
        width: "auto",
        "& ~.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
        },
        "&.Mui-error": {
          color: "rgba(43,43,43,0.87)",
        },
      },
      "& .MuiInputLabel-outlined.Mui-focused ": {
        "& ~.MuiOutlinedInput-root.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E",
        },
      },
      "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "#C7263E",
        },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: "400",
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 60px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        padding: "30px 25px",
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "24px",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginLeft: "24px",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "27px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },
            "& .select-control": {
              "&.multi-select": {
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginBottom: "32px",
                },
                "& .multiselect-label": {
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                    position: "static",
                    transform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    marginBottom: "16px",
                    letterSpacing: "0",
                  },
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                  [theme.breakpoints.down("sm")]: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    color: "#2B2B2B",
                    padding: "18px 40px 18px 24px",
                    lineHeight: "20px",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  [theme.breakpoints.down("sm")]: {
                    borderColor: "#e8ecf2",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      [theme.breakpoints.down("sm")]: {
                        borderWidth: "1px",
                      },
                    },
                  },
                },
                "& .multiple-clear": {
                  position: "absolute",
                  fontSize: "15px",
                  right: "18px",
                  top: "3px",
                  zIndex: 1,
                  color: "#94A3B8",
                  [theme.breakpoints.down("sm")]: {
                    top: "calc(50% + 17px)",
                    right: "27px",
                    transform: "translateY(-50%)",
                  },
                },
              },
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0",
                color: "#000000",
                opacity: "0.671",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                paddingBottom: "0",
                paddingTop: "0",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
            },
          },
        },
        "& .modal-description": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },          
        },
        "& .modal-footer": {
          [theme.breakpoints.down("xs")]: {
            marginTop: "auto",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "30px",
      },
      "& .download-template-list": {
        padding: "0",
        "& .filtersFormControl": {
          border: "1px solid #E8ECF2",
          borderRadius: "8px",
          padding: "23px 20px",
          marginBottom: "8px",
          "& .categoryBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            "& .categoryImg": {
              height: "16px"
            },
            "& .categoryText": {
              fontSize: "14px",
              color: "rgba(43, 43, 43, 1)",
              fontFamily: "Expletus Sans",
              lineHeight: "19px",
              fontWeight: 500,
              letterSpacing: "0.15px",
              marginLeft: "12px",
            },
            "& .download-link": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
              letterSpacing: "0",
              textDecoration: "none",
              color: "#2B2B2B",
            }
          }, 
          "& .left-content": {
            display: "flex",
            alignContent: "center",
            marginRight: "10px",
            "& .MuiListItemIcon-root": {
              minWidth: "20px",
            },
          },
        },        
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .modal-footer": {
        marginTop: "44px",
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
    },
    toastFileError:{
      maxHeight:"300px",
      overflowY:"auto",
      display: "flex",
      alignItems: "flex-start",
      "& ul": {
        paddingLeft:"20px",
        listStyle: "none",
        "& li": {
          position: "relative",
          "&::before": {
            content: "''",
            width: "3px",
            height: "3px",
            borderRadius: "100%",
            background: "#000000",
            position: "absolute",
            left: "-12px",
            top: "10px",
          }
        }
      },
      "& ol": {
        paddingLeft: "0",
        listStyle: "none",
        flex: "1",
      },
      "& .toast-link": {
        marginLeft:"15px",
        "& .MuiSvgIcon-root": {
          color: "#bf3e50",
        }
      }
    }
  });

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PermMediaIcon />,
    2: <CreateIcon />,
    3: <CreateIcon />,
  };

  return (
    <Box
      className={`stepIcon ${completed ? "completed" : active ? "active" : ""}`}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        <>{icons[String(props.icon)]}</>
      )}
    </Box>
  );
}

function getSteps(activeStep: number,t:any) {
  return [
    <>
      <Box className="step-no">{activeStep > 0 ? t(`${configJSON.completeTxt}`) : `${t(configJSON.stepTxt)} 1`} </Box>
      <Box className="step-label">{t(`${configJSON.txtBulkUploadTitle1}`)}</Box>
    </>,
    <>
      <Box className="step-no">{activeStep > 1 ? t(`${configJSON.completeTxt}`) : `${t(configJSON.stepTxt)} 2`} </Box>
      <Box className="step-label">{t(`${configJSON.previewTxt}`)}</Box>
    </>,
    <>
      <Box className="step-no">{t(`${configJSON.stepTxt}`)} 3</Box>
      <Box className="step-label">{t(`${configJSON.finishTxt}`)}</Box>
    </>,
  ];
}

class BulkUpload extends BulkUploadController {
  constructor(props: Props) {
    super(props);
  }

  listTeamsData = () => {
    if (this.state.isFilteringTeam) {
      if (this.state.filteredDataTeam.length > 0) {
        return (
          <TeamMember
            renderTeams={this.state.filteredDataTeam}
            hideActionColumn={true}
            isFromBulk={true}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.noTeamsFound}`)}</Box>;
      }
    } else if (this.state.teamsList.length > 0) {
      return (
        <TeamMember
          renderTeams={this.state.teamsList}
          hideActionColumn={true}
          isFromBulk={true}
        />
      );
    } else {
      return <Box className="no-member">{this.t(`${configJSON.noTeamsFound}`)}</Box>;
    }
  };
  memberTeamsData = () => {
    if (this.state.isFiltering) {
      if (this.state.filteredData.length > 0) {
        return (
          <MembersListTable
            renderMembers={this.state.filteredData}
            showDeleteInMembers={true}
            deleteMember={this.deleteMemberHandler}
            getSelectedRecords={this.getSelectedRecords}
            multipleDelete={this.multipleDelete}
            hideDeactivate={true}
            t={this.t}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.noMembersFoundTxt}`)}</Box>;
      }
    } else if (this.state.membersList.length > 0) {
      return (
        <MembersListTable
          renderMembers={this.state.membersList}
          showDeleteInMembers={true}
          deleteMember={this.deleteMemberHandler}
          getSelectedRecords={this.getSelectedRecords}
          multipleDelete={this.multipleDelete}
          hideDeactivate={true}
        />       

      );
    } else {
      return <Box className="no-member">{this.t(`${configJSON.noMembersFoundTxt}`)}</Box>;
    }
  };  

  getSecondStepContent = () => {
    const { classes } = this.props;
    if(this.state.isInventory)
      return this.getInventorySecondStepContent()
    return (
      <Box className={classes.teamModuleWrapper}>
        <Box className="heading-wrapper">
          <Box className="heading-left">
            <Typography className="heading" variant="h1">
              {this.state.membersList.length} {this.t(`${configJSON.contactFoundTxt}`)}
            </Typography>
            <Box component="p" className="sub-txt">
              {this.t(`${configJSON.constIsDependantTxt}`)}
            </Box>
          </Box>
        </Box>

      {this.searchContent()}
        {/* mobile filter */}
        {this.state.isFilter && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handle_modalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
          >
            <Box className={`${classes.modalDialogBox} filter-dialogbox`}>
              <Box className="modal-heading">
                <Box className="filter-heading">
                  <Box
                    className="auth-back"
                    onClick={this.handle_modalClose}
                  >
                    <i>
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                  </Box>
                  <Typography className="modal-title" component="h2">
                    {this.t(`${configJSON.txtFilters}`)}
                  </Typography>
                </Box>
                <Link to="#" className="filter-link" onClick={this.resetFilters}>
                  {this.t(`${configJSON.txtReset}`)}
                </Link>
              </Box>
              <Box className="modal-description">
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">   
                  {this.state.isTeamsTab ? 
                  <TeamSort
                  teamStateName={"selectedTeamFilter"}
                  selectTeamOptions={this.state.teamsList}
                  classes={classes}
                  onSelectedOptions={this.saveSelectedTeamsOption}
                  clearAction={this.handleClearSelectionTeam}
                  selectedTeam={this.state.selectedTeamFilter}
                />:                       
                    <TeamMembersSort
                      selectTeamOptions={this.state.teamsList}
                      selectJobOptions={this.state.membersList}
                      classes={classes}
                      onSelectedOptions={this.saveSelectedOption}
                      hideDeactivateColumn={true}
                      clearAction={this.handleClearSelection}
                      selectedTeam={this.state.selectedTeamVal}
                      selectedRole={this.state.selectedRoleVal}
                      selectedTitle={this.state.selectedTitleVal}
                    />
                  }
                  </Box>
                  
                </Box>
                <Box className="modal-footer">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Apply Filters"
                    onClick={this.handle_modalClose}
                  >
                    {this.t(`${configJSON.txtApplyFilters}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
                  
        <Box className={classes.tabWrapper}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={this.state.tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={this.tabsChangeHandler}
                  aria-label="lab API tabs example"
                  data-testid="tabChange"
                >
                  <Tab label={this.t(`Teams`)} value="1" />
                  <Tab label={this.t(`Members`)} value="2" />
                </TabList>
              </Box>

              {/* team tab */}
              <TabPanel value="1">
                <Box className="table-top">
                  <Box className="top-left">
                    <Box className="search-wrapper">
                      <Input
                        placeholder={this.t(`${configJSON.searchHereToTypeTxt}`)}
                        type="search"
                        onChange={this.handleTeamSearch}
                        value={this.state.searchValue}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        data-testid="teamSearch"
                      />
                    </Box>
                    <TeamSort
                      teamStateName={"selectedTeamFilter"}
                      selectTeamOptions={this.state.teamsList}
                      classes={classes}
                      onSelectedOptions={this.saveSelectedTeamsOption}
                      clearAction={this.handleClearSelectionTeam}
                      selectedTeam={this.state.selectedTeamFilter}
                      t={this.t}
                    />
                  </Box>
                  <Box className="top-right">
                    <FormControl className="select-control">
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                        }}
                        onChange={this.changeSortByTeams}
                        displayEmpty
                        defaultValue=""
                        data-testid="sortTeams"
                      >
                        <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                        <MenuItem value={1}>A to Z</MenuItem>
                        <MenuItem value={2}>Z to A</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                {this.listTeamsData()}
              </TabPanel>

              {/* member tab */}
              <TabPanel value="2">
                <Box className="table-top">
                  <Box className="top-left">
                    <Box className="search-wrapper">
                      <Input
                        placeholder={this.t(`${configJSON.searchHereToTypeTxt}`)}
                        type="search"
                        onChange={this.handleMemberSearch}
                        value={this.state.searchValue}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        data-testid="memberSearch"
                      />
                    </Box>
                    {/* <TeamMembersSearch /> */}
                    <TeamMembersSort
                      selectTeamOptions={this.state.teamsList}
                      selectJobOptions={this.state.membersList}
                      classes={classes}
                      onSelectedOptions={this.saveSelectedOption}
                      hideDeactivateColumn={true}
                      clearAction={this.handleClearSelection}
                      selectedTeam={this.state.selectedTeamVal}
                      selectedRole={this.state.selectedRoleVal}
                      selectedTitle={this.state.selectedTitleVal}
                    />
                  </Box>
                  <Box className="top-right">
                    <FormControl className="select-control">
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                        }}
                        onChange={this.changeSortByMembers}
                        displayEmpty
                        defaultValue=""
                        data-testid="sortMembers"
                      >
                        <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                        <MenuItem value={1}>A to Z</MenuItem>
                        <MenuItem value={2}>Z to A</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                {this.memberTeamsData()}
              </TabPanel>
            </TabContext>
          </Box>
        </Box>

        {this.getReuploadButton()}
      </Box>
  );
  }

  getInventorySecondStepContent = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.teamModuleWrapper}>
        <Box className="heading-wrapper">
          <Box className="heading-left">
            <Typography className="heading" variant="h1">
              {this.state.inventoryData.length}  {this.t(`${configJSON.productFoundText}`)}
            </Typography>
            <Box component="p" className="sub-txt">
            {this.t(`${configJSON.bulkSubText}`)}
            </Box>
          </Box>
        </Box>

        {this.searchContent()}     
        <Box className={classes.tabWrapper}>
          <Box sx={{ width: "100%" }}>            
            <Box className="table-top">
              <Box className="top-left">
                <Box className="search-wrapper">
                  <Input
                    placeholder={this.t(`${configJSON.searchHereToTypeTxt}`)}
                    type="search"
                    onChange={this.handleTableSearch}
                    value={this.state.searchValue}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    data-testid="filterSearch"
                  />
                </Box>
                <Box className="top-middle">
                  <Box className="table-selection">
                    {this.state.inventoryFilters.map((filter)=>{
                      return (
                      <FormControl className="select-control multi-select" variant={true ? 'outlined' : 'standard'}>
                        {filter.selected.length > 1 && (
                          <ClearIcon onClick={()=>this.clearFilter(filter.name)} className="multiple-clear" data-testid={`clearFilter-${filter.name}`}/>
                        )}
                        <InputLabel className="multiselect-label" id="team-multiple-checkbox">{this.t(filter.name)}</InputLabel>
                        <Select
                          labelId="team-multiple-checkbox-label"
                          id="team-multiple-checkbox"
                          multiple
                          value={filter.selected}
                          defaultValue={[]}
                          onChange={(event, type) =>
                            this.filterChange(event, filter.name)
                          }
                          renderValue={() => this.getFilterLabel(filter.selected, this.t(filter.name))}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: `${classes.dropdownStyle} multiSelect` },
                          }}
                          displayEmpty
                          data-testid={`inventoryFilter-${filter.name}`}
                        >
                          <ListSubheader>
                            <CustomInputWeb
                              placeholder={this.t("Search here")}
                              fullWidth={true}
                              autoFocus={true}
                              onChange={(e)=>this.setFilterSearch(e,filter.name)}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              value={filter.search}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
    
                          {this.getFilteredOptions(filter.name,filter.options,filter.search).map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              <ListItemText primary={option.value} />
                              <Box className="multiselect-check">
                                <img src={checkBlack} alt="sort-group" />
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      )
                    })}
                  </Box>
                </Box>                
              </Box>
              <Box className="top-right">
                <FormControl className="select-control">
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    onChange={this.changeSortByInventory}
                    displayEmpty
                    defaultValue=""
                    data-testid="sortInventory"
                  >
                    <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
                    <MenuItem value={1}>{this.t(`${configJSON.aToZTxt}`)}</MenuItem>
                    <MenuItem value={2}>{this.t(`${configJSON.zToATxt}`)}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <InventoryTableView
              headers={this.state.inventoryHeader}
              rows={this.getDataForTableByFilterAndSort()}
              subCategory={this.state.inventorySubCategory}
              category={this.state.inventoryCategory}
              rowsUpdate={this.setRemainingRows}
              t={this.t}
            />
          </Box>
        </Box>

        {this.getReuploadButton()}
      </Box>
  );
  }

  searchContent = () => {
    const {isInventory} = this.state
    return (
      <Box className="search-filter-sm">
          <Box className="search-wrapper">
            <CustomInputWeb
              type="search"
              value={this.state.searchValue}
              label=""
              placeholder={this.t(`${configJSON.searchHearTxt}`)}
              onChange={this.searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
          {!isInventory && 
            <>
              <Box className="sort-group">
                <img src={sortIcon} alt="sort-group" onClick={this.changeSortByResponsive} />
              </Box>
              
              <Box className="filter-group" onClick={this.filterTeamHandler}>
                <img src={filterSmIcon} alt="filter-group" />
              </Box>
            </>
          }
        </Box>
    )
  }
  getReuploadButton = () => {
    const { classes } = this.props;
    return (
      <Box className="sm-visible stepper-action">
          <Box className="button-wrapper">
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              title="Reupload"
              onClick={this.reUploadFile}                  >                    
              {this.t(`${configJSON.reuploadTxt}`)}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title={this.state.isInventory?"Confirm":"Confirm and Pay"}
              onClick={this.confirmClick}
              data-testid="confirmButton"
            >                    
              {this.t(`${this.state.isInventory?"Confirm":configJSON.confirmAndPayTxt}`)}
            </Button>
          </Box>
        </Box>
    )
  }

  getUploadedFiles = () => {
    const {classes} = this.props;

    return (
      (!this.state.isFileLoading || this.state.isInventory) && this.state.filesUploaded && 
        <List className={classes.uploadFileList}>
          {this.state.filesUploaded.map((file,index)=>{
            return(
            <ListItem key={`file-${index}`}>
            <Box className="file-info">
              <Box className="file-name">
                {file.name}
              </Box>
              <Link
                className="close"
                to="#"
                onClick={()=>this.cancelParsing(index)}
                data-testid="removeFileBtn"
              >
                <CloseIcon />
              </Link>
            </Box>
          </ListItem>
          )
          })}
          
        </List>      
    )
  }
  getStepContent = (step: any) => {
    const { classes } = this.props;
    const steps = getSteps(this.state.activeStep,this.t);
    const { activeStep } = this.state;

    switch (step) {
      case 0:
        return (
            <Box className="member-upload-content">
              <Typography className="heading" variant="h1">
                {this.t(`${configJSON.txtBulkUploadTitle}`)}
              </Typography>
              <Box component="p" className="sub-txt">                
                {this.t(`${configJSON.downloadTemplateTxt}`)}
              </Box>
              <FileDrop
                onDrop={this.handleOnDrop}
                onDragOver={this.dragEnter}
                onDragLeave={this.dragLeave}
              >
                <Box
                  className={`${classes.fileUploadWrapper} ${
                    this.state.fileDrop && classes.fileDropDrag
                  } `}
                  onClick={this.onBrowse}
                >
                  <Box className="upload-icon">
                    <img src={uploadImg} alt="uploadImg" />
                  </Box>
                  <form ref={this.formRef}>
                    <input
                      name="upload-photo"
                      accept=".csv"
                      type="file"
                      onChange={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        this.handleOnDrop(e.target.files);
                      }}
                      hidden
                      ref={this.fileRef}
                      data-testid="fileInput"
                      multiple={this.state.isInventory}
                    />
                  </form>
                  <Box className="file-upload-block">
                    <Typography className="file-heading" variant="h2">                      
                      {this.t(`${configJSON.fileDropTxt}`)} 
                    </Typography>
                    <Box component="p" className="file-subtxt">
                    {this.t(`${configJSON.orTxt}`)} 
                      <Box component="span" className="browse-link">
                      {this.t(`${configJSON.browseTxt}`)}
                      </Box>
                      {this.t(`${configJSON.chooseFileTxt}`)}                      
                    </Box>
                  </Box>
                </Box>
              </FileDrop>
              {this.state.isFileLoading &&
                <Box className={classes.uploadProgress}>
                  <Box
                    className="progress-status"
                    style={{ width: `${this.state.progress}%` }}
                  ></Box>
                  <Box className="progress-row">
                    <Box className="left-col">
                      <Typography className="progress-heading" variant="h2">                        
                        {this.t(`${configJSON.uploadingTxt}`)} 
                      </Typography>
                      <Box component="p" className="progress-subtxt">
                        <Box component="span" className="completed">
                          {this.state.progress}%
                        </Box>
                        <Box component="span" className="remaining">
                          {this.state.timeRemain} {this.t(`${configJSON.secondsRemainingTxt}`)}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="right-col">
                      <Link
                        to="#"
                        className="play-pause"
                        onClick={this.pauseResumeUpload}
                      >
                        {this.state.isPause ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Link>
                      <Link
                        to="#"
                        className="close"
                        onClick={this.cancelUpload}
                      >
                        <CloseIcon />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              }
              {this.getUploadedFiles()}
              <Box className="button-wrapper">
                <Button
                  onClick={this.state.isInventory?this.handleDownloadModalOpen:this.downloadCsvTemplate}
                  className={`secondary-btn ${classes.secondaryButton}`}
                  data-testid="downloadTemplate"
                >                  
                  {this.t(`${configJSON.downloadTemplatesTxt}`)}
                </Button>
                {this.downloadModal(classes)}
                <Button
                  onClick={this.handleNext}
                  className={`primary-btn ${classes.primaryButton}`}
                  data-testid="nextBtn"
                >
                  {activeStep === steps.length - 1 ? `${this.t(configJSON.finishTxt)}` : `${this.t(configJSON.continoueTxt)}`}
                </Button>
              </Box>
            </Box>
        );
      case 1:        
         return this.getSecondStepContent() 
      case 2:
        return `${this.t(configJSON.confirmAndPayTxt)} 2`;
      default:
        return this.t(`${configJSON.confirmAndPayTxt}`);
    }
  };

downloadModal=(classes:any)=>{
  return(
      (
        <Modal
          open={this.state.downloadModalOpen}
          onClose={this.handleDownloadModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
        >
          <Box className={`${classes.modalDialogBox}`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography
                    className="modal-title"
                    component="h2"
                >
                  Download Template
                </Typography>
                <Link
                  to="#"
                  className="close-icon"
                  data-testid="download-close"
                  onClick={this.handleDownloadModalClose}
                >
                  <CloseIcon />
                </Link>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                  Please choose the item type
                </Box>
                <List className="download-template-list">
                  <ListItem key="master" className="filtersFormControl">
                    <Box className="categoryBox">
                      <Box className="left-content">
                        <ListItemIcon>
                          <img
                            src={noInvetoryImg}
                            alt="product img"
                            className="categoryImg"
                          />
                        </ListItemIcon>
                        <Typography className="categoryText">
                          Master
                        </Typography>
                      </Box>                
                      <Link
                        to="#"
                        className="download-link"
                        data-testid={`download-template-master`}
                        onClick={this.downloadMasterTemplate}
                      >
                        Download
                      </Link>
                    </Box> 
                  </ListItem>
                  {this.inventoryFiletypes.map((itemType)=>{
                    return (
                      <ListItem key={itemType.type} className="filtersFormControl"> 
                        <Box className="categoryBox">
                          <Box className="left-content">
                            <ListItemIcon>
                              <img
                                src={itemType.img}
                                alt="product img"
                                className="categoryImg"
                              />
                            </ListItemIcon>
                            <Typography className="categoryText">
                              {itemType.name}
                            </Typography>
                          </Box>                
                          <Link
                            to="#"
                            className="download-link"
                            data-testid={`download-template-${itemType.type}`}
                            onClick={()=>this.downloadInventoryTemplate(itemType.type,itemType.name)}
                          >
                            Download
                          </Link>
                        </Box> 
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) 
  )
}

opensubscriptionmodal = () => {
  return (
    this.state.isCartOpen && (
      <SubscriptionScreenWeb
        type="Bulk-upload"
        navigation={undefined}
        id="subscription-screen-id"
        data-test-id="subscription-screen"
        isCartOpen={this.state.isCartOpen}
        handleCloseCartEvent={this.handleCloseCart}
        responseMember={this.state.responseAddedMembers}
        invitedMember={this.state.currentTotalAddedMembers}
      />
    )
  );
};

  render() {
    const { classes } = this.props;
    const steps = getSteps(this.state.activeStep,this.t);
    const { activeStep } = this.state;
    const maxSteps = steps.length;
    return (
      <>
        {this.state.showLoader && <WebLoader />}
        <ConfirmActionModal
          isOpen={this.state.isDeleteMembers}
          handleClose={this.handleClose}
          modalConfirmAction={this.membersDelete}
          deleteTeamId=""
          modalMessage={this.t(`${configJSON.deleteMsg}`)}
          confirmBtnTxt={this.t(`${configJSON.continoueTxt}`)}
          modalHeading={this.t(`${configJSON.deleteMemberTxt}`)}
          data-testid="confirmRemoveMember"
        />
        <ConfirmActionModal
          isOpen={this.state.isDeleteFile}
          handleClose={this.handleClose}
          modalConfirmAction={this.removeFile}
          deleteTeamId=""
          modalMessage={this.t(`${configJSON.confirmFileRemoval}`, { fileName: "testFile" })}
          confirmBtnTxt={this.t(`${configJSON.continoueTxt}`)}
          modalHeading={this.t(`${configJSON.deleteFile}`)}
          data-testid="confirmRemoveFile"
        />
        <Box className={classes.stepperRoot}>
          <Box className="stepper-top">
            <Box className="stepper-row">
              <Box className="stepper-col">
                <Box className="back-btn-wrap" onClick={this.handleBack}>
                  {this.state.activeStep === 1 ? <img src={imgLogo} className="auth-logo" /> :
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                    </svg>
                  </i>}
                </Box>
              </Box>
              <Box className="stepper-col stepper-middle">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const props = { completed: false };
                    const labelProps = {};
                    if (this.isStepCompleted(index)) {
                      props.completed = true;
                    }
                    return (
                      <Step
                        key={`step-${index.toString()}`}
                        {...props}
                        className={`step-root ${
                          activeStep == index ? "active-step-root" : ""
                        }`}
                      >
                        <StepLabel
                          {...labelProps}
                          StepIconComponent={ColorlibStepIcon}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={<></>}
                  backButton={<></>}
                  className="sm-visible"
                />
              </Box>
              {this.state.activeStep > 0 && (
                <Box className="stepper-col stepper-action">
                  <Box className="button-wrapper">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="Reupload"
                      onClick={this.reUploadFile}
                    >
                     {this.t(`${configJSON.reuploadTxt}`)}
                    </Button>
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title={this.state.isInventory?"Confirm":"Confirm and Pay"}
                      onClick={this.confirmClick}
                      data-testid="confirmButton"
                    >
                      {this.t(`${this.state.isInventory?"Confirm":configJSON.confirmAndPayTxt}`)}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="step-content-root">
            {activeStep === steps.length ? (
              <Box className="step-content">
                <Typography className="heading">                  
                  {this.t(`${configJSON.finishMsg}`)}
                </Typography>
              </Box>
            ) : (
              <Box className="step-content">
                {this.getStepContent(activeStep)}
              </Box>
            )}
          </Box>
        </Box>
        {this.opensubscriptionmodal()}
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(BulkUpload);
// Customizable Area End
