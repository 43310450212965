// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { configJSON } from "../../../customisableuserprofiles/src/CustomisableUserProfilesController.web";

interface EmailEditProps {
  classes: any;
  //modalOpen: boolean;
  modalClose: () => void;
  backToEdit: () => void;
  saveNewEmail: (value: string) => void;
  updateMemberEmail: () => void;
  allStates: any;
  pageType?: string;
  t?:any;
}

const EmailEdit = (props: EmailEditProps) => {
  const {
    classes,
    modalClose,
    backToEdit,
    saveNewEmail,
    updateMemberEmail,
    allStates,
    pageType,
    t
  } = props;

  const zeroPad = (num: any, places: any) => String(num).padStart(places, "0");
  const resendMessage = allStates.resendSec > 0
  ? `${t(configJSON.resendIn)} 00:${zeroPad(allStates.resendSec, 2)} `
  : `${t(configJSON.didntReceiveEmail)}`;
  return (
    <Modal
      open={allStates.modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {t(`${configJSON.newEmail}`)}
          </Typography>
          <Link to="#" className="close-icon" onClick={modalClose} data-test-id="closeEmailModal">
            <CloseIcon />
          </Link>
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">            
            {t(`${configJSON.enterNewEmailTxt}`)}
          </Box>
          <Box className="modal-form" component="form">
            <CustomInputWeb
              type="text"
              isRequired={true}
              errors={t(`${allStates.newEmailAddressError}`)}
              success={t(`${allStates.emailSuccessMsg}`)}
              label={t(`${configJSON.emailAddress}`)}
              value={allStates.newEmailAddress}
              onChange={(event) => saveNewEmail(event.target.value)}
              data-test-id="emailInput"
            />
            <Box component="p" className="sub-txt">            
              {t(`${configJSON.confirmationTxt}`)}
            </Box>
            <Box className="modal-footer">
              <Box className={pageType === "account_page" ? "half-btn" : ""}>
                {pageType==="account_page" && (

                  <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  title="Back to Edit"
                  onClick={backToEdit}
                  >
                  {t(`${configJSON.backToEdit}`)}
                </Button>
                )}        
                {allStates.isResend ? (
                  <Button
                    className={classes.primaryButton}
                    onClick={updateMemberEmail}
                    data-testid="emailBtn"
                    disabled={allStates.disable}
                  >
                    {resendMessage}
                  </Button>
                ) : (
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={updateMemberEmail}
                    title="Send Confirmation"
                    data-testid="emailBtn"
                  >                    
                    {t(`${configJSON.sendConfirmation}`)}
                  </Button>
                )}
               </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailEdit;
// Customizable Area End
