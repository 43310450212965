import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import {deleteIcon} from "./assets";
import i18n from "../../../web/src/utilities/i18n";
const baseConfig = require("../../../framework/src/config");
import StorageProvider from "../../../framework/src/StorageProvider";
import { appendDateRange, buildEndpointUrl, exportCSVInventory } from "../../../components/src/ReusableFunctions";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
// Customizable Area Start
isOptionMenu: null | HTMLElement;
isProductChecked: boolean;
searchProducts: string;
isModalOpen: boolean;
isCategoriesModal: boolean;
isExportModalOpen: boolean;
isShortOption: null | HTMLElement;
checkedItems:string[];
inventoryItems:any[];
selectedinventoryItems:any[];
isLoading:boolean;
currentCategory:string;
showPriceAs:string;
topOffset:number;
searchCatalogue: string;
pageNumber:number;
hasMore:boolean;
sortBy:string;
sortByOrder:string;
brandList:any[];
targetList:any[];
grapeVarietyOptions:any[];
rawMaterialOptions:any[];
colorOptions:any[];
dosageOptions:any[];
containerVolumeOptions:any[];
productionProcessOptions:any[];
trophyNameOptions:any[];
categoryOptions:any[];
countryOptions:any[];
regionOptions:any[];
subRegionOptions:any[];
appellationTypeOptions:any[];
appellationOptions:any[];
certificationOptions:any[];
vesselMeasurementOptions:any[];
quantityUnitOptions:any[];
materialOptions:any[];
specificationOptions:any[];
areaUnitOptions:any[];
filterSearch : FilterSearch;
checkedFilters : CheckedFilters;
priceLabelOptions:any;
countrySelected:string[];
regionSelected:string[];
moreFilter:{
  open:boolean;
  data: { [key: string]: FilterOptions[] };
  name:string;
  id:string
};
isDeletingProduct: boolean;
numberFilters:UNumberFilters
minMaxValues:NumberFilters
inputValues:NumberFilters
chooseContact: boolean;
isActiveFilter: boolean;
isFavouriteProductCreated: boolean;
contactLists:any[];
selectedContactLists:any[];
editProductIds:any,
expirationFilterDate: any;
revisionFilterDate: any;
categories:any;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start
export interface CheckedFilters {
  grapeVariety:string[],
  rawMaterial:string[],
  color:string[],
  dosage:string[],
  containerVolume:string[],
  productionProcess:string[],
  trophyName:string[],
  category:string[],
  appellation:string[],
  appellationType:string[],
  country:string[],
  vesselMeasurement:string[],
  quantityUnit:string[],
  material:string[],
  specification:string[];
  brand:string[];
  target:string[];
  areaUnit:string[];
  region:string[];
  subRegion:string[];
  certificationName:string[],
}

export interface FilterOptions {
  id:number,
  name:string
}

export interface FilterSearch {
  grapeVariety:string;
  rawMaterial:string;
  color:string;
  dosage:string;
  containerVolume:string;
  productionProcess:string;
  trophyName:string;
  category:string;
  appellation:string;
  appellationType:string;
  country:string;
  vesselMeasurement:string;
  quantityUnit:string;
  material:string;
  specification:string;
  brand:string;
  target:string;
  areaUnit:string;
  region:string,
  subRegion:string,
  certificationName:string,
 };

 export interface UNumberFilters {
  price?:number[],
  quantity?:number[],
  alcohol?:number[],
  ibu?:number[],
  srm?:number[],
  og?:number[],
  fg?:number[],
  ebc?:number[],
  hourly_cost?:number[],
  p_content?:number[],
  n_content?:number[],
  k_content?:number[],
  cost?:number[],
  stock_qty?:number[],
  age?:number[],
  aging_potential?:number[],
 }

 export interface NumberFilters {
  price:number[],
  quantity:number[],
  alcohol:number[],
  ibu:number[],
  srm:number[],
  og:number[],
  fg:number[],
  ebc:number[],
  hourly_cost:number[],
  p_content:number[],
  n_content:number[],
  k_content:number[],
  cost:number[],
  stock_qty:number[],
  age:number[],
  aging_potential:number[]
 }

// Customizable Area End
export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getCatalogueApiId: string = "";
  filterCatalogueApiId:string="";
  cloneCatalogueApiId:string="";
  deleteCatalogueApiId:string="";
  fetchBrandListApiId:string="";
  fetchTargetListApiId:string="";
  fetchGrapeVarietyApiId:string="";
  fetchRawMaterialApiId:string="";
  fetchColorApiId:string="";
  fetchDosageApiId:string="";
  fetchContainerVolApiId:string="";
  fetchproductionProcessApiId:string="";
  fetchTrophyNameApiId:string="";
  fetchCountryApiCallId:string = "";
  fetchCategoryListApiId:string="";
  fetchRegionApiCallId:string = "";
  fetchAppellationTypeApiCallId:string = "";
  fetchSubRegionApiCallId:string = "";
  fetchAppellationApiCallId:string = "";
  fetchQuantityUnitApiCallId:string = "";
  fetchVesselMeasurementApiCallId:string = "";
  fetchCertificationApiCallId:string="";
  fetchMaterialApiCallId:string="";
  fetchSpecificationApiCallId:string="";
  fetchAreaUnitApiCallId:string = "";
  fetchNumberRangeApiID:string = "";
  fetchPriceLabelApiID:string = "";
  fetchAllContactsAPID:string = "";
  addFavouritesContactsAPID:string = "";
  getPermittedCategoriesApiId:string="";

  targetRef: any = React.createRef();

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      // Customizable Area Start
      isOptionMenu: null,
      isProductChecked: false,
      searchProducts: '',
      isModalOpen: false,
      isCategoriesModal: false,
      isExportModalOpen: false,
      isShortOption: null,
      inventoryItems: [],
      selectedinventoryItems:[],
      checkedItems: [],
      isLoading:false,
      currentCategory:"",
      showPriceAs:"",
      topOffset:0,
      searchCatalogue:"",
      pageNumber:1,
      hasMore:false,
      sortBy:"",
      sortByOrder:"desc",
      brandList:[],
      targetList:[],
      grapeVarietyOptions:[],
      rawMaterialOptions:[],
      colorOptions:[],
      dosageOptions:[],
      containerVolumeOptions:[],
      productionProcessOptions:[],
      trophyNameOptions:[],
      categoryOptions:[],
      countryOptions:[],
      regionOptions:[],
      subRegionOptions:[],
      appellationTypeOptions:[],
      appellationOptions:[],
      certificationOptions:[],
      vesselMeasurementOptions:[],
      quantityUnitOptions:[],
      materialOptions:[],
      specificationOptions:[],
      areaUnitOptions:[],
      expirationFilterDate: [],
      revisionFilterDate:[],
      filterSearch:{
        grapeVariety: "",
        rawMaterial:"",
        color:"",
        dosage:"",
        containerVolume:"",
        productionProcess:"",
        trophyName:"",
        category:"",
        appellation:"",
        appellationType:"",
        country:"",
        vesselMeasurement:"",
        quantityUnit:"",
        material:"",
        specification:"",
        brand:"",
        target:"",
        areaUnit:"",
        region:"",
        subRegion:"",
        certificationName:"",
      },
      checkedFilters:{
        grapeVariety: [],
        rawMaterial:[],
        color:[],
        dosage:[],
        containerVolume:[],
        productionProcess:[],
        trophyName:[],
        category:[],
        appellation:[],
        appellationType:[],
        country:[],
        vesselMeasurement:[],
        quantityUnit:[],
        material:[],
        specification:[],
        brand:[],
        target:[],
        areaUnit:[],
        region:[],
        subRegion:[],
        certificationName:[],
      },
      priceLabelOptions:[],
      countrySelected:[],
      regionSelected:[],
      moreFilter:{
        open: false,
        name:"",
        data:{"#":[]},
        id:""
      },
      isDeletingProduct:false,
      numberFilters:{
        price:undefined,
        quantity:undefined,
        alcohol:undefined,
        ibu:undefined,
        srm:undefined,
        og:undefined,
        fg:undefined,
        ebc:undefined,
        hourly_cost:undefined,
        p_content:undefined,
        n_content:undefined,
        k_content:undefined,
        cost:undefined,
        stock_qty:undefined,
        age:undefined,
        aging_potential:undefined,
      },
      minMaxValues:{
        price:[0,0],
        quantity:[0,0],
        alcohol:[0,0],
        ibu:[0,0],
        srm:[0,0],
        og:[0,0],
        fg:[0,0],
        ebc:[0,0],
        p_content:[0,0],
        hourly_cost:[0,0],
        k_content:[0,0],
        n_content:[0,0],
        stock_qty:[0,0],
        cost:[0,0],
        age:[0,0],
        aging_potential:[0,0]
      },
      inputValues:{
        price:[0,0],
        quantity:[0,0],
        alcohol:[0,0],
        ibu:[0,0],
        srm:[0,0],
        og:[0,0],
        fg:[0,0],
        ebc:[0,0],
        hourly_cost:[0,0],
        p_content:[0,0],
        n_content:[0,0],
        k_content:[0,0],
        cost:[0,0],
        stock_qty:[0,0],
        age:[0,0],
        aging_potential:[0,0],
      },
      chooseContact: false,
      isActiveFilter: false,
      isFavouriteProductCreated: false,
      contactLists:[],
      selectedContactLists:[],
      editProductIds:[],
      categories: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    // Customizable Area End
  }
  calculateOffset = () => {
    const scrollPosition = window.scrollY;
    
    if (this.targetRef.current) {
      const { top } = this.targetRef.current.getBoundingClientRect();
      if (scrollPosition >= top) {
        this.setState({
          topOffset: 0,
        });         
      } else {
        this.setState({
          topOffset: top,
        }); 
      }      
    }
    const distanceFromBottom =
    document.documentElement.scrollHeight -
    (window.innerHeight + window.scrollY);
  
        if (
          distanceFromBottom <100 && !this.state.isLoading && this.state.hasMore
        ) {
          this.setState({
            pageNumber:this.state.pageNumber+1
          },this.getCatalogue)
        }
  };
  async componentDidMount(): Promise<void> {
    this.getCatalogue();
    this.fetchBrandList();
    this.calculateOffset();
    this.fetchGrapeVariety();
    this.fetchRawMaterial();
    this.fetchColor();
    this.fetchDosage();
    this.fetchContainerVolume();
    this.fetchCountryList();
    this.fetchAppellationTypeList();
    this.fetchAppellationList();
    this.fetchProductionProcess();
    this.fetchTrophyName();
    this.fetchCategoryList();
    this.getCertificationList();
    this.fetchVesselUnitList();
    this.fetchQuantityUnitList();
    this.fetchMaterialList();
    this.fetchSpecificationList();
    this.fetchAreaUnitList();
    this.fetchTargetList();
    this.fetchNumberFilters();
    this.fetchPriceLabels();
    this.getPermitedCategoryList();
    window.addEventListener('resize', this.calculateOffset);
    window.addEventListener('scroll', this.calculateOffset);
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);

    let selectedCategory = await StorageProvider.get("lastFilter") ?? configJSON.ProductsStaticId.toString();
    this.setState({
      currentCategory:selectedCategory,     
    })
   }
 
   t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentWillUnmount(): Promise<void> {
    window.removeEventListener('resize', this.calculateOffset);
    window.removeEventListener('scroll', this.calculateOffset);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.currentCategory !== this.state.currentCategory){
      StorageProvider.set("lastFilter",this.state.currentCategory)
    }

    if(prevState.checkedFilters != this.state.checkedFilters){

      this.fetchRegionList()
      this.fetchSubRegionList()
    }  
    if (prevState.currentCategory !== this.state.currentCategory || prevState.sortBy !== this.state.sortBy || prevState.sortByOrder !== this.state.sortByOrder || prevState.checkedFilters !== this.state.checkedFilters || prevState.numberFilters !== this.state.numberFilters || this.state.showPriceAs !== prevState.showPriceAs) {
      this.setState({
        pageNumber:1,
      },this.getCatalogue)
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
              case this.filterCatalogueApiId:
                this.setInventoryData(responseJson);
                break;
              case this.cloneCatalogueApiId:
                this.setState({
                  pageNumber:1
                },this.getCatalogue)
                break;
              case this.deleteCatalogueApiId:
                this.toastNotification("Item(s) deleted successfully", deleteIcon);
                this.setState({
                  pageNumber:1,
                  isDeletingProduct:false,
                },this.getCatalogue)               
                break;
              case this.fetchBrandListApiId:
                this.setState({brandList:responseJson.data});               
                break;
              case this.fetchTargetListApiId:
                this.setState({targetList:responseJson});               
                break;
                
              case this.fetchGrapeVarietyApiId:
                this.setState({grapeVarietyOptions: responseJson.options});         
                break;              

                case this.fetchRawMaterialApiId:
                  this.setState({rawMaterialOptions: responseJson.options});             
                  break;
                case this.fetchColorApiId:
                  this.setState({colorOptions: responseJson.options});             
                  break;
                case this.fetchDosageApiId:
                  this.setState({dosageOptions: responseJson.options});             
                  break;
                case this.fetchContainerVolApiId:
                  this.setState({containerVolumeOptions: responseJson.options});             
                  break;
                case this.fetchproductionProcessApiId:
                  this.setState({productionProcessOptions: responseJson.options});             
                  break;
                case this.fetchTrophyNameApiId:
                  this.setState({trophyNameOptions: responseJson.options});             
                  break;
                case this.fetchCategoryListApiId:
                  this.setState({categoryOptions: responseJson.options});             
                  break;
                case this.fetchAppellationTypeApiCallId:
                  this.setState({appellationTypeOptions: responseJson.options});             
                  break;
                case this.fetchAppellationApiCallId:
                  this.setState({appellationOptions: responseJson.options});             
                  break;
                case this.fetchVesselMeasurementApiCallId:
                  this.setState({vesselMeasurementOptions: responseJson.options});             
                  break;
                case this.fetchQuantityUnitApiCallId:
                  this.setState({quantityUnitOptions: responseJson.options});             
                  break;    
                case this.fetchMaterialApiCallId:
                  this.setState({materialOptions: responseJson.options});             
                  break;  
                case this.fetchSpecificationApiCallId:
                  this.setState({specificationOptions: responseJson.options});             
                  break;  
                case this.fetchAreaUnitApiCallId:
                  this.setState({areaUnitOptions: responseJson.options});             
                  break; 
                case this.fetchCountryApiCallId:
                  this.setState({countryOptions: responseJson.country_names});             
                  break;
                case this.fetchRegionApiCallId:
                  if(responseJson.error === "Country not found"){
                    this.setState({regionOptions: []});             
                  } else{
                    this.setState({regionOptions: responseJson.regions}); 
                  }
                  break;
                case this.fetchSubRegionApiCallId:
                  this.setState({subRegionOptions: responseJson.sub_regions});             
                  break;

                case this.fetchCertificationApiCallId:
                  this.setState({certificationOptions: responseJson.data});             
                  break;
    
                case this.fetchPriceLabelApiID:
                  this.setState({priceLabelOptions: responseJson});             
                  break;

                case this.fetchNumberRangeApiID:
                  let arrayValues:NumberFilters=this.state.minMaxValues;
                  responseJson.number_filters.forEach((value:any)=>{
                    const name = value.type as keyof NumberFilters
                    arrayValues[name] = [value.minimum,value.maximum];                    
                  })

                  this.setState({
                    minMaxValues:arrayValues,
                    inputValues:arrayValues
                  })
                  break;
                case this.fetchAllContactsAPID:
                  this.setState({isLoading:false,contactLists : responseJson.data});
                 break;
                 case this.addFavouritesContactsAPID:
                  this.setState({isLoading:false, isFavouriteProductCreated: true,});                 
                break;
                case this.getPermittedCategoriesApiId:                  
                  this.setState({categories: responseJson.categories.map((category:any) => ({
                    name: category,
                    staticId: configJSON.staticIdMapping[category] || 1, 
                  }))});
                break;
   
          }
        } else {
        this.setState({ isLoading: false }); 
      } 
      }
    }else if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const propData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );

      this.setState({
        checkedItems:propData.itemsToSendEdit,
        isProductChecked:propData.itemsToSendEdit.length===propData.itemsToSendEditData.length,
        selectedinventoryItems: propData.itemsToSendEditData
      })
   }
    // Customizable Area End
  }

  setInventoryData = (responseJson:any) => {
    if(responseJson.catalogues){
      this.setState({inventoryItems:this.state.pageNumber==1?responseJson.catalogues.data:[...this.state.inventoryItems,...responseJson.catalogues.data],hasMore:responseJson.hasMore ?? false},()=>{
        if(this.state.selectedinventoryItems.length === 0){
          this.setState({ isLoading: false,checkedItems:this.state.pageNumber==1?[]:this.state.checkedItems,isProductChecked:false });
        } else {
          this.setState({ isLoading: false , isProductChecked:this.state.checkedItems.length===this.state.selectedinventoryItems.length})
        }
      })
    }
    else{
      this.setState({ isLoading: false,checkedItems:[],isProductChecked:false,inventoryItems:[]})
    }
    
  }
  getPermitedCategoryList=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPermitedCategoryEndpoint
    );
    this.getPermittedCategoriesApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );  
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  toastNotification = (messsage: string, iconToast?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${messsage}`)}</div>
        <a data-test-id="undo-toast-link" href="#" className="toast-link" >
         <CloseIcon />
        </a>
      </div>,
      {
        autoClose: 2000,
        icon: iconToast ? () => <img src={iconToast} alt="icon" /> : undefined,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  
handleProductCheckbox=(event:React.ChangeEvent<HTMLInputElement>)=>{
  const isChecked = event.target.checked;
  
  if (isChecked) {
    const allItems = this.state.inventoryItems.map((item: any) => item.id);
    this.setState({
      checkedItems: allItems,
      selectedinventoryItems: this.state.inventoryItems,
      isProductChecked: true
    });
  } else {    
    this.setState({
      checkedItems: [],
      selectedinventoryItems: [],
      isProductChecked: false
    });
  }
}

handleItemCheckbox=(event:React.ChangeEvent<HTMLInputElement>,itemId:string)=>{
  const {checkedItems,inventoryItems } = this.state;
  let itemsChecked = checkedItems;
  if(itemsChecked.includes(itemId)){
    itemsChecked = itemsChecked.filter((x:string)=>x!==itemId);
  }
  else{
    itemsChecked.push(itemId)
  }
  
  let filteredItems = inventoryItems.filter((item) => {
    return itemsChecked.includes(item.id);
  });
   
this.setState({checkedItems:itemsChecked,isProductChecked:itemsChecked.length == this.state.inventoryItems.length, selectedinventoryItems: filteredItems })
}

handleSearchProduct =(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
this.setState({searchProducts: event.target.value})
}

addInventoryOption =(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
this.setState({isOptionMenu: event.currentTarget})
}

sortByPrice =(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
this.setState({isShortOption: event.currentTarget})
}

optionModal =()=>{
this.setState({isOptionMenu: null})
}

closeSortModal =()=>{
this.setState({isShortOption: null})
}

handlefilterModal =()=>{
this.setState({isModalOpen: true})
}

closefilterModal =()=>{
this.setState({isModalOpen: false})
}

handleCategories =()=>{
this.setState({isCategoriesModal: true})
}

openExportModal = () => {
  this.setState({isExportModalOpen: true})
}
closeExportModal = () => {
  this.setState({isExportModalOpen: false})
}

closeCategories =()=>{
this.setState({isCategoriesModal: false});
this.getPermitedCategoryList();
}
toAddinvetory =()=>{
const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AddItem");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(message);
}
handleexpirationFilterDates = (date: any) => {
  this.setState({ expirationFilterDate: date }
  );
};
handleRevisionFilterDates = (date: any) => {
  this.setState({ revisionFilterDate: date }
  );
};
addFiltersToApi=(formdata:FormData)=>{
  Object.entries(this.state.checkedFilters).forEach(([key, valueArray]) => {
    if(["trophyName","target","brand","certificationName"].includes(key)) return;
    let filterName = key;
    let withMetaData = true;
    switch(key){
      case "containerVolume":
        filterName = "container_with_volume";
        break;
      case "productionProcess":
        filterName = "production_process";
        break;
      case "appellation":
        filterName = `location_attributes[appellation]`;
        withMetaData = false;
        break;
      case "appellationType":
        filterName = `location_attributes[appellation_type]`;
        withMetaData = false;
        break;
      case "country":
        filterName = `location_attributes[country]`;
        withMetaData = false;
        break;
      case "region":
        filterName = `location_attributes[region]`;
        withMetaData = false;
        break;
      case "subRegion":
        filterName = `location_attributes[sub_region]`;
        withMetaData = false;
        break;
      case "vesselMeasurement":
        filterName = "unit_of_measurement";
        break;
      case "quantityUnit":
        filterName = "quantity_unit";
        break;    
      case "areaUnit":
        filterName = "area_unit";
        break;
      case "grapeVariety":
        filterName = "grape_variety";
        break;
      case "rawMaterial":
        filterName = "raw_material";
        break;
        
    }
    if(valueArray.length>0){
      valueArray.forEach((value:string)=>{
        if(withMetaData){
          formdata.append(`meta_data[${filterName}][]`,value)
        }
        else{
          formdata.append(`${filterName}[]`,value)
        }
    })
    }    
  });
  appendDateRange(formdata, this.state.expirationFilterDate, "expiration");
  appendDateRange(formdata, this.state.revisionFilterDate, "revision");
  if(this.state.checkedFilters.trophyName.length>0){
    this.state.checkedFilters.trophyName.forEach(element => {
      formdata.append("trophies_names[]",element)
    });
  }
  if(this.state.checkedFilters.certificationName.length>0){
    this.state.checkedFilters.certificationName.forEach(element => {
      formdata.append("certification_names[]",element)
    });
  }
  

  
  
}

addNumberFiltersToApi=(formdata:FormData)=>{
  Object.entries(this.state.numberFilters).forEach(([key, valueArray]) => {
    if(valueArray){
      formdata.append(`${key}_min`,valueArray[0]);
      formdata.append(`${key}_max`,valueArray[1]);
    }
  })
  
}
getCatalogue=()=>{

  if(this.state.currentCategory == "") return;
  let formdata = new FormData();
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
    formdata.append("category_static_id",this.state.currentCategory)
    formdata.append("per_page","25")
    formdata.append("page_number",String(this.state.pageNumber))
    formdata.append("order",this.state.sortByOrder)
  

  if(this.state.sortBy!=""){
    formdata.append("sort_check",this.state.sortBy)
  }
    formdata.append("price_type",this.state.showPriceAs)
  
  this.addFiltersToApi(formdata)
  this.addNumberFiltersToApi(formdata)
  if(this.state.checkedFilters.brand.length>0){
    this.state.checkedFilters.brand.forEach((value:string)=>{
      formdata.append("brand_names[]",value)
    })
  }
  if(this.state.checkedFilters.target.length>0){
    this.state.checkedFilters.target.forEach((value:string)=>{
      formdata.append("target_names[]",value)
    })
  }

  const header = {
    token: this.userToken.meta.token,
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.filterCatalogue
  );
  this.filterCatalogueApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  this.setState({ isLoading: true });
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

changeCategory = (event:any) =>{
  const { value } = event.target;
    this.setState({
      currentCategory:value
    });
}

changeCategoryTabs = (event:any,tabValue:string) =>{
  this.setState({
    currentCategory:tabValue
  });
}


viewNavigate=(event:any,itemId:any)=>{ 
  if(event.target.type !== 'checkbox' && event.target instanceof HTMLElement){
    this.props.navigation.navigate("ViewItem",{":itemId":itemId});
  }
}

changePrice = (event:any) => {
  this.setState({showPriceAs:event.target.value})
}
filterItem = () => {
  let catalogue = this.state.inventoryItems;
  if (this.state.searchCatalogue !== "") {
    catalogue = catalogue.filter((item) => {
      if (
        item.attributes.name && item.attributes.name
          .toLowerCase()
          .search(this.state.searchCatalogue.toLowerCase()) !== -1
      )
        return catalogue;
    });
  }
  return catalogue
};

isOwner = () => {
  return this.userToken?.data.attributes.job_type.toLowerCase() === "owner";
};

cloneItem=()=>{

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  let catalgoueIds = "";
  this.state.checkedItems.forEach((id)=>{
    if(catalgoueIds!=""){
      catalgoueIds += '&';
    }
    catalgoueIds += `catalogue_ids[]=${id}`
  })
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.cloneAPIEndPoint+ `?${catalgoueIds}`
  );
  this.cloneCatalogueApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deleteItem = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteCatalogueApiId = requestMessage.messageId;

  const body = {
    catalogues_ids: this.state.checkedItems.map(x=>Number(x))
  };
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.removeItemAPIEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

generateCataloguePDF = () => {
  let catalgoueIds = "";
  this.state.checkedItems.forEach((id)=>{
    if(catalgoueIds!=""){
      catalgoueIds += '&';
    }
    catalgoueIds += `catalogue_ids[]=${id}`
  })
  let url = baseConfig.baseURL+"/" + configJSON.generatePDFAPIEndPoint+ `?${catalgoueIds}`;

  fetch(url, {
    headers: { token: this.userToken.meta.token },
    method: "GET",
  })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blobPdf) {
      const pdfLink = document.createElement("a");
      const pdfURL = window.URL.createObjectURL(blobPdf);
      pdfLink.href = pdfURL;
      pdfLink.setAttribute(
        "download",
        `Catalogue_${Math.floor(
          new Date().getTime() + new Date().getSeconds() / 2
        )}.pdf`
      ); //or any other extension
      document.body.appendChild(pdfLink);
      pdfLink.click();
      document.body.removeChild(pdfLink);
    })
    .catch(() => {
      this.toastNotification("Something wen't wrong. Please try again");
    });
};

changeSortBy = (e:any) => {
  switch(e.target.value){
    case configJSON.SortOption1:
      this.setState({sortBy:"",sortByOrder:"desc"})
      break;
    case configJSON.SortOption2:
      this.setState({sortBy:"price",sortByOrder:"desc"})
      break;
    case configJSON.SortOption3:
      this.setState({sortBy:"price",sortByOrder:"asc"})
      break;
    case configJSON.SortOption4:
      this.setState({sortBy:"name",sortByOrder:"asc"})
      break;
    case configJSON.SortOption5:
      this.setState({sortBy:"name",sortByOrder:"desc"})
      break;
  }
}
fetchBrandList=()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.brandEndPoint
  );
  this.fetchBrandListApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

fetchGrapeVariety = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchGrapeVarietyApiId = requestMessage.messageId;
  this.getSelcectorApi("grape variety",requestMessage); 
}
fetchRawMaterial = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchRawMaterialApiId = requestMessage.messageId;
  this.getSelcectorApi("raw material",requestMessage); 
}
fetchColor = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchColorApiId = requestMessage.messageId;
  this.getSelcectorApi("color",requestMessage); 
}
fetchDosage = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchDosageApiId = requestMessage.messageId;
  this.getSelcectorApi("dosage",requestMessage); 
}
fetchContainerVolume = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchContainerVolApiId = requestMessage.messageId;
  this.getSelcectorApi("container with volume",requestMessage); 
}
fetchProductionProcess = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchproductionProcessApiId = requestMessage.messageId;
  this.getSelcectorApi("production process",requestMessage); 
}
fetchTrophyName = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchTrophyNameApiId = requestMessage.messageId;
  this.getSelcectorApi("trophy_name",requestMessage); 
}
fetchCategoryList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchCategoryListApiId = requestMessage.messageId;
  this.getSelcectorApi("category",requestMessage); 
}
fetchAppellationList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchAppellationApiCallId = requestMessage.messageId;
  this.getSelcectorApi("appellation",requestMessage); 
}
fetchAppellationTypeList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchAppellationTypeApiCallId = requestMessage.messageId;
  this.getSelcectorApi("appellation_type",requestMessage); 
}
fetchVesselUnitList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchVesselMeasurementApiCallId = requestMessage.messageId;
  this.getSelcectorApi("vessel measurement",requestMessage); 
}
fetchQuantityUnitList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchQuantityUnitApiCallId = requestMessage.messageId;
  this.getSelcectorApi("quantity measurement",requestMessage); 
}
fetchAreaUnitList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchAreaUnitApiCallId = requestMessage.messageId;
  this.getSelcectorApi("area measurement",requestMessage); 
}

fetchMaterialList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchMaterialApiCallId = requestMessage.messageId;
  this.getSelcectorApi("material",requestMessage); 
}
fetchSpecificationList= () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchSpecificationApiCallId = requestMessage.messageId;
  this.getSelcectorApi("specifications",requestMessage); 
}





getSelcectorApi = (name:string,requestMessage:Message) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };  

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.productsDropdownAPIEndPoint + name
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name as keyof S;
  const value = event.target.value;
  this.setState({
    filterSearch:{
      ...this.state.filterSearch,
      [name]: value
    }
  } as unknown as Pick<S, keyof S>);
  if(this.state.moreFilter.open){
    this.setState({
      moreFilter:{
        ...this.state.moreFilter,
        data:this.getMoreFilterData(this.getOptionsforFilter(name,value))
      },
    })
  }
};

getOptionsforFilter = (name: string, search: string) => {
  const filterOptions = (options:any) => {
    return options.filter((item:any) => {
      return item.name && item.name.toLowerCase().includes(search.toLowerCase());
    });
  };

  switch (name) {
    case "grapeVariety":
      return filterOptions(this.state.grapeVarietyOptions);
    case "rawMaterial":
      return filterOptions(this.state.rawMaterialOptions);
    case "color":
      return filterOptions(this.state.colorOptions);
    case "dosage":
      return filterOptions(this.state.dosageOptions);
    case "containerVolume":
      return filterOptions(this.state.containerVolumeOptions);
    case "productionProcess":
      return filterOptions(this.state.productionProcessOptions);
    case "trophyName":
      return filterOptions(this.state.trophyNameOptions);
    case "category":
        return filterOptions(this.state.categoryOptions);
    case "appellation":
      return filterOptions(this.state.appellationOptions);
    case "appellationType":
      return filterOptions(this.state.appellationTypeOptions);
    case "vesselMeasurement":
      return filterOptions(this.state.vesselMeasurementOptions);
    case "quantityUnit":
      return filterOptions(this.state.quantityUnitOptions);
    case "material":
      return filterOptions(this.state.materialOptions);
    case "specification":
      return filterOptions(this.state.specificationOptions);
    case "areaUnit":
      return filterOptions(this.state.areaUnitOptions);
      
    case "brand":
      return this.state.brandList.filter((brand:any)=>{
        return brand.attributes.name?.toLowerCase().includes(search.toLowerCase());
      }).map((brand:any)=>{
        return {
          id:brand.id,
          name:brand.attributes.name
        }
      }); 
      
    case "certificationName":
      return this.state.certificationOptions.filter((certificate:any)=>{
        return certificate.attributes.name?.toLowerCase().includes(search.toLowerCase());
      }).map((certificate:any)=>{
        return {
          id:certificate.id,
          name:certificate.attributes.name
        }
      });  
    case "target":
      return this.state.targetList.filter((target:any)=>{
        return target.name?.toLowerCase().includes(search.toLowerCase());
      }).map((target:any)=>{
        return {
          id:target.id,
          name:target.name
        }
      });  
      
      
    case "country":
    return this.state.countryOptions.filter((item:any) => {
      return item.toLowerCase().includes(search.toLowerCase());
    }).map((val:string,index:number)=>{
      return {
        id:index+1,
        name:val
      }
    }); 
    case "region":
      return this.state.regionOptions.filter((item:any) => {
        return item.toLowerCase().includes(search.toLowerCase());
      }).map((val:string,index:number)=>{
        return {
          id:index+1,
          name:val
        }
      });   
      case "subRegion":
        return this.state.subRegionOptions.filter((item:any) => {
          return item.toLowerCase().includes(search.toLowerCase());
        }).map((val:string,index:number)=>{
          return {
            id:index+1,
            name:val
          }
        }); 
      
    default:
      return [];
  }
};

handleCheckBoxChange=(name:string ,event:React.ChangeEvent<HTMLInputElement>,value:string)=>{
  const filterName = name as keyof CheckedFilters
  let checked:string[] = this.state.checkedFilters[filterName];
  if(event.target.checked){
    checked.push(value)
  }
  else{
    checked = checked.filter(x=>x!=value)
  }
  this.setState({
    checkedFilters:{
      ...this.state.checkedFilters,
      [name]: checked
    }
  } as unknown as Pick<S, keyof S>);
}

fetchCountryList = () => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchCountryApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.countryAPIEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

fetchRegionList = () => {
  if(this.state.checkedFilters.country.length ==0)
  {
    this.setState({regionOptions:[]})
    return;
  }
  
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchRegionApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  let countryName = "";
  this.state.checkedFilters.country.forEach((country)=>{
    countryName+=`country_names[]=${country}&`
  })
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.filterCountryRegionsAPIEndpoint +  countryName
 
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

fetchSubRegionList = () => {
  if(this.state.checkedFilters.country.length ==0 || this.state.checkedFilters.region.length ==0 )
  {
    this.setState({subRegionOptions:[]})
    return;
  }
  
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  let regionName = "";
  this.state.checkedFilters.region.forEach((region)=>{
    regionName+=`region[]=${region}&`
  })
  let countryName = regionName;
  this.state.checkedFilters.country.forEach((country)=>{
    countryName+=`country_names[]=${country}&`
  })

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.filterRegionSubregionAPIEndpoint + countryName
  );
  
  this.fetchSubRegionApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};



getCertificationList = () => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.certificationAPIEndpoint
  );

  this.fetchCertificationApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

fetchTargetList=()=>{

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.targetEndPoint
  );
  this.fetchTargetListApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

clearFilters = () => {
  this.setState({
    checkedFilters:{
      areaUnit:[],
      brand:[],
      color:[],
      dosage:[],
      containerVolume:[],
      productionProcess:[],
      trophyName:[],
      category:[],
      rawMaterial:[],
      vesselMeasurement:[],
      appellationType:[],
      country:[],
      quantityUnit:[],
      appellation:[],
      material:[],
      specification:[],
      grapeVariety: [],
      target:[],
      region:[],
      subRegion:[],
      certificationName:[]
      
    },
    filterSearch:{
      vesselMeasurement:"",
      rawMaterial:"",
      dosage:"",
      appellation:"",
      containerVolume:"",
      productionProcess:"",
      trophyName:"",
      category:"",
      grapeVariety: "",
      appellationType:"",
      country:"",
      areaUnit:"",
      quantityUnit:"",
      material:"",
      specification:"",
      brand:"",
      target:"",
      color:"",
      region:"",
      subRegion:"",
      certificationName:"",
    },
    numberFilters:{
      price:undefined,
      quantity:undefined,
      alcohol:undefined,
      ibu:undefined,
      srm:undefined,
      og:undefined,
      fg:undefined,
      ebc:undefined,
      hourly_cost:undefined,
      p_content:undefined,
      n_content:undefined,
      k_content:undefined,
      cost:undefined,
      stock_qty:undefined,
      age:undefined,
      aging_potential:undefined,
    },    
    inputValues:this.state.minMaxValues
  })
}

fetchPriceLabels = () => {  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  this.fetchPriceLabelApiID = requestMessage.messageId;

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getPriceLabelAPIEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
showMoreModalOpen=(name:string,options:any)=>{
  
  this.setState({
    moreFilter:{
      open:true,
      name:name,
      data:this.getMoreFilterData(options),
      id:""
    },
  })

}

getMoreFilterData = (options:any) => {
  const onlyAlpha = new RegExp(/^[a-zA-Z]/)
  options = options.sort((data1: any, data2: any) => {
    const startsWithLetter = (str: string) => onlyAlpha.test(str);

    const isData1Letter = startsWithLetter(data1.name);
    const isData2Letter = startsWithLetter(data2.name);

    if (!isData1Letter && isData2Letter) {
        return -1; // data1 comes before data2
    } else if (isData1Letter && !isData2Letter) {
        return 1; // data2 comes before data1
    } else {
        // Both start with a letter or both don't, continue with regular comparison
        const name1 = data1.name.toLowerCase();
        const name2 = data2.name.toLowerCase();
        
        if (name1 < name2) {
            return -1;
        } else if (name1 > name2) {
            return 1;
        } else {
            return 0; // names are equal
        }
    }
});
  let groupedData = options.reduce((acc:any, obj:any) => {
    const firstChar = obj.name.charAt(0).toUpperCase();
    if (onlyAlpha.test(firstChar)) { // Group by alphabet
      if (!acc[firstChar]) {
        acc[firstChar] = [];
      }
      acc[firstChar].push(obj);
    } else { // Group by non-alphabet
      if (!acc['#']) {
        acc['#'] = [];
      }
      acc['#'].push(obj);
    }
    return acc;
  }, {});
  
  return groupedData;
}

closeMorefilterModal=()=>{
  this.setState({
    moreFilter:{
      open:false,
      name:"",
      data:{"#":[]},
      id:""
    }
  })
}

scrollToDiv = (id:string) => {
  const element = document.getElementById(id);
  if (element) {
  element.scrollIntoView({ behavior: 'smooth' });
  this.setState({
    moreFilter:{
      ...this.state.moreFilter,
      id:id.replace('div-',"")
    }
  })
  }
}

hasFiltered = () => {
  let isFilter = this.state.searchCatalogue != "";

  if(!isFilter){
    isFilter = Object.entries(this.state.checkedFilters).filter((checked)=>checked[1].length>0).length > 0
  }

  if(!isFilter){
    isFilter = Object.entries(this.state.numberFilters).filter((checked)=>checked[1]!=undefined).length > 0
  }
  return isFilter
}
deleteProduct= (data: any) => {
  this.setState({ isDeletingProduct: true });
};
handleDeleteModalClose = () => {
  this.setState({
   isDeletingProduct:false
  });
};
numberChange=(name:string, newValue:number[])=>{
  const filterName = name as keyof NumberFilters
  this.setState({
    numberFilters:{
      ...this.state.numberFilters,
      [filterName]:newValue
    },
    inputValues:{
      ...this.state.inputValues,
      [filterName]:newValue
    }
  } )
}
numberInputMinChange=(event:any,name:string)=>{
  const filterName = name as keyof NumberFilters
  this.setState({
    inputValues:{
      ...this.state.inputValues,
      [filterName]:[event.target.value,this.state.inputValues[filterName][1]]
    }
  } )
}
numberInputMaxChange=(event:any,name:string)=>{
  const filterName = name as keyof NumberFilters
  this.setState({
    inputValues:{
      ...this.state.inputValues,
      [filterName]:[this.state.inputValues[filterName][0],event.target.value]
    }
  } )
}

numberInputBlur=(event:any,name:string)=>{
  const filterName = name as keyof NumberFilters
  let minValue = this.state.inputValues[filterName][0];
  if(minValue < this.state.minMaxValues[filterName][0] ||
    minValue > this.state.minMaxValues[filterName][1]){
      minValue = this.state.minMaxValues[filterName][0]
    }
  let maxValue = this.state.inputValues[filterName][1];
  if(maxValue < this.state.minMaxValues[filterName][0] ||
    maxValue > this.state.minMaxValues[filterName][1]){
      maxValue = this.state.minMaxValues[filterName][1]
    }
  this.numberChange(name,[minValue,maxValue])
}

fetchNumberFilters = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getNumberFiltersAPIEndPoint
  );
  this.fetchNumberRangeApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

navigateToBulk = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagementBulk");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}
backToInventory = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  this.setState({ isFavouriteProductCreated: false , chooseContact: false ,checkedItems:[] });
}
chooseContactModalOpen=()=>{  
  this.setState({chooseContact: true}, () => {this.fetchAllContacts()})
}
closechooseContactModal=()=>{
  this.setState({chooseContact: false})
}
favouriteProductModalClose = (e: any) => {
  e.preventDefault();
  this.setState({ isFavouriteProductCreated: false });
};
favouriteProductCreatedOpen = () => {
  this.setState({
    isFavouriteProductCreated: true,
  });
};
addProductsAsFavourites = () => {
  const formattedProducts1 = this.state.checkedItems.map((productId:any, index:any) => {
    

    return {
        id: productId , 
        quantity: 1,
        price: {
          price_id: 1,
          amount: 0
        }
    };
});

  const header = {
    token: this.userToken.meta.token,
    "Content-Type": "application/json",
  };
  const body={
    "contact": {
      "contact_products": {
        "products": formattedProducts1,
      },
      "contact_ids": [this.state.selectedContactLists],
    }
 }


  const requestMessage: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.addFavouritesContactsAPID = requestMessage.messageId;  

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addAsFavPRoductAPiEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  
  this.setState({ isLoading: true });
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
filterActivityHandler = () => {
  this.setState({ isActiveFilter: true } , () => this.fetchAllContacts());
};
filterModalClose = () => {
  this.setState({ isActiveFilter: false });
};

sendContactListRequest = (endpoint: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );
  this.fetchAllContactsAPID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  this.setState({ isLoading: true });
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

fetchAllContacts = () => {
  const endpoint = configJSON.contactsLists;
  this.sendContactListRequest(endpoint);
};

getContactListsFilters = (searchValues: any, filterValues: any, selectedGroups: any) => {
  const endpoint = buildEndpointUrl(configJSON.contactsLists, selectedGroups, searchValues, filterValues);
  this.sendContactListRequest(endpoint);
};

handleSelectedLists = (contactId: string) => {
  let changeContacts = this.state.selectedContactLists;
  if (changeContacts.includes(contactId)) {
    changeContacts = changeContacts.filter((x: any) => x !== contactId);
  } else {
    changeContacts.push(contactId);
  }
  this.setState({
    selectedContactLists: changeContacts,
  });
};
openSendToContactModal = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "SendToContact");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
raiseMessage.addData(getName(MessageEnum.SessionResponseData), {itemsToSend: this.state.selectedinventoryItems});
message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
this.send(message);
}
exportInventoryDownload = async(categoryName:string) => {
   exportCSVInventory(configJSON.exportInventoryEndPoint, this.userToken.meta.token, categoryName);
}
  // Customizable Area End
}
