// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useStyles,
  createTeams,
  formatDate,
  TeamMemberHead
} from "./CustomTable.web";
import TableItems from "../../blocks/email-account-registration/src/teams-web/CustomTableItems.web"
import { deleteOutline } from "../../blocks/email-account-registration/src/assets";
import i18n from "../../web/src/utilities/i18n";

export const configJSON = require("../../blocks/email-account-registration/src/config");

export default function TeamMember(props: any) {
  const {
    renderTeams,
    unAssignedTeams,
    renameTeam,
    deleteTeam,
    hideActionColumn,
    multipleDelete,
    isFromBulk,
    userRole,
    tableType = "teams",
    isAscendingTeam = null
  } = props;

  const userSessionData: string | null = sessionStorage.getItem("userData") || localStorage.getItem("userData");
  const userDetails = userSessionData ? JSON.parse(userSessionData) : null;
  const myTeamId: string | undefined = userDetails?.data?.attributes?.choose_team || undefined;

  const rows = React.useMemo(() => {
    const allRows = renderTeams.map((team: any) =>
      createTeams(team.id, team.attributes.title, team.attributes.users, 5.4)
    );

    return allRows.sort((a: any, b: any) => {
      // First priority: myTeam should always be at the top
      if (myTeamId) {
        if (a.id === myTeamId) return -1;
        if (b.id === myTeamId) return 1;
      }

      // Second priority: handle ascending/descending sorting
      if (isAscendingTeam !== null) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (isAscendingTeam) { // A to Z
          return nameA.localeCompare(nameB);
        } else { // Z to A
          return nameB.localeCompare(nameA);
        }
      }

      return 0;
    });
  }, [renderTeams, myTeamId, isAscendingTeam]);
  const classes = useStyles(props);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);

  useEffect(() => {
    updateSelected();
  }, [renderTeams]);

  const updateSelected = () => {
    setSelected(
      renderTeams.length > 0
        ? selected.filter((x) => renderTeams.some((y:any) => y.id === x))
        : []
    );
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked ? rows.map((n:any) => n.name) : []);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    if (isFromBulk) return;

    const selectedIndex = selected.indexOf(name);
    setSelected(updateSelection(selectedIndex, name));
  };

  const updateSelection = (selectedIndex: number, name: string) => {
    if (selectedIndex === -1) return selected.concat(name);
    if (selectedIndex === 0) return selected.slice(1);
    if (selectedIndex === selected.length - 1) return selected.slice(0, -1);
    return [
      ...selected.slice(0, selectedIndex),
      ...selected.slice(selectedIndex + 1),
    ];
  };

  const unSelect = (event: React.MouseEvent<unknown>, name: string) => {
    setSelected(selected.filter((item) => item !== name));
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  const isSelected = (name: any) => selected.includes(name);
  const tableClass = userRole === "manager" ? "team-list-manager" : "team-list-employee";
  const tabName = tableType === "groups" ? `${t("Group")}` : `${t("Team")}`;

  const selectionMessage = selected.length
    ? `${selected.length} ${selected.length === 1 ? tabName : tabName + 's'} ${t("Selected")}`
    : `${rows.length} ${rows.length === 1 ? tabName : tabName + 's'}`;

  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
          <Box className="member-count">
              {userRole !== "employee" && (
                <>
                  {!hideActionColumn && (
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                      data-test-id="handle-select-all"
                    />
                  )}
                </>
              )}
              {selectionMessage}
              {userRole !== "employee" && selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={() => multipleDelete(selected)}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
          />
        </Box>
        <TableContainer>
          <Table
            className={`${hideActionColumn ? "bulk-upload-confirm" : `viewTeamList ${tableClass}`} ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TeamMemberHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              tableType="teams"
              membersType={false}
              hideActionColumn={hideActionColumn}
              userRole={userRole}
              moduleType={tableType}
            />
            <TableBody>
            {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const lastIndex = row.length - 1;
                  const teamFields = {
                    id: row.id,
                    teamName: row.name,
                    users: row.users,
                    email: row.email,
                    team: row.team,
                    joinDate: formatDate(row.createdDate),
                  };
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      data-test-id="unAssignedListcheckbox"
                    >
                      <TableItems
                        {...props}
                        teamDetails={teamFields}
                        teamActions={{ renameAction: renameTeam, deleteAction: deleteTeam }}
                        checkBoxHandler={(event: any) => handleClick(event, row.name)}
                        unCheckHandler={(event: any) => unSelect(event, row.name)}
                        id={row.id}
                        teamName={row.name}
                        users={row.users}
                        joinDate={formatDate(row.createdDate)}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        tableType="teams"
                        hideActionColumn={hideActionColumn}
                        userRole={userRole}
                        lastIndex={lastIndex}
                        rowIndex={index}
                        moduleType={tableType}
                      />
                    </TableRow>
                  );
                })}
              {unAssignedTeams?.length > 0 && (
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    className="name-cell"
                    component="th"
                    id="myid"
                    scope="row"
                    padding="none"
                  >
                    <Link to={`/view-team-members/unassigned-members`} className="table-link">
                      <Box className="name-col">Unassigned Members</Box>
                    </Link>
                  </TableCell>
                  <TableCell className="email-cell">
                    {unAssignedTeams.length}
                  </TableCell>
                  <TableCell className="action-link">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End
