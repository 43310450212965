import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
  Checkbox,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import { useStyles } from "../../../blocks/email-account-registration/src/teams-web/CustomTable.web"
import { deleteOutline } from "../../../blocks/email-account-registration/src/assets";

interface TableProps {
  headers: { text: string; numeric: boolean }[];
  rows: string[][];
  category: string;
  subCategory: string;
  rowsUpdate: (rowsRemainging: string[]) => void;
  t:any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiCheckbox-root": {
      color: "#94A3B8",
      "&:hover": {
        backgroundColor: "rgba(54, 145, 166, 0.04)",
      },
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
      "& .MuiSvgIcon-root": {
        height: "20px",
        width: "20px",
      },
    },
  },
  table: {
    minWidth: 1250,
    [theme.breakpoints.down("sm")]: {
      minWidth: 950,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 1050,
    },
    "&.bulk-upload-inventory": {
      "& .MuiTableCell-head": {
        padding: "14px",
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
      },
      "& .MuiTableCell-body": {
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
      },
    },
    "& .MuiTableRow-root": {
      "&.Mui-selected": {
        background: "#fafcfe",
        "&:hover": {
          background: "#fafcfe",
        },
        "& .MuiTableCell-root": {
          "& .profile-name": {
            backgroundColor: "#eaeff2",
          },
        },
      },
      "&.disable-member-row": {
        "&:hover": {
          background: "#ffffff",
        },
      },
    },
    "& .MuiTableCell-head": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43, 43, 43, 0.369)",
      padding: "19px 23px",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("xs")]: {
        padding: "17px 10px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "19px 15px",
      },

      "&.MuiTableCell-paddingNone": {
        padding: "0",
      },
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
      "& .MuiTableSortLabel-iconDirectionAsc": {
        display: "none",
      },
    },
    "& .disable-member-row": {
      "& .MuiTableCell-body": {
        "&:not(.action-link)": {
          userSelect: "none",
          pointerEvents: "none",
          opacity: "0.569",
        },

        "&.action-link": {
          "& .table-link": {
            userSelect: "none",
            pointerEvents: "none",
            opacity: "0.569",
            "&.reactivate": {
              userSelect: "unset",
              pointerEvents: "unset",
              opacity: "1",
            },
          },
        },
      },
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      padding: "26px 16px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",

      [theme.breakpoints.down("xs")]: {
        padding: "25px 18px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "26px 21px",
      },

      "&.MuiTableCell-paddingNone": {
        padding: "0 12px 0 0",
      },
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
      "&.action-link": {
        whiteSpace: "noWrap",
        "& .table-link": {
          marginLeft: "0",
          whiteSpace: "noWrap",
        },
      },
      "& .MuiLink-root": {
        color: "inherit",
      },

      "& .show-me-link": {
        marginLeft: "5px",
        background: "#4ba3b7",
        borderRadius: "6px",
        color: "#FFFFFF",
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        padding: "2px 8px",
        textTransform: "capitalize",
      },
      "& .table-link": {
        color: "#2b2b2b",
        textDecoration: "none",
        marginLeft: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      },

      "& .table-menu": {
        display: "inline-block",
        "& .table-link": {
          padding: "0",
          textTransform: "none",
          font: "inherit",
          "& .MuiButton-endIcon": {
            marginLeft: "2px",
          },
        },
      },
      "& .profile-name": {
        width: "48px",
        height: "48px",
        backgroundColor: "#fafcfe",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        borderRadius: "24px",

        fontWeight: "700",
        marginRight: "12px",
      },

      "& .select-control": {
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },

      "& .name-col": {
        display: "flex",
        alignItems: "center",
        "& .table-link": {
          marginLeft: "0",
        },
      },
    },
    "& .action-link": {
      "&> a": {
        marginRight: "25px",
        "&:last-child": {
          marginRight: "0",
        },
      },
      width: "14.6%",
      [theme.breakpoints.down("md")]: {
        width: "21.6%",
      },
    },
  },
  paginationToolbar: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e8ecf2",
    padding: "4px 0px 5px 16px",
    "& .member-count": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43,43,43,0.87)",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "16px",
        color: "#2B2B2B",
      },
      "& .delete-icon": {
        marginLeft: "23px",
        cursor: "pointer",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 23px 7px 14px",
    },
  },
  paginationTable: {
    "& .MuiTablePagination-toolbar": {
      minHeight: "unset",
    },
    "& .MuiTablePagination-caption": {
      opacity: "0.671",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiIconButton-root": {
        color: "#2b2b2b",
        padding: "6px",
        "&.Mui-disabled": {
          opacity: "0.369",
        },
      },
    },
    "& .MuiSelect-select": {
      fontFamily: "Expletus Sans",
    },
  },
}));

export const InventoryTableView = ({
  headers,
  rows,
  category,
  subCategory,
  rowsUpdate,
  t
}: TableProps) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: string
  ) => {
    const checked = event.target.checked;
    let newSelected: string[] = [];
    if (checked) {
      newSelected = [...selected, rowIndex];
    } else {
      newSelected = [...selected.filter((index) => index != rowIndex)];
    }
    setSelected(newSelected);
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    let newSelected: string[] = [];
    if (checked) {
      newSelected = [...rows.map((row) => row[0])];
    }
    setSelected(newSelected);
  };

  let selectionMessage: string = "";

  if (selected.length > 0) {
    selectionMessage =
    `${selected.length} ${subCategory} ${t("Selected")}`;
  } else {
    selectionMessage = `${rows.length} ${t(subCategory)}`;
  }

  const deleteRow = (rowindex: string) => {
    let newRows: string[][] = [...rows.filter((row) => rowindex != row[0])];
    let newselect: string[] = [
      ...selected.filter((selectIndex) => rowindex != selectIndex),
    ];
    setSelected(newselect);
    rowsUpdate(newRows.map((row) => row[0]));
  };

  const multipleDelete = () => {
    let newRows: string[][] = [
      ...rows.filter((row) => !selected.includes(row[0])),
    ];
    let newselect: string[] = [];
    setSelected(newselect);
    rowsUpdate(newRows.map((row) => row[0]));
  };

  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
              <Checkbox
                onChange={handleSelectAllChange}
                checked={selected.length == rows.length && rows.length>0}
              />
              {selectionMessage}
              {selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={multipleDelete}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            // rowsPerPageOptions={[5, 10, 25]}
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
          />
        </Box>        
        <TableContainer>
          <Table
            className={`${classes.table} bulk-upload-inventory`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    align={header.numeric ? "right" : "left"}
                    padding="normal"
                  >
                    {t(header.text)}
                  </TableCell>
                ))}
                <TableCell padding="normal">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, row[0])
                        }
                        checked={selected.includes(row[0])}
                      />
                    </TableCell>
                    {row.slice(1).map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        // className="name-cell"
                        component="td"
                        id="myid"
                        scope="row"
                        padding="none"
                      >
                        {cell}
                      </TableCell>
                    ))}
                    <TableCell className="action-link">
                      <Link
                        to="#"
                        onClick={() => {
                          deleteRow(row[0]);
                        }}
                        title="Delete"
                        className="table-link"
                        data-testid={`delete-row-${rowIndex}`}
                      >
                        {t("Delete")}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
