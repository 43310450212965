import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { ChooseMetrics } from "../../cfmetaapi/src/ChooseMetrics.web";

export const configJSON = require('./config')
const globalJSON = require('../../../framework/src/config')

export type NavigationRouteParamsType = {
    serviceCaller: string,
    connectCallback: () => void
}

interface S {
    isMetaConnected: boolean,
    isGoogleConnected: boolean,
    isSendinBlueConnected: boolean,
    showErrorModal: boolean,
    errorMessage: string
}

interface SS { }

export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    backToStartIntegration?: any;
    skipToProfile?: any;
    state:any;
}

export default class AddIntegrationController extends BlockComponent<Props, S, SS> {

    getMetaAuthUrlRequestId: string = "";
    getGoogleAuthUrlRequestId: string = "";
    getBravoConnectRequestId: string = "";

    getGoogleAccessTokenRequestId: string = "";
    getMetaAccessTokenRequestId: string = "";

    userSessionData: any;
    userToken: any;

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

        this.userSessionData = localStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);
        this.state = {
            isMetaConnected: false,
            isGoogleConnected: false,
            isSendinBlueConnected: false,
            showErrorModal: false,
            errorMessage: ''
        }
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

            if (apiRequestCallId == this.getMetaAuthUrlRequestId) {
                const url = responseJson.url;
                window.open(url, '_blank', 'noopener,noreferrer');
                return
            }

            if (apiRequestCallId == this.getGoogleAuthUrlRequestId) {
                let url = responseJson.url;
                window.open(url, '_blank', 'noopener,noreferrer');
                return
            }

            if (apiRequestCallId == this.getBravoConnectRequestId) {
                let url = responseJson.url;
                window.open(url, '_blank', 'noopener,noreferrer');
                return
            }

          
        }
    };

    connectToMeta = () => {

        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToMetaEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getMetaAuthUrlRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    connectToGoogle = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToGoogleEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getGoogleAuthUrlRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    connectToBravo = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToBrevoEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getBravoConnectRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

  

}

