// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  Link,
  InputAdornment,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import EmailAccountLoginControllerBlock from "./EmailAccountLoginControllerBlock.web";
import Footer from "../../../components/src/Footer.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { imgEmailChecked } from "./assets";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import AuthLeft from "../../../components/src/AuthLeft.web";
import AppHeader from "../../../components/src/AppHeader.web";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton.web";
import WebLoader from "../../../components/src/WebLoader.web";
export const configJSON = require("./config");
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
        socialButton: {},
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    socialButton: {
      fontSize: "14px",
      lineHeight: "19px",
      border: "1px solid rgba(0,0,0,0.12)",
      borderRadius: "7px",
      width: "100%",
      color: "#2b2b2b",
      textTransform: "unset",
      background: "rgba(98,2,238,0.0001)",
      padding: "12px 10px",
      marginBottom: "0",
      fontWeight: 500,
      minHeight: "56px",
      fontFamily: "Expletus Sans",
      transition: "all 0.5s ease-in-out",
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-startIcon": {
        width: "18px",
        height: "18px",
        marginRight: "6px",
      },
    },
    formLink: {
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: 0,
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      fontFamily: "Expletus Sans",
    },
    orTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontSize: "16px",
      lineHeight: "21px",
      //  marginTop: "41px",
      marginBottom: "30px",
      //  marginBottom: "58px",
      color: "#94a3b8",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      "&:before": {
        content: '""',
        position: "absolute",
        width: "100%",
        top: "50%",
        borderBottom: "1px solid #e8ecf2",
      },
      "& span": {
        position: "relative",
        background: "#ffffff",
        padding: "0 10px",
      },
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%",
      },
      "& .auth-inner-block": {
        display: "flex",
        flexWrap: "wrap",
        "& .auth-right": {
          flex: "1",
          padding: "64px 63px 142px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            padding: "64px 63px 83px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px",
          },
          "& .right-inner-content": {
            maxWidth: "512px",
            width: "100%",
            margin: "auto",
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          "& .back-btn-wrap": {
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "120px",
            },
            [theme.breakpoints.down("xs")]: {
              position: "absolute",
              top: "110px",
            },
            "& i": {
              display: "block",
            },
          },
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        // margin: "0 0 18px",
        margin: "0 0 12px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        //  margin: "0 0 49px",
        margin: "0 0 55px",
        color: "#505050",
        fontFamily: "Roboto",
        letterSpacing: "0.25px",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          margin: "0 0 31px",
        },
      },
      "& .page-link": {
        textAlign: "right",
        // marginBottom: "50px",
        marginBottom: "16px",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px",
        },
      },
    },
    wrapper: {
      minHeight: "100vh",
      paddingBottom: "142px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "105px",
      },
    },
    authMainWrapper: {
      "& .wrapper": {
        paddingBottom: "0",
      },
      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        },
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "384px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },
      },
      "& .MuiInputBase-root": {
        "&.email-checked": {
          "&:after": {
            content: "''",
            backgroundImage: `url(${imgEmailChecked})`,
            width: "15px",
            height: "12px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "absolute",
            right: "25px",
          },
          "& .MuiInputBase-input": {
            paddingRight: "45px",
          },
        },
      },
    },
    innerWrapper: {
      margin: "auto",
      width: "100%",
    },
  });

class EmailAccountLogin extends EmailAccountLoginControllerBlock {
  showButtonLabel = () => {
    let buttonLabel;
      if (this.state.emailNotExist) {
          buttonLabel =  this.t(`${configJSON.webBtnSignUp}`);
      } else if (!this.state.emailNotExist && this.state.showLoginText) {
          buttonLabel = this.t(`${configJSON.webBtnLogin}`);
      } else {
          buttonLabel =  this.t(`${configJSON.webBtnContinue}`);
      }
      return buttonLabel
  }
  render() {
    const { classes } = this.props;
    const { emailNotExist, error, email, isEmailChecked } = this.state;
      const emailMessage = emailNotExist ? '' : error.emailError.message;
      const successMessage = emailNotExist ? error.emailError.message : '';
      const successClass = emailNotExist ? 'success-class' : '';
    const {hideContinueButon} = this.state;
    let isChecked;
    if (this.state.isEmailChecked || this.state.emailNotExist === false) {
      isChecked = "email-checked";
    }

    
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <Box className={`${classes.authMainWrapper} ${classes.mainWrapper}`}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <AppHeader />
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      {this.state.isEmailChecked && (
                        <Box
                          className="back-btn-wrap"
                          onClick={this.handleBack}
                          data-testid="backToEmailCheck"
                        >
                          <i>
                            <svg
                              className="MuiSvgIcon-root"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                          </i>
                        </Box>
                      )}
                      <Box className="right-inner-content">
                        <Typography className="heading" variant="h1">
                          {this.state.isEmailChecked
                            ? this.t(`${configJSON.webLoginText}`)
                            : this.t(`${configJSON.webWelcomeText}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.state.isEmailChecked
                            ? this.t(`${configJSON.webSubtext2}`)
                            : this.t(`${configJSON.webSubtext1}`)}
                        </Box>
                        {/* <CustomGoogleLogInButton />
                        <Box className={classes.orTxt}>
                          <Box component="span">{configJSON.webTxtOr}</Box>
                        </Box> */}

                        <Box component="form">
                          <CustomInputWeb
                            errors={this.t(emailMessage)}
                            success={successMessage}
                            successClass={successClass}
                            label={this.t(`${configJSON.webLabelEmail}`)}
                            onBlur={this.handleBlur}
                            customClass={isChecked}
                            value={email}
                            onChange={this.handleEmailChange}
                            disabled={isEmailChecked}
                            isDealForm={true}
                          />
                          {this.state.isEmailChecked && (
                            <CustomInputWeb
                              errors={this.t(this.state.error.passwordError.message)}
                              label={this.t(`${configJSON.webLabelPassword}`)}
                              isDealForm={true}
                              endAdornment={
                                <InputAdornment position="end">
                                  {this.state.isPasswordVisible ? (
                                    <VisibilityOffIcon
                                      className="visibility-icon"
                                      onClick={this.togglePasswordVisibility}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      className="visibility-icon"
                                      onClick={this.togglePasswordVisibility}
                                      data-testid="emailTogglePwd"
                                    />
                                  )}
                                </InputAdornment>
                              }
                              type={
                                this.state.isPasswordVisible
                                  ? "text"
                                  : "password"
                              }
                              value={this.state.password}
                              onChange={this.handlePassChange}
                            />
                          )}
                          {this.state.isEmailChecked && (
                            <Box className="page-link">
                              <Link
                                title={this.t("Forgot Password?")}
                                className={classes.formLink}
                                onClick={this.handleForgot}
                                data-testid="emailForgotPwd"
                              >
                                {this.t(`${configJSON.webLinkForgotPassword}`)}
                              </Link>
                            </Box>
                          )}
                          {!hideContinueButon && 
                          <Button
                          className={classes.primaryButton}
                          onClick={this.handleLogin}
                          data-testid="emailLoginBtn"
                          type="submit"
                        >
                          {this.showButtonLabel()}
                        </Button>
                          }
                        </Box>
                        <Box className={classes.orTxt}>
                          <Box component="span">{configJSON.webTxtOr}</Box>
                        </Box>
                        <CustomGoogleLogInButton />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer/>
        </Box>
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(EmailAccountLogin);

// Customizable Area End
