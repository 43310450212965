import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider";
import {BrandData, BrandObjType, ImageDataType} from "./InventoryTypes";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  nextBtn: ()=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
productName: string;
brandName: string;
imagePreview: ImageDataType[];
brandList:{
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    created_at: string,
    updated_at: string,
  }
}[],
toolBrand:{
id: number,
title:string
} | null;
isBrandDelete: boolean;
deleteBrandId: string | number;
errorMsg:{
  name:string
},
colourList:any[];
colour:any;
isDeletingColor: boolean;
deleteColorId: string;
colorSelectorId:number;
addedImages:any[];
removedImgs: number[];
isError:boolean;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class ToolsInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  createBrandApiCallId:string = "";
  deleteBrandApiCallId: string = '';
  getBrandListApiCallID:string = "";
  getColorListApiID:string="";
  deleteColorApiID:string="";
  createColorApiID:string="";
  userSessionData: any;
  userToken: {meta:{token:string}};
  setErrorBrandList: any;
  showDialogBrandList: any;
  currentBrand: { id: number };
  currentColor: { id: number,title:string };
  setErrorColor:any;
  showDialogColor:any;
  validationApiId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      productName: '',
      brandName: '',
      imagePreview: [],
      brandList:[],
      toolBrand: null,
      isBrandDelete: false,
      deleteBrandId: '',
      errorMsg : {
        name: ""
      },
      colourList: [],
      colour:null,
      deleteColorId:"",
      isDeletingColor:false,
      colorSelectorId:0,
      addedImages:[],
      removedImgs:[],
      isError:false,
      // Customizable Area End
    };
    this.currentBrand = { id: 0 };
    this.currentColor = { id: 0 ,title:""};
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getBrandListApiCallID:
            this.setState({
              brandList: responseJson.data,
            });
            break;
          case this.createBrandApiCallId:
            this.createBrandResponse(responseJson);
            break;
          case this.deleteBrandApiCallId:
            this.deleteBrandResponse(responseJson);
            break;
          case this.createColorApiID:
            this.createColorSuccess(responseJson);
            break;
          case this.getColorListApiID:
            this.setState({
              colourList: responseJson.options,colorSelectorId:responseJson.id, 
            });
            break;
            case this.deleteColorApiID:
              this.deleteColorSuccess(responseJson)
              break;
              case this.validationApiId:
                if(responseJson.is_valid){
                  this.setState({
                    isError: responseJson.is_valid
                  })
                }

        default:
          break;
        }
    }
    // Customizable Area End
  }

  createBrandResponse =(response:BrandData)=>{
    this.currentBrand = { id : response.data.attributes.id}
    this.getBrandList();
    this.showDialogBrandList(false);
  }

  deleteBrandResponse =(response:BrandData)=>{
    if(this.state.toolBrand && this.state.toolBrand.id == this.state.deleteBrandId){
      this.setState({toolBrand:null})
    }
    this.getBrandList();
    this.deleteBrandModalClose();
  }
  handlebrandName = (e:any,value:BrandObjType) => {
    this.setState({     
      toolBrand:value,
    });
 };
  handleName=(event:React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    switch (this.props.seletedItem) {
       case configJSON.machineText:
        this.setState({ productName: value }, () => {
        this.handleNameValidationApi(value, configJSON.MachineryStaticId);
       });
       break;
  
      case configJSON.hardwareText:
      this.setState({ productName: value }, () => { 
        this.handleNameValidationApi(value, configJSON.HardwareStaticId);
      });
      break;
      default:
        break;
    }; 
  }

  uploadFile = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files != null){
        const selectedFilesData:ImageDataType[] = Array.from(e.target.files).map((file) => ({
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
        }));
        const addedImgLength = this.state.imagePreview.length;
        if(addedImgLength<6){
          this.setState({imagePreview: [...this.state.imagePreview,...selectedFilesData.slice(0,6-addedImgLength)]});
        }
    }
  }

  onClickBrowse = () => {this.inputRef.current && this.inputRef.current.click()}
  
  getBrandList=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandEndPoint
    );
    this.getBrandListApiCallID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addBrand = (data: BrandObjType, setError?: string, setShowDialog?: boolean) => {
    this.setErrorBrandList = setError;
    this.showDialogBrandList = setShowDialog;
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    if(data.id != null && data.id > 0){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteBrandEndPoint + `/${data.id}?name=${data.title}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateApiMethod
      );
    }
    else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.brandEndPoint + `?name=${data.title}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
    }
    
    this.createBrandApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteBrand = (data:{id:number}) => {
    this.setState({ isBrandDelete: true, deleteBrandId: data.id });
  };
  deleteBrandModalClose = () => {
    this.setState({
      isBrandDelete: false,
      deleteBrandId:""
    });
  };

  deleteBrandApi =()=> {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteBrandApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBrandEndPoint + `/${this.state.deleteBrandId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.txtDelete
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleNameValidationApi = (value:string,staticId:number) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.validationApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nameValidationApi}?name=${value}&sub_category_static_id=${staticId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  nextButton=()=>{
    const path = window.location.pathname;
    const editId = path.split("/")[2] || "";
    let hasError = false;
    const trimmedProductName = this.state.productName.trim();
    if (trimmedProductName ==="") {
      this.setState({
        errorMsg:{name:this.t("Please Enter Name")}
      })
      hasError = true;
    }
    if(editId==="" && !this.state.isError){
      this.setState({
        errorMsg:{name:this.t("Please enter a unique name")}
      })
      hasError = true;
    }else{
      this.setState({
        errorMsg:{name:""}
      })
      hasError = false;
    }
    if(hasError){
      return
    }else{
      const toolsData ={
        "brand_id": this.state.toolBrand && this.state.toolBrand.id,
        "brand_name": this.state.toolBrand && this.state.toolBrand.title,
        "product_name": this.state.productName,
        "image_data": this.state.imagePreview,
        "addedImages":this.state.addedImages,
        "color":this.state.colour,
        "removedImgs":this.state.removedImgs,
        
      }
      StorageProvider.set("toolsdata", JSON.stringify(toolsData));
      this.props.nextBtn();

    }
  }

  checkPrevBrand = (prevState: S) => {
    if (prevState.brandList !== this.state.brandList) {
      if (this.currentBrand.id !== 0) {
        const currBrand = this.state.brandList.find(
          (i) => i.attributes.id == this.currentBrand.id
        );
        this.setState({
          toolBrand: {
            id: Number(currBrand && currBrand.attributes.id),
            title: String(currBrand && currBrand.attributes.name),
          },
        });
      }
      this.currentBrand = { id: 0 };
    }
  };

  async componentDidMount(): Promise<void> {
    const toolsItemdata = await StorageProvider.get("toolsdata");
    const tools = await JSON.parse(toolsItemdata)
    if(tools){
      this.setState({
        productName:tools.product_name,
        toolBrand: tools.brand_id ? { id: tools.brand_id,title:tools.brand_name}:null,
        imagePreview:tools.image_data ?? [],
        colour:tools.color ?? null,
        addedImages: tools.addedImages ?? [],
        removedImgs: tools.removedImgs ?? []
      })
     
    }
    this.getBrandList();
    this.getColorList();
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkPrevBrand(prevState);
    this.checkPrevColor(prevState)
  }

  
  removeAttachment = (imgIndex:number) => {
    const updatedArray = [...this.state.imagePreview.slice(0, imgIndex), ...this.state.imagePreview.slice(imgIndex + 1)];
    this.setState({ imagePreview: updatedArray });
  }

  checkPrevColor = (prevState: S) => {
    if (prevState.colourList !== this.state.colourList) {
      if (this.currentColor.id !== 0) {
        const curClr = this.state.colourList.find(
          (x) => x.id == this.currentColor.id
        );
        this.setState({
          colour: {
            id: curClr.id,
            title: curClr.name,
          },
        });
      }
      else if (this.currentColor.title !== "") {
        const curClr = this.state.colourList.find(
          (x) => x.name == this.currentColor.title
        );
        this.setState({
          colour: {
            id: curClr.id,
            title: curClr.name,
          },
        });
      }
      this.currentColor = { id: 0,title:"" };
    }
  };
  colorChangeHandler = (e: any, newValue: any) => {
    this.setState({     
      colour: newValue,
    });
 };

 getColorList=()=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.productsDropdownAPIEndPoint+"color"
  );
  this.getColorListApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

 addColor = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorColor = setError;
  this.showDialogColor = setShowDialog;
  this.currentColor = {id:data.id??0,title:data.title}
  this.addDeleteColorApi(data.id ?? 0,data.title)  
}

addDeleteColorApi = (id:number,title:string,isDelete:boolean=false) => {

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    token: this.userToken.meta.token,
  };
  const formdata = new FormData();

  if(isDelete){
    formdata.append(`options_attributes[${id}][_destroy]`, "true");
    formdata.append(`options_attributes[${id}][id]`, id.toString());
    this.deleteColorApiID = requestMessage.messageId;
  }
  else{
    formdata.append(`options_attributes[${id}][name]`, title);
    if(id>0){
      formdata.append(`options_attributes[${id}][id]`, id.toString());
    }
    this.createColorApiID = requestMessage.messageId;
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.manageSelectorEndPoint + this.state.colorSelectorId
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.updateApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
createColorSuccess = (responseJson: any) => {
  this.getColorList();
  this.showDialogColor(false);
};
deleteColorSuccess = (responseJson:any) => {  
  if(this.state.colour && this.state.colour.id == this.state.deleteColorId){
    this.setState({colour:null})
  }
  this.getColorList();
  this.deleteColorModalClose();
}
deleteColor = (data: any) => {
  this.setState({ isDeletingColor: true, deleteColorId: data.id });
};
deleteColorModalClose = () => {
  this.setState({
    isDeletingColor: false,
    deleteColorId:""
  });
};
deleteColorApi = () => {
  this.addDeleteColorApi(Number(this.state.deleteColorId),"",true)
};

removeAddedImages = (id:number) => {
  const updatedArray = this.state.addedImages.filter((img:ImageDataType)=>img.id!=id)
  this.setState({ addedImages: updatedArray,removedImgs:[...this.state.removedImgs,id] });
}

  // Customizable Area End
}

