Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskList";
exports.labelBodyText = "TaskList Body";
exports.txtTasks = "Tasks"
exports.txtTasksSubLabel = "Create a new task or manage existing one."

exports.btnExampleTitle = "CLICK ME";

// exports.baseURL = "https://etohapp-123437-ruby.b123437.stage.eastus.az.svc.builder.ai";
exports.baseURL = "https://etohapp-123437-ruby.b123437.stage.eastus.az.svc.builder.ai"
exports.createTaskEndPoint = "/account_block/create_task";
exports.methodGET = "GET"
exports.methodPOST = "POST"
exports.methodDELETE = "DELETE"
exports.methodPATCH = "PATCH"
exports.methodPUT = "PUT"
exports.taskListEndPoint = "/account_block/task_list";
exports.createTaskApiMethod = "POST";
exports.userProfileEndPoint = "/account_block/profile_list";
exports.memberListEndPoint = "/account_block/member_list"
exports.taskDeleteEndPoint = '/account_block/multiple_delete'
exports.taskChangePriorityEndPoint = '/account_block/update_priority'
exports.taskCreateEndPoint = "/account_block/create_task"
exports.profileEndPoint = "/account_block/profile_list"
exports.singleTaskEndPoint = "/account_block/task_show";
exports.getTotalCostEndpoint="/account_block/total_cost_data"
exports.taskChangeStatusEndPoint = "/account_block/update_status"
exports.taskEditEndPoint = "/account_block/edit_task"
exports.membersListEndPoint = "/account_block/member_list"
exports.addAssigneesEndPoint = "/account_block/add_assignees"
exports.removeAssigneesEndPoint = "/account_block/remove_assignees"
exports.taskFilterEndPoint = "/account_block/task_filter";
exports.projectFilterEndPoint="/account_block/project_filter"
exports.singleTaskActivityLog = "/account_block/activitylogs"
exports.taskActivityLogFilter = "/account_block/log_filter"
exports.createDuplicateTask = "/account_block/create_duplicate_task"
exports.webTeamsGetEndPoint = "/account_block/teams";
exports.webUpdateDueDate = "/account_block/update_due_date";
exports.downloadActivityLogCsv = "/account_block/download_activitylogs.csv";
exports.projectListEndPoint = "/account_block/projects";
exports.getCategoryListEndPoint = "/account_block/categories";
exports.createCategoryEndPoint = "/account_block/multiple_category";
exports.editCategoryEndPoint = "/account_block/category_update";
exports.projectShowEndPoint = "/account_block/project_show";
exports.createTaskListEndPoint = "/account_block/tasklist";
exports.projectTaskListViewEndPoint = "/account_block/project_tasklist_view";
exports.projectTaskViewEndPoint = "/account_block/tasklist_task_view";
exports.taskListViewEndPoint = "/account_block/tasklist_view";
exports.projectDeleteEndPoint = "/account_block/project_delete";
exports.projectUpdateEndPoint = "/account_block/project_update";
exports.tasklistDeleteEndPoint = "/account_block/tasklist_delete";
exports.tasklistUpdateEndPoint = "/account_block/tasklist_update";
exports.tasklistMoveEndPoint = "/account_block/tasklist_move";
exports.projectActivityLogEndPoint = "/account_block/project_index";
exports.duplicateProject = "/account_block/project_duplicate";
exports.moveTasklist = "/account_block/tasklist_move";
exports.getTasklistEndPoint="/account_block/filter_sort_tasklists";
exports.projectStatusEndPoint="/account_block/project_status";
exports.updateTaskProgress = "/account_block/update_task_progress";

exports.txtSortBy = "Sort by"
exports.txtTask = "Task"
exports.txtTasks = "Tasks"
exports.txtCreateTask = "+ Create Task"
exports.txtStartTypingYourTask = "Start typing your task"

exports.txtCreateTask = "Create Task";
exports.txtLetCreate = "Let's create a task.";
exports.txtTitle = "Title";
exports.txtDueDate = "Due Date";
exports.txtDeadline = "Deadline";
exports.txtDescription = "Description";
exports.txtTitleError = "Please enter task title.";
exports.txtDueDateError = "Please select due date.";
exports.txtDeadlineError = "Please select deadline";
exports.txtDescriptionError = "Please enter task description";
exports.txtEmergency = "Emergency";
exports.txtCritical = "Critical";
exports.txtNone = "None";
exports.txtTaskHours = "Task Hours";
exports.txtTaskHoursUnTracked = "Set Untracked Hours";
exports.txtSave = "Save";
exports.txtDone = "Done";
exports.txtMemberSelected = "Member Selected";
exports.txtAssigneeDescription = "Select the team member(s) you want to assign to this task";
exports.txtAssignees = "Assignees";
exports.txtRemoveAllAssignees = "Remove all assignees";
exports.txtUnassigned = "Unassigned";

exports.txtDeleteTask = "Delete task"
exports.txtCancel = "Cancel"
exports.txtDelete = "Delete"
exports.txtUpdate = "Update"
exports.txtDeleteTaskContent = "Are you sure you want to delete"
exports.txtNoTasksSelected = "No tasks selected. Please select a task to continue."

exports.txtFilters = "Filters"
exports.txtReset = "Reset"
exports.txtDueDate = "Due Date"
exports.txtDeadline = "Deadline"
exports.txtAssignee = "Assignee (individual or team)"
exports.txtSearchAssignees = "Search Assignees"
exports.txtPriority = "Priority"
exports.txtPriorityNone = "None"
exports.txtPriorityCritical = "Critical"
exports.txtPriorityEmergency = "Emergency"
exports.txtStatus = "Status"
exports.txtStatusOpen = "Open"
exports.txtStatusCancelled = "Cancelled"
exports.txtStatusCompleted = "Completed";
exports.txtStatusInProgress = "In Progress";
exports.txtLabourCost = "Labour Cost"
exports.txtHours = "Hours"
exports.txtAssignees = "Assignees"
exports.txtDates = "Dates"
exports.unTrackedHrs = "Untracked Hours"
exports.txtStatusOpenColor = "#12AEF1"
exports.txtStatusCancelledColor = "#F7E223"
exports.txtStatusCompletedColor = "#2B2B2B"
exports.txtApplyFilters = "Apply Filters"
exports.txtChangeStatus = "Change Status"
exports.txtChangePriority = "Change Priority"
exports.txtSelectPriority = "Select Priority"
exports.txtSelectStatus = "Select Status"
exports.txtSaveDetails = "Save Details"
exports.txtEditTask = "Edit Task"
exports.txtEditTaskSubtitle = "Please make your modification below."
exports.txtProjectedLabourCost = "Projected Labor Cost"
exports.txtChooseDate = "Choose Date"
exports.txtActivityLog = "Activity Log"
exports.txtActivityLogDescription = "Shows all activity on this task"
exports.undoActionAPIEndPint = "bx_block_action_log/undo"
exports.onTrackTxt="On Track";
exports.offTrackTxt="Off Track";
exports.aTozTxt="A-Z";
exports.zToaTxt="Z-A";
exports.membersTxt="Members";
exports.memberTxt="Member";
exports.teamTxt="Team";
exports.teamsTxt="Teams";
exports.chooseProjectTxt="Choose Project Template";
exports.selectedProjectTxt =" Selected project will be duplicated.";
exports.searchTypeTxt="Start typing to search...";
exports.templatesTxt="Templates";
exports.templateTxt="Template";
exports.newWineLaunch="New Wine Launch";
exports.createProjectTxt="Create Project";
exports.startDateTxt="Start Date";
exports.dueDate="Due Date";
exports.projectTxt="Project";
exports.serchHereTxt="Search here";
exports.endDateTxt="End Date";
exports.categoryTxt="Category";
exports.assigneeTxt="Assignee";
exports.projectLeadTxt="Project Lead";
exports.redWinePhotoshootTxt="Red Wine Photoshoot";
exports.editTxt="Edit";
exports.goToInventoryTxt="Go to Inventory";
exports.duplicateProjectTxt="Duplicate Project";
exports.saveAsTemplate="Save as Template";
exports.getTimesheetTxt="Get Timesheet";
exports.craeteTaskListTxt="Create Tasklist";
exports.deleteProjectTxt="Delete Project";
exports.budgetTxt="Budget";
exports.attacmentsTxt="Attachments ({{count}})";
exports.activityLogsTxt="Activity Log";
exports.actualTxt="Actual";
exports.projectedTxt="Projected";
exports.fullDetailsTxt="Full Details";
exports.fullDetailsSubTxt="Actual values pulled from tasks within this project.";
exports.laborCostTxt ="Labor Cost";
exports.employeesTxt="employees";
exports.employeeTxt="employee";
exports.hoursTxt="hours";
exports.inventoryCostTxt="Inventory Cost";
exports.machineryItemTxt ="Machinery Item A";
exports.expendituresTxt="Expenditures";
exports.totalCostActual="Total Cost (Actual)";
exports.totalCostTxt="Total Cost";
exports.uploadTxt="Upload";
exports.activityLogSubTxt="Currently shows 30 days logs.";
exports.tasklistsTxt="Tasklists";
exports.noTasklistsTxt="No Tasklists";
exports.notTsaklistSubTxt="No Tasklists have been created here";
exports.createTstklistTxt="Create Tasklists";
exports.noTasksTxt="No Tasks";
exports.noTasksSubTxt="No Tasks have been created here";
exports.createTaskTxt="Create Tasks";
exports.editProjectTxt="Edit Project";
exports.editProjectSubTxt="Update the below information.";
exports.letsCreateProjectTxt="Let's create a project.";
exports.addNewCategoryTxt="Add New Category";
exports.noCategoryFoundTxt="No Category found with";
exports.noCategoryCreatedTxt ="No Category to create";
exports.confirmationTxt="Are you sure you want to delete Category ?";
exports.deleteCategoryTxt="Delete Category";
exports.updateProjectTxt="Update Project";
exports.changeProjectLeadTxt="Change Project Lead";
exports.selectSubTxt="Choose the lead for this project";
exports.searchProjectLeadTxt="Search Project Lead";
exports.projectRemoveTxt ="Remove Project Lead";
exports.confirmDeleteProject="Are you sure you want to delete {{title}} project?";
exports.projectCreatedMsgTxt="Project Created Successfully";
exports.projectCreateSuccessSubTxt="You can now add tasks to this project or create another project.";
exports.createAnotherProject="Create Another Project";
exports.draftTxt="Draft";
exports.createNewTxt="Create New";
exports.useExistingTxt="Use Existing";
exports.filterTxt="Filters";
exports.aductActivityTxt ="Adjust activity log filters";
exports.dateRangeTxt="Date Range";
exports.allAssignessTxt="All Assignees";
exports.allAssignessSubTxt ="Below are the {{length}} assignees which are in this task.";
exports.moreTxt="more";
exports.salesDepartmentTxt="Sales Department";
exports.accountsTeamTxt ="Accounts TeamAccounts Team";
exports.perHoursTxt="Per Hour";
exports.timesheetTxt="Timesheet";
exports.chooseAssigneesTxt="Choose Assignees";
exports.setDefaultTxt="Set default permissions to each module of EtOH Suite";
exports.selectDetailsTxt ="Select the details you require.";
exports.chooseMemeberOrTeam="Choose Team or Members";
exports.chooseTaskListTxt="Choose Tasklist";
exports.createTimesheetTxt="Create Timesheet";
exports.okTxt="Ok";
exports.caneleRequestTxt="Cancel Request";
exports.timesheetGeneratingTxt="Timesheet Generating";
exports.requestConfirmationTxt="Requested timesheet is being generated. You will get a notification once its ready. This could take up to 2 hours."
exports.allProjectsTxt="All Projects";
exports.allProjectTxt="All Project";
exports.projectsTxt="Projects";
exports.allTasksTxt="All Tasks";
exports.todayTxt="Today";
exports.tomorrowTxt="Tomorrow"
exports.thisWeekTxt ="This Week";
exports.thisMonthTxt="This Month";
exports.viewAssigneeTxt="View Assignee";
exports.duplicateTaskTxt="Duplicate Task";
exports.attachmentsTxt="Attachments";
exports.atTxt="at";
exports.currentStatusTxt="Current Status";
exports.addANoteTxt="Add a note";
exports.letsEditTaskSubTxt="Let's edit this task.";
exports.addNewTaskListTxt="Add New TaskList";
exports.noTaskListFoundTxt="No TaskList found with";
exports.notCreatedTasklistTxt="No TaskList to create";
exports.dragTxt="Drag and drop files to attach or";
exports.browseTxt="browse";
exports.updateTaskTxt="Update Task";
exports.renameTasklistTxt="Rename Tasklist";
exports.tasklistNameTxt="TaskList Name";
exports.moveTasklistTxt="Move Tasklist";
exports.moveTasklistSubTxt="Select the project you want to move this tasklist to";
exports.currentProjectTxt="Current Project";
exports.currentTasklistTxt="Current Tasklist";
exports.photoshopTxt="Photoshop";
exports.otherProjectsTxt="Other Projects";
exports.otherTasklistTxt="Other Tasklists";
exports.completedTxt="completed";
exports.moreDetailsTxt="More Details";
exports.highPriorityTxt ="High Priority";
exports.pastDueTxt="Past Due";
exports.tasksSelectedTxt ="Task Selected";
exports.closeTxt="Close";
exports.createTasklistSubTxt="Tasklist will be created in New Wine Launch.";
exports.deleteTaskListTxt="Delete Tasklist";
exports.deleteTasklistPhotoshopTxt="Are you sure you want to delete the ";
exports.deleteTasklistTxt=" tasklist?";
exports.confirmDeleteTask ="Are you sure you want to delete these {{taskCount}} tasks?";
exports.confirmDeleteSingleTask ="Are you sure you want to delete these task?";
exports.addInventoryTxt ="Add Inventory or Assets";
exports.expendituresTxt ="Add Expenditures";
exports.clickHereTxt="Click here to";
exports.browseTxt ="browse";
exports.yourFilesTxt ="your files";
exports.addAttachmentTxt ="Add attachments";
exports.tasklistNameTxt="Tasklist Name";
exports.moveTasklistProjectTitle="Move to Project or Tasklist";
exports.moveTasklistProjectSubTitle="Select the project or tasklist to move this task to";
exports.moveTasklistAndProjectApiPath="/account_block/move_task_to_project_or_tasklist";
exports.tasksMovedTxt="Tasks Moved";
exports.taskMovedTxt="Task Moved";
exports.addExpenditures="Add Expenditures";
exports.addProductAssestsTxt="Add Inventory or Assets";
exports.addDealTxt="Add Deal";
exports.expendituresText="Expenditures";
exports.chooseDealsTxt="Choose Deals";
exports.chosseDealsSubTxt="Below are the all deals.";
exports.getDealsEndpoint ="/bx_block_contactslist/deals";
exports.continoueBtnTxt="Continue";
exports.contactTxt="Contact";
exports.selectContactTxt="Select Contact";
exports.dealOwnerNameTxt="deal owner";
exports.dealsTxt="Deals";
exports.dealTxt="Deal";
exports.searchPlaceholder="Start typing to search...";
exports.viewSingleDeal = "bx_block_contactslist/show_deal?";
exports.searchDealEndPoint = "/bx_block_contactslist/search_sort_filter_deal";
exports.stageState = [
  { value: 0, name: "Initiated", className: "sky-blue" },
  { value: 1, name: "Sent Proposal", className: "orange" },
  { value: 2, name: "Follow Up", className: "yellow" },
  { value: 3, name: "Won", className: "green" },
  { value: 4, name: "Lost", className: "black" },
];
exports.dealAmountTxt="Deal Amount";
exports.stageTxt="Stage";
exports.hasTasks = "Has a Tasks"; 
exports.hasAttachment = "Has an Attachment"; 
exports.hasAssociatedProduct = "Has an Associated Product"; 
exports.fromTxt="From" ;
exports.toTxt="To";
exports.closeByDateTxt="Close by Date";
exports.selectDateFilter="Select Date";
exports.onOrBefore="on or before";
exports.onOrAfter="on or After";
exports.favProductsEndPoint = "/bx_block_contactslist/product_list";
exports.brandEndPoint = "/bx_block_catalogue/brands";
exports.itemSelectedTxt="Item Selected";
exports.itemsSelectedTxt="Items Selected";
exports.subTxtForSummary="The following items have been added to this task.";
exports.associatedFilters = "Filters";
exports.contactFavclearTxt = "Clear all";
exports.ShowAsOption1 = "Retail Price";
exports.ShowAsOption2 = "Wholesale Price";
exports.ShowAsOption3 = "Custom Price";
exports.itemSelectedTxt="Item Selected";
exports.itemsSelectedTxt="Items Selected";
exports.ProductsTxt="Products";
exports.ProductTxt="Product";
exports.searchTxtType="Start typing to search...";
exports.aDashZTxt="A-Z";
exports.zDashATxt="Z-A";
exports.itemSummaryTxt="Item";
exports.itemsSummaryTxt="Items";
exports.chooseItemHeading = "Choose Inventory or Assets";
exports.chooseItemSubHeading = "Please choose the items associated with this task.";
exports.catergoryName1 = "Products";
exports.catergoryName2 = "Tools";
exports.catergoryName3 = "Assets";
exports.catergoryName4 = "Consumables";
exports.filterCatalogue="bx_block_inventory_management/filter_catalogue";
exports.ProductsStaticId = 1
exports.WineStaticId = 1
exports.BeerStaticId = 2
exports.SpritStaticId = 3
exports.OtherProductStaticId = 4

exports.ToolsStaticId = 2
exports.MachineryStaticId = 5
exports.HardwareStaticId = 6

exports.AssetsStaticId = 3
exports.LandStaticId = 7
exports.VesselStaticId = 8

exports.ConsumablesStaticId = 4
exports.FertilizerStaticId = 9
exports.PhytoProductStaticId = 10
exports.CheimcalStaticId = 11;
exports.SortTxt = "Sort by";
exports.ShowasTxt = "Show as";
exports.SortOption1 = "Recently added";
exports.SortOption2 = "Price - High to Low";
exports.SortOption3 = "Price - Low to High";
exports.SortOption4 = "A to Z";
exports.SortOption5 = "Z to A";
exports.inventoryItemAddedTxt="Inventory Items Added";
exports.yourPictureTxt="your files";
exports.addAttachmentsTxt="Add attachments";
exports.updateTaskProgress="/account_block/update_task_progress";
exports.inventoryAseetsViewTitle="Inventory / Assets";
exports.brandTxt="brand";
exports.createdByTxt="Created by";
exports.notAttachmentTest="No Attachments";
exports.subTextForDEfaultAttaments="Attachments added to this task will appear here.";
exports.selecteFileTxt="Select File";
exports.statusMap = {
  open: 0,
  cancelled: 1,
  completed: 2,
  in_progress: 3,
};
exports.projectStatusMap= {
  "Open": 0,
  "on track": 1,
  "off track": 2,
  "completed": 3,
};

exports.projectClassMap = {
  0: 'open-status',
  1: 'on-track',
  2: 'off-track-status',
  3: 'completed-project-status'
};
exports.classMap = {
  0: 'open-status',
  1: 'cancelled-status',
  2: 'completed-status',
  3: 'in-progress-status',
};
exports.projectAttachments="/account_block/update_project_attachment";
exports.taskAttachments="/account_block/update_task_attachment";
exports.getAllProductEndPoint = "/bx_block_contactslist/product_list";
exports.taskAssign = "Task Reassigned.";
exports.memberNotFountError="No member found";
exports.teamNotFountError="No team found";
exports.duplicateSuccesssMsg = "Task Duplicated.";
exports.taskCompeletd = "Task Completed.";
exports.taskStarted = "Task Started.";
exports.createdOnTxt="Created on";
exports.timeTrackedTxt="Time Tracked";
exports.inventoryHeadding="Inventory / Assets";
exports.allInventorySubTxt ="Below are the inventory and/or assets to be used for this task.";
exports.allInventoryProductApiEndpint="/account_block/get_selected_catalogues";
exports.projectBudgetEndpoint ="/account_block/project_budget";
exports.tooltip1="Budget compares the total budget calculated by summing labor costs, machinery costs, and task expenditures (Actual) against the estimated values for this Project (Projected).";
exports.tooltip2="Hours compares the total hours tracked by Assignees on Tasks in this Project (Actual) against the hours estimated for those tasks (Projected)."
// Customizable Area End