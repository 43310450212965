import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Popover,
  CardMedia,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  Select,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  InputAdornment,
  Input,
  Switch,
  TextField,
  Modal,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  noEmailCampaigns,
  minimizeGray,
  closeGray,
  stepperArrow,
  expandForm,
  sldierImg1,
  sldierImg2,
  helpCircle,
  attachmentIcon,
  previewTemplateCampaign,
  CalenderIcon,
  senderList,
  contactList,
  artBoard,
  calendarRange,
  formatList,
  viewColumn,
  sortBy,
  arrowDown,
  connectBrevo,
  previewTxt,
  checkGreen,
  checked,
  toContactorGroup,
} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/CfsendinblueHelper.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import CustomMarketingSidebar from "../../../components/src/CustomMarketingSidebar.web";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import WebLoader from "../../../components/src/WebLoader.web";
import moment from "moment";
import ViewEmailCampaign from "./ViewEmailCampaigns.web";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ConnectBrevoAPI from "../../../components/src/ConnectBrevoKey.web";
import CreateEmailCampaign from  "./CreateEmailCampaign.web";
import SyncBrevoScreen from "../../../components/src/SyncBrevoScreen.web";
import { showLettersFromName } from "../../../components/src/ReusableFunctions";
// Customizable Area End

import CfsendinblueapiControllerWeb, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./CfsendinblueapiController.web";
import { config } from "process";

export class CfSendinblueWeb extends CfsendinblueapiControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  displayGroups = (group_names:any) => {
    if (group_names.length <= 2) {
      return group_names.join(', ');
    }
    return `${group_names.slice(0, 2).join(', ')} & ${group_names.length - 2} More`;
  };

  displayRecipientsGroups = (groupNames:any) => {
    return (
      <TableCell className="name-cell sender-cell" scope="row">
                    {groupNames.length > 0  && 
                    <Box className="name-col">
                      <Box className="profile-name">{showLettersFromName(groupNames[0])}</Box>
                      <Box className="name-mail-wrapper">
                        <Typography className="table-text">
                          {groupNames[0]}
                          <Box className="assignee-bubble" data-test-id="assignee-bubble-id" onMouseEnter={this.handleHoverClick}>
                              + {groupNames.length - 1}
                              <Box className="text-detail">
                                <List>
                                  {groupNames.slice(1).map((group:any) => (
                                    <ListItem key={group.email}>
                                      <Typography className="title">{group}</Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              </Box>
                            </Box>
                          {/* {campaign.sender.name} */}
                        </Typography>
                        <Typography className="text-light">
                          {/* {campaign.sender.email} */}
                        </Typography>
                      </Box>
                    </Box>
                    }
                  </TableCell>

      )

  }


  renderCampaignTable = (campaignList: any, isSent: boolean) => {
    const { classes } = this.props;
    const columns = isSent
      ? [
          "Recipients",
          "Title",
          "Subject",
          "Delivered",
          "Openers",
          "Clicks",
          "Unsubscribers",
          "Design",
          "Sent",
          "Action",
        ]
      : [
          "Recipients",
          "Title",
          "Subject",
          "Design",
          "Scheduled",
          "Actions",
        ];

    return campaignList.length > 0 ? (
      <Box className="marketing-inner">
        <TableContainer>
          <Table
            className={`table-wrap-deals ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    className={`${column.toLowerCase()}-cell`}
                  >
                    {this.t(`${column}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignList.map((campaign: any, index: any) => (
                <TableRow key={campaign.id}>
                  {this.displayRecipientsGroups(campaign.group_names)}
                  <TableCell className="title-cell">
                    <Box className="form-col select-modal-field">
                      <Typography className="table-text">
                        {campaign.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className="title-cell">
                  <Typography>
                      {campaign.subject}
                      </Typography>
                    </TableCell>
                  {isSent ? (
                    <>
                      <TableCell className="delivered-cell">
                        <Typography className="value-text">
                          {campaign.delivered ||
                            0}
                        </Typography>

                        {/* <Typography className="value-text" >128<span>(100%)</span> </Typography> */}
                      </TableCell>

                      <TableCell className="openers-cell">
                        <Typography className="value-text">
                          {campaign.unique_openers || 0}
                        </Typography>
                      </TableCell>

                      <TableCell className="clicks-cell">
                        <Typography className="value-text">
                          {campaign.unique_clicks ||
                            0}{" "}
                        </Typography>
                      </TableCell>

                      <TableCell className="unsubscribers-cell">
                        <Typography className="value-text">
                          0
                        </Typography>
                      </TableCell>
                      <TableCell className="designers-cell">
                        <Typography className="value-text">
                        {campaign.template.name}
                        </Typography>
                      </TableCell>
                      <TableCell className="sent-cell">
                        <Typography className="value-text">
                        {moment(campaign.created_at).format("D MMM YYYY h:mm a")}  
                        </Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                      <Box className="form-col select-modal-field">
                          {campaign.template.name}
                        </Box>
                        
                      </TableCell>
                      <TableCell>
                      <Box className="form-col select-modal-field">
                        {moment(campaign.scheduled_at).format("D MMM YYYY h:mm a")}
                        </Box>
                      </TableCell>
                    </>
                  )}
                  <TableCell className="action-link action-cell">
                    <Link
                      to="#"
                      title="View"
                      className="table-link"
                      onClick={() => this.viewCampaignDetails(campaign.id)}
                      data-test-id={`viewCampaign-${campaign.id}`}
                    >
                      {this.t(`${configJSON.viewTxt}`)}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <>{this.showEmptyBox()}</>
    );
  };

  renderCampaignTableDrafts = (draftedLists: any, isSent: boolean) => {
    const { classes } = this.props;
    const draftedColumns = 
       [
          "",
          "Recipients",
          "Title",
          "Subject",
          "Design",
          "Saved",
          "Actions",
        ];

    return draftedLists.length > 0 ? (
      <Box className="marketing-inner">
        <TableContainer>
          <Table
            className={`table-wrap-deals ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {draftedColumns.map((column) => (
                  <TableCell
                    key={column}
                    className={`${column.toLowerCase()}-cell`}
                  >
                    {this.t(`${column}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {draftedLists.map((drafts: any) => (
                <TableRow key={drafts.id}>
                  <TableCell className="draft-cell">Draft</TableCell>
                  <TableCell className="name-cell sender-cell" scope="row">
                    {drafts.sender.email !== "" && 
                    <Box className="name-col">
                      <Box className="profile-name">{showLettersFromName(drafts.sender.name)}</Box>
                      <Box className="name-mail-wrapper">
                        <Typography className="table-text">
                          {drafts.sender.name}
                        </Typography>
                        <Typography className="text-light">
                          {drafts.sender.email}
                        </Typography>
                      </Box>
                    </Box>
                    }
                  </TableCell>
                  <TableCell className="title-cell">
                    <Box className="form-col select-modal-field">
                      <Typography className="table-text">
                        {drafts.name}
                      </Typography>
                      <Typography className="text-light">
                      {drafts.subject}
                      </Typography>
                    </Box>
                  </TableCell>
                      <TableCell>
                        <Box className="form-col select-modal-field">
                          {drafts.group_names.length > 0 && 
                            <>
                               {this.displayGroups(drafts.group_names)}
                            </>
                          }
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="form-col select-modal-field">
                          {drafts.template.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="form-col select-modal-field">
                       {moment(drafts.updated_at).format("D MMM YYYY h:mm a")}
                        </Box>
                      </TableCell>
                  <TableCell className="action-link action-cell">
                    <Link
                      to="#"
                      title="View"
                      className="table-link"
                      onClick={() => this.viewCampaignDetails(drafts.id)}
                      data-test-id={`viewCampaign-${drafts.id}`}
                    >
                      {this.t(`${configJSON.viewTxt}`)}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (
      <>{this.showEmptyBox()}</>
    );
  };

  showEmptyBox = () => {
    return (
      <Box className="empty-box">
      <CardMedia
        component="img"
        className="img-contact-book"
        image={noEmailCampaigns}
      />
      <Box className="box-empty-right">
        <Typography className="txt-no-contact-title">
          {this.t(`${configJSON.noCampaignText}`)}
        </Typography>
        <Typography className="txt-no-contact-sub-title">
          {this.t(`${configJSON.noCampaignDescription}`)}
        </Typography>
        <Button
          onClick={this.createCampaignOpen}
          data-test-id="empty-campaign-btn-no-mails"
        >
          + {this.t(`${configJSON.createCampaignTxt}`)}
        </Button>
      </Box>
    </Box>
    )
  }

  sortByDropdownExistingCampaigns = (classes:any) => {
    return (
      <FormControl className="select-control-campaigns">
        <Select
          inputProps={{ IconComponent: () => null }}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          defaultValue={this.t(`${configJSON.sortCampaaignText}`)}
          renderValue={(value) => {
            return (
              <Box>
                {this.t(`${value}`)}
                <img
                  className="sort-icon"
                  src={arrowDown}
                  alt="sortExistingCampaign"
                />
              </Box>
            );
          }}
          onChange={(event) => this.handleSortByLists(event.target.value)}
          data-test-id="sort-contact-lists"
        >
           <MenuItem value="Sort by"> {this.t(`${configJSON.sortCampaaignText}`)}</MenuItem>
          <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
          <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
        </Select>
    </FormControl>
    )
  }

  getCampaignCount = () => {
      const { emailCampaignType, sentCampaignList } = this.state;
      let draftedList = sentCampaignList.filter(
        (status: any) => status.status === "draft"
      );
      let scheduledList = sentCampaignList.filter(
        (status: any) => status.status === "queued"
      );

      if (emailCampaignType === "draft") {
        return `${draftedList.length} ${this.t("Drafts")}`;
      } else if (emailCampaignType === "queued") {
        return `${scheduledList.length} ${this.t("Scheduled")}`;
      } else if (emailCampaignType === "sent") {
        return `${sentCampaignList.length} ${this.t("Sent")}`;
      } else {
        return null;
      }
    };
    filtersSearchTypes = (searchVal:string) => {
      return (
        <CustomInputWeb
        type="search"
        value={""}
        label=""
        placeholder={this.t(searchVal)}
        // onChange={(event: any) => handleSearch(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        data-test-id="searchfield"
      />
      )
    }

    showSentEmailFilters = () => {
      const {classes} = this.props;
      return (
        <>
        <Box className="filter-control">
            <CustomDatePicker
            placeholder={this.t(`${configJSON.dateRange}`)}
            range={true}
            //value={this.state.emailFilterDate}
            currentDate=""
            onChange={this.handleEmailFilterDates}
            t={this.t}
            onOpenPickNewDate={false}
            data-test-id="emailFilterdates"
            />
        </Box>
        <Box className="filter-control">
        <Box className={`switch-wrapper ${classes.switchWrapper}`}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            checked={this.state.hasAttachments}
                            // onChange={this.toggleHasAttachments}
                            data-test-id="attachment-switch"
                          />
                        }
                        label={this.t(
                          `${configJSON.hasAttachment}`
                        )}
                        labelPlacement="end"
                        data-test-id="attachment-form-control"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
        </Box>
        {/* <Box className="filter-control">
        <FormControl className="select-control">
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    displayEmpty
                    defaultValue=""
                    data-test-id="contact-list-filter"
                    value={this.state.selectedList}
                    onChange={(event: any) =>
                      this.handleFilterLists(event)
                    }
                  >
                    <MenuItem value="">
                      {this.t(`${configJSON.toContactGroupTxt}`)}
                    </MenuItem>
                    {this.state.brevoContactLists.length > 0 &&
                      <>
                      {this.state.brevoContactLists.map((list:any) => {
                          return (
                          <MenuItem value={list.name}>
                            {list.name}
                          </MenuItem>
                        );

                        })}
                      </>
                      }
                  </Select>
                </FormControl>
          </Box> */}
          <Box className="filter-control border-hl" data-test-id="columns-contacts" onClick={this.handleContactsMenuOpen}>
              <img
                src={toContactorGroup}
                alt="toContactorGroup"
                className="icon"
              />{" "}
              {this.t(`${configJSON.toContactsorGroups}`)}
            </Box>
            
            <Popover
              open={Boolean(this.state.anchorElContacts)}
              anchorEl={this.state.anchorElContacts}
              onClose={this.handleContactsMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            classes={{ paper: `${classes.popoverStyle}` }}
            data-test-id="columns-popover-contacts"
            >
            <Box className="view-more-dropdown" >
              {this.filtersSearchTypes("Search Columns Type")}
              {this.state.brevoContactLists.map((list:any) => {
                const isChecked = this.state.filterRecipientIds.includes(list.brevo_group_id);
                return (
                  <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">                   
                  {list.name}
                  </Typography>
                  <Checkbox 
                  className="dropdown-menu-item-checked"
                  checked={isChecked} 
                  onChange={() => this.handlListsSelection(list.brevo_group_id)} 
                  data-test-id={`chooseContacts-${list.brevo_group_id}`} 
                  name="recipients" 
                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                  />
                </Box>
                )
              })}
              
            </Box>
          </Popover>
          
          <Box className="filter-control border-hl" data-test-id="column-design" onClick={this.handleDesignsOpen}>
              <img
                src={formatList}
                alt="formatList"
                className="icon"
              />{" "}
              {this.t(`${configJSON.designTxt}`)}
            </Box>
            <Popover
              open={Boolean(this.state.anchorElDesigns)}
              anchorEl={this.state.anchorElDesigns}
              onClose={this.handleDesignsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            classes={{ paper: `${classes.popoverStyle}` }}
            data-test-id="columns-popover-design"
            >
            <Box className="view-more-dropdown" >
            {this.filtersSearchTypes("Search Designs")}
            {this.state.emailTemplatesList.map((designs:any) => {
               const isChecked = this.state.filterDesignIds.includes(designs.id);
                return (
                  <Box className="dropdown-menu-item-column">
                <Typography className="dropdown-menu-item-text">                   
                {designs.name}
                </Typography>
                <Checkbox 
                className="dropdown-menu-item-checked"
                checked={isChecked} 
                onChange={() => this.handleDesignSelection(designs.id)} 
                data-test-id={`design-${designs.id}`} 
                name="recipients" 
                checkedIcon={<img src={checked} alt="checkbox" />}                   
                />
              </Box>
                )
            })}
              
            </Box>
          </Popover>
        <Box className="filter-control">
          <img
            src={viewColumn}
            alt="calendarRange"
            className="icon"
          />{" "}
          {this.t(`${configJSON.columnsTxt}`)}
        </Box>
      </>
      )

    }

    showDraftedEmailFilters = () => {
      const {classes} = this.props;
      return (
      <>
          <Box className="filter-control border-hl" data-test-id="column-campaign" onClick={this.handleCampaignsOpen}>
              <img
                src={artBoard}
                alt="artBoard"
                className="icon"
              />{" "}
              {this.t(`${configJSON.campaign}`)}
            </Box>
            <Popover
              open={Boolean(this.state.anchorElCampaigns)}
              anchorEl={this.state.anchorElCampaigns}
              onClose={this.handleCampaignsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            classes={{ paper: `${classes.popoverStyle}` }}
            data-test-id="columns-popover-campaigns"
            >
            <Box className="view-more-dropdown" >
            {this.filtersSearchTypes("Search Campaigns")}
            
              {this.state.sentCampaignList.map((campaign:any) => {
                if(campaign.status === "queued"){
                  return (
                    <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">                   
                  {campaign.name}
                  </Typography>
                  <Checkbox 
                  className="dropdown-menu-item-checked"
                  checked={false} 
                  // onChange={this.handleCheckedHideColumns} 
                  data-test-id="recipientTxt" 
                  name="recipients" 
                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                  />
                </Box>
                  )
                }
              })}
              
            </Box>
          </Popover>
          <Box className="filter-control">
          <img
          src={CalenderIcon}
          alt="calendarRange"
          className="icon"
          />{" "}
          {this.t(`${configJSON.timeAndDateTxt}`)}
          </Box>
      </>
      );
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const { sentCampaignList } = this.state;

    let draftedList = sentCampaignList.filter(
      (status: any) => status.status === "draft"
    );
    let scheduledList = sentCampaignList.filter(
      (status: any) => status.status === "queued"
    ); 
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <div style={{position: "fixed", width: "100%", zIndex: 2}}>
        <GlobalHeaderWeb />
        </div>
        <Box className={classes.campaignMarketingRoot}>
          <Box className={classes.campaigndrawerWrapper} 
          sx={{
            paddingTop: sentCampaignList.length === 0 ? { xs: "10%", sm: "8%", md: "6%" } : "",
           }}
          >
            <Box className={classes.campaigndrawer}>
              <Drawer
                classes={{
                  root: classes.campaignRootWrapper,
                  paper: classes.campaignPaper,
                }}
                variant={"permanent"}
                open={true}
              >
                <CustomMarketingSidebar
                  toggleMenu={this.closeMenuDrawer}
                  data-test-id="email-campaign-sidebar"
                  t={this.t}
                  allTabsCount={this.state.totalSubCounts}
                />
              </Drawer>
            </Box>
            <Box className={classes.dealsdrawerContent}>
              <Box className="marketing-content-deals">
                {sentCampaignList.length > 0 && 
                <Box className="marketing-top-deals">
                  <Box className="top-left">
                    <Box className="email-count">{this.getCampaignCount()}</Box>
                    <Box className="email-search-wrapper">
                      <Input
                        placeholder={this.t(`${configJSON.searchTypeHereTxt}`)}
                        type="search"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        data-test-id="campaigns-search-input"
                      />
                    </Box>
                  </Box>
                  <Box className="top-right">
                    <Box className="filter-list">
                      {this.state.emailCampaignType === "sent" ? 
                       <>{this.showSentEmailFilters()}</>: <>
                        {this.showDraftedEmailFilters()}
                        </>
                      } 
                     
                      <FormControl className="select-control-deals sortby-control">
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyleDeals },
                          }}
                          displayEmpty
                          defaultValue={this.t(
                            `${configJSON.sortCampaaignText}`
                          )}
                          data-test-id="sort-email-campaign"
                          inputProps={{ IconComponent: () => null }}
                          onChange={(event: any) =>
                            this.handleSortCampaign(event)
                          }
                          renderValue={(value) => {
                            return (
                              <Box>
                                <img
                                  className="select-icon"
                                  src={sortBy}
                                  alt="sortBy"
                                />
                                {value}
                              </Box>
                            );
                          }}
                        >
                          <MenuItem value="Sort by">
                            {this.t(`${configJSON.sortCampaaignText}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.newestooldTxt}`)}>
                            {this.t(`${configJSON.newestooldTxt}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.oldtonewTxt}`)}>
                            {this.t(`${configJSON.oldtonewTxt}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.delivered}`)}>
                            {this.t(`${configJSON.delivered}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.openers}`)}>
                            {this.t(`${configJSON.openers}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.clicks}`)}>
                            {this.t(`${configJSON.clicks}`)}
                          </MenuItem>
                          <MenuItem value={this.t(`${configJSON.unsubscribers}`)}>
                            {this.t(`${configJSON.unsubscribers}`)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              }
                {this.state.isBrevoAccountConnected ? (
                  <>
                    {this.state.emailCampaignType === "sent"
                      ? this.renderCampaignTable(sentCampaignList, true)
                      : this.state.emailCampaignType === "draft"
                      ? this.renderCampaignTableDrafts(draftedList, false)
                      : this.renderCampaignTable(scheduledList, false)}
                  </>
                ) : (
                  <ConnectBrevoAPI
                    classes={classes}
                    t={this.t}
                    connectBtnHandler={this.handleConnectBrevoAccount}
                    isInputModal={this.state.isConnectingBrevoAccount}
                    closeModalHandler={this.closeBrevoInput}
                    brevoKeyVal={this.state.brevoAPIKey}
                    keyInputError={this.state.brevoAPIKeyError}
                    keyInputHandler={this.handleBrevoAPIKeyInput}
                    validateAccount={this.validateBrevoAccount}
                    data-test-id="connect-brevo-api"
                  />
                )}
                
                  {this.state.showSyncButton && 
                     <SyncBrevoScreen
                     classes={classes}
                     t={this.t}
                     showSyncModal={this.state.showSyncButton}
                     closeSyncModal={this.closeSyncModal}
                     syncImage={noEmailCampaigns}
                     data-test-id="syncBrevoScreen"
                   />
                  }
                 
              </Box>
            </Box>
          </Box>
          {this.state.isBrevoAccountConnected && 
            <Box className={classes.createTaskWrapper}>
            <Box className="task-actions">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                aria-describedby={popoverId}
                aria-controls="createProjectEl"
                aria-haspopup="true"
                onClick={this.createCampaignOpen}
                data-test-id="empty-campaign-btn"
              >
                + {this.t(`${configJSON.createCampaignTxt}`)}
              </Button>
            </Box>
          </Box>
          }
          
          <Menu
            id="createProjectEl"
            anchorEl={this.state.createCampaignEl}
            keepMounted
            open={Boolean(this.state.createCampaignEl)}
            onClose={this.createCampaignClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className={`changepriority-dropdown ${classes.dropDropdown}`}
          >
            <MenuItem
              aria-describedby={popoverId}
              onClick={(event: any) => this.popoverHandleClick(event)}
              ref={this.popoverRef}
              data-test-id="create-new-campaign"
            >
              {this.t(`${configJSON.createNewTxt}`)}
            </MenuItem>
            <MenuItem
              onClick={this.openExistingCampaignModal}
              data-test-id="use-existing-campaign"
            >
              {this.t(`${configJSON.useExistingTxt}`)}
            </MenuItem>
          </Menu>
        </Box>
        {this.state.createEmailCampaignForm && (
          <CreateEmailCampaign 
          popoveAnchorEl={this.state.popoveAnchorEl}
          popoverClose={this.popoverHandleClose}
          getAllCampaingsList={this.getAllCreatedCampaigns}
          isReusingCampaign={this.state.reUseExisting}
          campaignIdToReuse={this.state.reuseExistingCampaignId}
          data-test-id="create-email-campaign-form"
          navigation={this.props.navigation}
          />
        )}
        
        {this.state.openViewCampaignModal && (
          <ViewEmailCampaign
            openViewModal={this.state.openViewCampaignModal}
            classes={classes}
            data-test-id="viewEmailCampaignModal"
            closeViewModal={this.closeCampaignModal}
            campaignViewId={this.state.campaignId}
            getAllList={this.getAllCreatedCampaigns}
            navigation={this.props.navigation}
          />
        )}
        
        {this.state.openExistingCampaigns && (
          <Modal
            open={this.state.openExistingCampaigns}
            onClose={this.closeExistingCampaignModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.chooseModalBox}
          >
            <Box className={classes.existingCampaignModal}>
              <Box className="modal-heading">
                <Box className="heading-left">
                  <Typography className="modal-title" component="h2">
                    {this.t(`${configJSON.chooseCampaignTxt}`)}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.chooseCampaignTxtDescription}`)}
                  </Box>
                </Box>
                <Box className="heading-right">
                  <Link
                    to="#"
                    className="close-icon"
                    onClick={this.closeExistingCampaignModal}
                    data-test-id="close-campaign-modal"
                  >
                    <CloseIcon style={{ color: "Gray" }} />
                  </Link>
                </Box>
              </Box>
              <Box className="search-box">
                <CustomInputWeb
                  type="text"
                  data-test-id="search-recipients"
                  label=""
                  placeholder={this.t(`${configJSON.searchTypeHereTxt}`)}
                  errors={""}
                  rows={4}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  
                />
              </Box>
              <Box className="existing-campaigns">
              <Box className="un-assign-owner">
              <Box className="un-assign-owner-top">
                    <Typography className="top-title">
                      {this.t(`${configJSON.previousCamTxt}`)}
                    </Typography>
                    {this.sortByDropdownExistingCampaigns(classes)}
                  </Box>
                  </Box>
                  </Box>
              {this.state.sentCampaignList.map((item:any)=>{
                return  <Box className="existing-campaigns">
                <Box className="un-assign-owner">                  
                  <Box className="un-assign-owner-list">
                    <Box className="owner-item" data-test-id={`existing-campaign-${item.id}`} onClick={() => this.reuseExistingCampaigns(item.id)}>
                      <Box className="owner-info">
                        <Typography className="name">
                          {item.name}
                        </Typography>
                        <Typography className="email">
                        {this.t(`${configJSON.createdOn}`)} {moment("2024-10-29T11:10:54.807Z").format("MMM DD, YYYY hh:mma").replace("am", "AM").replace("pm", "PM")} 
                        </Typography>
                      </Box>
                      {this.state.reuseExistingCampaignId === item.id ? (
                        <CheckCircleIcon className="icon-check" />
                      ) : (
                        <RadioButtonUnchecked className="circle-check" />
                      )}
                     
                    </Box>
                  </Box>
                </Box>
              </Box>
              })} 
              {this.state.reuseExistingCampaignId !=="" && (
                 <Box className="continue-btn">
                 <Button 
                 className={`primary-btn ${classes.primaryButton}`}
                 onClick={(event: any) => this.continueReuseExistingCampaign(event)}
                 data-test-id="continue-btn-reuse">
                   {this.t(`${configJSON.continueTxt}`)}
                 </Button>
                 </Box>
              )}
                         
            </Box>
          </Modal>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(CfSendinblueWeb);
// Customizable Area End
