import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Modal,
  Input,
  ListSubheader,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  withStyles
} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "../../../components/src/CustomInput.web";
import { fileIcon, tickIcon } from "../../catalogue/src/assets";
import { ImageDataType } from "./InventoryTypes";
import { customStyles } from "../../../components/src/AddItemStyles.web";
import SearchIcon from "@material-ui/icons/Search";

// Customizable Area End

import ItemBasicInfoController, {
  Props,  
  configJSON,
} from "./ItemBasicInfoController.web";

export class ItemBasicInfo extends ItemBasicInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes, seletedItem, backBtn } = this.props;
    let ageVintageText = this.state.ageTab === true ? this.state.ageValue : this.state.vintageValue;
    if (ageVintageText == '') {
      ageVintageText = '000'
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.basicInfoSection}>
        <Box className="heading-box">
          <Box>
            <Typography className={classes.heading}
              variant="h1">
              {this.t(`${configJSON.addingTitle}`)}
            </Typography>
            <Typography
              className={classes.subtitleText}>
              {this.t(`${configJSON.addingSubtext}`)}
            </Typography>
          </Box>          
          <Box className="item-box" data-testId="itemText">{this.t(seletedItem)}</Box>
        </Box>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.product}
              onChange={(event) => this.handleProduct(event)}
              placeholder={this.t(`${configJSON.productNamePlaceHolder}`)}
              data-testId={'productName'}
              errors={this.state.error.product}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                value={this.state.producer}
                onChange={(event) => this.handleproducer(event)}
                placeholder={this.t(`${configJSON.producerPlaceHolder}`)}
                data-testId={'produreName'}
                errors={this.state.error.producer}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                value={this.state.brand}
                onChange={(event) => this.handleBrand(event)}
                placeholder={this.t(`${configJSON.branPlaceHolder}`)}
                data-testId={'brandName'}
                errors={this.state.error.brand}
              />
            </Grid>
          </Grid>
          {seletedItem === configJSON.spiritText &&
            <>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>
                    {this.t(`${configJSON.categoryText}`)}
                  </InputLabel>
                  <Select
                    value={this.state.category}
                    onChange={(event) => this.handleCategory(event)}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    label={this.t(`${configJSON.categoryText}`)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-testId={"categoryField"}
                  >
                    {this.state.categoryData.map((data) => {
                      return <MenuItem value={data.name} key={data.id}
                        data-testId={`category-data-${data.id}`}
                      >
                        {data.name}
                      </MenuItem>
                    })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <CustomInput
                  value={this.state.style}
                  onChange={(event) => this.handleStyle(event)}
                  placeholder={this.t(`${configJSON.styleText}`)}
                  data-testId={'styleFeild'}
                />
              </Grid>
            </>
          }
          {seletedItem === configJSON.wineText ?
            <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>
                    {this.t(`${configJSON.varietyPlaceHolder}`)}
                  </InputLabel>
                  <Select
                    value={this.state.grapeVariety}
                    onChange={(event) => this.handleGrapeVeriety(event)}
                    onClose={() => this.clearSearchValue()}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    label={this.t(`${configJSON.varietyPlaceHolder}`)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-testId={"grapeVerietyField"}
                  >
              <Box className="search-box">
              <CustomInput
                placeholder={"Search here"}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => this.multiSelectSearchChange(e)}
                value={this.state.selectSearchVal}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                data-test-id="grape-variety-search"
              />
            </Box>
                    {this.displayGrapeOptions().map((data) => {
                      return <MenuItem value={data.name} key={data.id} data-testId={`menuItem-${data.id}`}>
                        {data.name}
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <CustomInput
                  value={this.state.verityPercent}
                  onChange={(event) => this.handleNumberChange(event)}
                  placeholder={this.t(`${configJSON.varietyPlaceHolder} %`)}
                  data-testId={'veriety-percent'}
                  name="verityPercent"
                />
              </Grid>
            </Grid>
            :
            <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>{this.t(`${configJSON.rawMaterialPlaceHolder}`)}</InputLabel>
                  <Select data-testId={"rawMaterialField"}
                    value={this.state.rawMaterial}
                    onChange={(event) => this.handleRawMaterial(event)}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    label={this.t(`${configJSON.rawMaterialPlaceHolder}`)}
                  >
                    {this.state.rawMaterialData.map((rawData) => {
                      return <MenuItem value={rawData.name} key={rawData.id} data-testId={`material-${rawData.id}`}>
                        {rawData.name}
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <CustomInput
                  value={this.state.rawMaterialPercent}
                  onChange={(event) => this.handleNumberChange(event)}
                  placeholder={this.t(`${configJSON.rawMaterialPlaceHolder} %`)}
                  data-testId={'raw-material-percent'}
                  name="rawMaterialPercent"
                />
              </Grid>
            </Grid>
          }
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.complement}
              onChange={(event) => this.handleComplement(event)}
              placeholder={this.t(`${configJSON.complementPlaceHolder}`)}
              data-testId={"complementTextFeild"}
              //errors={this.state.error.complement}
            />
          </Grid>
          {seletedItem === configJSON.otherText ? null :
            <>
              <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <FormControl className="select-outer" variant="outlined">
                    <InputLabel>
                      {this.t(`${configJSON.colorPlaceHolder}`)}
                    </InputLabel>
                    <Select data-testId="colour-dropdown"
                      value={this.state.colour}
                      onChange={(event) => this.handleColour(event)}
                      label={this.t(`${configJSON.colorPlaceHolder}`)}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {/* {this.state.colorData.map((color)=>{
                        return <MenuItem value={color.name} key={color.id} data-testId={`color-${color.id}`}>
                          {color.name}</MenuItem>
                      })} */}
                      {this.state.colorData
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort the colorData alphabetically by name
                        .map((color) => {
                          return (
                            <MenuItem style={{ display: 'flex', justifyContent: 'space-between' }} value={color.name} key={color.id} data-testId={`color-${color.id}`}>
                              {color.name}
                              <Box style={{ width: '20px', height: '20px', borderRadius: '10px', backgroundColor: this.getRadioStyle(color.name).color }} />
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <FormControl className="select-outer" variant="outlined">
                    <InputLabel>
                      {this.t(`${configJSON.dosagePlaceHolder}`)}
                    </InputLabel>
                    <Select data-testId="dosage-dropdown"
                      value={this.state.dosage}
                      onChange={(event) => this.handleDosage(event)}
                      label={this.t(`${configJSON.dosagePlaceHolder}`)}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {/* {this.state.dosageData.map((item) => {
                        return <MenuItem value={item.name} key={item.id} data-testId={`dosage-${item.id}`}>
                          {item.name}</MenuItem>
                      })} */}
                      {this.state.dosageData
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort the dosageData alphabetically by name
                        .map((item) => (
                          <MenuItem value={item.name} key={item.id} data-testId={`dosage-${item.id}`}>
                            {item.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                  <Box tabIndex={0} className={classes.vintageBox}>
                  <Box className="age-vintage-box" data-testId="vintage-box">
                    <Typography className={this.state.ageTab === true ? "age-box" : 'text-box'}
                      onClick={this.chooseAgeTab} data-testId={'age-tab'}>
                      {this.t(`${configJSON.ageText}`)}
                    </Typography>
                    <Typography className={this.state.ageTab === false ? "age-box" : 'text-box'}
                      onClick={this.chooseVintageTab} data-testId={'vintage-tab'} >
                      {this.t(`${configJSON.vintageText}`)}
                    </Typography>
                  </Box>
                  <Box className="item-age-value" data-testId="item-age-vintage-box">
                  {this.state.ageTab === true ?
                  <Input
                    className={classes.inputField}
                    value={this.state.age}
                    data-testId="ageInput"
                    onChange={(event) => this.handleNumberChange(event)}
                    name="age"
                    disableUnderline
                    placeholder="000"
                  />
                  :
                  <Input
                    className={classes.inputField}
                    value={this.state.vintage}
                    data-testId="vintageInput"
                    onChange={(event) => this.handleNumberChange(event)}
                    name="vintage"
                    disableUnderline
                    placeholder="0000"
                  />
                }
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <CustomInput
                  value={this.state.quantity}
                  onChange={(event) => this.handleQuantity(event)}
                  placeholder={this.t(`${configJSON.quantityPlaceHolder}`)}
                  data-testId={"quntityTextFeild"}
                />
              </Grid>
            </>
          }
        </Grid>
        <Typography className={classes.uploadText}>
          {this.t(`${configJSON.attachmentText}`)}
        </Typography>
        <input hidden ref={this.inputRef} multiple type="file" onChange={this.uploadFile} data-testId={"upload-feild"} />
        <Box className={classes.browseBox} onClick={this.onClickEditIcon} data-testId={"fileIcon"} >
          <img src={fileIcon} className="pinImg" />
          <Typography className="upload-text">
            {this.t(`${configJSON.uploadText1}`)} {" "}<span className="span-text">{this.t(`${configJSON.browseText}`)}</span>
            {" "}{this.t(`${configJSON.uploadText2}`)} {" "}{this.t(`${configJSON.uploadImgIext}`)}
          </Typography>
        </Box>
        {this.state.prodImages.map((img: any, index: number) => (
          <Box key={index} className={classes.uploadFileBox} data-test-id={`file-preview-${index}`}>
            <img src={tickIcon} data-test-id={`tick-icon-${index}`} />
            <Typography className="image-text" data-test-id={`file-name-${index}`}>
              {img.filename}
            </Typography>
            <CloseIcon onClick={()=>this.removeAddedProdImage(img.id)} className="file-close-icon" data-test-id={`remove-added-${index}`} />
          </Box>
        ))}
        {this.state.imagePreview.map((img: ImageDataType, index: number) => {
          return (<Box key={img.url} className={classes.uploadFileBox}>
            <img src={tickIcon} />
            <Typography className="image-text" data-testId={`img-${img.name}`}>{img.name}</Typography>
            <CloseIcon onClick={() => this.delImage(index)}
              className="file-close-icon"
              data-testId="img-closeIcon"
            />
          </Box>
          )
        })
        }
      
        <Box className={classes.formButtons}>
          <Button className="backBtn" onClick={backBtn}
            data-testId={"back-btn"}
          >
            {this.t(`${configJSON.backButton}`)}
          </Button>
          <Button className="next-step-btn" onClick={this.handleItemInfo}
            data-testId={"next-btn"}
          >
            {this.t(`${configJSON.nextButton}`)}
          </Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(ItemBasicInfo);
// Customizable Area End
