// Customizable Area Start
import React from "react";
import {
  Box,
  Menu,
  Typography,
  Button,
  Drawer,
  InputAdornment,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Modal,
  TextField,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import CustomisableUserProfilesController, {
  Props, configJSON
} from "./CustomisableUserProfilesController.web";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import ProfileSidebar from "../../../components/src/CustomProfileSidebar";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import EmailEdit from "../../email-account-registration/src/teams-web/EmailEdit.web";
import EditPassword from "../../email-account-registration/src/teams-web/EditPassword.web";
import {
  menu,
  minusGray,
  arrowGreenUp,
  plusCircle,
  checkBoxFilled,
  checkBoxEmpty,
  borderIcon,
  backArrowIcon,
} from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import WebLoader from "../../../components/src/WebLoader.web";
import DeleteContact from "../../ContactsList/src/DeleteContact.web";

export const customStyles = (theme: AugmentedTheme) =>

  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
      },
    },
    profileWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "73px 0 194px",
        [theme.breakpoints.down("sm")]: {
          padding: "21px 0 129px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 0",
        },
      },
    },
    profileInnerWrapper: {
      textAlign: "left",
      maxWidth: "100%",
      width: "100%",
      padding: "0 160px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "0 116px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        paddingBottom: "48px",
        marginBottom: "48px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "32px",
          marginBottom: "44px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .profile-menu": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
          },
        },
      },
      "& .profile-team": {
        marginBottom: "24px",
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
      "& .edit-row": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& .edit-left": {
          flex: "1",
        },
        "& .edit-right": {
          marginLeft: "10px",
        },
        "& .edit-fullbtn": {
          marginTop: "24px",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .profile-info": {
          textAlign: "left",
          margin: "0",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          "& .profile-outer": {
            width: "80px",
            height: "80px",
            margin: "0",
            padding: "6px",
            "& .profile-icon": {
              fontSize: "21.3333px",
              lineHeight: "28px",
            },
          },
          "& .profile-details": {
            marginLeft: "12px",
          },
          "& .job-info": {
            marginBottom: "6px",
          },
        },
        "& .primary-btn": {
          padding: "7px",
          fontSize: "12px",
          minWidth: "125px",
          minHeight: "36px",
          borderRadius: "6px",
        },
      },
      "& .invite-member-row": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
        },
        "& .invite-member-left": {
          flex: "1",
          marginRight: "10px",
          [theme.breakpoints.down("xs")]: {
            flex: "unset",
            marginRight: "0",
            marginBottom: "24px",
            width: "100%",
          },
        },
        "& .invite-member-right": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            width: "100%",
          },
        },
        "& .invite-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          margin: "0 0 6px",
          color: "#2B2B2B",
          letterSpacing: 0,
          fontFamily: "Expletus Sans",
          fontWeight: 400,
        },
        " & .sub-txt": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
        },
        "& .primary-btn": {
          padding: "7px",
          fontSize: "12px",
          minWidth: "114px",
          minHeight: "36px",
          borderRadius: "6px",
          [theme.breakpoints.down("sm")]: {
            minWidth: "100px",
          },
          [theme.breakpoints.down("xs")]: {
            flex: "1",
          },
        },
        "& .secondary-btn": {
          padding: "7px",
          fontSize: "12px",
          width: "114px",
          minHeight: "36px",
          marginRight: "16px",
          borderRadius: "6px",
          [theme.breakpoints.down("sm")]: {
            width: "100px",
          },
          [theme.breakpoints.down("xs")]: {
            flex: "1",
          },
        },
      },
    },
    formInfoWrapper: {
      margin: "0 0 12px",
      borderBottom: "1px solid #E8ECF2",
      paddingBottom: "32px",
      marginBottom: "32px",
      "&:last-child": {
        paddingBottom: "0",
        marginBottom: "0",
        borderBottom: "0",
      },
      "& .form-heading": {
        fontSize: "16px",
        lineHeight: "24px",
        margin: "0 0 32px",
        color: "#2b2b2b",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .form-subheading": {
        fontSize: "14px",
        lineHeight: "26px",
        margin: "0 0 16px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0.105px",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -12px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -10px",
        },
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
          "& .hourlyPay": {
            "& .form-control": {
              marginBottom: "0",
            },
          },
          "& .col6": {
            "& .form-control": {
              marginBottom: "32px",
            },
          },
        },
        "& .email-field, & .password-field": {
          "& .form-control": {
            "& .MuiOutlinedInput-input": {
              color: "#94A3B8",
            },
          },
        },
        "& .form-col": {
          padding: "0 12px",
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.down("xs")]: {
            padding: "0 10px",
          },
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
          },
        },
      },
      "& .form-control": {
        marginBottom: "32px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        fontFamily: "Roboto",
        fontWeight: 400,
        "&.error-select": {
          color: "#C7263E",
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
      "& .helper-text": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        fontFamily: "Roboto",
        fontWeight: 400,
        marginLeft:"15px",       
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "32px",
        "&.error-outer": {
          "& .MuiInputLabel-formControl": {
            color: "#C7263E",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "#94A3B8",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    profileInfo: {
      marginBottom: "32px",
      textAlign: "center",
      "& .profile-outer": {
        width: "120px",
        height: "120px",
        // border: "1px dashed rgba(43, 43, 43, 0.87)",
        borderRadius: "100%",
        padding: "10px",
        margin: "0 auto 16px",
        backgroundImage: `url(${borderIcon})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "120px",
        "& .profile-icon": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#4BA3B7",
          width: "100%",
          height: "100%",
          borderRadius: "inherit",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#FFFFFF",
          fontFamily: "Expletus Sans",
          overflow: "hidden",
        },
      },
      "& .profile-name": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#2B2B2B",
        marginBottom: "4px",
        fontFamily: "Expletus Sans",
        textTransform: "capitalize"
      },
      "& .job-info": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
        textTransform:"capitalize",
        wordBreak:'break-all',
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
      },
      " & .profile-email": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
      },
    },
    drawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: 302,
      [theme.breakpoints.down("md")]: {
        minWidth: 123,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
      "& .sidebar-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
    },
    drawerContent: {
      flex: 1,
    },
    profileBlockOuter: {
      maxWidth: "622px",
      width: "100%",
      margin: "0 auto",
      padding: "0 15px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "543px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
      "&.mvc-profile-outer": {
        minHeight: "85vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          minHeight: "49vh",
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: "66.5vh",
        },
      },
      "& .profile-block-wrapper": {
        border: "1px solid #E8ECF2",
        borderRadius: "12px",
        padding: "23px 40px",
        marginBottom: "24px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          border: "0",
          borderRadius: "0",
          padding: "0 0 32px",
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "32px",
        },
        "&.profile-form-wrapper": {
          padding: "36.2px",
          minWidth: "592px",
          [theme.breakpoints.down("xs")]: {
            padding: "0 0 32px",
          },
        },
        "&:last-child": {
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            border: "0",
            padding: "0",
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
    },
    chipButton: {
      background: "#ECF1F4",
      borderRadius: "6px",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#2B2B2B",
      padding: "5px 6px",
      cursor: "pointer",
      marginRight: "8px",
      marginBottom: "8px",
      minHeight: "26px",
      height: "auto",
      fontFamily: "Roboto",
      border: "1px solid transparent",
      "&.last-child": {
        marginRight: "0",
      },
      "&.secondary-chip": {
        borderColor: "#4BA3B7",
        color: "#4BA3B7",
        backgroundColor: "transparent",
      },
      "&.primary-chip": {
        color: "#FFFFFF",
        backgroundColor: "#4BA3B7",
      },
      "& .MuiChip-avatar": {
        width: "14px",
        height: "14px",
        marginLeft: "0",
        marginRight: "5px",
      },
      "& .MuiChip-label": {
        padding: "0",
      },
      "&.more-btn": {
        cursor: "pointer",
        marginRight: "8px",      
        "&:focus": {
          background: "#ECF1F4",
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "150px",
      letterSpacing: "0",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "150px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    rightModalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
      },
      "& .team-multiselect": {
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "82px 60px",
        [theme.breakpoints.down("md")]: {
          padding: "45px 60px 29px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "30px 25px",
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "32px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        color: "#2B2B2B",
        letterSpacing: "0.0075em",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "48px",
      },
      "& .close-icon": {
        color: "rgba(148, 163, 184, 1)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .profile-info": {
        "& .profile-outer": {
          width: "120px",
          height: "120px",
          padding: "8px",
        },
      },
      "& .button-wrapper": {
        marginTop: "64px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "32px",
        },
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%",
          },
        },
      },
      "& .select-control": {
        "&.multi-select": {
          "&.outline-control": {
            width: "100%",
            marginTop: "0",
            "& .multiselect-label": {
              display: "block",
              position: "static",
              transform: "none",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "18px 40px 18px 24px",
              lineHeight: "20px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "calc(50% + 17px)",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          borderRadius: "12px",
          background: "rgba(18, 174, 241, 0.07)",
          padding: "6px 12px",
          "& .down-arrow": {
            width: "16px",
            height: "16px",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "88px",
        display: "flex",
        justifyContent: "flex-end",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 60px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px 24px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        padding: "30px 25px",
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "24px",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginLeft: "24px",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "27px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },
          },
        },
        "& .modal-description": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
        },
        "& .modal-footer": {
          [theme.breakpoints.down("xs")]: {
            marginTop: "auto",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "27px",
      },
      "& .close-icon": {
        color: "rgba(148, 163, 184, 1)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        margin: "0 0 8px",
        marginTop: "15px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .modal-footer": {
        marginTop: "44px",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
      backdropFilter: "blur(3px)",
    },
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
    },
    dropdownStyle: {
      maxWidth: "190px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    dropDropdown: {
      "& .MuiMenu-paper": {
        minWidth: "156px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        marginTop: "52px",
        boxShadow: "none",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          padding: "14px 17px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start"
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px"
        }
      }
    },
    teamAutocompleteDropbox: {
      padding: "0",
      "& .MuiAutocomplete-paper": {
        borderRadius: "12px",
        background: "#ffffff",
        filter: "none",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      },
      "& .MuiAutocomplete-listbox": {
        padding: "0",
        "& .MuiAutocomplete-option": {
          padding: "0",
          background: "transparent",
        },
      },
      "& .tasklist-li": {
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        position: "relative",
        borderBottom: "0",
        padding: "16px 24px",
        width: "100%",
        "& .tasklist-left": {
          fontSize: "14px",
          lineHeight: "19px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
        },
        "& .tasklist-icon": {
          minWidth: "unset",
          "& .checklist-check": {
            padding: "0",
          },
        },
      },
    },
    secondaryHeader:{
      padding:"25px 0",
      borderBottom:"1px solid #E8ECF2",
      [theme.breakpoints.down("xs")]: {
        display:"none",
      },
      "& .container":{
        maxWidth: "100%", 
        padding: "0 25px", 
        margin: "0 auto"
      },
      "& .image-wrapper":{
        "& a":{
          display: "inline-block",
          color: "#000000de",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: 1.5,
          textDecoration: "none",
          "& em":{
            marginRight:"16px",
          },
        },
      },
    },
  });



class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  dropMenuClick = (e: any) => {this.setState({ anchorEl: e.currentTarget })}

  dropMenuClose = () => {this.setState({ anchorEl: null }) };

  dropMenuClose2 = () => {this.setState({ anchorEl: null });};

  render() {
    const { classes, isMobile } = this.props;
    console.log(this.state.country)

    const getMyTeams = () => {
      const { showMoreTeams, teamLength, userTeams } = this.state;

      const defTeamLength: number = 2;
      const displayTeams = showMoreTeams
        ? userTeams
        : userTeams.slice(0, defTeamLength);
      return (
        <>
          {displayTeams.map((team: any) => {
            return (
              <Chip
                key={team.title}
                className={classes.chipButton}
                avatar={
                  this.isOwner() || this.isManager() ? (
                    <Avatar alt="minus-gray" src={minusGray} />
                  ) : undefined
                }
                label={team.title}
                onClick={() => this.removeTeamHandler(team.id,team.title)}
                data-test-id={`deleteTeam-${team.id}`}
              />
            );
          })}
          {userTeams.length > defTeamLength && (
            <>
              {showMoreTeams ? (
                <Chip
                  className={`secondary-chip ${classes.chipButton}`}
                  label="Show Less"
                  avatar={<Avatar alt="arrowGreenUp" src={arrowGreenUp} />}
                  onClick={this.toggleShowTeams}
                  data-test-id="showLessBtn"
                />
              ) : (
                <Chip
                  className={`more-btn ${classes.chipButton}`}
                  label={`+${teamLength - displayTeams.length} more`}
                  onClick={this.toggleShowTeams}
                  data-test-id="showMoreBtn"
                />
              )}
            </>
          )}

          {(this.isOwner() || this.isManager()) && (
            <Chip
              className={`primary-chip ${classes.chipButton}`}
              label="Add New Team"
              avatar={<Avatar alt="plusCircle" src={plusCircle} />}
              onClick={this.addNewTeam}
              data-test-id="addTeamModal"
            />
          )}
        </>
      );
    };
    const displayTeamList = this.state.teams
      .filter(
        (team: any) =>
          this.state.userTeams.find((x: any) => x.id == team.id) == undefined
      )
      .map((team: any) => {
        return {
          id: team.id,
          title: team.attributes.title,
        };
      });

    return (
      <>
      {this.state.isLoading && <WebLoader />}
      <style>{`
            #menu-Currency-select .MuiMenu-list .MuiMenuItem-root {
              height: 51px;
              display: flex;
              gap: 16px;
              font-family: "Expletus Sans";
              font-size: 14px;
              font-weight: 500;
             }

            #menu-Currency-select .MuiPaper-root {
              background-color: #F2F8FC;
              padding: 8px;
              border-radius: 12px;
             }

            #menu-Currency-select .MuiPaper-root ul {
              height: 220px;
              overflow: auto;
              scrollbar-width: thin; 
              scrollbar-color: #4BA3B7 #D9D9D9; 
            }

            #menu-Currency-select .MuiPaper-root ul::-webkit-scrollbar-thumb {
              background-color: #4BA3B7; 
              border-radius: 4px; 
            }

            #menu-Currency-select .MuiPaper-root ul::-webkit-scrollbar-thumb:hover {
              background-color: #3B92A3;
            }

            #menu-Currency-select .MuiPaper-root ul::-webkit-scrollbar {
              width: 4px;
            }

            #menu-Currency-select .MuiPaper-root ul::-webkit-scrollbar-track {
              background: #D9D9D9;
              border-radius: 4px;
            }
            #mui-component-select-Currency-Select{
              display:flex;
              gap: 16px;
            }
            #menu-Currency-select .MuiPaper-root ul::-webkit-scrollbar-track {
              background: #D9D9D9 !important;
            }
           
            #menu-Currency-select .MuiMenu-list .MuiMenuItem-root .currencySymbol {
              width: 20px;
              color: #2B2B2B;
            }

            #menu-Currency-select .MuiMenu-list .MuiMenuItem-root .currencyCode {   
              color: #94A3B8;
            }

            #menu-Currency-select .MuiMenu-list .MuiMenuItem-root .currencyName {   
              color: #2B2B2B;
            }
            `}</style>
        <Box>
          <Box
            className={`wrapper ${classes.wrapper} ${classes.profileWrapper}`}
          >
            <Box className={classes.secondaryHeader}>
              <Box className="container">
                <Box onClick={this.backToDashboard} className="image-wrapper" data-test-id="backToDashboard">
                    <Link title="Back" to="#" > 
                    <em><img alt="Arrow Back Icon" src={backArrowIcon}  /></em> 
                    {this.t("Back")}</Link>
                </Box>
              </Box>
            </Box>
            <Box className={`inner-wrapper ${classes.innerWrapper}`}>
              <Box className={classes.profileInnerWrapper}>
                <Box className="main-heading">
                  <Box className="back-btn-wrap">
                    <i>
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                  </Box>
                  <Typography className="heading" variant="h1">
                    {this.t("Profile")}
                  </Typography>
                  <Box
                    className="profile-menu"
                    onClick={this.toggleProfileDrawer}
                  >
                    <img src={menu} alt="menu" />
                  </Box>
                </Box>
                <Box className={classes.drawerWrapper}>
                  <Box className={classes.drawer}>
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                        root: classes.drawerRootWrapper,
                      }}
                      variant={isMobile ? "temporary" : "permanent"}
                      open={isMobile ? this.state.isProfileDrawerOpened : true}
                    >
                      <ProfileSidebar toggleMenu={this.closeProfileDrawer} t={this.t}/>
                    </Drawer>
                  </Box>
                  <Box className={classes.drawerContent}>
                    <Box
                      className={`${classes.profileBlockOuter} mvc-profile-outer`}
                    >
                      {/*<Box className="profile-block-wrapper">
                        <Box className="edit-row">
                          <Box className="edit-left">
                            <Box
                              className={`${classes.profileInfo} profile-info`}
                            >
                              <Box className="profile-outer">
                                <Box className="profile-icon">
                                  {this.getFirstLetterOfWord(
                                    this.state.userFirstName
                                  )}
                                  {this.getFirstLetterOfWord(
                                    this.state.userLastName
                                  )}
                                </Box>
                              </Box>
                              <Box className="profile-details">
                                <Box className="profile-name">
                                  {this.state.userFirstName}{" "}
                                  {this.state.userLastName}
                                </Box>
                                <Box className="job-info">
                                  {this.state.userCompanyName} -{" "}
                                  {this.state.userJobRole}
                                </Box>
                                <Box className="profile-email">
                                  {this.state.userEmail}
                                </Box>
                              </Box>
                            </Box>
                            <Box className="edit-fullbtn">
                              <Button
                                className={`primary-btn ${
                                  classes.primaryButton
                                }`}
                                onClick={this.editProfile}
                              >
                                Edit Profile
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>*/}
                      <Box className="profile-block-wrapper profile-form-wrapper">
                        <Box className={`${classes.profileInfo} profile-info`}>
                          <Box className="profile-outer">
                            <Box className="profile-icon">
                              {this.getFirstLetterOfWord(
                                this.state.userFirstName
                              )}
                              {this.getFirstLetterOfWord(
                                this.state.userLastName
                              )}
                            </Box>
                          </Box>
                          <Box className="profile-details">
                            <Box className="profile-name">
                              {this.state.userFirstName}&nbsp;
                              {this.state.userLastName}
                            </Box>
                            <Box className="job-info">
                              {this.state.userCompanyName} - {this.userToken?.data.attributes.job_type}
                              ({this.state.userJobRole})
                            </Box>
                          </Box>
                        </Box>
                        <Box component="form">
                          <Box className="profile-team">
                            <Box className="team-label">Teams</Box>
                            <Box className="chip-wrapper">{getMyTeams()}</Box>
                          </Box>
                          <Box className="form-info-outer">
                            <Box className={`${classes.formInfoWrapper} form-info-wrapper`}>
                              <Typography className="form-heading" variant="h2">
                                {this.t(`${configJSON.personalInfo}`)}
                              </Typography>
                              <Box className="form-row">
                                <Box className="form-col col6">
                                  <CustomInputWeb
                                    isRequired
                                    type="text"
                                    label={this.t(`${configJSON.firstName}`)}
                                    name="firstName"
                                    value={this.state.firstName}
                                    errors={
                                      this.state.error.errorFirstnameNotValid
                                    }
                                    onChange={this.handleChange}
                                    data-test-id="userFname"
                                  />
                                </Box>
                                <Box className="form-col col6">
                                  <CustomInputWeb
                                    isRequired
                                    type="text"
                                    label={this.t(`${configJSON.lastName}`)}
                                    name="lastName"
                                    value={this.state.lastName}
                                    errors={
                                      this.state.error.errorlastNameNotValid
                                    }
                                    onChange={this.handleChange}
                                    data-test-id="userLname"
                                  />
                                </Box>
                              </Box>
                              <Box className="form-row">
                                <Box className="form-col email-field">
                                  <CustomInputWeb
                                    isRequired
                                    type="text"
                                    label={this.t(`${configJSON.email}`)}
                                    disabled={true}
                                    value={this.state.userEmail}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Link
                                          to="#"
                                          className="secondary-link"
                                          onClick={this.changeEmailHandler}
                                          data-test-id="changeModalOpen"
                                        >
                                          {this.t(`${configJSON.changeEmail}`)}
                                        </Link>
                                      </InputAdornment>
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box className="form-row">
                                <Box className="form-col password-field">
                                  <CustomInputWeb
                                    isRequired
                                    type="password"
                                    label="Password"
                                    disabled={true}
                                    value="12345678"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Link
                                          to="#"
                                          onClick={this.changePasswordHandler}
                                          className="secondary-link"
                                          data-test-id="changePassModalOpen"
                                        >
                                          {this.t(`${configJSON.changePassword}`)}                                          
                                        </Link>
                                      </InputAdornment>
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box className="form-row">
                                <Box
                                  className={`form-col ${
                                    this.isOwner() ? "col6" : ""
                                  }`}
                                >
                                  <FreeSoloCreateOption
                                    options={this.state.jobTitles.map((val) => {
                                      return {
                                        id: val.id,
                                        title: val.attributes.title,
                                      };
                                    })}
                                    addEditCallback={this.addJobTitle}
                                    addLinkTitle={this.t(`${configJSON.addNewJobtxt}`)}
                                    noOptionTitle={this.t(`${configJSON.noJobTitle}`)}
                                    label={this.t(`${configJSON.jobTitle}`)}
                                    id="job-id"
                                    onChange={this.titleChangeHandler}
                                    value={this.state.jobTitle}
                                    errors={this.state.error.errorJobTitle}
                                    deleteCallback={this.deleteJobTitle}
                                    errorMsgNoRecord={this.t(`${configJSON.noJobTitleCreated}`)}
                                    disabled={!this.isOwner()}
                                    notRequired={!this.isOwner()}
                                    data-test-id="profileJobTitle"
                                    isIcon={false}
                                  />
                                  {this.state.isDeleting && (
                                    <ConfirmActionModal
                                      isOpen={this.state.isDeleting}
                                      handleClose={this.handleCloseTitle}
                                      modalConfirmAction={
                                        this.deleteJobTitleFromList
                                      }
                                      deleteTeamId={this.state.deleteJobTitleId}
                                      modalMessage={this.t(`${configJSON.deleteJobTitke}`)}
                                      confirmBtnTxt={this.t(`${configJSON.delete}`)}
                                      modalHeading={this.t(`${configJSON.deleteTitle}`)}
                                      cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                                      data-testid="confirmDeleteTitle"
                                    />
                                  )}
                                </Box>
                                {this.isOwner() && (
                                  <Box className="form-col col6 hourlyPay">
                                    <CustomInputWeb
                                      isRequired
                                      type="number"
                                      label={this.t(`${configJSON.hourlyPay}`)}
                                      name="hourlyPay"
                                      value={this.state.hourlyPay ?? ""}
                                      errors={this.state.error.errorHourlyPay}
                                      onChange={this.handleChange}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <p style={{ color: "#94A3B8" }}>$</p>
                                        </InputAdornment>
                                      }
                                      data-test-id="userHourlyPay"
                                    />
                                    <FormHelperText className="helper-text">
                                      {this.t(`${configJSON.calculateTxt}`)}
                                    </FormHelperText>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            {this.isOwner() && (
                              <Box
                                className={`${classes.formInfoWrapper} form-info-wrapper`}
                              >
                                <Typography
                                  className="form-heading"
                                  variant="h2"
                                >
                                  {this.t(`${configJSON.businessInfoTxt}`)}
                                </Typography>
                                <Box className="form-row">
                                  <Box className="form-col">
                                    <CustomInputWeb
                                      isRequired
                                      type="text"
                                      label={this.t(`${configJSON.companyName}`)}
                                      name="userCompanyName"
                                      value={this.state.userCompanyName}
                                      errors={this.state.error.errorCmpName}
                                      onChange={this.handleChange}
                                      data-test-id="userCmpname"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  className="form-subheading"
                                  variant="h3"
                                >                                  
                                  {this.t(`${configJSON.billingAddress}`)}
                                </Typography>
                                <Box className="form-row">
                                  <Box
                                    className={`form-col col6 billing-select`}
                                  >
                                    <FormControl
                                      required
                                      className={`select-outer ${
                                        this.state.error.errorCountry !== ""
                                          ? "error-outer"
                                          : ""
                                      }`}
                                      variant="outlined"
                                      error={
                                        this.state.error.errorCountry !== ""
                                      }
                                    >
                                      <InputLabel id="choose-country">
                                        {this.t(`${configJSON.country}`)}
                                      </InputLabel>
                                      <Select
                                        labelId="choose-country"
                                        id="choose-country"
                                        value={this.state.country}
                                        label={this.t(`${configJSON.country}`)}
                                        name="country"
                                        defaultValue={this.state.country}
                                        disabled={
                                          this.state.countryList.length === 0
                                        }
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          getContentAnchorEl: null,
                                          classes: {
                                            paper: classes.dropdownStyle,
                                          },
                                        }}
                                        onChange={this.handleSelectChange}
                                        data-test-id="countrySelect"
                                      >
                                        {/* <MenuItem
                                                key=" United States"
                                                value=" United States"
                                              >
                                                 United States
                                              </MenuItem>
                                              <MenuItem
                                                key="Australia"
                                                value="Australia"
                                              >
                                                 Australia
                                              </MenuItem> */}
                                        {this.state.countryList.map(
                                          (country: any) => {
                                            return (
                                              <MenuItem
                                                key={country.value}
                                                value={country.label}
                                              >
                                                {country.label}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                      {this.state.error.errorCountry !== "" ? (
                                        <FormHelperText className="error-select">
                                          {this.state.error.errorCountry}
                                        </FormHelperText>
                                      ) : (
                                        ""
                                      )}
                                    </FormControl>
                                  </Box>
                                  <Box
                                    className={`form-col col6 billing-select `}
                                  >
                                    <FormControl
                                      required
                                      className={`select-outer ${
                                        this.state.error.errorState !== ""
                                          ? "error-outer"
                                          : ""
                                      }`}
                                      variant="outlined"
                                    >
                                      <InputLabel id="choose-state">
                                      {this.t(`${configJSON.state}`)}
                                      </InputLabel>
                                      <Select
                                        labelId="choose-state"
                                        id="choose-state"
                                        name="stateVal"
                                        value={this.state.stateVal}
                                        label={this.t(`${configJSON.state}`)}
                                        disabled={
                                          this.state.stateList.length === 0
                                        }
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          getContentAnchorEl: null,
                                          classes: {
                                            paper: classes.dropdownStyle,
                                          },
                                        }}
                                        onChange={this.handleSelectChange}
                                        data-test-id="stateSelect"
                                      >
                                        {this.state.stateList.map(
                                          (state: any) => {
                                            return (
                                              <MenuItem
                                                key={state.value}
                                                value={state.label}
                                              >
                                                {state.label}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                      {this.state.error.errorState !== "" && (
                                        <FormHelperText className="error-select">
                                          {this.state.error.errorState}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Box>
                                  <Box className="form-col col6">
                                    <CustomInputWeb
                                      // isRequired
                                      type="text"
                                      label={this.t(`${configJSON.postalCode}`)}
                                      name="postalCode"
                                      // errors={this.state.error.errorPostalCode}
                                      value={this.state.postalCode}
                                      onChange={(event) => this.handleProfilePostalCode((event))}
                                      data-test-id="postalCode"
                                    />
                                  </Box>
                                  <Box
                                    className={`form-col col6 billing-select`}
                                  >
                                    <FormControl
                                      // required
                                      className="select-outer"
                                      variant="outlined"
                                    >
                                      <InputLabel id="choose-city">
                                      {this.t(`${configJSON.city}`)}
                                      </InputLabel>
                                      <Select
                                        labelId="choose-city"
                                        id="choose-city"
                                        name="city"
                                        value={this.state.city}
                                        disabled={
                                          this.state.cityList.length === 0
                                        }
                                        label={this.t(`${configJSON.city}`)}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          getContentAnchorEl: null,
                                          classes: {
                                            paper: classes.dropdownStyle,
                                          },
                                        }}
                                        onChange={this.handleSelectChange}
                                        data-test-id="citySelect"
                                      >
                                        {this.state.cityList.map(
                                          (city: any) => {
                                            return (
                                              <MenuItem
                                                key={city.value}
                                                value={city.label}
                                              >
                                                {city.label}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Box>
                                <Box className="form-row">
                                  <Box className="form-col">
                                    <CustomInputWeb
                                      type="text"
                                      label={this.t(`${configJSON.companyAddress}`)}
                                      name="userCompanyAddress"
                                      errors={this.state.error.errorAddress}
                                      value={this.state.userCompanyAddress}
                                      onChange={this.handleChange}
                                      data-test-id="cmpAdreess"
                                    />
                                  </Box>
                                </Box>
                                <Box className="form-row">
                                  <Box className="form-col">
                                    <FormControl variant="outlined" className="select-outer">
                                      <InputLabel className={classes.inActiveLabel} style={{ color: '#94a3b8' }}>
                                        Currency
                                      </InputLabel>
                                      <Select
                                        value={this.state.currencyName}
                                        name={"Currency-Select"}
                                        onChange={this.currencyHandler}
                                        label={"Currency"}
                                        IconComponent={(props) => (
                                          <ExpandMoreIcon {...props} />
                                        )}
                                        data-test-id="currency-handler"
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          classes: { paper: classes.dropdownStyle },
                                          getContentAnchorEl: null,
                                        }}
                                      >
                                        {this.displayCurrency().map((item: any) => {
                                          return (
                                            <MenuItem value={item.value} key={item.value}>
                                              <span className="currencySymbol">{item.currencySymbol}</span><span className="currencyCode">{item.currencyCode}</span><span className="currencyName">{item.currencyName}</span>
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box className="button-wrapper full-btn">
                            <Button
                              className={`primary-btn ${classes.primaryButton}`}
                              title="Save"
                              onClick={this.editProfileController}
                              data-test-id="saveProfile"
                            >
                             {this.t(`${configJSON.save}`)}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      {/*
                                            <Box className="profile-block-wrapper">
                                                <Box className="edit-row">
                                                <Box className="edit-left">
                                                    <Box
                                                    className={`${classes.profileInfo} profile-info`}
                                                    >
                                                    <Box className="profile-outer">
                                                        <Box className="profile-icon">EH</Box>
                                                    </Box>
                                                    <Box className="profile-details">
                                                        <Box className="profile-name">
                                                        Esther Howard
                                                        </Box>
                                                        <Box className="job-info">
                                                        Company Name - Role (Job Title)
                                                        </Box>
                                                        <Box className="profile-email">
                                                        estherhoward@gmail.com
                                                        </Box>
                                                    </Box>
                                                    </Box>
                                                </Box>
                                                <Box className="edit-right">
                                                    <Button
                                                    className={`primary-btn ${classes.primaryButton}`}
                                                    onClick={this.editProfile}
                                                    >
                                                    Edit Profile
                                                    </Button>
                                                </Box>
                                                </Box>
                                            </Box>
                                            */}
                      {/*this.state.isEditProfile && (
                                                <Modal
                                                    open={this.state.isEditProfile}
                                                    onClose={this.handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                    className={classes.modalDialog}
                                                >
                                                    <Box className={`${classes.rightModalDialogBox}`}>
                                                        <Box className="modal-dialoginner">
                                                            <Box className="modal-heading">
                                                                <Typography
                                                                    className="modal-title"
                                                                    component="h2"
                                                                >
                                                                    Edit Profile
                                                                </Typography>
                                                                <Link
                                                                    to="#"
                                                                    className="close-icon"
                                                                    onClick={this.handleClose}
                                                                >
                                                                    <CloseIcon />
                                                                </Link>
                                                            </Box>
                                                            <Box className="modal-description">
                                                                <Box component="p" className="sub-txt">
                                                                    You can modify your profile according to your
                                                                    need.
                                                                </Box>
                                                                <Box
                                                                    className={`${classes.profileInfo
                                                                        } profile-info`}
                                                                >
                                                                    <Box className="profile-outer">
                                                                        <Box className="profile-icon">{this.getFirstLetterOfWord(this.state.userFirstName)}{this.getFirstLetterOfWord(this.state.userLastName)}</Box>
                                                                    </Box>
                                                                    <Box className="profile-name">
                                                                        {this.state.userFirstName} {this.state.userLastName}
                                                                    </Box>
                                                                    <Box className="job-info">
                                                                        {this.state.userCompanyName} - {this.state.userJobRole}
                                                                    </Box>
                                                                </Box>
                                                                <Box className="modal-form" component="form">
                                                                    <Box
                                                                        className={`${classes.formInfoWrapper
                                                                            } form-info-wrapper`}
                                                                    >
                                                                        <Box className="form-row">
                                                                            <Box className="form-col col6">
                                                                                <CustomInputWeb
                                                                                    type="text"
                                                                                    label="First name"
                                                                                    name="fName"
                                                                                    value={this.state.firstName}
                                                                                    onChange={(event) =>
                                                                                        this.setState({
                                                                                            firstName: event.target.value,
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Box className="form-col col6">
                                                                                <CustomInputWeb
                                                                                    type="text"
                                                                                    label="Last name"
                                                                                    name="lName"
                                                                                    value={this.state.lastName}
                                                                                    onChange={(event) =>
                                                                                        this.setState({
                                                                                            lastName: event.target.value,
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="form-row">
                                                                            <Box className="form-col">
                                                                                <CustomInputWeb
                                                                                    type="text"
                                                                                    label="Email"
                                                                                    disabled={true}
                                                                                    value={this.state.userEmail}
                                                                                    endAdornment={
                                                                                        <InputAdornment position="end">
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="secondary-link"
                                                                                                onClick={this.changeEmailHandler}
                                                                                            >
                                                                                                Change Email
                                                                                            </Link>
                                                                                        </InputAdornment>
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="form-row">
                                                                            <Box className="form-col">
                                                                                <CustomInputWeb
                                                                                    type="password"
                                                                                    label="Password"
                                                                                    disabled={true}
                                                                                    value="123456"
                                                                                    endAdornment={
                                                                                        <InputAdornment position="end">
                                                                                            <Link
                                                                                                to="#"
                                                                                                onClick={this.changePasswordHandler}
                                                                                                className="secondary-link"
                                                                                            >
                                                                                                Change Password
                                                                                            </Link>
                                                                                        </InputAdornment>
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="modal-footer">
                                                                        <Button
                                                                            className={`secondary-btn ${classes.secondaryButton
                                                                                }`}
                                                                            title="Cancel"
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                        <Button
                                                                            className={`primary-btn ${classes.primaryButton
                                                                                }`}
                                                                            title="Save"
                                                                            onClick={this.editProfileController}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                                                            )*/}
                      {this.state.isEditingEmail && (
                        <EmailEdit
                          classes={classes}
                          modalClose={this.handleClose}
                          backToEdit={this.handleEditBack}
                          saveNewEmail={this.saveEmailAddressValue}
                          updateMemberEmail={this.updateUserEmail}
                          allStates={this.state}
                          pageType="profile_page"
                          t={this.t} 
                        />
                      )}
                      {this.state.isEditingPassword && (
                        <EditPassword
                          classes={classes}
                          modalOpen={this.state.modalOpen}
                          modalClose={this.isPasswordPopupClose}
                          backToEdit={this.handleEditBack}
                          newPasswordVal={this.state.newPassword}
                          passwordValError={this.state.newPasswordError}
                          saveNewPassword={this.savePasswordValue}
                          updateMemberEmail={this.updateUserPassword}
                          pageType="profile_page"
                          oldPasswordVal={this.state.oldPassword}
                          oldPasswordValError={this.state.oldPasswordError}
                          saveOldPassword={this.saveOldPasswordValue}
                          t={this.t}
                        />
                      )}
                      {this.state.onExitSave && 
                      <DeleteContact
                      modalOpen={this.state.onExitSave}
                      modalClose={this.handleClose}                      
                      deleteContactAction={this.onSave}
                      headding={this.t(`${configJSON.exitWithoutSave}`)}
                      cancleBtntxt={this.t(`${configJSON.exit}`)}
                      deleteBtntxt={this.t(`${configJSON.saveTxt}`)}
                      confirmationMsg={this.t(`${configJSON.exitSubTxt}`)}
                      data-test-id="exitPopup"
                    />
                      }
                      {this.isOwner() && (
                        <Box className="profile-block-wrapper">
                          <Box className="invite-member-row">
                            <Box className="invite-member-left">
                              <Box className="invite-heading">
                                {this.state.totalMembers} {this.t(`${configJSON.members}`)}
                              </Box>
                              <Box component="p" className="sub-txt">                                
                                {this.t(`${configJSON.letsInviteMember}`)}
                              </Box>
                            </Box>
                            <Box className="invite-member-right">
                              <Button
                                className={`secondary-btn ${classes.secondaryButton}`}
                                onClick={this.returnTeam}
                              >                                
                                {this.t(`${configJSON.myTeamText}`)}
                              </Button>
                              <Button
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              variant="contained"
                                className={`primary-btn ${classes.primaryButton}`}
                                onClick={this.dropMenuClick}
                              >
                                {this.t(`${configJSON.inviteMember}`)}
                              </Button>
                              <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  className={classes.dropDropdown}
                  getContentAnchorEl={null}
                  onClose={() => { this.setState({ anchorEl: null }) }}
                >
                  <MenuItem data-test-id="addAnotherMember" onClick={this.addAnotherMember}>{this.t(`${configJSON.addManuallyTxt}`)}</MenuItem>
                  <MenuItem data-test-id="navigateToBulk" onClick={this.navigateToBulk}>{this.t(`${configJSON.bulkUploadTxt}`)}</MenuItem>
                </Menu>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {/* <Box className="profile-block-wrapper">
                                <Box className={`${classes.profileInfo} profile-info`}>
                                  <Box className="profile-outer">
                                    <Box className="profile-icon">
                                      EH
                                    </Box>
                                  </Box>                                                
                                  <Box className="profile-name">Esther Howard</Box>
                                  <Box className="job-info">Company Name - Role (Job Title)</Box>                                
                                </Box>
                                <Box component="form">
                                  <Box className="profile-team">
                                    <Box className="team-label">Teams</Box>
                                    <Box className="chip-wrapper">
                                      <Chip
                                        className={classes.chipButton}
                                        avatar={<Avatar alt="minus-gray" src={minusGray} />}
                                        label="Production Managment"
                                      />
                                      <Chip
                                        className={classes.chipButton}
                                        avatar={<Avatar alt="minus-gray" src={minusGray} />}
                                        label="Accounts Team"
                                      />
                                      <Chip
                                        className={classes.chipButton}
                                        avatar={<Avatar alt="minus-gray" src={minusGray} />}
                                        label="Sales Team"
                                      />
                                      <Chip
                                          className={classes.chipButton}
                                          avatar={<Avatar alt="minus-gray" src={minusGray} />}
                                          label="Managment Team"
                                      />
                                      <Chip
                                        className={`more-btn ${classes.chipButton}`}                                                        
                                        label="+2 more"
                                      />
                                    </Box>
                                  </Box>  
                                  <Box className={`{"form-info-wrapper}`}>
                                    <Typography
                                      className="form-heading"
                                      variant="h2"
                                    >
                                      Personal Information
                                    </Typography>
                                    <Box className="form-row">
                                      <Box className="form-col col6">
                                        <CustomInputWeb
                                          type="text"
                                          label="First name"
                                          name="fName"
                                        />
                                      </Box>
                                      <Box className="form-col col6">
                                        <CustomInputWeb
                                          type="text"
                                          label="Last name"
                                          name="lName"
                                        />
                                      </Box>
                                    </Box>
                                    <Box className="form-row">
                                      <Box className="form-col col6">
                                      <CustomInputWeb
                                        type="text"
                                        isRequired={true}
                                        label="Email"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Link
                                              to=""
                                              className="secondary-link"
                                            >
                                              Change Email
                                            </Link>
                                          </InputAdornment>
                                        }
                                      />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>                              
                              </Box>   */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Footer /> */}

          {this.state.isAddTeam && (
            <Modal
              open={this.state.isAddTeam}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialog}
            >
              <Box className={`${classes.rightModalDialogBox}`}>
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Typography className="modal-title" component="h2">                     
                      {this.t(`${configJSON.addTeam}`)}
                    </Typography>
                    <Link
                      to="#"
                      className="close-icon"
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </Link>
                  </Box>
                  <Box className="modal-description">
                    <Box component="p" className="sub-txt">
                      {this.t(`${configJSON.selectTeamText}`)}
                    </Box>
                    <Box className="modal-form" component="form">
                      <Box className="form-info-outer">
                        <Box
                          className={`${classes.formInfoWrapper} form-info-wrapper`}
                        >
                          <Box className="form-row">
                            <Box className="form-col">
                              <Autocomplete
                                multiple
                                className="team-multiselect"
                                options={displayTeamList}
                                value={this.state.selectedTeams}
                                onChange={this.teamSelectHandler}
                                disableCloseOnSelect
                                ChipProps={{ deleteIcon: <CloseIcon /> }}
                                popupIcon={<ExpandMoreIcon />}
                                getOptionLabel={(option) => option.title}
                                classes={{
                                  popper: classes.teamAutocompleteDropbox,
                                }}
                                renderOption={(option, { selected }) => (
                                  <Box className="tasklist-li">
                                    <Box className="tasklist-left">
                                      {option.title}
                                    </Box>
                                    <Box className="tasklist-icon">
                                      <Checkbox
                                        className="checklist-check"
                                        icon={
                                          <img
                                            src={checkBoxEmpty}
                                            alt="checkBoxEmpty"
                                          />
                                        }
                                        checkedIcon={
                                          <img
                                            src={checkBoxFilled}
                                            alt="checkBoxFilled"
                                          />
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        checked={selected}
                                      />
                                    </Box>
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Choose Team"
                                    placeholder=""
                                    autoComplete="off"
                                  />
                                )}
                                getOptionSelected={(option, value) =>
                                  option.id == value.id
                                }
                                data-test-id="addTeamSelect"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="button-wrapper full-btn">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          title="Add Team"
                          onClick={this.addToNewTeamApiCall}
                          data-test-id="addTeam"
                        >
                          {this.t(`${configJSON.addTeam}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>

        {this.state.isRemoveTeam && (
          <ConfirmActionModal
            isOpen={this.state.isRemoveTeam}
            handleClose={this.handleClose}
            modalConfirmAction={this.removeMemberApiCall}
            modalMessage= {this.t(configJSON.removeConfirmationTxt,  {title: this.state.teamTitle} )}
            confirmBtnTxt={this.t(`${configJSON.remove}`)}
            modalHeading={this.t(`${configJSON.removeTeam}`)}
            cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
            data-testid="confirmRemoveTeam"
          />
        )}
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(CustomisableUserProfiles)
);
// Customizable Area End