// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import SplashScreen from './common/SplashScreen.web';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import EmailAccountRegister from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import EmailAccountConfirmation from '../../blocks/email-account-login/src/EmailAccountConfirmation.web'
import CreateProfile from '../../blocks/email-account-registration/src/CreateAccount.Web';
import AccountCreation from '../../blocks/email-account-registration/src/teams-web/AccountCreation.web';
import AccountViewTeam from '../../blocks/email-account-registration/src/teams-web/AccountCreationViewTeam.web';
import AddMembersTeam from "../../blocks/email-account-registration/src/teams-web/AddMembersTeam.web"
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import RecoverPassword from '../../blocks/email-account-login/src/RecoverPassword.web';
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import PasswordResetSuccess from '../../blocks/forgot-password/src/PasswordResetSuccess.web';
import FirstLoginCreatePassword from '../../blocks/email-account-login/src/FirstLoginCreatePassword.web';
import InviteMembers from '../../blocks/email-account-registration/src/teams-web/InviteMembers.web';
import OwnerOnboardingBlock from '../../blocks/email-account-registration/src/OwnerOnboardingBlock.web';
import OnboardingWelcome from '../../blocks/email-account-registration/src/OnboardingWelcome.web';
import MemberAddedSuccess from '../../blocks/email-account-registration/src/teams-web/MemberAddedSuccess.web';
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web';
import BulkUpload from '../../blocks/bulkuploading/src/web/BulkUpload.web';
import ProcessingContactScreen from '../../blocks/bulkuploading/src/web/ProcessingContactScreen.web';
import Settings5 from '../../blocks/Settings5/src/Settings5.web';
import DateAndNumberFormat from '../../blocks/Settings5/src/DateAndNumberFormat.web';
import TaskList from '../../blocks/TaskList/src/web/TaskList.web';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide.web';
import EquizDashboardWeb from '../../blocks/cfescore/src/EquizDashboard.web';
import EScoreDashboardWeb from "../../blocks/cfescore/src/EquizDashboard.web"
import OwnerDashboard from '../../blocks/cfescore/src/OwnerDashboard.web';
import MarketingDashboard from '../../blocks/cfescore/src/components/MarketingDashboard.web';
import ManagerDashboard from '../../blocks/cfescore/src/components/ManagerDashboard.web';
import ProjectLeadDashboard from '../../blocks/cfescore/src/components/ProjectLeadDashboard.web';

import SubscriptionPaymentSuccess from '../../blocks/email-account-registration/src/teams-web/SubscriptionPaymentSuccess.web';
import SubscriptionMemberAddedSuccess from '../../blocks/email-account-registration/src/teams-web/SubscriptionMemberAddedSuccess.web';

import TutorialScreen from '../../blocks/email-account-registration/src/TutorialScreen.web'

import EnteringQuiz from '../../blocks/cfescore/src/EnteringQuiz.web';
import EquizScoreWeb from '../../blocks/cfescore/src/QuizScoreScreen.web';
import SimulationModeScore from "../../blocks/cfescore/src/SimulationModeScore.web";
import ContactsList from "../../blocks/ContactsList/src/ContactsList.web";
import ProductList from "../../blocks/ContactsList/src/ProductList.web";
import GroupList from "../../blocks/ContactsList/src/GroupList.web";
import ViewContactsGroup from "../../blocks/ContactsList/src/ViewContactsGroup.web";
import ReTakeQuizWeb from "../../blocks/cfescore/src/ReTakeQuiz.web";
import AddItem from "../../blocks/inventorymanagement/src/AddItem.web";
import DirectEmailsHomePage from "../../blocks/EmailNotifications/src/DirectEmailsHomePage.web";
import BulkUploadContact from "../../blocks/ContactsList/src/BulkUploadContact.web.tsx";
import ViewContact from "../../blocks/ContactsList/src/ViewContact.web.tsx";
import TaskListSideBar from '../../blocks/TaskList/src/web/TaskListSideBar.web';
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement.web";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement.web";
import ItemSuccessPage from "../../blocks/inventorymanagement/src/ItemSuccessPage.web";
import SendEmailSuccessPage from "../../blocks/inventorymanagement/src/SendEmailSuccess.web"
import ViewItem from "../../blocks/inventorymanagement/src/ViewItem.web";
import CfSendinblueWeb from "../../blocks/cfsendinblueapi/src/Cfsendinblueapi.web.tsx";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem.web";
import LanguageSupport from '../../blocks/languagesupport/src/LanguageSupport.web';
import CfEtohApi6 from "../../blocks/CfEtohApi6/src/CfEtohApi6.web"
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails.web";
import PaymentSuccess from "../../blocks/shoppingcart/src/PaymentSuccess.web";
import OrderHistory from "../../blocks/ordermanagement/src/OrderHistory.web";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2.web";
import SendToContact from "../../blocks/inventorymanagement/src/SendToContact.web";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement.web";
import DownloadTimeSheet from "../../blocks/TimesheetManagement/src/DownloadTimesheet.web";
import ChooseMetrics from "../../blocks/cfmetaapi/src/ChooseMetrics.web";
import StartIntegration from "../../blocks/cfgoogleapi/src/StartIntegration.web";
import Scheduling from "../../blocks/scheduling/src/Scheduling.web";
import AddOwner from "../../blocks/cfescore/src/components/AddOwner";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2.web";
import SalesDashboard from '../../blocks/cfescore/src/SalesDashboard.web';
import TaskOwnerDashboard from '../../blocks/cfescore/src/TaskOwnerDashboard.web';

const routeMap = {
  SplashScreen: {
    component: SplashScreen,
    path: '/',
    exact: true
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/login',
    isAuth: true
  },
  EmailAccountRegister: {
    component: EmailAccountRegister,
    path: '/register',
    isAuth: true
  },
  TutorialScreen: {
    component: TutorialScreen,
    path: '/tutorial',
    isProtected: true
  },
  CreateProfile: {
    component: CreateProfile,
    path: '/create-account',
    isProtected: true
  },
  OwnerOnboardingBlock: {
    component: OwnerOnboardingBlock,
    path: '/profile/create-team',
    exact: true,
    isProtected: true,
    accessBy: ['owner']
  },
  OnboardingWelcome: {
    component: OnboardingWelcome,
    path: '/profile/team-success',
    isProtected: true,
    accessBy: ['owner']
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide',
    isProtected: true,
    accessBy: ['owner']
  },
  AccountCreation: {
    component: AccountCreation,
    path: '/create-team',
    isProtected: true,
    exact: true,
    isProfileCompleted: true,
    accessBy: ['owner', 'manager', 'employee']
  },
  AccountViewTeam: {
    component: AccountViewTeam,
    path: '/view-team-members/:teamID',
    isProfileCompleted: true,
    isProtected: true
  },
  AddMembersTeam: {
    component: AddMembersTeam,
    path: '/add-members/:teamID',
    isProfileCompleted: true,
    isProtected: true
  },
  RecoverPassword: {
    component: RecoverPassword,
    path: '/recover-password'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/profile',
    isProtected: true,
    isProfileCompleted: true,
    exact: true
  },
  TicketSystem: {
    component: TicketSystem,
    path: '/help-center',
    isProtected: true,
    isProfileCompleted: true,
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/password/forgot',
    exact: true
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/password/reset',
    exact: true
  },
  PasswordResetSuccess: {
    component: PasswordResetSuccess,
    path: '/reset/success',
    exact: true
  },
  FirstLoginCreatePassword: {
    component: FirstLoginCreatePassword,
    path: '/password/create'
  },
  InviteMembers: {
    component: InviteMembers,
    path: '/invite-members',
    isProfileCompleted: true,
    isProtected: true,
    accessBy: ['owner']
  },
  MemberAddedSuccess: {
    component: MemberAddedSuccess,
    path: '/members-success',
    isProtected: true,
    isProfileCompleted: true,
    accessBy: ['owner']
  },
  BulkUpload: {
    component: BulkUpload,
    path: '/bulk',
    isProtected: true,
    isProfileCompleted: true,
    accessBy: ['owner']
  },
  ProcessingContactScreen: {
    component: ProcessingContactScreen,
    path: '/processing-contact',
    isProtected: true,
    isProfileCompleted: true,
    accessBy: ['owner']
  },
  EmailAccountConfirmation: {
    component: EmailAccountConfirmation,
    path: '/accounts/email-confirmation/'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/languageSupport',
    isProtected: true,
    // accessBy: ['owner']
  },
  Settings5: {
    component: Settings5,
    path: '/settings',
   isProtected: true,
    // accessBy: ['owner']
  },
  DateAndNumberFormat: {
    component: DateAndNumberFormat,
    path: '/formatting',
   isProtected: true,
  },
  TaskList: {
    component: TaskList,
    path: '/taskList',
    isProfileCompleted: true,
    isProtected: true
  },
  EquizDashboardWeb: {
    component: EquizDashboardWeb,
    path: '/dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  EScoreDashboardWeb: {
    component: EScoreDashboardWeb,
    path: '/EScore',
    isProfileCompleted: true,
    isProtected: true,
  },
  EnteringQuiz: {
    component: EnteringQuiz,
    path: '/enter-quiz/:quizId?',
    isProfileCompleted: true,
    isProtected: true,
  },
  EquizScoreWeb: {
    component: EquizScoreWeb,
    path: '/quiz-score',
    isProfileCompleted: true,
    isProtected: true,
  },
  SimulationModeScore: {
    component: SimulationModeScore,
    path: '/simulationModeScore',
    isProfileCompleted: true,
    isProtected: true,
  },
  DirectEmailsHomePage: {
    component: DirectEmailsHomePage,
    path: '/direct-emails',
  },
  DirectEmailsDraftsPage: {
    component: DirectEmailsHomePage,
    path: '/direct-emails-drafts',
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/inventorymanagement',
    isProfileCompleted: true,
    isProtected: true,
  },
  AddItem: {
    component: AddItem,
    path: '/AddItem/:itemId?',
    isProfileCompleted: true,
    isProtected: true,
  },
  InventoryManagementBulk: {
    component: BulkUpload,
    path: '/bulk-inventory',
    isProfileCompleted: true,
    isProtected: true,
  },
  ItemSuccessPage: {
    component: ItemSuccessPage,
    path: '/ItemSuccessPage',
    isProfileCompleted: true,
    isProtected: true,
  },
  SendEmailSuccessPage: {
    component: SendEmailSuccessPage,
    path: '/SendEmailSuccess',
    isProfileCompleted: true,
    isProtected: true,
  },
  ViewItem: {
    component: ViewItem,
    path: '/ViewItem/:itemId',
    isProfileCompleted: true,
    isProtected: true,
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails/:itemId',
    isProfileCompleted: true,
    isProtected: true,
  },
  ReTakeQuizWeb: {
    component: ReTakeQuizWeb,
    path: '/retake-quiz',
    isProfileCompleted: true,
    isProtected: true,
  },
  ContactsList: {
    component: ContactsList,
    path: '/contacts',
    isProfileCompleted: true,
    isProtected: true,
  },
  BulkUploadContact: {
    component: BulkUploadContact,
    path: '/contacts-import',
    isProfileCompleted: true,
    isProtected: true,
    exact: true,
  },
  ViewContact: {
    component: ViewContact,
    path: '/view-contact',
  },
  ProductList: {
    component: ProductList,
    path : '/products',
    isProfileCompleted: true,
    isProtected: true,
  },
  GroupList: {
    component: GroupList,
    path : '/groups',
    isProfileCompleted: true,
    isProtected: true,
  },
  ViewContactsGroup: {
    component: ViewContactsGroup,
    path : '/group-contacts',
    isProfileCompleted: true,
    isProtected: true,
  },
  TaskListSideBar: {
    component: TaskListSideBar,
    path : '/project',
    isProfileCompleted: true,
    isProtected: true
  },
  LeadManagement:{
    component: LeadManagement,
    path: '/deals',
    isProfileCompleted: true,
    isProtected: true,
  },
  CfSendinblueWeb:{
    component: CfSendinblueWeb,
    path: '/email-campaigns',
    isProfileCompleted: true,
    isProtected: true,
  },
  CfSendinblueWebDrafts:{
    component: CfSendinblueWeb,
    path: '/email-campaigns-drafts',
    isProfileCompleted: true,
    isProtected: true,
  },
  CfSendinblueWebScheduled:{
    component: CfSendinblueWeb,
    path: '/email-campaigns-scheduled',
    isProfileCompleted: true,
    isProtected: true,
  },
  CfEtohShop:{
    component: CfEtohApi6,
    path: '/etoh-shop',
    isProfileCompleted: true,
    isProtected: true,
  },
  ShoppingCart:{
    component: PaymentSuccess,
    path: '/payment-success',
    isProfileCompleted: true,
    isProtected: true,
  },
  OrderHistory:{
    component: OrderHistory,
    path: '/order-history',
    isProfileCompleted: true,
    isProtected: true,
  },
  LiveChat:{
    component: LiveChat2,
    path: '/live-chat',
    isProfileCompleted: true,
    isProtected: true,
  },
  SendToContact: {
    component: SendToContact,
    path: '/sendContact',
    isProfileCompleted: true,
    isProtected: true,
  },
  TimesheetManagement:{
    component: TimesheetManagement,
    path: '/timesheet',
    isProfileCompleted: true,
    isProtected: true,
  },
  DownloadTimeSheet:{
    component: DownloadTimeSheet,
    path: '/downloadtimesheet/:id',
    isProfileCompleted: true,
    isProtected: true,
  },
  ConnectAccountIntegrationsScreen:{
    component: StartIntegration,
    path: '/connect',
    isProfileCompleted: true,
    isProtected: true,
  },
  ChooseMetrics:{
    component: ChooseMetrics,
    path: '/choose-metrics',
    isProfileCompleted: true,
    isProtected: true,
  },
  AddOwner:{
    component: AddOwner,
    path: '/add-owner',
    isProfileCompleted: true,
    isProtected: true,
  },
  OwnerDashboard:{
    component: OwnerDashboard,
    path: '/owner-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  MarketingDashboard:{
    component: MarketingDashboard,
    path: '/marketing-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  SalesDashboard:{
    component: SalesDashboard,
    path: '/sales-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  RolesPermissions2:{
    component: RolesPermissions2,
    path: '/roles-permissions',
    isProfileCompleted: true,
    isProtected: true,
  },
  TaskOwnerDashboard:{
    component: TaskOwnerDashboard,
    path: '/task-owner-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  ManagerDashboard:{
    component: ManagerDashboard,
    path: '/manager-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  ProjectLeadDashboard:{
    component: ProjectLeadDashboard,
    path: '/project-lead-dashboard',
    isProfileCompleted: true,
    isProtected: true,
  },
  SubscriptionPaymentSuccess: {
    component: SubscriptionPaymentSuccess,
    path: '/subscription-success',
    isProtected: true,
    isProfileCompleted: true,
    accessBy: ['owner']
  },
  SubscriptionMemberAddedSuccess: {
    component: SubscriptionMemberAddedSuccess,
    path: '/subscription-member-success',
    isProtected: true,
    isProfileCompleted: true,
    accessBy: ['owner']
  },
  Scheduling:{
    component: Scheduling,
    path: '/scheduling',
    isProfileCompleted: true,
    isProtected: true,
  },
};

const RouteWrapper = () => {
  return (
    <Switch>
      <WebRoutesGenerator routeMap={routeMap} />
    </Switch>
  );
};

export default RouteWrapper;