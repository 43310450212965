import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CustomInput from "../../../components/src/CustomInput.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";
// import { customStyles } from "../../../components/src/Inventory/AllergensStyles.web";

// Customizable Area End

import AllergensController, {
  Props,
  configJSON,
} from "./AllergensController.web";

export class Allergens extends AllergensController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {classes, seletedItem} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box className={classes.basicInfoSection}>
          {this.state.isLoading && <WebLoader />}
            <Box className="heading-box">
                <Box>
                  <Typography className={classes.heading} variant="h1">
                  {this.t(`${configJSON.allergensTitle}`)}
                  </Typography>
                  <Typography className={classes.subtitleText}>
                  {this.t(`${configJSON.allergensSubtext}`)}
                  </Typography>
                </Box>
                <Box className="item-box" data-testId="itemText">
                    {this.t(seletedItem)}
                </Box>
            </Box>
            <Box className={classes.allergenBox}>
            <CustomInput
                value={this.state.allergens}
                onChange={(event)=>this.handleAllergens(event)}
                placeholder={this.t(`${configJSON.allergensTitle}`)}
                data-testId={'allergensField'}
                isMultiline={true}
                rows={5}
            />
            </Box>
            <Box className={classes.formButtons}>
              <Button className="backBtn" onClick={this.backOneStep}  data-testId={"back-btn"}>
                {this.t(`${configJSON.backButton}`)}
              </Button>
              <Button className="next-step-btn" onClick={this.createCatalogueItems}
                data-testId={"finish-btn"}
              >
                {this.t(`${configJSON.finishButton}`)}
              </Button>
            </Box>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(Allergens);
// Customizable Area End
