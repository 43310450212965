// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import Papa from "papaparse";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./BulkUpload.web";
import { toast } from "react-toastify";
import { warningIcon } from "../assets";
import {
  beerImg,
  tractorImg,
  vesselImg,
  landImg,
  wineImg,
  nailImg,
  fuelImg,
  fuelIcon,
  testtubeImg,
  otherProducts,
  spiritImg
} from "../../../categoriessubcategories/src/assets";
import i18n from "../../../../web/src/utilities/i18n";
export const configJSON = require("../config");
const configInventory = require("../../../inventorymanagement/src/config")
const baseConfig = require("../../../../framework/src/config");
import CloseIcon from '@material-ui/icons/Close';

export type csvDataType = {
  "job title": string;
  email: string;
  "first name" : string;
  "hourly pay": string;
  "last name": string;
  role: string;
  team: string;
};

export type secondtype = {
  product_name: string;
};

type FileRows = {
  [key: string]: string;
}

type FilesRead = {
  index: number,
  file: File,
  rows: FileRows[]
}

type ApiResponse = {
  success_response: Record<string, string[]>;
  failure_response: Record<string, string[]>;
};

type ErrorRecord = {
  [key: string] : number[]
}

type ValidFileEntry = Record<string, ApiResponse>;
type ErrorFileEntry = Record<string, ErrorRecord>

type UploadApiResponse = {
  success_response: Record<string, string>;
  failure_response: Record<string, string>;
};
export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
}

interface S {
  filesUploaded: File[] | null;
  isFileValidType: boolean;
  isFileLoading: boolean;
  progress: number;
  activeStep: number;
  skipped: Set<number>;
  isPause: boolean;
  isCancel: boolean;
  timeRemain: number;
  tabValue: string;
  isTeamsTab: boolean;
  teamsList: any;
  membersList: any;
  membersEmailCheckList: any;
  searchValue: string;
  searchData: any;
  modalOpen: boolean;
  fileDrop: boolean;
  isDeleteFile: boolean;
  isDeleteMembers: boolean;
  fileSelected: FileList | null;
  selected: number[];
  selectedTeamVal: any;
  selectedRoleVal: any;
  selectedTitleVal: any;
  isFiltering: boolean;
  filteredData: any;
  isFilter: boolean;
  showLoader: boolean;
  isAscending: boolean | null;
  isFilteringTeam: boolean;
  filteredDataTeam: any;
  isAscendingTeam: boolean | null;
  selectedTeamFilter: any;
  downloadModalOpen: boolean;
  isInventory:boolean;
  inventoryFilesCurrent : FilesRead[];
  inventoryFiles : FilesRead[];
  inventoryHeader: {
    text: string,
    numeric: boolean
  }[],
  inventoryAllHeader:string[]
  inventoryDataUpload: string[][],
  inventoryData: string[][],
  inventoryFileType : string,
  inventoryCategory : string,
  inventorySubCategory:string,
  filesNeedToCheck : File[],
  sortByInventory:number,
  inventoryFilters : {
    name:string,
    selected:string[],
    options:{
      id:string,
      value:string
    }[],
    search:string
  }[],
  isCartOpen:boolean,
  responseAddedMembers: object[],
  currentTotalAddedMembers: object[],
}

interface SS {
  id: any;
}

export default class BulkUploadController extends BlockComponent<Props, S, SS> {
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  loginApiID: string = "";
  forgotPasswordApiID: string = "";
  resetPasswordApiID: string = "";
  bulkImportCsvApiID: string = "";
  bulkImportInventoryApiID:string="";
  createJobTitlesFromCsvApiId: string = "";
  createTeamsFromCsvApiId: string = "";
  filterBulkEmailApiId: string = "";
  validateFileApiId: string = "";
  userSessionData: any;
  userToken: any;
  curProgress: number = 0;
  currentParser: any;
  myRef: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      filesUploaded: null,
      isFileValidType: true,
      isFileLoading: false,
      progress: 0,
      activeStep: 0,
      skipped: new Set(),
      isPause: false,
      isCancel: false,
      timeRemain: 100,
      tabValue: "1",
      isTeamsTab: true,
      teamsList: [],
      membersList: [],
      membersEmailCheckList: [],
      searchValue: "",
      searchData: [],
      modalOpen: false,
      fileDrop: false,
      isDeleteFile: false,
      isDeleteMembers: false,
      fileSelected: null,
      selected: [],
      selectedTeamVal: [],
      selectedRoleVal: [],
      selectedTitleVal: [],
      isFiltering: false,
      filteredData: [],
      isFilter: false,
      showLoader: false,
      isAscending: null,
      isFilteringTeam: false,
      filteredDataTeam: [],
      isAscendingTeam: null,
      selectedTeamFilter: [],
      downloadModalOpen:false,
      isInventory:this.isInventory(),
      inventoryFilesCurrent:[],
      inventoryFiles:[],
      inventoryHeader:[],
      inventoryAllHeader:[],
      inventoryData:[],
      inventoryDataUpload:[],
      inventoryFileType:"",
      inventoryCategory:"",
      inventorySubCategory:"",
      filesNeedToCheck:[],
      sortByInventory:0,
      inventoryFilters:[],
      isCartOpen:false,
      responseAddedMembers: [],
      currentTotalAddedMembers:[],
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.currentParser = null;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.myRef = React.createRef();
  }


  csvFileSchema: string[] = ["First Name","Last Name","Email","Team","Role","Job Title","Hourly Pay"];
  validJobTypes: string[] = ["owner", "manager", "employee"];
  
  inventoryFiletypes = [
    {
      name: configInventory.wineText,
      type: "wine",
      img: wineImg,
      category:"Product"
    },
    {
      name: configInventory.beerText,
      type: "beer",
      img: beerImg,
      category:"Product"
    },
    {
      name: configInventory.spiritText,
      type: "spirit",
      img: spiritImg,
      category:"Product"
    },
    {
      name: configInventory.otherText.replace("Product", "product"),
      type: "other_product",
      img: otherProducts,
      category:"Product"
    },
    {
      name: configInventory.machineText,
      type: "machinery",
      img: tractorImg,
      category:"Tool"
    },
    {
      name: configInventory.hardwareText,
      type: "hardware",
      img: nailImg,
      category:"Tool"
    } ,
    {
      name: configInventory.landText,
      type: "land",
      img: landImg,
      category:"Asset"
    },
    {
      name: configInventory.vesselsText.slice(0, -1),
      type: "vessels",
      img: vesselImg,
      category:"Asset"
    },
    {
      name: configInventory.FertilizersText.slice(0, -1),
      type: "fertilizers",
      img: fuelIcon,
      category:"Consumable"
    }, 
     
    {
      name: configInventory.PhytoProductsText,
      type: "phyto_products",
      img: fuelImg,
      category:"Consumable"
    },
    {
      name: configInventory.ChemicalsText.slice(0, -1),
      type: "chemicals",
      img: testtubeImg,
      category:"Consumable"
    }, 
  
  ]

  handleClose = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }
    this.setState({
      isDeleteFile: false,
      fileSelected: null,
      isDeleteMembers: false
    });
  };

  membersDelete = () => {
    this.setState({
      membersList: this.state.membersList.filter(
        (x: any) => this.state.selected.find((y) => y == x.id) == null
      ),
      isDeleteMembers: false,
    });
  };

  multipleDelete = () => {
    this.setState({
      isDeleteMembers: true,
    });
  };
  getSelectedRecords = (selectedRecords: number[]) => {
    this.setState({
      selected: selectedRecords,
    });
  };

  handleClearSelection = (key: any) => {
    const newVal: any[] = [];
    const newState = { [key]: newVal } as Pick<S, keyof S>;
    this.setState({ ...newState, isFiltering: true }, () => {
      this.filterRecords();
    });
  };
  handleClearSelectionTeam = () => {
    this.setState({ selectedTeamFilter: [], isFilteringTeam: true }, () => {
      this.filterTeams();
    });
  };

  removeFile = () => {
    this.setState({
      isDeleteFile: false,
    });
    if (this.state.fileSelected != null) {
      this.readCsv(this.state.fileSelected);
    }
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
        this.setState({ showLoader: false });
        this.createToastNotification(this.t(`${configJSON.somethingWentWrongMsg}`));
      } else if (apiRequestCallId === this.bulkImportCsvApiID) {
        this.handleimportcsvResponse(responseJson);
      } else if (apiRequestCallId === this.createJobTitlesFromCsvApiId) {
        this.setState({ showLoader: false });
        this.importCsv();
      } else if (apiRequestCallId === this.createTeamsFromCsvApiId) {
        this.setState({ showLoader: false });
        this.createJobTitles();
      } else if (apiRequestCallId === this.filterBulkEmailApiId) {
        let validEmails = responseJson.valid_emails;
        let valuesArray: any[] = this.state.membersEmailCheckList.filter(
          (member: any) => validEmails.includes(member.attributes.email)
        );
        if (valuesArray.length > 0) {
          this.setState({
            isFileLoading: false,
            membersList: valuesArray,
          });
        } else {
          this.createToastNotification(           
            this.t(`${configJSON.emailInvalidMsg}`)
          );
          this.cancelUpload();
        }
      } else if (apiRequestCallId === this.validateFileApiId){
        this.getRecordsFromValidResponse(responseJson.validation_response as ValidFileEntry[]);        
      }
      else if (apiRequestCallId === this.bulkImportInventoryApiID){
        this.inventoryUploadResponse(responseJson as UploadApiResponse)
      }     
      
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  handleimportcsvResponse = (response: any) => {
    if(response.status_code === "443"){
      const ids = response.members.map((member: { id: number }) => member.id);
      this.setState({ currentTotalAddedMembers: ids, responseAddedMembers: ids, showLoader: false },() => {this.handleOpenCart(); this.createToastNotificationSuccess(this.t(`${configJSON.errorMsg}`));});
    } else if (response.status_code === "444") {
      this.setState({ showLoader: false }, () => {
        this.props.navigation.navigate("ProcessingContactScreen");
        this.createToastNotificationSuccess(this.t(`${configJSON.errorMsg}`));
      });
    } else {
      this.setState({ showLoader: false }, () => {
        this.createToastNotificationSuccess(this.t(`${configJSON.errorMsg}`));
      });
    }
  }
  inventoryUploadResponse = (uploadResponse:UploadApiResponse) => {
    this.setState({ showLoader: false }, () => {
      const failedResponse = Object.keys(uploadResponse.failure_response).length
      this.createToastNotificationSuccess(failedResponse>0?`Some ${this.state.inventorySubCategory} failed`: this.t(configJSON.allProductsAddedTxt, { inventorySubCategory: this.state.inventorySubCategory }));
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    });
  }
  async componentDidMount() {
    
    const lang = localStorage.getItem("lang") ?? "en"; 
    await(i18n as any).changeLanguage(lang);
   }
 
   t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.membersList != this.state.membersList) {
      this.filterRecords();
      if (this.state.membersList.length > 0) {
        let teamsArray: string[] = this.createTeamsFromCsvFile();
        const teamList = teamsArray.map((team: string, index: number) => {
          return {
            id: index,
            attributes: {
              id: index,
              title: team,
              users: this.state.membersList.filter(
                (x: any) =>
                  x.attributes.team.find((x: any) => x.title == team) != null
              ).length,
            },
          };
        });
        this.setState({ teamsList: teamList });
      } else {
        this.setState({ teamsList: [] });
      }
    }
    if (
      prevState.membersEmailCheckList != this.state.membersEmailCheckList &&
      this.state.isFileLoading &&
      this.state.membersEmailCheckList.length > 0
    ) {
      this.filterBulkEmails();
    }

  }
  createToastNotification = (toastMesssage: string,autoClose:number = 2000) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => <img src={warningIcon} alt="emptydata" />,
      autoClose: autoClose
    });
  };
  createToastNotificationSuccess = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        // icon: () => <img src={checkedIcon} alt="emptydata" />,
      }
    );
  };
 
  createToastNotificationFileErrors = (fileErrors: ErrorFileEntry[]) => { 
    toast.error(
      <div className={this.props.classes.toastFileError}>
      <ol >
        {fileErrors.map((fileError)=>{
          const msgKey = Object.keys(fileError)[0]
          return             (
          <li key={msgKey}>
          {msgKey}
          <ul>
            {Object.values(fileError).map((error)=>{
             return Object.keys(error).map((errorMsg)=>{
              return (
                <li key={`sub-${errorMsg}`}>{errorMsg} [Rows: {error[errorMsg].join(',')}]</li>
              )})
            })}
          </ul>
          </li>)
          
        })}
      </ol>
      <div className="toast-link" >
            <CloseIcon />
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      }
    );
  };
  deleteMemberHandler = (id: any) => {
    this.setState({
      membersList: this.state.membersList.filter((x: any) => x.id != id),
    });
  };

  downloadCsvTemplate = () => {
    let url = baseConfig.baseURL + configJSON.webDownloadCsvTemplateEndPoint;
    this.downloadFile(url,"EtOH_Template");
  };

  downloadFile = async (url: string, filename: string, filetype: string = ".csv") => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { token: this.userToken.meta.token },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch the file.');
      }
      const textContent = await response.text();

      const blob = new Blob([textContent], { type: 'text/csv' });
  
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `${filename}_${year}${month}${day}${filetype}`
      );
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(href);
  
    } catch (error) {
      this.createToastNotification("Something went wrong. Please try again.");
    }
  };
  
  resetFilters = () => {
    if (this.state.isTeamsTab) {
      this.setState({ selectedTeamFilter: [] }, () => {
        this.filterTeams();
      });
    } else {
      this.setState(
        {
          selectedRoleVal: [],
          selectedTitleVal: [],
          selectedTeamVal: [],
        },
        () => {
          this.filterRecords();
        }
      );
    }
  };

  importCsv = () => {
    const fileData = [this.csvFileSchema.join(",")];
    const fileRows: string[] = [];
    this.state.membersList.forEach((member: any) => {
      const memData = member.attributes;
      const rowArray = [
        memData.first_name,
        memData.last_name,
        memData.email,
        memData.team[0].title,
        memData.job_role,
        memData.job_type,
        memData.hourly_pay,
      ];
      fileRows.push(rowArray.join(","));
    });
    fileData.push(...fileRows);
    const parts = [
      new Blob([fileData.join("\r\n")], {
        type: "text/csv;charset=utf-8;",
      }),
    ];
    const file = new File(parts, "MembersImport.csv", {
      type: "text/csv",
    });

    let formData = new FormData();
    if (file !== null) {
      formData.append("file", file);
    }

    const body = formData;
    const header = {
      Accept: "*/*",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkImportCsvApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webBulkImportCsvEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ showLoader: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterBulkEmails = () => {
    // const fileData = [this.csvFileSchema.join(",")];
    const fileRows: string[] = [];
    this.state.membersEmailCheckList.forEach((member: any) => {
      const memData = member.attributes;
      fileRows.push(memData.email);
    });
    const parts = [
      new Blob([fileRows.join("\r\n")], {
        type: "text/csv;charset=utf-8;",
      }),
    ];
    const file = new File(parts, "BulkEmailCheck.csv", {
      type: "text/csv",
    });

    let formData = new FormData();
    if (file !== null) {
      formData.append("email_file", file);
    }

    const body = formData;
    const header = {
      Accept: "*/*",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterBulkEmailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webFilterEmailBulkUpload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  reUploadFile = () => {
    this.cancelUpload();
    this.setState({
      activeStep: 0,
    });
  };

  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };

  validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(email);
  };
  handleTeamSearch = (newSearchTerm: any) => {
    this.setState(
      {
        searchValue: newSearchTerm.target.value,
        isFilteringTeam: true,
      },
      () => {
        this.filterTeams();
      }
    );
  };

  handleMemberSearch = (newSearchTerm: any) => {
    this.setState(
      {
        searchValue: newSearchTerm.target.value,
        isFiltering: true,
      },
      () => {
        this.filterRecords();
      }
    );
  };
  searchHandler = (val: any) => {
    if (this.state.isTeamsTab) {
      this.handleTeamSearch(val);
    } else {
      this.handleMemberSearch(val);
    }
  };

  filterTeams = () => {
    const { searchValue, isFilteringTeam, selectedTeamFilter, teamsList } =
      this.state;
    let filterDataTeam = teamsList;
    if (isFilteringTeam) {
      filterDataTeam = filterDataTeam.filter((member: any) => {
        if (
          selectedTeamFilter.length > 0 &&
          selectedTeamFilter.find((x: any) => x == member.id) == null
        ) {
          return false;
        }

        return true;
      });
      if (searchValue) {
        filterDataTeam = filterDataTeam.filter((team: any) => {
          let teamTitle = team.attributes.title.toLowerCase();
          if (teamTitle.search(searchValue.toLowerCase()) !== -1) return team;
        });
      }
    }

    filterDataTeam = this.sortTeamRecords(filterDataTeam);
    this.setState({ filteredDataTeam: filterDataTeam });
  };

  sortRecords = (filterData: any) => {
    const { isAscending } = this.state;
    if (isAscending != null) {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = isAscending ? -1 : 1;
      rightIndex = isAscending ? 1 : -1;
      filterData = filterData.sort((member1: any, member2: any) => {
        let member1FullName =
          member1.attributes.first_name + " " + member1.attributes.last_name;
        let member2FullName =
          member2.attributes.first_name + " " + member2.attributes.last_name;

        if (member1FullName < member2FullName) return leftIndex;
        if (member1FullName > member2FullName) return rightIndex;
        return 0;
      });
    }

    return filterData;
  };

  sortTeamRecords = (filterData: any) => {
    const { isAscendingTeam } = this.state;
    if (isAscendingTeam != null) {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = isAscendingTeam ? -1 : 1;
      rightIndex = isAscendingTeam ? 1 : -1;
      filterData = filterData.sort((team1: any, team2: any) => {
        let teamName = team1.attributes.title;
        let team2Name = team2.attributes.title;

        if (teamName < team2Name) return leftIndex;
        if (teamName > team2Name) return rightIndex;
        return 0;
      });
    }
    return filterData;
  };
  searchRecords = (filterData: any) => {
    const { searchValue } = this.state;
    return filterData.filter((member: any) => {
      let memTitle = member.attributes.first_name.toLowerCase();
      let memEmail = member.attributes.email.toLowerCase();
      let memLastName = member.attributes.last_name.toLowerCase();
      let memFullName = memTitle + " " + memLastName;
      if (
        memTitle.search(searchValue.toLowerCase()) !== -1 ||
        memLastName.search(searchValue.toLowerCase()) !== -1 ||
        memEmail.search(searchValue.toLowerCase()) !== -1 ||
        memFullName.search(searchValue.toLowerCase()) !== -1
      )
        return memTitle;
    });
  };
  filterRecords = () => {
    const {
      selectedTeamVal,
      selectedRoleVal,
      selectedTitleVal,
      membersList,
      searchValue,
      isFiltering,
      teamsList,
    } = this.state;
    let filterData = membersList;
    if (isFiltering) {
      filterData = filterData.filter((member: any) => {
        if (
          (selectedTeamVal.length > 0 &&
            selectedTeamVal.find(
              (x: any) =>
                teamsList.find((y: any) => y.id == x).attributes.title ==
                member.attributes.team[0].title
            ) == null) ||
          (selectedRoleVal.length > 0 &&
            selectedRoleVal.find((x: any) => x == member.attributes.job_role) ==
              null) ||
          (selectedTitleVal.length > 0 &&
            selectedTitleVal.find(
              (x: any) => x == member.attributes.job_type
            ) == null)
        ) {
          return false;
        }

        return true;
      });
      if (searchValue) {
        filterData = this.searchRecords(filterData);
      }
    }
    filterData = this.sortRecords(filterData);
    this.setState({ filteredData: filterData });
  };

  changeSortByMembers = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscending: null }, () => {
        this.filterRecords();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscending: true }, () => {
        this.filterRecords();
      });
    } else {
      this.setState({ isAscending: false }, () => {
        this.filterRecords();
      });
    }
  };
  changeSortByTeams = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscendingTeam: null }, () => {
        this.filterTeams();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscendingTeam: true }, () => {
        this.filterTeams();
      });
    } else {
      this.setState({ isAscendingTeam: false }, () => {
        this.filterTeams();
      });
    }
  };
  changeSortByResponsive = () => {
    if (this.state.isTeamsTab) {
      this.setState({ isAscendingTeam: !this.state.isAscendingTeam }, () => {
        this.filterTeams();
      });
    } else {
      this.setState({ isAscending: !this.state.isAscending }, () => {
        this.filterRecords();
      });
    }
  };

  saveSelectedTeamsOption = (event: { target: { value: any } }, key: any) => {
    if (event.target.value != undefined) {
      const setNewValue = event.target.value.filter((x: any) => x != undefined);
      const newState = { [key]: setNewValue } as Pick<S, keyof S>;
      this.setState({ ...newState, isFilteringTeam: true }, () => {
        this.filterTeams();
      });
    }
  };

  saveSelectedOption = (event: { target: { value: any } }, key: any) => {
    if (event.target.value != undefined) {
      const setNewValue = event.target.value.filter((x: any) => x != undefined);
      const newState = { [key]: setNewValue } as Pick<S, keyof S>;
      this.setState({ ...newState, isFiltering: true }, () => {
        this.filterRecords();
      });
    }
  };
  createTeamsFromCsvFile = (): string[] => {
    // getting list of teams from each member and converting them to (array of array of teams)
    let teams: string[] = this.state.membersList.map((member: any) =>
      // member.attributes.team.join(",")
      member.attributes.team.map((x: any) => x.title).join(",")
    );

    // reducing above result
    teams = teams.reduce(function (acc: any, val: any) {
      return acc.concat(val);
    }, []);

    // trimming, removing empty strings and duplicate teams
    let teamsArray = [
      ...new Set(teams.map((team) => team.trim()).filter(Boolean)),
    ];

    return teamsArray;
  };
  createJobTitlesFromCsvFile = (): string[] => {
    // getting list of teams from each member and converting them to (array of array of teams)
    let jobTitles: string[] = this.state.membersList.map(
      (member: any) => member.attributes.job_role
    );

    // reducing above result
    jobTitles = jobTitles.reduce(function (acc: any, val: any) {
      return acc.concat(val);
    }, []);

    // trimming, removing empty strings and duplicate teams
    let jobTitlesArray = [
      ...new Set(jobTitles.map((title) => title.trim()).filter(Boolean)),
    ];

    return jobTitlesArray;
  };
  createJobTitles = () => {
    let jobTitles: string[] = this.createJobTitlesFromCsvFile();
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      data: {
        title: jobTitles,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createJobTitlesFromCsvApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webCreateJobTitlesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadApiMethod
    );
    this.setState({ showLoader: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createTeams = () => {
    let teams: string[] = this.createTeamsFromCsvFile();
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      data: {
        title: teams,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTeamsFromCsvApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webCreateTeamsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadApiMethod
    );

    this.setState({showLoader:true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  confirmClick = () => {
    this.state.isInventory?this.importInventoryCsv(): this.createTeams();
  };
  pauseResumeUpload = () => {
    if (!this.state.isPause) {
      this.currentParser.pause();
    }
    this.setState(
      {
        isPause: !this.state.isPause,
      },
      () => {
        if (this.currentParser != null) {
          if (!this.state.isPause) {
            this.currentParser.resume();
          }
        }
      }
    );
  };
  cancelParsing = (index:number) => {
    let resetEverything = true;
    if(this.state.isInventory){
      resetEverything = false;
      if(this.state.filesUploaded != null){
        let files = this.state.filesUploaded
        files.splice(index, 1); // Remove file at the specified index
        if(files.length == 0){
          resetEverything = true;
        }
        else{
          this.setState({
            filesUploaded: files
          })
          
        }
      }
      
    }
    
    if(resetEverything){
      if (this.currentParser != null) {
        this.currentParser.abort();
        this.currentParser = null;
      }
      if (this.formRef.current != null) {
        this.formRef.current.reset();
      }
      this.setState({
        isCancel: true,
        isFileLoading: false,
        filesUploaded: null,
        fileSelected: null,
        membersList: [],
        membersEmailCheckList: [],
        isTeamsTab: true,
        searchValue: "",
        isFiltering: false,
        selected: [],
        selectedTeamVal: [],
        selectedRoleVal: [],
        selectedTitleVal: [],
        filteredData: [],
        isFilter: false,
        isAscending: null,
        isFilteringTeam: false,
        filteredDataTeam: [],
        isAscendingTeam: null,
        selectedTeamFilter: [],
        tabValue: "1",
        filesNeedToCheck:[],
        inventoryFilesCurrent:[],
        inventoryFiles:[],
        inventoryHeader:[],
        inventoryAllHeader:[],
        inventoryData:[],
        inventoryDataUpload:[],
        inventoryFileType:"",
        inventoryCategory:"",
        inventorySubCategory:"",
      });
    }   
    
  };

  cancelUpload = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }

    this.setState({
      isCancel: true,
      isFileLoading: false,
      filesUploaded: null,
      fileSelected: null,
      membersList: [],
      membersEmailCheckList: [],
      teamsList: [],
      isTeamsTab: true,
      searchValue: "",
      isFiltering: false,
      selected: [],
      selectedTeamVal: [],
      selectedRoleVal: [],
      selectedTitleVal: [],
      filteredData: [],
      isFilter: false,
      isAscending: null,
      isFilteringTeam: false,
      filteredDataTeam: [],
      isAscendingTeam: null,
      selectedTeamFilter: [],
      tabValue: "1",
      inventoryFilesCurrent : [],
      inventoryFiles: [],
      inventoryHeader: [],
      inventoryAllHeader:[],
      inventoryDataUpload: [],
      inventoryData: [],
      inventoryFileType : "",
      inventoryCategory : "",
      inventorySubCategory:"",
      filesNeedToCheck:[],
    });
  };

  isStepCompleted(step: number) {
    return this.state.activeStep > step;
  }

  getOptionsForFilter = (name:string) => {
    let options:{id:string,value:string}[] = []
    let selectedArray:string[] = []
    const nameHeaderIndex = this.state.inventoryAllHeader.findIndex(header=>header.toLowerCase()==name.toLowerCase())??1;
    this.state.inventoryDataUpload.forEach((rows)=>{
      const value = rows[nameHeaderIndex+1];
      if(options.findIndex((option)=>option.value == value)==-1){
        options.push({id:value,value:value})
      }
    })
    options.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
    return {
      name:name,
      selected:selectedArray,
      options:options,
      search:""
    }
  }

  filterItems = () => {
    let filterOptions:string[] = []
    if (this.state.inventoryCategory == "Product") {
      filterOptions = ["Producer","Brand", "Country"];
    }
    if (this.state.inventoryCategory == "Asset") {
      if(this.state.inventorySubCategory == configInventory.landText){
        filterOptions = ["Unit of measurement"];
      }
      else

      filterOptions = ["Brand", "Material", "Specifications","Unit of measurement"];
    }
    if (this.state.inventoryCategory == "Tool") {
      filterOptions = ["Brand", "Color"];
    }
    if (this.state.inventoryCategory == "Consumable") {
      if(this.state.inventorySubCategory == configInventory.FertilizersText.slice(0, -1)){
        filterOptions = ["Quantity unit", "Area unit", "Brand"];
      }
      if(this.state.inventorySubCategory == configInventory.PhytoProductsText.replace("-", " ").replace("Products", "product")){
        filterOptions = ["Quantity unit", "Area unit","Target", "Brand"];
      }
      if(this.state.inventorySubCategory == configInventory.ChemicalsText.slice(0, -1)){
        filterOptions = ["Quantity unit", "Volume unit","Target", "Brand","Color"];
      }
    }
    const allFilters =  filterOptions.map((filtername)=>this.getOptionsForFilter(filtername))
    return allFilters.filter(filters=>filters.options.length>0)
  };

  handleNext = () => {
    if (this.state.isFileLoading) {
      return;
    }
    if (this.state.filesUploaded == null || (!this.state.isInventory && this.state.membersList.length == 0)) {
      this.createToastNotification(`${this.t("Please choose a file")}`);
      return;
    } 
    let inventoryFilters = [] 
    if(this.state.isInventory){
      this.setState({
        inventoryFilters:this.filterItems()
      },()=>{
        this.setState({
          activeStep: this.state.activeStep + 1,
        });
      })
    } 
    else{
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
    
  };

  handleBack = () => {
    if(this.state.isInventory){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else
    {
    this.props.navigation.navigate("AccountCreation");
    }
  };

  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  tabsChangeHandler = (e: any, tabValue: string) => {
    this.setState({ tabValue: tabValue, searchValue: "", isAscending: null });
    if (tabValue === "2") {
      this.setState({ isTeamsTab: false });
    } else {
      this.setState({ isTeamsTab: true });
    }
  };

  filterTeamHandler = () => {
    this.handle_modalOpen();
    this.setState({ isFilter: true });
  };

  handle_modalOpen = () => {
    this.setState({ modalOpen: true });
  };
  handle_modalClose = () => {
    const newState = {
      modalOpen: false,
    };
    this.setState(newState);
  };

  readCsv = async (files: FileList) => {
    if(this.state.isInventory){
      const fileName = files[0].name.split("_")[0].toLowerCase()
      const filetype=this.inventoryFiletypes.find((x)=>x.name.toLowerCase()==fileName)
      if(filetype!=undefined){
        this.checkFileTypesInventoryAndRead(files,filetype)
      }
      else{
        this.createToastNotification(
          "Invalid File name.Please use downloaded template"
        );
      }
    }
    else{
      this.readCsvBulk(files[0])
    }
    
  };

  checkFileTypesInventoryAndRead = (files:FileList,
    filetype:{
        name: string;
        type: string;
        img: string;
        category: string;
    }) => {
    let allFilesSame = true;
    const filesArray = Array.from(files);
    for (const element of filesArray) {
      const file = element;
      const filetypeSub = file.name.split("_")[0].toLowerCase();
      
      if (filetypeSub !== filetype.name.toLowerCase()) {
        allFilesSame = false;
        break; 
      }
    }
    if(allFilesSame && (this.state.inventorySubCategory=="" || this.state.inventorySubCategory.toLowerCase()==filetype.name.toLowerCase() )){
      this.readCsvInventory(files,filetype.type)
    }
    else{
      this.createToastNotification(
        !allFilesSame ? "All files must be of same type" : "New Files must be same as already added files"
      );
    }
  }
  readCsvInventory = async (files:FileList,filetype:string) => {
    let csvHeader: string[] = [];
    this.fetchAndReadBlob(filetype).then(async (respMHeader:any)=>{
      let csvArray = respMHeader.split("\n");
      const regex=/\r/g
      csvHeader = csvArray[0].replace(regex, "").split(",");
      const sortedCsvHeader =[...csvHeader] ;   
      sortedCsvHeader.sort((a, b) => a.localeCompare(b))
      
      const resp = await this.checkFileContent(files, JSON.stringify(sortedCsvHeader));
      const fileType = this.inventoryFiletypes.find(x=>x.type.toLowerCase()==filetype)
      if(resp.success && fileType){
        this.setState({
          inventoryFileType: fileType.type ,
          inventorySubCategory: fileType.name,
          inventoryCategory: fileType.category
        })
        this.parseCsv(Array.from(files), resp.totalSize ?? 0, csvHeader,resp.fileRows ?? []);
      }
      else{
        this.createToastNotification(
          `Header not matched in file ${files[resp.index??0].name}`
        );
      }          
    })
  }

  readCsvBulk = (file:File) => {

    let reader = new FileReader();
    reader.readAsText(file);
    let totalSize = 0;
    let csvHeader: string[] = [];
    reader.onload = (event: any) => {
      let csv = event.target.result; //the string version of your csv.
      let csvArray = csv.split("\n").filter((str:string) => str.trim() !== "");
      const regex=/\r/g
      csvHeader = csvArray[0].replace(regex, "").split(",");
      totalSize = csvArray.length-1;
      if (JSON.stringify(csvHeader) !== JSON.stringify(this.csvFileSchema)) {
        this.createToastNotification(
          "File doesn't contain all required columns. Please try again."
        );
      } else {       
        this.parseCsv([file], totalSize,csvHeader,[totalSize]);
      }
    };
  }
  
  parseCsv = (files: File[], totalSize: number,csvHeader:string[],fileRows:number[]) => {
    const addedFiles = this.state.filesUploaded ?? []
    this.setState({
      isFileLoading: true,
      filesUploaded: this.state.isInventory ? addedFiles : files,
      filesNeedToCheck: this.state.isInventory ? files : [],
      progress: 0,
      fileSelected: null,
    });

    let resultArray: FilesRead[] = [];
    let start = Date.now();
    this.curProgress = 0;
    
    this.parseFilesSequentially(files,0,start,totalSize,resultArray,fileRows)
    .then((resultArray:FilesRead[]) => {
      
      if(this.state.isInventory){
        this.readCsvCompleteInventory(resultArray,csvHeader)
      }
      else{
        this.readCsvComplete(resultArray.length>0 ? resultArray[0].rows : []);
      }
    })
    .catch((error) => {
        this.cancelUpload()
    });

    
  };

  readCsvCompleteInventory = (files: FilesRead[],csvHeader: string[]) => {
    this.currentParser = null;    
    const tableHeaders = this.getTableHeadersForFileType(csvHeader);
    this.setState({ 
      progress: 100,
      isFileLoading:false,
      inventoryFilesCurrent:files,
      inventoryHeader:tableHeaders,
      inventoryAllHeader:csvHeader,
      showLoader: true
     },()=>{
          this.validateFile()
     })
  }

  handleStep = async (
    results: Papa.ParseStepResult<unknown>,
    parser: Papa.Parser,
    currentFileRead: FilesRead,
    resultArray: FilesRead[],
    totalSize: number,
    fileRows: number[],
    start: number
  ): Promise<void> => {
    return new Promise<void>((innerresolve) => {
      setTimeout(async () => {
        await new Promise<void>((resolve, reject) => {
          const intervalId = setInterval(() => {
            if (!this.state.isPause) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
  
        if (this.state.isCancel) {
          parser.abort();
        } else {
          currentFileRead.rows.push(
            Object.fromEntries(
              Object.entries(results.data as FileRows).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            )
          );
          let otherRows = resultArray.filter((x) => x.index != currentFileRead.index);
          let otherTotalLength = otherRows.reduce((totalLength, fileRead) => {
            return totalLength + fileRead.rows.length;
          }, 0);
  
          const duration = Date.now() - start;
          const time = (totalSize * duration) / (currentFileRead.rows.length + otherTotalLength);
          let timeRemaining = Math.ceil((time - duration) / 1000);
          this.setState({
            timeRemain : timeRemaining
          })
          this.currentParser = parser;
          let curProgress = Math.round(
            ((currentFileRead.rows.length + otherTotalLength) * 100) / totalSize
          );
          this.setState({
            progress : curProgress
          })
  
          innerresolve();
        }
      }, 1000);
    });
  };

  parseFilesSequentially = (fileList: File[], index: number,start:number,totalSize:number,resultArray:FilesRead[],fileRows:number[]): Promise<any[]> => {
    return new Promise<FilesRead[]>((resolve, reject) => {
        if (index >= fileList.length) {
            resolve(resultArray); 
            return;
        }

        const file = fileList[index];
        let currentFileRead = resultArray.find(x=>x.index == index) ?? {
          index : index,
          file: file,
          rows: []
        }
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          delimiter: ",",
          
          step: (results, parser) => {            
            return this.handleStep(
              results,
              parser,
              currentFileRead,
              resultArray,
              totalSize,
              fileRows,
              start
            );
          },
          
          complete: async () => {
            await new Promise<void>((resolve, reject) => {
                const intervalId = setInterval(() => {
                    if (currentFileRead.rows.length == fileRows[index] || this.state.isCancel) {
                        clearInterval(intervalId);
                        resolve();
                    }
                }, 250); 
            });
            resultArray.push(currentFileRead)
            if(!this.state.isCancel){
              this.parseFilesSequentially(fileList, index + 1,start,totalSize,resultArray,fileRows)
              .then(resolve)
              .catch(reject);
            }
            else{
              reject()
            }            
          },
        });
    });
  };

  readCsvComplete = (resultArray: FileRows[]) => {
    this.currentParser = null;
    this.setState({ progress: 100 }, () => {
      const rowsArray: any = [];
      let valuesArray: any[] = [];
      let notValidMembers: string[] = [];
      let notValidMembersEmails: string = "";
  
      for (const member of resultArray) {
        const jobType = member.job_type?.trim()?.toLowerCase();
        if (jobType && !this.validJobTypes.includes(jobType)) {
          notValidMembers.push(member.email);
        }
      }
  
      if (notValidMembers.length) {
        notValidMembersEmails = notValidMembers.join(", ");
        this.createToastNotification(
          `${notValidMembers.length === 1 ? "Member" : "Members"} with the following E-Mail Ids do not have correct job_type: ${notValidMembersEmails}`
        );
        this.cancelUpload();
      } else {
        valuesArray = resultArray.map((row: FileRows, index: number) => {

          const member = row as csvDataType;
          const firstName = member["first name"]?.trim();
          const lastName = member["last name"]?.trim();
          const email = member.email?.trim();
          const chooseTeam = member.team?.trim();
          const jobRole = member.role?.trim();
          const hourlyPay = member["hourly pay"]?.trim();
          const jobType = member["job title"].trim();
  
          if (
            firstName &&
            lastName &&
            email &&
            this.validateEmail(email) &&
            chooseTeam &&
            jobRole &&
            hourlyPay && 
            jobType
          ) {
            return {
              id: index + 1,
              attributes: {
                first_name: firstName,
                last_name: lastName,
                email: email,
                team: chooseTeam.split(",").map((x: string) => {
                  return { title: x.trim() };
                }),
                job_role: jobRole,
                created_at: new Date(),
                hourly_pay: hourlyPay,
                job_type: jobType
              },
            };
          } 
        });

        valuesArray = valuesArray.filter((member) => member !== undefined);

        if (valuesArray.length > 0) {
          this.setState({
            membersEmailCheckList: valuesArray,
          });
        } else {
          this.createToastNotification("Empty file or invalid file. Please choose other file.");
          this.cancelUpload();
        }
      }
    });
  };
  

  handleOnDrop = (files: FileList | null, event?: any) => {
    let isCsvFile = true;
    let sameFilename = ""
    this.dragLeave();
    if (files) {
      
      Array.from(files).forEach((file,index)=>{
        let file_type = file.type;
        if (file_type.toLowerCase() !== "text/csv") {
          isCsvFile = false;
          return
        }
        if(this.state.filesUploaded!=null){
          const sameFile = this.state.filesUploaded.find(addedFile=>addedFile.name==file.name);
          if(sameFile){
            sameFilename = file.name;
            return
          }
        }
      })

      this.setState({
        isFileValidType: isCsvFile,
        isPause: false,
        isCancel: false,
      });
      this.checkAndUploadFiles(files,isCsvFile,sameFilename)
    }
  };

  checkAndUploadFiles = (files:FileList,isCsvFile:boolean,sameFilename:string) => {
    if (!isCsvFile) {
      this.createToastNotification(
        "Incorrect file type. Please download the template provided"
      );
      return;
    }
    if (sameFilename!="") {
      this.createToastNotification(
        `Cannot upload file with same name (${sameFilename}) again`,
        5000
      );
      return;
    }
    this.currentParser = null;

    if (this.state.filesUploaded !== null && !this.state.isInventory) {
      this.setState({
        isDeleteFile: true,
        fileSelected: files,
      });
    } else {
      this.readCsv(files);
    }
  }
  handleDownloadModalOpen = () => {
    this.setState({ downloadModalOpen: true });
  };

  handleDownloadModalClose = () => {
    this.setState({ downloadModalOpen: false });
  };

  isInventory = () => {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart.includes('inventory');
  };

  downloadInventoryTemplate = (fileType:string,fileName:string) => {
    let url = baseConfig.baseURL + configJSON.webDownloadInventoryTemplateEndPoint + "?file_type="+fileType
    this.downloadFile(url,fileName)
  };

  fetchAndReadBlob = (fileType:string) => {
    let url = baseConfig.baseURL + configJSON.webDownloadInventoryTemplateEndPoint + "?file_type="+fileType
    return fetch(url, {
      method: "GET",
      headers: { token: this.userToken.meta.token },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = event => {
            const content = event.target?.result;
            resolve(content);
          };
  
          reader.onerror = event => {
            reject(event.target?.error);
          };
          
          reader.readAsText(blob);
        });
      });
  }

  checkFileContent = (files:FileList, headerText:string) => {
    let totalSize = 0;
    return new Promise<{success:boolean,index?:number,totalSize?:number,fileRows?:number[]}>((resolve, reject) => {
      // Helper function to read file content
      const readFileContent = (file:File) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
  
          reader.onload = () => {
            const fileContent = reader.result;
            resolve(fileContent);
          };
  
          reader.onerror = (error) => {
            reject(error);
          };
  
          reader.readAsText(file);
        });
      };
  
      // Process files sequentially
      const processFiles = async ():Promise<void> => {
        let fileRows:number[] = []
        for (let i = 0; i < files.length; i++) {
          try {
            const fileContent = await readFileContent(files[i]);
            let csv = fileContent as string;
            let csvArray = csv.split("\n").filter((str:string) => str.trim() !== "");
            const regex=/\r/g
            let innerCsvHeader = csvArray[0].replace(regex, "").split(",");
            innerCsvHeader = innerCsvHeader.sort((a, b) => a.localeCompare(b));
            fileRows.push(csvArray.length-1)
            totalSize += csvArray.length-1;
            // Check if file content matches the specified text
            if (JSON.stringify(innerCsvHeader) !== headerText) {
              resolve({success:false,index:i}); // File content doesn't match, return false
              return; // Exit the loop and function
            }  
          } catch (error) {
            resolve({success:false,index:i});
            return; // Exit the loop and function
          }
        }
        resolve({success:true,totalSize:totalSize,fileRows:fileRows}); // All files have content matching the specified text, return true
      };
  
      // Start processing files
      processFiles();
    });
  }


  validateFile = () => {
    
    const header = {
      
      token: this.userToken.meta.token,
    };

    let formData = new FormData();
    formData.append("data[sub_category]",this.state.inventorySubCategory)
    this.state.inventoryFilesCurrent.forEach((row: FilesRead) => {      
      if (row.file !== null) {
        formData.append("data[file][]", row.file);
      }      
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateFileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateBulkUploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  importInventoryCsv = () => {
    const fileHeader = this.state.inventoryAllHeader
    const fileData = [fileHeader.join(",")];
    const fileRows: string[] = [];
    this.state.inventoryDataUpload.forEach((rowArray: string[]) => {
      fileRows.push(rowArray.slice(1).join(","));
    });
    fileData.push(...fileRows);
    const parts = [
      new Blob([fileData.join("\r\n")], {
        type: "text/csv;charset=utf-8;",
      }),
    ];
    const file = new File(parts, "MembersImport.csv", {
      type: "text/csv",
    });

    let formData = new FormData();
    if (file !== null) {
      formData.append("data[file]", file);
    }
    formData.append("data[sub_category]",this.state.inventorySubCategory)
    formData.append("data[category]",this.state.inventoryCategory)
    const body = formData;
    const header = {
      Accept: "*/*",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkImportInventoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webInventoryBulkUpload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ showLoader: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTableHeadersForFileType = (csvHeader:string[]) => {
    if(this.state.inventoryCategory == "Product"){
      return ([
        {
          text: "SKU",
          numeric: false
        },
        {
          text: "Name",
          numeric: false
        },
        {
          text: "Producer",
          numeric: false
        },
        {
          text: "Brand",
          numeric: false
        },
        {
          text: "Quantity",
          numeric: false
        },
        {
          text: "Country",
          numeric: false
        }
      ])
    }
    else{
      return csvHeader.map((header)=>{
        return (
          {
            text:header,
            numeric:false
          }
        )
      })
    }
  }

  getRecordsFromValidResponse = (allFiles:ValidFileEntry[]) => {
    let newFiles = this.state.filesNeedToCheck
    let newInventoryFiles = this.state.inventoryFiles
    let allData: string[][] = [];
    let dataForTable: string[][] = [];
    let errorRecords : ErrorFileEntry[] = [];
    allFiles.forEach((eachFile:ValidFileEntry) => {
        const allFileNames = Object.keys(eachFile);        
        allFileNames.forEach((fileName:string) => {
            const fileRead = this.state.inventoryFilesCurrent.find((x)=>x.file.name.split('.').slice(0, -1).join('.') == fileName)
            const validRowIndex = Object.keys(eachFile[fileName].success_response);          
            if(fileRead){
              const result = this.getValidRowsFromIndex(fileRead,validRowIndex,dataForTable,allData)
              allData = result.allData;
              dataForTable = result.dataForTable;

              errorRecords = this.getErrorsFromIndex(eachFile[fileName].failure_response,errorRecords,fileRead.file.name)
              if(validRowIndex.length == 0){
                newFiles = newFiles.filter(file=>file.name.split('.').slice(0, -1).join('.') != fileName)
                newInventoryFiles = newInventoryFiles.filter(file=>file.file.name.split('.').slice(0, -1).join('.') != fileName)
              }
            }            
        });
    });

    if(errorRecords.length>0) this.createToastNotificationFileErrors(errorRecords)
    const newResultAllData = this.state.inventoryDataUpload;
    const newResultForTable = this.state.inventoryData;
    if(dataForTable.length > 0){      
      newResultAllData.push(...allData)
      newResultForTable.push(...dataForTable)
      const filesUploaded = this.state.filesUploaded ?? [];
      filesUploaded.push(...newFiles)
      const inventoryFiles = this.state.inventoryFiles ?? [];
      inventoryFiles.push(...newInventoryFiles)
      this.setState({
        showLoader:false,
        inventoryDataUpload:newResultAllData,
        inventoryData:newResultForTable,
        filesUploaded:filesUploaded,
        filesNeedToCheck:[],
        inventoryFilesCurrent:[],
        inventoryFiles: inventoryFiles
      })
    }
    else{
      this.setState({
        showLoader:false,
      })
      if(newResultAllData.length==0) this.cancelUpload()
    }
  
  }

  getValidRowsFromIndex = (fileRead:FilesRead,validRowIndex:string[],dataForTable:string[][],allData:string[][]) => {
    const tableHeaders = this.state.inventoryHeader;
    const allHeader = this.state.inventoryAllHeader;
    let rowArray:string[] = [];
    let allRowArray:string[] = [];
    fileRead.rows.forEach((row,index:number)=>{
      if(validRowIndex.includes((index+1).toString())){    
        rowArray = []    
        allRowArray = []    
        rowArray.push(index.toString())
        allRowArray.push(index.toString())
        tableHeaders.forEach((headerText)=>{
          rowArray.push(row[headerText.text.toLowerCase()])
        })
        dataForTable.push(rowArray)
        allHeader.forEach((headerText)=>{
          allRowArray.push(row[headerText.toLowerCase()])
        })
        allData.push(allRowArray)
      }             
    })
    return {
      allData: allData,
      dataForTable: dataForTable
    }  
  }

  getErrorsFromIndex = (failResponse:Record<string, string[]>,errorFileEntry:ErrorFileEntry[],filename:string) => {
    const invalidRowIndex = Object.keys(failResponse);    
    let errorRecords:ErrorRecord = {}
    invalidRowIndex.forEach((key:string)=>{
      const errorRows = failResponse[key];
      errorRows.forEach((errorMessage:string)=>{
        if(!errorRecords[errorMessage]){
          errorRecords[errorMessage] = [Number(key)]
        }
        else{
          errorRecords[errorMessage].push(Number(key))
        }
      })      
    })
    
    if(Object.keys(errorRecords).length>0){
      errorFileEntry.push({
        [filename]: errorRecords
      })
    }
    
    return errorFileEntry;
  }

  getDataForTableByFilterAndSort = () => {
    let inventoryData = this.state.inventoryData;

    this.state.inventoryFilters.forEach((filter)=>{
      if(filter.selected.length>0){
        const filterHeaderIndex = this.state.inventoryHeader.findIndex(header=>header.text.toLowerCase()==filter.name.toLowerCase())

        if(filterHeaderIndex>=0){
          inventoryData = inventoryData.filter((rowData)=>filter.selected.includes(rowData[filterHeaderIndex+1]))
        }
      }
    })

    if(this.state.searchValue != ""){
      inventoryData = inventoryData.filter((rowData)=>rowData.filter((colValue)=>colValue.toLowerCase().includes(this.state.searchValue.toLowerCase())).length>0)
    }

    const sortOrder = this.state.sortByInventory;
    if(sortOrder>0){
      const nameHeaderIndex = this.state.inventoryHeader.findIndex(header=>header.text.toLowerCase()=='name')??1;
      inventoryData.sort(function(a, b) {
        const comparison = a[nameHeaderIndex+1].localeCompare(b[nameHeaderIndex+1]); 
        return sortOrder==2 ? -comparison : comparison; 
      }); 
    }
    
    return inventoryData
  }

  changeSortByInventory = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
}>)  => {
    const sortValue = event.target.value == "" ? 0 : event.target.value as number
    this.setState({sortByInventory:sortValue})
    
  }

  setRemainingRows = (rowsRemaining:string[]) => {
    if(rowsRemaining.length == 0){
      this.reUploadFile()
      return
    }
    let inventoryData = this.state.inventoryData;
    let inventoryDataUpload = this.state.inventoryDataUpload;
    inventoryData = inventoryData.filter(x=>rowsRemaining.includes(x[0]))
    inventoryDataUpload = inventoryDataUpload.filter(x=>rowsRemaining.includes(x[0]))

    this.setState({
      inventoryData:inventoryData,
      inventoryDataUpload:inventoryDataUpload,
    })
  }

  setFilterSearch = (e:React.ChangeEvent<HTMLInputElement>,name:string) => {
    const filters = this.state.inventoryFilters
    const filter = filters.find(x=>x.name == name)
    if(filter){
      filter.search = e.target.value
      this.setState({
        inventoryFilters:filters
      })
    }    
  }

  getFilteredOptions = (name:string,options:{id:string,value:string}[],filterSearch:string)=>{
    return options.filter((option)=>option.value.toLowerCase().includes(filterSearch.toLowerCase()))
  }

  getFilterLabel = (value: string[],name:string,isMulti:boolean = true) => {
    value = value.filter(item => item != undefined || item != null);
    const selectedItemCount = value.length;

    if (selectedItemCount === 0) {
      return name;
    } 
    else if (selectedItemCount === 1) {
      return value[0]
    }
    else{
      return `${selectedItemCount} ${name}s selected`
    }
  };

  filterChange = (event:React.ChangeEvent<{name?: string ;value: unknown;}>,name:string) => {
    if (event.target.value != undefined) {
      const selectedValue = event.target.value as string[];
      const filters = this.state.inventoryFilters
      const filter = filters.find(x=>x.name == name)
      if(filter){
        filter.selected  = selectedValue.filter((x: any) => x != undefined);
        this.setState({
          inventoryFilters:filters
        })
      } 
    }
  }

  handleTableSearch = (event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      searchValue: event.target.value
    })
  }

  clearFilter = (name:string) => {
    const filters = this.state.inventoryFilters
    const filter = filters.find(x=>x.name == name)
    if(filter){
      filter.selected  = [];
      this.setState({
        inventoryFilters:filters
      })
    } 
  }

  downloadMasterTemplate = () => {
    let url = baseConfig.baseURL + configJSON.webDownloadInventoryMasterTemplateEndPoint;
    this.downloadFile(url,"Master_Template",".xlsx")
  }

  handleOpenCart = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  };
}
// Customizable Area End
