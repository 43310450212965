// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface S {
   // Customizable Area Start
    isLoading: boolean;
    tutorialData: any;
    activeTab: number
  // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TutorialScreenControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    userToken: any;
    userSessionData: any;
    fetchAllTutorialApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
         // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            isLoading: false,
            tutorialData: [],
            activeTab: 0
        }

       this.userSessionData = sessionStorage.getItem("userData");
       this.userToken = JSON.parse(this.userSessionData);
       runEngine.attachBuildingBlock(this, this.subScribedMessages);
       // Customizable Area End
    }

    async receive(from: string, message: Message) {      
        // Customizable Area Start 
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.error) {  
              this.setState({ isLoading: false });
              return;
            }else{
              switch (apiRequestCallId) { 
                case this.fetchAllTutorialApiCallId:
                  this.setState({ isLoading: false });
                  this.setState({tutorialData:responseJson.data})
                  break;
                  default:
                  this.parseApiCatchErrorResponse(errorResponse);
                  break;
              }
            }
          }   
        // Customizable Area End
      }
      // Customizable Area Start 
    async componentDidMount() {
        this.fetchAllTutorialsScreens();
        const langC = localStorage.getItem("lang") ?? "en"; 
        await (i18n as any).changeLanguage(langC);
    }

    t(key:any, variable?: Record<string, any>) {
      return (i18n as any).t(key, { ns: "translation" , ...variable } )
    }

    onRedirectCreateAccount = () => {
        const token = localStorage.getItem("token")
        this.props.navigation.navigate("CreateProfile", {
            token: token,
        });
    }

    tutorialSlider = () => {
        this.setState({
            activeTab: this.state.activeTab + 1
        })
    }

    previousTutorialSlider=()=>{
        this.setState({
            activeTab: this.state.activeTab - 1
        })
    }

   fetchAllTutorialsScreens = () =>{
    const header = {
        token: this.userToken.meta.token,
      };

      const requestMessage: Message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.fetchAllTutorialApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tutorialScreen
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodGet
      );
      this.setState({ isLoading: true });
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }; 

    // Customizable Area End
}