import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Drawer,
  FormControlLabel,
  Radio,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { customStyles } from "../../../components/src/LanguageSwitchHelper.web";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import LanguageSupportController, {
  Props,
  configJSON,
} from "./LanguageSupportController.web";
import { backArrow2, menuBar } from "./assets";
import CustomSettingSidebar from "../../../components/src/CustomSettingSidebar.web";
import WebLoader from "../../../components/src/WebLoader.web";
import DeleteContact from "../../ContactsList/src/DeleteContact.web";
// Customizable Area End

export class LanguageSupport extends LanguageSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile} = this.props;
    const { selectedLanguage , languageOptions,isLoading} = this.state;

    return (
      <>
      {isLoading && <WebLoader/>}
      <Box className={`wrapper ${classes.wrapper} ${classes.langWrapper}`}>
        <Box className="back-btn-wrap" data-test-id="goBack" onClick={this.goBack}>           
           <img src={backArrow2} alt="Back Arrow Icon" className="arrow-image" />
            <Typography variant="h6" className="back-btn">
              {this.t(`${configJSON.backTxt}`)}
            </Typography>
        </Box>        
      <Box className={`inner-wrapper`}>
        <Box className={classes.langInnerWrapper}>
          <Box className="main-heading">
            <Typography variant="h1" className="heading">
              {this.t(`${configJSON.langHeading}`)}
            </Typography>
            <Box
              onClick={this.toggleLangSettingsDrawer}
              data-test-id="settingToggleMenu"
              className="setting-menu"
            >
              <img alt="menu" src={menuBar}/>
            </Box>
          </Box>
          <Box className={classes.langDrawerWrapper}>
            <Box className={classes.drawer}>         
              <Drawer                
                open={isMobile ? this.state.isLangSettingDrawerOpened : true}
                variant={isMobile ? "temporary" : "permanent"}
                classes={{
                  root: classes.langDrawerRootWrapper,
                  paper: classes.langDrawerPaper,
                }}
              >
                <CustomSettingSidebar
                  toggleMenu={this.closeSettingDrawer}
                  activeMenu={1}
                  navigation={this.props.navigation}
                  data-test-id="custom-sider"
                  t={this.t}
                />
              </Drawer>
            </Box>
            <Box className={classes.drawerContent}>
            <Box className={`${classes.langBlockOuter}`}> 
             <Typography className="lang-headding">{this.t(`${configJSON.currentLangtxt}`)}</Typography>
             <Box className="radio-content">            
              {languageOptions.map((option:any) => (
              <FormControlLabel
                key={option.id}
                className={`${classes.formControl} lang-title`}
                value={option.code}
                control={<Radio checked={selectedLanguage === option.code} />}
                label={option.display_name}
                onClick={() => this.handleLanguageChange(option.code,option.display_name)}
                labelPlacement="start"
                data-test-id={`radio-button-${option.id}`}
              />
             ))}             
             </Box>
             <Button className={`primary-btn ${classes.primaryButton}`}
               title={this.t(configJSON.updateTxt)}
               onClick={this.handleLangPopup}
               data-test-id="updateLanguage"
               >
               {this.t(configJSON.updateTxt)}
              </Button>
            </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    {this.state.isLangUpdate && 
    <DeleteContact 
      modalOpen={this.state.isLangUpdate}
      modalClose={this.handleLangPopupClose}
      deleteContactAction={this.updateLanguage}
      headding={this.t(`${configJSON.updateLangTxt}`)} 
      cancleBtntxt={this.t(`${configJSON.exitTxt}`)}
      deleteBtntxt={this.t(`${configJSON.updateTxt}`)}
      confirmationMsg={this.t(`${configJSON.confirmationMsg}`)}
      data-test-id="update-language"
      isLangTxt="lang"
      selectedLang={this.state.displayName}    
    />}
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(LanguageSupport)
);
// Customizable Area End
