import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import { DateObject } from "react-multi-date-picker";
import i18n from "../../../../web/src/utilities/i18n";
export const configJSON = require("../config.js");
import moment from "moment";
import { normalizeDate } from "../../../../components/src/helpers/Utilities";
import {deleteIcon,copyIcon} from "../assets";
import { convertToFiles, getCurrentTime } from "../../../../components/src/ReusableFunctions";
import { Project ,Task } from "../../../../components/src/ReusableEnums";
const baseConfig = require("../../../../framework/src/config");
import { toast } from "react-toastify";
import { CardMedia } from "@material-ui/core";
export const warningIcon = require("../../assets/warning-red.png");
// Customizable Area End



export interface Props{
  // Customizable Area Start
  classes: any;
  navigation?: any;
  id?: string;
  isOpen: boolean;
  projectClose: () => void;
  projectId: number;
  tasklistPopoverOpen: (e: any,popoverRef:React.RefObject<any>) => void;
  taskPopoverOpen: (e: any, id: number,popoverRef:React.RefObject<any>) => void;
  createToastNotification: (toastMesssage: string, toastIcon?: string) => void;
  fetchAllProjects: () => void;
  updateData?:() => void;
  popoverHandleClose:()=>void;
  projectList:any[];
  showCreateProject?:boolean;
  editProjectOpen?:boolean;
  deleteCallback?:() => void;
  showTimeSheet?:boolean;
  closeTimeSheet?:()=>void;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  anchorEl: any;
  currentProject: any;
  viewTabsValue: number;
  projectTaskTabsValue: number;
  projectTaskList: any[];
  tasklistTaskChecked: any[];
  startDate: string;
  endDate: string;
  error: {
    title: string;
    startDate: string;
    category: string;
    taskListName: string;
    taskListProject: string;
    due_date: string;
  };
  title: string;
  category: any;
  categoryList: any[];
  description: string;
  isDeletingCategory: boolean;
  deleteCategoryId: string;
  assignees: any[];
  isActiveProjectCreated: boolean;
  isActiveChangeProjectLead: boolean;
  assigneeList: any[];
  sortByAssignee: string;
  assigneeSearchText: string;
  popoverAnchorEl:any;
  isEditing:boolean;
  isDeletingProject: boolean;
  createProjectEl: any;  
  draftProjects: any[];
  currentDraftId: number;
  updateProjectId:number;  
  isGetTimesheet: boolean;
  isActiveTimesheetChooseAssignees: boolean;  
  logDates: DateObject[];
  isTimesheetGenerating:boolean;
  isActiveFilter: boolean;
  timeSheetProject:number;
  logAssignees: any[];
  activityLogList: any[];
  projectStatus:number;
  isActiveAllAssignee: boolean;
  fileDropEvent: boolean;
  filesUploaded: any;
  attachmentRemove:boolean;
  attachmentID:string;
  isUploading:boolean;
  openUseExisting:boolean;
  chooseTemplateChecked:number;
  projectList:Project[];
  searchQuery:string;
  useExistingProjectId:number;
  filteredProjects:Project[];
  isSearching:boolean;
  sortQuery:string;
  isSorting:boolean;
  sortedProjects:Project[];
  taskTimers:{
    [taskId: string]: boolean;
  };
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProjectViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userToken: any;
  userSessionData: any;
  getProjectDetailApiID: string = "";
  createDuplicateProject:string = "";
  projectTasklistViewApiID: string = "";
  getCategoryListApiID:string = "";
  deleteCategoryApiID:string = ""  
  editCategoryApiID: string = "";
  createCategoryApiID: string = "";
  projectCreateApiId: string = "";
  editProjectApiID: string = "";
  memberListApiID: string = "";  
  activityLogFilterApiID: string = "";
  projectActivityLogApiID: string = "";
  projectStatusApiID:string="";  
  showDialogCategory: any = null;
  setErrorCategory: any = null;
  currentCategory: { id: number; category: string } = { id: 0, category: "" };
  deleteProjectApiID:string = "";  
  popoverRef: React.RefObject<any>;
  draftProjectId: number = 1;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  attachmentsReqestApiId: string = "";
  projectListApiRequestID: string = "";
  updateTaskProgressAPICallId:string ="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);    
    this.popoverRef = React.createRef();
    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    // Customizable Area End


    this.state = {
    // Customizable Area Start
    anchorEl: null,
    currentProject: null,
    viewTabsValue: 0,
    projectTaskTabsValue: 0,
    projectTaskList: [],
    tasklistTaskChecked: [0],
    error: {
      title: "",
      startDate: "",
      category: "",
      taskListName: "",
      taskListProject: "",
      due_date: "",
    },
    title: "",
    description: "",
    category: null,
    startDate: "",
    endDate: "",
    categoryList: [],
    isDeletingCategory: false,
    deleteCategoryId: "",
    assignees: [],
    isActiveProjectCreated: false,
    isActiveChangeProjectLead: false,
    assigneeList: [],
    sortByAssignee: "",
    assigneeSearchText: "",
    isEditing:false,
    popoverAnchorEl:null,
    isDeletingProject:false,
    createProjectEl: null,    
    draftProjects: [],
    currentDraftId: 0,
    updateProjectId: 0,
    isGetTimesheet: false,
    isActiveTimesheetChooseAssignees:false,    
    logDates: [],
    isTimesheetGenerating:false,
    isActiveFilter:false,
    timeSheetProject:0,
    logAssignees: [],
    activityLogList: [],
    projectStatus:0,
    isActiveAllAssignee: false,
    fileDropEvent: false,
    filesUploaded: [],
    attachmentRemove:false,
    attachmentID:"",
    isUploading:false,
    openUseExisting:false,
    chooseTemplateChecked: 0,
    projectList:[],
    searchQuery:"",
    useExistingProjectId:0,
    filteredProjects:[],
    isSearching:false,
    sortQuery:"",
    isSorting:false,
    sortedProjects:[],   
    taskTimers:{}
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkCategoryUpdate(prevState);
    
    if (prevProps.isOpen != this.props.isOpen && this.props.projectId) {
      if(this.props.isOpen)
      this.getProjectDetails(this.props.projectId);
      this.TasklistGetofProject(this.props.projectId);
      this.getAssigneeList();
      this.getCategory();
      this.setState({timeSheetProject:this.props.projectId})
    }
    
    if(prevProps.editProjectOpen != this.props.editProjectOpen && this.props.editProjectOpen){
      this.getCategory();
      this.getProjectDetails(this.props.projectId)
      this.getAssigneeList();
      this.TasklistGetofProject(this.props.projectId);
      
    }

    if(prevProps.showTimeSheet != this.props.showTimeSheet  && this.props.showTimeSheet){
      this.getTimesheetModalOpen({preventDefault:()=>{}})
      
    }
  }
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
    this.setState({ filteredProjects: this.state.projectList });
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.projectListApiRequestID:
              this.setState({
                projectList: responseJson.data,
            });
            break;
            case this.projectTasklistViewApiID:
              this.setState({
                projectTaskList: responseJson.data,
              });
            break;                         
            case this.memberListApiID:
              this.setState({ assigneeList: responseJson.members.data });
              break;
            case this.attachmentsReqestApiId:
                 this.getProjectDetails(this.props.projectId);
                 this.setState({filesUploaded:[]})
              break;   
              case this.updateTaskProgressAPICallId:
                this.TasklistGetofProject(this.props.projectId);
                this.getProjectDetails(this.props.projectId);
             break;        
            default:
              this.categoryApiResponse(apiRequestCallId,responseJson)
              this.projectApiResponse(apiRequestCallId,responseJson)
            }          
        } else {
          this.checkApiError(responseJson, apiRequestCallId);
        }
      }
    }
    // Customizable Area End
   }


  // Customizable Area Start
  dragEnterEvent = () => {
    this.setState({
      fileDropEvent: true,
    });
  };
  dragLeaveEvent = () => {
    this.setState({
      fileDropEvent: false,
    });
  };
  onBrowseEvent = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();     
    }
  };

  handleUpload =()=>{
    this.
    setState({isUploading:true})
  }

  startTaskTimerAPI = (taskId:string,actionType:string) => {
    const timestamp = getCurrentTime();   
    
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
      const body = {
        "data":{
           "task_id": taskId,
           "status_updated_at": timestamp,
           "action_type": actionType       
       }
    } 
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskProgressAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskProgress
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState((prevState) => ({
      taskTimers: {
        ...prevState.taskTimers,
        [taskId]: actionType === "started",
      }
    }));  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOnDropEvent = (fileEvent: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({attachmentRemove:false,attachmentID:""});
    const uplodedFiles = fileEvent.target.files;
    if (uplodedFiles) {
      const newFilesArray = Array.from(uplodedFiles);
  
      this.setState((prevState) => {
        const allUploadedFiles = [...prevState.filesUploaded, ...newFilesArray];
        const uniqueFilesUplods = Array.from(
          new Map(
            allUploadedFiles.map((fileItme) => [`${fileItme.name}-${fileItme.lastModified}`, fileItme])
          ).values()
        );  
        return { filesUploaded: uniqueFilesUplods };
      }); 
      if(this.state.isUploading){
        this.addAttachmentsForProject(newFilesArray);       
      }
    }   
  };

  removeFileUPloadEvent = (indexNumber: any) => {
    const updatedFilesArray = [
      ...this.state.filesUploaded.slice(0, indexNumber),
      ...this.state.filesUploaded.slice(indexNumber + 1),
    ];
    this.setState({ filesUploaded: updatedFilesArray });
  };

  setProjectStatus=(status:any)=>{
    let statusId=0
    status=status?status.toLowerCase():""
    switch(status){
      case "on track":
        statusId=1
        break;
        case "off track":
        statusId=2
        break;
        case "completed":
        statusId=3
        break;
    }
    this.setState({projectStatus:statusId})
  }

  projectApiResponse=(apiRequestCallId:any,responseJson:any) =>{
    const updateData = () => {
      if (this.props.updateData) {
        this.props.updateData();
      }
    }
    switch(apiRequestCallId)
    {
      case this.getProjectDetailApiID:

        this.setState({
          currentProject: responseJson.data,
        },()=>{
          if(this.props.editProjectOpen){
            this.editProjectOpen()
          }
        });
        this.setProjectStatus(responseJson.data.attributes.status)
        break;
      case this.createDuplicateProject:
        this.props.createToastNotification(this.t("Project Duplicated."),copyIcon);
        this.props.projectClose();
        this.props.fetchAllProjects();
        updateData();
        break;
      case this.projectCreateApiId:
        this.projectCreated();
        this.props.fetchAllProjects();
        updateData();
        this.setState({filesUploaded:[]})
        break;
      case this.deleteProjectApiID:
        this.props.createToastNotification("Project deleted.",deleteIcon);
        this.props.projectClose();
        this.projectDeleteModalClose();
        if (this.props.deleteCallback) {
          this.props.deleteCallback();
        }
        this.props.fetchAllProjects();
        updateData();
        break;
      case this.editProjectApiID:
        this.projectFromDraft();
        this.props.createToastNotification(responseJson.message);
        this.props.fetchAllProjects();
        this.popoverHandleClose();
        updateData();
        this.setState({filesUploaded:[]})
        break;
    
      case this.projectActivityLogApiID:
        this.setState({ activityLogList: responseJson.data });
        break;
      case this.projectStatusApiID:
        this.props.fetchAllProjects();
        this.getProjectDetails(this.props.projectId);
        updateData();
        break;     
    }
  }

  categoryApiResponse=(apiRequestCallId:any,responseJson:any) =>{
    switch(apiRequestCallId)
    {
      case this.getCategoryListApiID:
        this.setState({
          categoryList: responseJson.data,
        });
        break;
      case this.createCategoryApiID:
        this.createCategorySuccess(responseJson);
        break;
      case this.editCategoryApiID:
        this.updateCategorySuccess(responseJson);
        break;
    }
  }

  projectFromDraft = () => {
    if (this.state.currentDraftId !== 0) {
      this.setState({
        draftProjects: this.state.draftProjects.filter(
          (x) => x.id !== this.state.currentDraftId
        ),
        currentDraftId: 0,
      });
    }
  }

  openDraftProject= (event: any, id: number) => {
    const draftProject:any = this.state.draftProjects.find((x) => x.id == id);
    this.setState({
      title: draftProject.title,
      startDate: draftProject.startDate,
      endDate: draftProject.endDate,
      description: draftProject.description,
      assignees: draftProject.assignees,
      popoverAnchorEl: event.currentTarget,
      currentDraftId: id,
      category: draftProject.category,
      updateProjectId:draftProject.projectId,
      isEditing:draftProject.projectId>0
    });
  };

  deleteDraftProject = (id: number) => {
    const draftProject = this.state.draftProjects?.filter((x) => x.id !== id);
    this.setState({ draftProjects: draftProject });
  };
  saveAsDraftProject = () => {
    let draftProjects = this.state.draftProjects;
    if (this.state.currentDraftId !== 0) {
      let draftProject = draftProjects.find(
        (x: any) => x.id == this.state.currentDraftId
      );
      draftProject.title = this.state.title;
      draftProject.startDate = this.state.startDate;
      draftProject.endDate = this.state.endDate;
      draftProject.description = this.state.description;
      draftProject.assignees = this.state.assignees;
      draftProject.category = this.state.category;
    } else {
      draftProjects = [
        ...draftProjects,
        {
          id: this.draftProjectId,
          projectId:this.state.isEditing?this.state.currentProject.id:0,
          title: this.state.title,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          description: this.state.description,
          assignees: this.state.assignees,
          category: this.state.category,
        },
      ];
      this.draftProjectId = this.draftProjectId + 1;
      this.setState({
        draftProjects: draftProjects,
      });
    }   
    this.popoverHandleClose();
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenuOpen = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  setViewTabsValue = (obj: any, val: any) => {
    this.setState({
      viewTabsValue: val,
    });
  };
  setProjectTaskTabsValue = (obj: any, val: any) => {
    this.setState({
      projectTaskTabsValue: val,
    });
  };

  getProjectDetails = (projectId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectShowEndPoint + `?id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  TasklistGetofProject = (projectId: number) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projectId}`
    );
    this.projectTasklistViewApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tasklistOpenPopover = (e: any) => {
    e.preventDefault();
    this.props.tasklistPopoverOpen(e,this.popoverRef);
    this.setState({
      anchorEl: null,
    },this.props.projectClose);
  };

  taskOpenPopover = (e: any) => {
    e.preventDefault();
    this.setState({
      anchorEl: null,
    },this.props.projectClose);
    this.props.taskPopoverOpen(e, this.props.projectId,this.popoverRef);
  };
  
  projectDelete = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    
    this.deleteProjectApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDeleteEndPoint + `?id=${this.props.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

  projectDeleteModalClose = () => {
    this.setState({
      isDeletingProject: false,
    });
  };

  deleteProjectHandler = () => {
    this.setState({
      isDeletingProject: true,
      anchorEl: null,
    });
  };

  duplicateProject = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDuplicateProject = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateProject + `?id=${this.state.openUseExisting?this.state.useExistingProjectId:this.state.currentProject.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTaskListTasks = (value: number) => () => {
    const currentIndex = this.state.tasklistTaskChecked.indexOf(value);
    const newtasklistTaskChecked: any = [...this.state.tasklistTaskChecked];

    if (currentIndex === -1) {
      newtasklistTaskChecked.push(value);
    } else {
      newtasklistTaskChecked.splice(currentIndex, 1);
    }

    this.setState({ tasklistTaskChecked: newtasklistTaskChecked });
  };

  getCategory = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryListApiID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
   
  };
  
  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    let error = this.state.error;
    if (name=="title") {
        error.title = "";
    }
    this.setState({ error: error });
  };

  
  categoryChangeHandler = (e: any, newValue: any) => {
    this.setState({
      category: newValue,
      error:{
        ...this.state.error,
        category:"",
      }
    });
  };

  deleteCategory = (data: any) => {
    this.setState({ isDeletingCategory: true, deleteCategoryId: data.id });
  };
  deleteCategoryModalClose = () => {
    this.setState({
      isDeletingCategory: false,
    });
  };
  deleteCategoryApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCategoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint + `?id=${this.state.deleteCategoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addCategory = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorCategory = setError;
    this.showDialogCategory = setShowDialog;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    if (data.id != null) {
      this.editCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCategoryEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            category: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPATCH
      );
    } else {
      this.createCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCategoryEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            category: [data.title],
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeStartDate = (date: any) => {
    this.setState({
      startDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
      error: { ...this.state.error, startDate: "" },
    });

  };
  changeEndDate = (date: any) => {
    this.setState({
      endDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };
  checkApiError = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId == this.editCategoryApiID) {
        const msg: string = responseJson.errors[0].message;
        if (msg.includes("already exists")) {
          this.setErrorCategory("Category already exists");
        } else {
          this.setErrorCategory(msg);
        }
    }
  };
  checkCategoryUpdate = (prevState: S) => {
    if (prevState.categoryList !== this.state.categoryList) {
      if (this.currentCategory.id !== 0) {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.id == this.currentCategory.id
        );

        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      } else if (this.currentCategory.category != "") {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.category == this.currentCategory.category
        );
        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      }
      this.currentCategory = { id: 0, category: "" };
    }
  };
  createCategorySuccess = (responseJson: any) => {
    if (responseJson.failed_categories) {
      const msg: string = responseJson.failed_categories;
      if (msg.includes("already exists")) {
        this.setErrorCategory("Category already exists");
      } else {
        this.setErrorCategory(msg);
      }
    } else {
      this.currentCategory.category = responseJson.created_categories[0];
      this.showDialogCategory(false);
      this.getCategory();
    }
  };
  updateCategorySuccess = (responseJson: any) => {
    this.currentCategory.id = responseJson.data.attributes.id;
    this.currentCategory.category = responseJson.data.attributes.category;
    this.showDialogCategory(false);
    this.getCategory();
  };

  createProject = async () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.title.trim() === "") {
      error.title = this.t("Please enter title");
      this.setState({ error: error });
      hasError = true;
    }
    // if (this.state.startDate.trim() === "") {
    //   error.startDate = this.t("Please enter start date");
    //   this.setState({ error: error });
    //   hasError = true;
    // }
    // if (!this.state.category) {
    //   error.category = this.t("Please select Category");
    //   this.setState({ error: error });
    //   hasError = true;
    // }
    if (hasError) {
      return;
    }

    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [''];
    }
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[start_date]", this.state.startDate);
    formdata.append("data[end_date]",this.state.endDate);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[project_lead]", assignees[0]);
    let filesArray;
   
    if (this.state.isEditing) {
      filesArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesArray = this.state.filesUploaded;
    }

    if(filesArray){
      for (const file of filesArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateProjectId.toString())
    }

    if (this.state.isEditing) {
      this.editProject(formdata);
      return;
    }
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectCreateApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint+`?category_id=${this.state.category.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify({ data: body })
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  attachmentRemove = (attachmentID:string) =>{
   this.setState({attachmentRemove:true,attachmentID:attachmentID,filesUploaded:[]},
    () => this.addAttachmentsForProject([])
   )
  }

  addAttachmentsForProject = (attachments:any) => {
    const formdata = new FormData();
      formdata.append("attachments[0][remove]", this.state.attachmentRemove.toString());
      formdata.append("id", this.props.projectId.toString());
      formdata.append("attachments[0][id]", this.state.attachmentID )
      if(attachments){
         attachments.forEach((file:any, index:number) => {
           const key = `attachments[${index}][data]`;
           formdata.append(key, file);
         });
      } 

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachmentsReqestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAttachments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectCreatedModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveProjectCreated: false });
  };
  projectLeadChangeModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveChangeProjectLead: false });
  };
  projectLeadHandler = (e: any, id: number = 0) => {
    e.preventDefault();
    this.setState({ isActiveChangeProjectLead: true });
  };

  clearProjectModal = () => {
    this.setState({
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      category: null,      
      currentDraftId: 0,
      assignees: [],
      error: {
        title: "",
        startDate: "",
        category: "",
        taskListName: "",
        taskListProject: "",
        due_date: "",
      },
    
    });
  };

  popoverHandleClose = () => {
    this.clearProjectModal();
    this.setState({
      popoverAnchorEl: null,
      isEditing: false,
      filesUploaded:[],
    });
    this.props.popoverHandleClose();
  };

  projectCreated = () => {
    this.setState({
      isActiveProjectCreated: true,
    });
    this.popoverHandleClose();
  };

  removeProjectLead = () => {
    this.setState({ assignees: [] });
  };



  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          team: any[];
          last_name: string;
          email: string;
          first_name: string;
          deactivated:boolean;
        };
      }) => ({
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        id: item.id,
        email: item.attributes.email,
        initials: `${item?.attributes?.first_name
          ?.charAt(0)
          ?.toUpperCase()}${item?.attributes?.last_name?.charAt(0)?.toUpperCase()}`,
        team: item.attributes.team
          ? item.attributes.team.map((team) => team.title)
          : [],
        deactivated:item.attributes.deactivated
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };

  getAssigneeNames=(assigneeList:any)=>{
    return assigneeList.filter((x:any)=>x.first_name!=null).map((assignee:any)=>
      { 
        var item = this.state.assigneeList.find(x=>x.id==assignee.id);
      return ({
      ...assignee,
      title: `${assignee.first_name} ${assignee.last_name}`,
      id: assignee.id,
      initials: `${assignee?.first_name?.charAt(0)
        ?.toUpperCase()}${assignee?.last_name?.charAt(0)?.toUpperCase()}`,
        team: item?.attributes.team
          ? item.attributes.team.map((team:any) => team.title)
          : [],
    })})
  }

  containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  changeProjectLead = (assigneeId: number, deactivated:boolean) => {
    let selectedAssignee = this.state.assignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = [];
    } else {
      if(!deactivated)
      selectedAssignee = [assigneeId];
      else {
        toast.error("Deactivated User Can't be select", {
        position: toast.POSITION.TOP_CENTER,
        icon: () => (
          <CardMedia component="img" src={warningIcon} alt="emptydata" />
        ),
      });}
    }
    this.setState({
      assignees: selectedAssignee,
    });
  };

  sortByAssigneeHandler = (e: any) => {
    this.setState({ sortByAssignee: e.target.value });
  };
  sortAssignees = (dataToSort: any) => {

    const { sortByAssignee } = this.state;
    
    if (sortByAssignee != "") {
      let rightSort: number;
      let leftSort: number;
      rightSort = sortByAssignee == "Z-A" ? -1 : 1;
      leftSort = sortByAssignee == "Z-A" ? 1 : -1;
      dataToSort = dataToSort.sort((assignee1: any, assignee2: any) => {        
        if (assignee1.title.toLowerCase() > assignee2.title.toLowerCase())
          return rightSort;
        if (assignee1.title.toLowerCase() < assignee2.title.toLowerCase())
          return leftSort;
        return 0;
      });
    }

    return dataToSort;
  };
  editProject = (formData: any) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProjectApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateEndPoint+`?category_id=${this.state.category.id}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  editProjectOpen = () => {
    let curProject=this.state.currentProject;
    const assigneeValue: string[] = curProject.attributes.project_lead.map(
      (item: any) => String(item.id)
    );

    this.setState({
      popoverAnchorEl: this.popoverRef.current,
      viewTabsValue: 0,
      isEditing: true,
      title: curProject.attributes.title,
      description: curProject.attributes.description,
      startDate: curProject.attributes.start_date,
      endDate: curProject.attributes.end_date,
      category: {id:curProject.attributes.category?.id,title:curProject.attributes.category?.category},
      assignees: assigneeValue,
      updateProjectId:curProject.id,
      filesUploaded:curProject.attributes.attachments_url
    });
    this.props.projectClose()
    this.handleMenuClose();
  };

  getAssigneeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.memberListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
   
  };
  createAnotherProject = () => {
    this.setState({
      isActiveProjectCreated: false,
      popoverAnchorEl: this.popoverRef.current,
    });
  };
  createProjectOpen = (event: any) => {
    this.setState({ createProjectEl: event.currentTarget });
  };
  createProjectClose = () => {
    this.setState({
      createProjectEl: null,
    });
  };

  popoverHandleClick = (event: any) => {
    this.getAssigneeList();
    this.getCategory();
    this.setState({
      createProjectEl:null,
      popoverAnchorEl: event.currentTarget,
      viewTabsValue: 0,
      isEditing: false,
      currentDraftId: 0,
    });
  };


  getTimesheetModalClose = () => {
    this.setState({ isGetTimesheet: false },this.props.closeTimeSheet);
  };

  getTimesheetModalOpen = (e: any) => {
    e.preventDefault();
    this.setState({ isGetTimesheet: true,anchorEl:null });
  };  
  
  timesheetChooseAssigneesHandler = (e:any) => {
    e.preventDefault();
    this.setState({ isActiveTimesheetChooseAssignees: true });
  };

  timesheetChooseAssigneesModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveTimesheetChooseAssignees: false });
  };

  dateRangeFilter = (date: any) => {
    this.setState({ logDates: date });
  };
  
  
  
  handleProjectChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
          timeSheetProject: event.target.value,
      });
    }
  };
  
  TimesheetGeneratingModalClose = () => {
    this.setState({
      isTimesheetGenerating: false,
    });
  };

  TimesheetGenerating = () => {
    this.setState({ isTimesheetGenerating: true });
  };

  
  selectAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.logAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      logAssignees: selectedAssignee,
    });
  };

  changeTimeSheetAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.assignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      assignees: selectedAssignee,
    });
  };

  projectStatusUpdate = (statusId:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectStatusApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectStatusEndPoint + `?id=${this.props.projectId}&status=${statusId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectChangeStatus = (e: any) => {
    this.setState({projectStatus:e.target.value ?? 0})
    this.projectStatusUpdate(e.target.value ?? 0);
  };
  allAssigneeModalOpen = () => {
    this.setState({ isActiveAllAssignee: true });
  };
  allAssigneeModalClose = () => {
    this.setState({ isActiveAllAssignee: false });
  };

  getTodayDate = () => normalizeDate(moment());

  getTomorrowDate = () => normalizeDate(moment().add(1, "day"));

  getThisWeeksDate = () => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    return {
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: endOfWeek.format("YYYY-MM-DD"),
    };
  };

  getProjectTasksByTab = () => {
    const projectTasks:any = this.state.currentProject?.attributes.tasks.data;
    const today = projectTasks.filter((task:any) => {
      if (task.attributes.due_date === this.getTodayDate()) return task;
    });
    const tomorrow = projectTasks.filter((task:any) => {
      if (task.attributes.due_date === this.getTomorrowDate()) return task;
    });
    const thisWeek = projectTasks.filter((task:any) => {
      if (
        moment(task.attributes.due_date).isBetween(
          this.getThisWeeksDate().startDate,
          this.getThisWeeksDate().endDate
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDate().startDate)
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDate().endDate)
        )
      )
        return task;
    });
    const thisMonth = projectTasks.filter((task:Task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          moment().startOf('month'),
          moment().endOf('month')
        ) ||
        moment(task.attributes.due_date).isSame(
          moment().startOf('month')
        ) ||
        moment(task.attributes.due_date).isSame(
          moment().endOf('month')
        )
      )
        return task;
    });
    const later = projectTasks.filter((task:Task) => {
      if (moment(task.attributes.due_date).isAfter(moment().endOf('month'))) return task;
    });
    return {
      today: today,
      tomorrow: tomorrow,
      thisWeek: thisWeek,
      thisMonth: thisMonth,
      later: later
    }    
  }
  handleOpenUseExisting = () =>{
    this.setState({openUseExisting:true});
    this.getAllProjects();
  }
  handleCloseUseExisting = () =>{
    this.setState({openUseExisting:false});
  }
  handleTemplateGroupButton = (value: number) => () => {
    this.setState({ chooseTemplateChecked: value , useExistingProjectId:value });
  };
  handleSearchChange = (event: { target: { value: string } }) => {
    this.setState({ searchQuery: event.target.value , isSearching:true ,isSorting:false }, () => {
      this.filterProjects();
    });
  };
  handleSortChange = (event: { target: { value: string } }) => {
    this.setState({ sortQuery: event.target.value , isSorting:true ,isSearching:false }, () => {
      this.sortProjects();
    });
  };
  sortProjects = () => {
    const { projectList, sortQuery } = this.state;
    
    let sortedProjects;
    if (sortQuery === 'A-Z') {
      sortedProjects = projectList.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
    } else {
      sortedProjects = projectList.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
    }
  
    this.setState({ sortedProjects });
  };
  filterProjects = () => {
    const { projectList, searchQuery } = this.state;
    const filteredProjects = projectList.filter((project:Project) =>
      project.attributes.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({ filteredProjects });
  };
  getAllProjects = () => { 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    this.projectListApiRequestID = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleProjectEscEvent = (event: any) => {
    event.stopPropagation()
    if (event.key === 'Escape') {
      this.saveAsDraftProject()
    }
  }
  getProjectLead(currentProject: any): string | null {
    const projectLead = currentProject?.attributes?.project_lead?.[0];
    if (projectLead) {
      return `${projectLead.first_name} ${projectLead.last_name}`;
    }
    return null;
  }
  
  projectDataEmpty = () => {
    this.setState({
      currentProject: {
        "attributes": {
          "title": "",
          "description": "",
          "status": "",
          "attachments_url": [],
          "tasks": {
            "data": []
          },
          "labor_data": {
            "projected_labor_cost": 0,
            "projected_labor_hours": 0,
            "actual_labor_cost": 0,
            "actual_labor_hours": 0,
            "labor_count": 0
          },
          "machinery_data": {
          "projected_machinery_cost": 0,
          "projected_machinery_hours": 0,
          "actual_machinery_cost": 0,
          "actual_machinery_hours": 0
        }
        }
      }
    });
  }
  // Customizable Area End

}