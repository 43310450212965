// Customizable Area Start
import {
  createStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import { borderCircle } from "../assets";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    HeaderWrapper: {
      padding: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        padding: "40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "60px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "60px 24px 24px"
      },
      "&.profile-header": {
        padding: "24px 32px",
        flexDirection: "unset",
        background: "transparent",
        boxShadow: "none",
        "& .header-left": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          "& .auth-logo": {
            maxWidth: "112px",
            marginRight: "120px"
          }
        },
        "& .header-nav": {
          "& .nav-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "0",
            "& .MuiListItem-root": {
              width: "auto",
              padding: "0",
              marginRight: "48px",
              "& .nav-link": {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2B2B2B",
                opacity: "0.37",
                textDecoration: "none",
                fontFamily: "Expletus Sans"
              }
            }
          }
        },
        "& .header-right": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginLeft: "15px",
          "& .MuiButton-root": {
            minHeight: "40px",
            padding: "10px 9px"
          },
          "& .right-icon": {
            marginLeft: "26px",
            "& .icon-btn": {
              padding: "6px"
            }
          },
          "& .profile-icon": {
            width: "36px",
            height: "36px",
            background: "#ECF1F4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2B2B2B",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            textTransform: "uppercase"
          }
        }
      },
      "& .auth-logo": {
        maxWidth: "162px",
        width: "100%",
        "& img": {
          maxWidth: "100%"
        }
      },
      "& .auth-back": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& i": {
          marginRight: "10px"
        }
      },
      "& .auth-right": {
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        // [theme.breakpoints.down("xs")]: {
        //   marginLeft: "0",
        //   marginTop: "20px",
        //   width: "100%",
        // },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px"
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          transition: "none",
          "&:last-child": {
            marginRight: "0"
          }
        }
      }
    },
    membersuccessWrapper: {
      "& .inner-wrapper": {
        margin: "auto"
      }
    },
    memberAddedWrapper: {
      padding: "60px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "24px 60px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px"
      },
      "& .member-added-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "1040px",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "850px"
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          // justifyContent: "center",
          // textAlign: "center",
          maxWidth: "320px"
        },
        "& .member-added-img": {
          maxWidth: "456px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "325px"
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "290px",
            display: "none"
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "235px"
          }
        }
      },
      "& .member-added-content": {
        marginLeft: "168px",
        flex: "1",
        [theme.breakpoints.down("md")]: {
          marginLeft: "100px"
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
          maxWidth: "512px",
          width: "100%"
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "20px"
        }
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 9px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400
        // [theme.breakpoints.down("sm")]: {
        //   fontSize: "18px",
        //   lineHeight: "24px",
        //   margin: "0 0 10px",
        // },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Roboto",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 30px"
        }
      },
      "& .team-button-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& .MuiButton-root": {
          minHeight: "56px",
          width: "auto",
          minWidth: "156px",
          flex: "0 0 48%",
          maxWidth: "48%",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
            flex: "0 0 100%",
            maxWidth: "100%"
          }
        }
      }
    },
    memberList: {
      padding: "0",
      marginBottom: "24px",
      "& .MuiListItem-root": {
        padding: "15px",
        borderRadius: "12px",
        border: "1px solid #e8ecf2",
        marginBottom: "9px",
        "&:last-child": {
          marginBottom: "0"
        },
        "& .MuiListItemText-root": {
          margin: "0"
        }
      },
      "& .name-status": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "4px",
        "& .name": {
          fontSize: "14px",
          letterSpacing: "0",
          lineHeight: "20px",
          color: "#000000",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          marginRight: "10px"
        },
        "& .status": {
          fontSize: "10px",
          letterSpacing: "1px",
          lineHeight: "16px",
          textTransform: "uppercase",
          color: "#2b2b2b",
          fontWeight: 500,
          fontFamily: "Roboto",
          borderRadius: "6px",
          backgroundColor: "#ecf1f4",
          padding: "0 5px",
          "&.owner": {
            backgroundColor: "#225a80",
            color: "#ffffff"
          },
          "&.manager": {
            backgroundColor: "#92c7d3",
            color: "#ffffff"
          }
        }
      },
      "& .email": {
        marginBottom: "5px",
        display: "block"
      },
      "& span": {
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "16px",
        fontWeight: 400,
        fontFamily: "Roboto",
        color: "rgba(0,0,0,0.671)"
      },
      "& .memberlist-info": {
        "& span": {
          position: "relative",
          marginRight: "9px",
          paddingRight: "16px",
          "&:last-child": {
            "&:after": {
              display: "none"
            }
          },
          "&:after": {
            content: '""',
            width: "6px",
            height: "6px",
            borderRadius: "3px",
            backgroundColor: "#ecf1f4",
            display: "inline-block",
            position: "absolute",
            top: "5px",
            right: "0"
          }
        }
      }
    },
    profileInfo: {
      marginBottom: "32px",
      textAlign: "center",
      "& .profile-outer": {
        width: "120px",
        height: "120px",
        border: "0",
        borderRadius: "100%",
        padding: "10px",
        margin: "0 auto 16px",
        backgroundPosition: "center",
        backgroundImage: `url(${borderCircle})`,
        backgroundRepeat: "no-repeat",
        "& .profile-icon": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#4BA3B7",
          width: "100%",
          height: "100%",
          borderRadius: "inherit",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#FFFFFF",
          fontFamily: "Expletus Sans",
          overflow: "hidden",
          textTransform: "uppercase"
        }
      },
      "& .profile-name": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#2B2B2B",
        marginBottom: "4px",
        fontFamily: "Expletus Sans",
        textTransform: "capitalize"
      },
      "& .job-info": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto"
      },
      " & .profile-email": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto"
      }
    },
    chipButton: {
      background: "#ECF1F4",
      borderRadius: "6px",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#2B2B2B",
      padding: "7px 6px",
      cursor: "pointer",
      marginRight: "8px",
      marginBottom: "8px",
      height: "26px",
      "&.more-btn": {
        marginRight: "0"
      },
      "& .MuiChip-avatar": {
        width: "12px",
        height: "12px",
        marginLeft: "0",
        marginRight: "5px"
      },
      "& .MuiChip-label": {
        padding: "0"
      }
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px"
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px"
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px"
                }
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0"
                }
              }
            }
          }
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .MuiListItemText-root": {
            margin: "0"
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC"
            },
            "& .multiselect-check": {
              display: "block"
            }
          },
          "& .multiselect-check": {
            display: "none",
            flex: "0 0 16px",
            marginLeft: "5px",
            maxWidth: "16px"
          }
        },
        "& .MuiTouchRipple-root": {
          display: "none"
        }
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#000000",
        letterSpacing: "0",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit"
        }
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px"
      }
    },
    teamModuleWrapper: {
      padding: "35px 24px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "24px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "25px 24px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px 0 0"
      },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginBottom: "24px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px"
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px"
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: "10px"
          },
          "& .secondary-btn": {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "nowrap"
          }
        },
        "& .members-count": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2b2b2b",
          letterSpacing: "0.1px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          marginRight: "14px"
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0 0 9px",
          color: "rgba(0,0,0,0.87)",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",

          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B"
          }
          // [theme.breakpoints.down("sm")]: {
          //   fontSize: "18px",
          //   lineHeight: "24px",
          //   margin: "0 0 10px",
          // },
        },
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#505050",
          fontFamily: "Roboto",
          fontWeight: "400",
          margin: "0",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px"
          }
        }
      },
      "& .search-filter-sm": {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          padding: "0 24px",
          marginBottom: "3px"
        },
        "& .search-wrapper": {
          flex: "1",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              paddingTop: "16px",
              paddingBottom: "16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8"
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8"
              },
              "&::placeholder": {
                color: "#94A3B8"
              }
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                width: "20px",
                height: "20px"
              }
            }
          }
        },
        "& .sort-group": {
          marginLeft: "16px",
          cursor: "pointer"
        },
        "& .filter-group": {
          marginLeft: "16px",
          cursor: "pointer"
        }
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center"
      },
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "55px",
        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px"
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          flexWrap: "wrap"
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          }
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px"
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          }
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px"
          // [theme.breakpoints.down("sm")]: {
          //   marginLeft: "0",
          //   marginTop: "10px",
          //   width: "100%",
          // },
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px"
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px"
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px"
          },
          "& .select-control": {
            marginRight: "51px",
            [theme.breakpoints.down("md")]: {
              marginRight: "50px"
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px"
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px"
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0"
              }
            }
          }
        },
        "& .search-wrapper": {
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: {
              width: "308px"
            },
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px"
          },
          "& .MuiInput-underline": {
            "&:before, &:after": {
              display: "none"
            }
          },
          "& .MuiSvgIcon-root": {
            color: "#2b2b2b",
            fontSize: "22px",
            opacity: "0.67",
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px"
            }
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none"
            },
            "& .MuiInput-formControl": {
              marginTop: "0"
            },
            "& .MuiSelect-select": {
              paddingRight: "38px"
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "18px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8"
            }
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "rgba(0, 0, 0, 0.87)",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent"
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red"
            }
          },
          "& .MuiInput-underline:before": {
            display: "none"
          },
          "& .MuiInput-underline:after": {
            display: "none"
          }
        }
      }
    },
    dashboardWrapper: {
      "& .header-wrapper": {
        padding: "16px 24px",
        "& .auth-logo": {
          maxWidth: "118px"
        }
      }
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px"
      },
      "& .MuiFormControl-root": {
        width: "100%"
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "rgba(0, 0, 0, 0.87)",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B"
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit"
        }
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8"
        }
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px"
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px"
          }
        }
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff"
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)"
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6"
          }
        }
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px"
        }
      }
    },
    emptyDataBox: {
      minHeight: "54vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .emptydata-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "809px",
        margin: "25px auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "661px"
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "100%",
          padding: "0 24px",
          margin: "18px auto"
        },
        "& .emptydata-img": {
          maxWidth: "389px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "281px"
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "327px"
          }
        }
      },
      "& .emptydata-content": {
        marginLeft: "100px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "60px"
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "50px",
          width: "100%"
        }
      },
      "& .heading": {
        fontSize: "20px",
        lineHeight: "27px",
        margin: "0 0 12px",
        color: "rgba(0, 0, 0, 0.87)",
        letterSpacing: "0.25px",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {
          fontSize: "18px",
          lineHeight: "24px"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "21px",
          letterSpacing: "0.03em",
          color: "#363636",
          fontFamily: "Roboto",
          fontWeight: 700,
          margin: "0 0 16px"
        }
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 48px",
        color: "#2b2b2b",
        opacity: "0.671",
        fontFamily: "Roboto",
        fontWeight: "400",
        letterSpacing: "0.25px",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 32px"
        },
        [theme.breakpoints.down("xs")]: {
          letterSpacing: "0.03em",
          color: "#7A7A7A",
          margin: "0 0 24px"
        }
      }
    },
    tabWrapper: {
      "& .MuiTabs-flexContainer": {
        margin: "0 30px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 24px"
        }
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#3691a6"
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid #E8ECF2"
        }
      },
      "& .MuiTab-root": {
        lineHeight: "20px",
        fontSize: "14px",
        color: "#2b2b2b",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        letterSpacing: 0,
        textTransform: "unset",
        opacity: "0.671",
        [theme.breakpoints.up("sm")]: {
          minWidth: "166px"
        },
        [theme.breakpoints.down("xs")]: {
          width: "50%"
        },
        "&.Mui-selected": {
          opacity: "1"
        }
      },
      "& .MuiTabPanel-root": {
        padding: "0"
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between"
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "48%",
            minWidth: "unset"
          },
          "&:last-child": {
            marginRight: "0"
          }
        }
      }
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "150px",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff"
      }
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "150px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b"
      }
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },
    authMainWrapper: {
      "& .add-option-wrapper": {
        borderRadius: "8px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        padding: "19px 22px 22px",
        margin: "5px 0",
        "& .MuiOutlinedInput-input": {
          padding: "10px 0",
          fontSize: "14px",
          letterSpacing: "0px",
          lineHeight: "20px",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Expletus Sans"
        },
        "& .MuiFormLabel-root": {
          transform: "none"
        },
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          }
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent"
        },
        "& .form-control": {
          marginBottom: "0"
        },
        "& .button-wrapper": {
          borderTop: "1px solid #e8ecf2",
          marginTop: "9px",
          paddingTop: "16px",
          display: "flex",
          justifyContent: "flex-end",
          "& .add-link": {
            marginRight: "28px",
            opacity: "0.671",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0",
            color: "#4ba3b7",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            "&.secondary-link": {
              color: "#2b2b2b"
            },
            "&:last-child": {
              marginRight: "0"
            },
            "&:hover": {
              textDecoration: "underline"
            }
          }
        }
      },
      "& .wrapper": {
        paddingBottom: "0"
      },

      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex"
        }
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "470px"
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0"
        }
      }
    },
    innerWrapper: {
      width: "100%"
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 60px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px"
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px"
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        padding: "30px 25px"
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "24px",
          width: "100%"
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer"
            },
            "& .modal-title ": {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginLeft: "24px"
            }
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none"
          }
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0"
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8"
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8"
                },
                "&::placeholder": {
                  color: "#94A3B8"
                }
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "27px"
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px"
                }
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0"
                }
              }
            },
            "& .select-control": {
              "&.multi-select": {
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginBottom: "32px"
                },
                "& .multiselect-label": {
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                    position: "static",
                    transform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    marginBottom: "16px",
                    letterSpacing: "0"
                  }
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                  [theme.breakpoints.down("sm")]: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    color: "#94A3B8",
                    padding: "18px 40px 18px 24px",
                    lineHeight: "20px"
                  }
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  [theme.breakpoints.down("sm")]: {
                    borderColor: "#e8ecf2"
                  }
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      [theme.breakpoints.down("sm")]: {
                        borderWidth: "1px"
                      }
                    }
                  }
                },
                "& .multiple-clear": {
                  position: "absolute",
                  fontSize: "15px",
                  right: "18px",
                  top: "3px",
                  zIndex: 1,
                  color: "#94A3B8",
                  [theme.breakpoints.down("sm")]: {
                    top: "calc(50% + 17px)",
                    right: "27px",
                    transform: "translateY(-50%)"
                  }
                }
              },
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0",
                color: "#000000",
                opacity: "0.671",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                paddingBottom: "0",
                paddingTop: "0",
                "&:focus": {
                  backgroundColor: "transparent"
                }
              },
              "& .MuiInput-underline:before": {
                display: "none"
              },
              "& .MuiInput-underline:after": {
                display: "none"
              }
            }
          }
        },
        "& .modal-description": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }
        },
        "& .modal-footer": {
          [theme.breakpoints.down("xs")]: {
            marginTop: "auto"
          }
        }
      },
      "& .form-control": {
        "& .MuiOutlinedInput-input": {
          "&.Mui-disabled": {
            color: "#94A3B8"
          }
        }
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px"
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        textTransform: "capitalize"
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "27px"
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0"
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          margin: "0 -12px",
          [theme.breakpoints.down("xs")]: {
            margin: "0 -10px"
          },
          "&:last-child": {
            "& .form-control, & .select-outline": {
              marginBottom: "0"
            },
            "& .hourlyPay": {
              "& .form-control": {
                marginBottom: "0"
              }
            },
            "& .col6": {
              "& .form-control": {
                marginBottom: "32px"
              }
            }
          },
          "& .email-field, & .password-field": {
            "& .form-control": {
              "& .MuiOutlinedInput-input": {
                color: "#94A3B8"
              }
            }
          },
          "& .form-col": {
            padding: "0 12px",
            flex: "0 0 100%",
            maxWidth: "100%",
            [theme.breakpoints.down("xs")]: {
              padding: "0 10px"
            }
          },
          "& .col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 100%",
              maxWidth: "100%"
            }
          }
        },
        "& .select-outline": {
          marginBottom: "24px"
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
          letterSpacing: "0.0075em",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          "&.error-select": {
            color: "#C7263E"
          },
          "&.Mui-error": {
            color: "#C7263E"
          },
          "&.Mui-default": {
            color: "#2B2B2B"
          }
        },
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "&.error-outer": {
            "& .MuiInputLabel-formControl": {
              color: "#C7263E"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#C7263E"
            }
          },
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              fontFamily: "Roboto",
              fontWeight: "400"
            }
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#2B2B2B",
            fontWeight: 400,
            fontFamily: "Roboto",
            "&:focus": {
              backgroundColor: "transparent"
            }
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2"
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px"
              }
            }
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              fontFamily: "Roboto",
              fontWeight: "400"
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto"
            }
          }
        }
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        margin: "0 0 8px",
        marginTop: "15px",
        letterSpacing: "0"
      },
      "& .form-subheading": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        margin: "0 0 8px",
        letterSpacing: "0"
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto"
        }
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px"
        }
      },
      "& .modal-form": {
        marginTop: "47px"
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px"
      },
      "& .modal-footer": {
        marginTop: "44px",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0"
            },
            "&.primary-btn": {
              padding: "12px 38px"
            },
            "&.secondary-btn": {
              minWidth: "140px"
            }
          }
        }
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px"
        }
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%"
      }
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
        backdropFilter: 'blur(2px)',
      }
    },
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize"
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%"
      },
      "& .auth-inner-block": {
        flexWrap: "wrap",
        display: "flex",
        "& .auth-right": {
          flex: "1",
          display: "flex",
          padding: "64px 63px 142px",
          flexDirection: "column",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            padding: "64px 63px 83px"
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px"
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px"
          },
          "& .right-inner-content": {
            width: "100%",
            margin: "auto",
            maxWidth: "512px"
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%"
          },
          "& .back-logout-wrap": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: "0 0 90px",
            [theme.breakpoints.down("md")]: {
              margin: "0 0 50px"
            }
          },
          "& .back-btn-wrap": {
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "120px"
            },
            [theme.breakpoints.down("xs")]: {
              position: "absolute",
              top: "110px"
            },
            "& i": {
              display: "block"
            }
          }
        }
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 20px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans"
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        //   margin: "0 0 15px",
        // },
        // [theme.breakpoints.down("sm")]: {
        //   fontSize: "18px",
        //   lineHeight: "24px",
        //   margin: "0 0 10px",
        // },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Expletus Sans",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 30px"
        }
      },
      "& .form-border": {
        borderRadius: "12px",
        border: "1px solid #e8ecf2",
        padding: "15px"
      },
      "& .form-info-wrapper": {
        margin: "0 0 12px",
        "&.invite-info-wrapper": {
          margin: "0 0 24px"
        }
      },
      "& .invite-member-block": {
        "&.auth-inner-block": {
          "& .auth-right": {
            "& .back-logout-wrap": {
              margin: "0 0 56px"
            }
          }
        },
        "& .heading": {
          margin: "0 0 11px"
        },
        "& .sub-txt": {
          margin: "0 0 37px"
        },
        "& .form-row": {
          "&:last-child": {
            "& .form-control": {
              margin: "0"
            }
          }
        }
      },
      "& .team-button-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        // marginTop: "49px",
        marginTop: "44px",
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%"
          }
        },
        "& .MuiButton-root": {
          minHeight: "56px",
          width: "auto",
          minWidth: "156px",
          [theme.breakpoints.down("xs")]: {
            width: "48%",
            minWidth: "110px"
          }
        }
      },
      "& .form-heading": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 20px",
        color: "#000000",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 500
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -15px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -10px"
        },
        "& .form-col": {
          padding: "0 15px",
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.down("xs")]: {
            padding: "0 10px"
          }
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%"
        }
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "95px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "60px"
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "40px"
        }
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px"
        }
      }
    },
    radioChoose: {
      width: "100%",
      marginBottom: "24px",
      "&.error-show": {
        "& .MuiRadio-colorSecondary ~ span": {
          border: "1px solid #C7263E",
          color: "#C7263E"
        }
      },
      "& .radio-group": {
        flexDirection: "row",
        margin: "0 -5px",
        [theme.breakpoints.down("xs")]: {
          margin: "0"
        },
        "& .MuiFormControlLabel-root": {
          margin: "0",
          flex: "0 0 33.33%",
          maxWidth: "33.33%",
          padding: "0 5px",
          [theme.breakpoints.down("xs")]: {
            flex: "1",
            maxWidth: "33.33%",
            marginRight: "5px",
            padding: "0"
          },
          "&:last-child": {
            marginRight: "0"
          },
          "&:nth-child(3n)": {
            [theme.breakpoints.down("xs")]: {
              marginRight: "0"
            }
          }
        }
      },
      "& .MuiRadio-root": {
        display: "none"
      },
      "& .MuiRadio-colorSecondary ~ span": {
        padding: "10px 15px",
        width: "100%",
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "57px",
        transition: "all 0.3s ease-in-out"
      },
      "& .MuiRadio-colorSecondary.Mui-checked ~ span": {
        background: "#4ba3b7",
        color: "#ffffff"
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        // color: "#C7263E",
        color: "#00BA88",
        // fontFamily: "Expletus Sans",
        fontFamily: "Roboto",
        fontWeight: "400",
        marginLeft: "14px",
        marginRight: "14px",
        "&.Mui-error": {
          color: "#C7263E"
        },
        "&.Mui-default": {
          color: "#2B2B2B"
        }
      }
    },
    logoutButton: {
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      minWidth: "94px",
      padding: "10px 16px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 6px"
      },
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b"
      }
    },
    createdTeamData: {
      borderTop: "1px solid #e8ecf2",
      marginTop: "51px",
      paddingTop: "67px",
      "& .heading": {
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        color: "#000000",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
        marginBottom: "7px",
        textTransform: "capitalize"
      },
      "& .sub-txt": {
        color: "#000000",
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: "16px",
        marginBottom: "37px",
        letterSpacing: "0"
      }
    },
    createdTeamDataList: {
      padding: "0",
      "& .MuiListItem-root": {
        padding: "0",
        marginBottom: "25px",
        "&:last-child": {
          marginBottom: "0"
        }
      },
      "& .list-row": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        flexWrap: "wrap",
        "& .list-left": {
          flex: "1"
        },
        "& .list-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px"
          }
        },
        "& .menu-icon": {
          width: "17px",
          marginLeft: "23px"
        },
        "& .team-name": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          marginBottom: "7px",
          textTransform: "capitalize"
        },
        "& .team-user": {
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0",
          color: "rgba(0,0,0,0.671)",
          fontWeight: 400,
          fontFamily: "Roboto"
        },
        "& .team-date": {
          fontSize: "12px",
          lineHeight: "23px",
          letterSpacing: "0",
          color: "rgba(0,0,0,0.671)",
          fontWeight: 400,
          fontFamily: "Roboto"
        }
      }
    },
    selectOutline: {
      width: "100%",
      "&.team-outline": {
        "& .MuiFormLabel-filled": {
          display: "none"
        }
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(25,25,25,0.32)"
      },
      "& .MuiFormControl-root": {
        width: "100%"
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#94a3b8",
        letterSpacing: "0.0075em",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        width: "calc(100% - 28px)",
        "& .MuiInputLabel-asterisk": {
          marginLeft: "auto",
          fontSize: "18px",
          lineHeight: "23px",
          color: "#e86577"
        },
        "&.Mui-error": {
          color: "rgba(43, 43, 43, 0.87)"
        }
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2"
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "23px"
        }
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b5b5b5",
        borderWidth: "1px"
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2b2b2b",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.0075em",
        padding: "20px 16px",
        "&:focus": {
          background: "transparent"
        },
        "&.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0"
        }
      },
      "& .MuiInputLabel-outlined": {
        color: "#94a3b8"
      },
      "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(43,43,43,0.87)",
        letterSpacing: "0.0075em",
        fontFamily: "Roboto",
        fontWeight: "400",
        width: "auto",
        "& ~.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2"
          }
        },
        "&.Mui-error": {
          color: "rgba(43,43,43,0.87)"
        }
      },
      "& .MuiInputLabel-outlined.Mui-focused ": {
        "& ~.MuiOutlinedInput-root.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E"
          }
        }
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E"
        }
      },
      "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C7263E"
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: "400",
        "&.error-select": {
          color: "#C7263E"
        },
        "&.Mui-error": {
          color: "#C7263E"
        },
        "&.Mui-default": {
          color: "#2B2B2B"
        }
      }
    },
    dropMenu: {
      "& .dropmenu-btn": {
        letterSpacing: "0",
        background: "#4ba3b7",
        border: "1px solid #4ba3b7",
        borderRadius: "7px",
        filter: "none",
        boxShadow: "none",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#ffffff",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textAlign: "center",
        padding: "12px 10px",
        minHeight: "48px",
        marginBottom: "0",
        transition: "all 0.5s ease-in-out",
        textTransform: "capitalize",
        width: "auto",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 6px",
          minHeight: "46px",
          minWidth: "unset"
        }
      },
      "& .team-dropmenu-btn" :{
        letterSpacing: "0",
        border: "1px solid #E8ECF2",
        borderRadius: "7px",
        filter: "none",
        boxShadow: "none",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textAlign: "center",
        padding: "12px 10px",
        minHeight: "48px",
        marginBottom: "0",
        transition: "all 0.5s ease-in-out",
        textTransform: "capitalize",
        width: "auto",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 6px",
          minHeight: "46px",
          minWidth: "unset"
        }
      }},
    dropDropdown: {
      "& .MuiMenu-paper": {
        minWidth: "156px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        marginTop: "52px",
        boxShadow: "none",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          padding: "14px 17px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start"
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px"
        }
      }
    },
    simpleMenuDropDown: {
      "& .MuiMenu-paper": {
        marginTop: "0px",
      }
    }
  });
// Customizable Area End
