// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
      },
    },
    settingWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "78px 0 194px",
        [theme.breakpoints.down("sm")]: {
          padding: "21px 0 129px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 0",
        },
      },
    },
    settingInnerWrapper: {
      textAlign: "left",
      // maxWidth: "1640px",
      //padding: "0 20px",
      maxWidth: "100%",
      width: "100%",
      padding: "0 160px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        // maxWidth: "1088px",
        padding: "0 116px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        paddingBottom: "48px",
        marginBottom: "48px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "32px",
          marginBottom: "44px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .setting-menu": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
          },
        },
      },
    },
    formInfoWrapper: {
      margin: "0 0 12px",
      "& .form-title": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 16px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          fontSize: "22px",
          lineHeight: "26px",
        },
        "&.verify-title": {
          margin: "0 0 9px",
        }
      },
      "& .format-title":{
        marginBottom:"24px"
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        margin: "0 0 16px",
        color: "#94A3B8",
        letterSpacing: "0.105px",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
      "& .form-label": {
        fontSize: "14px",
        lineHeight: "26px",
        margin: "0 0 8px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0.105px",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        marginTop: "6px",
        fontFamily: "Roboto",
        fontWeight: 400,
        "&.error-select": {
          color: "#C7263E",
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
      "& .form-heading": {
        fontSize: "16px",
        lineHeight: "24px",
        margin: "0 0 32px",
        color: "#2b2b2b",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -12px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -10px",
        },
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          padding: "0 12px",
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.down("xs")]: {
            padding: "0 10px",
          },
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
          },
        },
      },
      "& .form-control": {
        marginBottom: "24px",
      },
      "& .description-box": {
        borderRadius: "12px",
        background: "#F2F8FC",
        padding: "16px 24px",
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.105px",
        fontFamily: "Roboto",
        "& .label": {
          fontWeight: 700,
        },
      },
    },
    drawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: 302,
      [theme.breakpoints.down("md")]: {
        minWidth: 123,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
      "& .sidebar-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
    },
    drawerContent: {
      flex: 1,
    },
    settingBlockOuter: {
      maxWidth: "680px",
      width: "100%",
      padding: "0 15px",
      margin: "0",
      [theme.breakpoints.down("md")]: {
        maxWidth: "543px",
        margin: "0 80px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
      "&.date-number-formating-block":{
        width:"100%",
        maxWidth:"100%",
        margin:"0",
        padding: "0",
        [theme.breakpoints.down("md")]: {
          padding: "0 0 0 95px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "0",
        },
      },
      "&.deactive-block": {
        maxWidth: "880px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "743px",
        },
      },
      "&.mvc-setting-outer": {
        minHeight: "85vh",
        display: "flex",
        //justifyContent: "center",
        flexDirection: "column",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          minHeight: "49vh",
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: "66.5vh",
        },
      },
      "& .setting-block-wrapper": {
        "& .form-box": {
          display: "flex",
          gap: "94px",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            gap: "25px"
          },
        },
        "& .button-wrapper": {
          display: "flex",
          justifyContent: "space-between",
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "& .primary-btn": {
            minWidth: "156px",
            [theme.breakpoints.down("xs")]: {
              padding: "7px",
              fontSize: "12px",
              minHeight: "36px",
              borderRadius: "6px",
              flex: "1",
              minWidth: "100px",
            },
          },
          "& .deactive-btn": {
            minWidth: "200px",
            [theme.breakpoints.down("xs")]: {
              minWidth: "100px",
            },
          },
          "& .secondary-btn": {
            minWidth: "156px",
            color: "#4BA3B7",
            borderColor: "#4BA3B7",
            [theme.breakpoints.down("xs")]: {
              padding: "7px",
              fontSize: "12px",
              minHeight: "36px",
              marginRight: "16px",
              borderRadius: "6px",
              flex: "1",
              minWidth: "100px",
            },
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    selectOutline: {
      width: "100%",
      "& .down-arrow": {
        width: "16px",
        height: "16px",
        position: "absolute",
        top: "0",
        right: "16px",
        display: "inline-block",
        color: "#c0c0c0",
        pointerEvents: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(25,25,25,0.32)",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#94a3b8",
        letterSpacing: "0.0075em",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        width: "calc(100% - 28px)",
        "& .MuiInputLabel-asterisk": {
          marginLeft: "auto",
          fontSize: "18px",
          lineHeight: "23px",
          color: "#e86577",
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "23px",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b5b5b5",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2b2b2b",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.0075em",
        padding: "19px 46px 19px 16px",
        "&:focus": {
          background: "transparent",
        },
        "&.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0",
        },
      },
      "& .MuiSelect-iconOutlined": {
        right: "25px",
        fontSize: "20px",
      },
      "& .MuiInputLabel-outlined": {
        color: "#94a3b8",
      },
      "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(43,43,43,0.87)",
        letterSpacing: "0.0075em",
        fontFamily: "Roboto",
        fontWeight: "400",
        width: "auto",
        "& ~.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
      },
      "& .MuiInputLabel-outlined.Mui-focused ": {
        "& ~.MuiOutlinedInput-root.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E",
        },
      },
      "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C7263E",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: 400,
        "&.error-select": {
          color: "#C7263E",
        },
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    dropdownStyle: {
      maxWidth: "190px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      // transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    CancelBtn: {
      color:"rgba(43, 43, 43, 1)",
      fontSize: "14px",
      width: "156px",
      height: "48px",
      border:"1px solid #E8ECF2"
    },
    updateBtn:{
      marginLeft:"24px",
      fontSize: "14px !important"
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    secondaryHeader:{
      borderBottom:"1px solid #E8ECF2",
      padding:"25px 0",
      [theme.breakpoints.down("xs")]: {
        display:"none",
      },
      "& .image-wrapper":{
        "& a":{
          lineHeight: 1.5,
          fontFamily: "Expletus Sans",
          color: "#000000de",
          fontWeight: 400,
          fontSize: "16px",
          display: "inline-block",
          textDecoration: "none",
          "& em":{
            marginRight:"16px",
          },
        },
      },
      "& .container":{
        margin: "0 auto",
        padding: "0 25px", 
        maxWidth: "100%", 
      },
      
    },
  });
// Customizable Area End
