import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getCurrentTime} from "../../../components/src/ReusableFunctions";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  taskDetails: any;
  viewTaskHandler: (taskId:number) => void;
  type: string;
  updateCurrentStatus: (taskId: number) => void;
  currId: any;
  timerRunningProp: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  time: any;
  totalTime: any;
  timer:any;
  timerRunning: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TimetrackingbillingControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateTaskProgressAPICallId: string = "";
  timer:any;
  userSessionData:any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];
    
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");

    this.state = {
      // Customizable Area Start
      time:this.props.taskDetails?.attributes?.duration,
      totalTime: this.props.taskDetails?.attributes?.hours,
      timer: "",
      timerRunning: this.props.taskDetails?.attributes?.is_running,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: S) {
    let durations;
    const {taskDetails} = this.props;
    let currentTime = getCurrentTime();
    if(prevProps.taskDetails?.id !== this.props.currId) {
      if(this.props.taskDetails?.attributes?.current_status==="started"){
        let convertCurrentTime:any = new Date(currentTime)
        let convertStartedTime:any= new Date(taskDetails.attributes.started_at)
        const timeDifferenceInMilliseconds:any = convertCurrentTime - convertStartedTime;
        durations = taskDetails.attributes.duration + (timeDifferenceInMilliseconds/1000);
        this.setState({
          time : durations
        })
        this.handleStartPause()

      }
    }
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
  }
  
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  hoursToSeconds =  (hours:any) => {
    const [h, m, s] = hours.split(':').map(Number);
    const totalSeconds = (h * 3600) + (m * 60) + (s || 0);
    return totalSeconds;
   
  }

  handleStartPause = () => {
    let interval;
    if (this.props.timerRunningProp) {
      clearInterval(this.timer);
    } else {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({ time: prevState.time + 1 }));
      }, 1000);
    }
  }

  // Customizable Area End
}
