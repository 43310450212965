import React from "react";
// Customizable Area Start
import { Modal, Typography, Radio, Button, Box } from "@material-ui/core";
import withMediaQuery from "../../../../components/src/withMediaQuery.web";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { etohShopStyle } from "../../../../components/src/EtohShopStyleHelper.web";
import SubscriptionScreenController, {
  Props,
  configJSON,
  SubscriptionItem
} from "./SubscriptionScreenController.web";
import ConfirmActionModal from "./ConfirmActionModal.web";
import { RadioChecked } from "../assets";
// Customizable Area End

export class SubscriptionScreen extends SubscriptionScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, handleCloseCartEvent, isCartOpen } = this.props;
    const { subscriptiondata, selectedPlan, currencySymbol } = this.state;
    const Totalinvitedmember = this.calculateTotalInvitedMembers();
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Modal
          open={isCartOpen}
          onClose={handleCloseCartEvent}
          className={classes.modalBoxCenter}
          data-test-id="subscription-screen-modal-open"
        >
          <div className={classes.subcriptionpayModal}>
            <Box className={`view-container`}>
              <div className="header-container">
                <div className="modal-title-box">
                  <Typography className="title-txt">
                    {this.t(`${configJSON.SubscriptionHeadingTxt}`)}
                  </Typography>
                  <Typography className="sub-title-txt">
                    {this.t(`${configJSON.SubscriptionSubHeadingTxt}`)}
                  </Typography>
                </div>
                <div className="heading-bar">
                  <CloseIcon
                    className="close-icon"
                    onClick={this.handleOpenModal}
                  />
                </div>
              </div>
              <div className="plan-container">
                {subscriptiondata.map((item: SubscriptionItem) => {
                  const totalPrice = this.calculateTotalPrice(
                    item,
                    Totalinvitedmember
                  );

                  return (
                    <div key={item.id} className="plan-containerWrapper">
                      <div className="plan-inner-containerWrapper-1">
                        <Typography className="plan-title-txt">
                          {item.name}
                        </Typography>
                        <div className="plan-inner-containerWrapper-2">
                          <Typography className="plan-Price-txt">
                            {currencySymbol}{totalPrice.toFixed(2)}
                          </Typography>
                          <Radio
                            value={item.id}
                            checked={selectedPlan === item.id}
                            checkedIcon={
                              <img src={RadioChecked} alt="RadioChecked" />
                            }
                            onChange={this.handleRadioChange}
                            className="plan-Radio-input"
                            name="subscription-plan"
                            inputProps={{ "aria-label": item.name }}
                            data-test-id="subscription-screen-plan-select"
                          />
                        </div>
                      </div>
                      <Typography className="plan-sub-title-txt">
                        {this.renderPlanSubtitle(item, Totalinvitedmember)}
                      </Typography>
                    </div>
                  );
                })}
              </div>
              <Box className={`button-container`}>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.buySubscription}
                  data-test-id="Pay-subscription"
                >
                  {this.t(`${configJSON.PaysubscriptionTxt}`)}
                </Button>
              </Box>
            </Box>
          </div>
        </Modal>
        {this.state.isModalopen && (
          <ConfirmActionModal
            isOpen={this.state.isModalopen}
            handleClose={handleCloseCartEvent}
            modalConfirmAction={this.buySubscription}
            modalMessage={this.t(`To complete the addition of this member, a subscription is necessary. Please proceed with the payment to continue.`)}
            confirmBtnTxt={this.t("Add Members")}
            modalHeading={this.t("Subscription Required")}
            cancelBtnTxt={this.t("Don’t Add Members")}
            data-testid="confirmOption"
          />
        )}
        {this.state.isErrorModalopen && (
          <ConfirmActionModal
            isOpen={this.state.isErrorModalopen}
            handleClose={this.handleErrorCloseModal}
            modalConfirmAction={this.buySubscription}
            modalMessage={this.t(`There was an issue processing your payment. Please try again.`)}
            confirmBtnTxt={this.t("Retry")}
            modalHeading={this.t("Payment Failed")}
            cancelBtnTxt={this.t("Cancel")}
            data-testid="confirmOption"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(SubscriptionScreen)
);
// Customizable Area End
