// Customizable Area Start
import React from "react";
import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import { imgLogo } from "./assets";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import BackArrowWeb from "./BackArrow.web";
import { configJSON } from "../../blocks/email-account-registration/src/EmailAccountRegistrationController.web";


const useStyles = makeStyles((theme) => ({
  HeaderWrapper: {
    padding: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      padding: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "60px 24px 24px",
    },
    "&.profile-header": {
      padding: "24px 32px",
      flexDirection: "unset",
      background: "#ffffff",
      boxShadow: "0px 1px 0px 0px #E8ECF2",
      position: "sticky",
      left: "0",
      right: "0",
      top: "0",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& .header-left": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& .auth-logo": {
          maxWidth: "112px",
          marginRight: "120px",
          [theme.breakpoints.down("sm")]: {
            marginRight: "50px",
          },
        },
      },
      "& .header-nav": {
        "& .nav-list": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "0",
          "& .MuiListItem-root": {
            width: "auto",
            padding: "0",
            marginRight: "48px",
            "& .nav-link": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              opacity: "0.37",
              textDecoration: "none",
              fontFamily: "Expletus Sans",
            },
          },
        },
      },
      "& .header-right": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& .MuiButton-root": {
          minHeight: "40px",
          padding: "10px 9px",
        },
        "& .right-icon": {
          marginLeft: "24px",
          "& .icon-btn": {
            padding: "0",
          },
        },
        "& .profile-icon": {
          width: "36px",
          height: "36px",
          background: "#ECF1F4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          lineHeight: "19px",
          color: "#2B2B2B",
          borderRadius: "100%",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
        },
      },
    },
    "& .auth-logo": {
      maxWidth: "162px",
      width: "100%",
      "& img": {
        maxWidth: "100%",
      },
    },
    "& .auth-back": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Expletus Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textTransform: "capitalize",
      "& i": {
        marginRight: "10px",
      },
    },
    "& .auth-right": {
      marginLeft: "20px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      // [theme.breakpoints.down("xs")]: {
      //   marginLeft: "0",
      //   marginTop: "20px",
      //   width: "100%",
      // },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "10px",
      },
      "& .MuiButton-root": {
        marginRight: "15px",
        transition: "none",
        [theme.breakpoints.down("xs")]: {
          marginRight: "10px",
        },
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    width: "auto",
    minWidth: "156px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 10px",
      minHeight: "46px",
      minWidth: "unset",
    },
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    width: "auto",
    minWidth: "94px",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  dropMenu: {
    "& .dropmenu-btn": {
      letterSpacing: "0",
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      filter: "none",
      boxShadow: "none",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 6px",
        minHeight: "46px",
        minWidth: "unset",
      },
    },
  },
  dropDropdown: {
    "& .MuiMenu-paper": {
      minWidth: "156px",
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      backgroundColor: "#fafafa",
      borderRadius: "8px",
      marginTop: "7px",
      boxShadow: "none",
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
      },
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
  notificationBox: {
    "&.display-center": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    "& .MuiButton-label": {
      textTransform: "initial",
      color: "#4BA3B7",
      fontFamily: "Expletus Sans",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "&.btn-mark-as-read": {
      textTransform: "initial",
      color: "#4BA3B7",
      fontSize: "14px",
    },
    "&.title": {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "400",
      fontFamily: "Expletus Sans",
    },
    "&.sub-title": {
      fontSize: "12px",
      marginTop: "6px",
      color: "#2B2B2B",
      fontFamily: "Roboto",
    },
    "&.list": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "&.list-item": {
      paddingLeft: "0px",
      paddingRight: "24px",
      height: "80px",
    },
    "&. MuiTypography-displayBlock": {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "26px",
      letterSpacing: "0.0075em",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "0px",
    },
    "&.notify-item-title": {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "26px",
      letterSpacing: "0.0075em",
      marginRight: "24px",
    },
    "&.notify-item-content": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "12px",
      height: "16px",
      lineHeight: "16px",
      letterSpacing: "0.0075em",
      color: "#94A3B8",
      marginTop: "6px",
    },
    "&.image-box": {
      height: "48px",
      width: "48px",
      backgroundColor: "#F2F8FC",
      borderRadius: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "24px",
      marginLeft: "24px",
    },
    "&.image-tasks-list": {
      width: "6px",
      height: "6px",
      marginLeft: "8px",
      marginRight: "8px",
    },
    "&.bg-white": {
      backgroundColor: "#FFFFFF",
    },
    "&.bg-gray": {
      backgroundColor: "#ECF1F4",
    },
    "&.divider": {
      backgroundColor: "#000000",
      borderWidth: "1px",
      with: "100%",
    },
    "&.fiber-icon": {
      fontSize: "10px",
      color: "#94A3B8",
      marginLeft: "8px",
      marginRight: "8px",
      marginTop: "6px",
    },
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "orange",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: 2,
    },
  },
}));
function AppHeader(props: any) {
  const classes = useStyles();
  const {
    openModal,
    buttonTxt,
    isTeamsHeader,
    pageLink,
    history,
    teamIDParam,
    clickHandler,
    match,
    notifications,
    markNotificationRead,
    readAllNotifications,
    fetchMoreData,
    hasNextPage,
    userRole,
    showSkipButton,
    skipButtonClickHandler,
    t
  } = props;

  const pathToMatch = "/view-team-members/:teamID";
  const isOnTeamMembersPage = match.path === pathToMatch;

  const pathtoMatch2 = "/add-members/:teamID";
  const isOnAddMembersPage = match.path === pathtoMatch2;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dropMenuClick = React.useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const showHeaders = () => {
    if (isOnTeamMembersPage) {
      return (
        <>
          <Box className="auth-back" onClick={handleViewTeamBack}>
            <i>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
            <span>Back</span>
          </Box>
          {pageLink !=="/view-team-members/unassigned-members" && <> {userRole !== "employee" && (
            <Box className="auth-right">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Create Team"
                onClick={addMemberBtnHandler}
                //data-testid="create-teams-btn"
              >
                {buttonTxt}
              </Button>
            </Box>
          )}</> }
         
         
        </>
      );
    } else if (isOnAddMembersPage) {
      return (
        <>
          <BackArrowWeb />
          <Box className="auth-right">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title="Add Members"
              onClick={clickHandler}
              data-testid="add-members-btn"
            >
              {buttonTxt}
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <Box className="auth-logo">
          <img src={imgLogo} alt="logo" />
        </Box>
      );
    }
  };

  const dropMenuClose = React.useCallback(() => {
    history.push("/invite-members");
    setAnchorEl(null);
  }, [history]);

  const dropMenuClose2 = React.useCallback(() => {
    history.push("/bulk");
    setAnchorEl(null);
  }, [history]);

  // const handleSkipClick = React.useCallback(() => {
  //   history.push("/dashboard");
  //   setAnchorEl(null);
  // }, [history]);

  const addMemberBtnHandler = () => {
    history.push(`/add-members/${teamIDParam}`);
  };

  const handleViewTeamBack = () => {
    history.push(`/create-team`);
  };
  const handleBackToProfile = () => {
    history.push(`/profile`);
  }

  return (
      <Box className={`header-wrapper ${classes.HeaderWrapper}`}>
        {pageLink == "/create-team" ? (
          <>
          <Box className="auth-back" >
            {userRole == "employee" ?  <img src={imgLogo} className="auth-logo" /> :
              <>
                 <Box className="auth-back" onClick={handleBackToProfile}>
            <i>
          <svg
            className="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
          </svg>
        </i>
            {t(`${configJSON.backTxt}`)}
            </Box>
              </>  
            }
                     
          </Box>
           
            <Box className="auth-right">
              {isTeamsHeader ? (
                <>
                {userRole === "owner" && (
                    <>
                    {showSkipButton &&
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                        title="Skip"
                        onClick={skipButtonClickHandler}
                        data-test-id="skip-btn"
                      >                        
                        {t(`${configJSON.skipTxt}`)}
                      </Button>
                       }
                      </>
                  )}
                  {userRole !== "employee" && (
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Create Team"
                      onClick={openModal}
                      data-testid="create-teams-btn"
                    >
                      {buttonTxt}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {userRole === "owner" && (
                    <>
                    {showSkipButton &&
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                        title="Skip"
                        onClick={skipButtonClickHandler}
                        data-test-id="skip-btn"
                      >                        
                        {t(`${configJSON.skipTxt}`)}
                      </Button>
                       }
                      <Box className={classes.dropMenu}>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          variant="contained"
                          onClick={dropMenuClick}
                          className="dropmenu-btn"
                        >
                          + {t(`${configJSON.inviteMemberTxt}`)}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => {setAnchorEl(null)}}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          className={classes.dropDropdown}
                        >
                          <MenuItem onClick={dropMenuClose}>                            
                            {t(`${configJSON.addManuallyTxt}`)}
                          </MenuItem>
                          <MenuItem onClick={dropMenuClose2}>
                            {t(`${configJSON.bulkUploadTxt}`)}
                          </MenuItem>
                        </Menu>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </>
        ) : (
          showHeaders()
        )}
      </Box>
    
  );
}

export default withRouter(AppHeader);
// Customizable Area End
