import React from "react";

import // Customizable Area Start
{ Drawer,
Box,
Typography,
Grid,
InputAdornment,
FormControl,
MenuItem,
Select,
Button,
Dialog,
Modal,
Switch,
} from
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import LiveChat2Controller, {
  Props,
  configJSON,
} from "./LiveChat2Controller.web";
import { withStyles } from "@material-ui/core/styles";
import { liveChatCustomStyles } from "../../../components/src/LiveChatStyles.web";
import CustomProfileSidebar from "../../../components/src/CustomProfileSidebar";
import { backArrowIcon, menu } from "../../customisableuserprofiles/src/assets";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import CustomInputContent from "../../../components/src/CustomInput.web";
import {
  DoubleTick,
  PlusImage,
  defaultView,
  doubleTickGray,
  information,
  sortby,
  
  close,
  menuIcon,
  deleteIcon,
} from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chat, GroupedMessages, ChatMessagesResponsess } from "../../../components/src/ReusableEnums";
import NewChatModal from "../../../components/src/NewChatModal.web";
import ChatImagePreview from "../../../components/src/ChatImagePreview.web";
import WebLoader from "../../../components/src/WebLoader.web";
import {
  renderMedia,
  renderContent,
  formatChatDate,
  formatLiveChatTime
} from "../../../components/src/ReusableFunctions";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import { CustomSearchDropdown } from "../../../components/src/CustomSearchDropdown.web";
import { ResolveLastMessage } from "../../utilities/src/Resolvecondition";
import NewChatModalWeb from "./NewChatModal.web";
// Customizable Area End

export class LiveChat2 extends LiveChat2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      open: false,
      selectedImage: "",
      selectedDocument:''
    };
    // Customizable Area End
  }

  // Customizable Area Start

  handleClickOpen = (image: any) => {
    if (image[0].filename.includes('Image')){
      this.setState({ open: true, selectedImage: image[0].url, selectedDocument: '' });
    }  else{
      window.open(image[0].url, '_blank'); 
    }
  };
  
  handleClose = () => {
    this.setState({ open: false, selectedImage: '' ,selectedDocument:''});
  };
  renderNoChatsView() {
    const { classes } = this.props;
    return (
      <Box className={classes.drawerContent}>
        <Box className={`${classes.profileBlockOuter} mvc-profile-outer`}>
          <Box className="profile-block-wrapper">
            <Box>
              <img
                className="default-image"
                src={defaultView}
                alt="defaultView"
              ></img>
            </Box>
            <Box className="container-2">
              <Typography className="heading">
                {this.translateChat(`${configJSON.defaultHeading}`)}
              </Typography>
              <Typography className="sub-heading">
                {this.translateChat(`${configJSON.subTxtDefault}`)}
              </Typography>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={this.openNewChat}
                data-test-id="openNewChat"
              >
                + {this.translateChat(`${configJSON.newChatBtn}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  renderChatsList() {
    const { classes } = this.props;
    return (
      <Box className={classes.drawerContent}>
        <Box className={`${classes.profileBlockOuter}`}>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12} md={6} className="chat-block-wrapper">
              {this.renderChatSearch()}
              {this.renderChatItems()}
              <Box className="new-chat-wrapper">
                <Button
                  className="btn-new-chat"
                  data-test-id="openNewChat"
                  onClick={this.openNewChat}
                >
                  <img src={PlusImage} alt="PlusImage" />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={6} className="chat-container">
              {this.renderChatMessages()}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  renderChatSearch() {
    const { classes } = this.props;
    return (
      <>
        <Typography className="conversations">
          {this.translateChat(`${configJSON.chatConversationsTxt}`)}
        </Typography>
        <Typography className="conversations-sub">
          {this.translateChat(`${configJSON.chatSubtext}`)}
        </Typography>
        <Box className="search-wrapper">
          <Box className="search-box">
            <CustomInputContent
              type="text"
              placeholder={this.translateChat(
                `${configJSON.searchPlaceholder}`
              )}
              value={this.state.chatRoomSearch}
              name="chatRoomSearch"
              onChange={this.handleAllInputChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#225980" }} />
                </InputAdornment>
              }
              data-test-id="chatRoomSearch"
            />
            <Box onClick={this.openSortModal} className="sort-by" data-test-id="handleFiltersOPen">
              <img src={sortby} alt="bg-image" className="filter-image" />
            </Box>
            <Modal
              open={this.state.isSortModalOpen}
              onClose={this.closeSortModal}
              aria-labelledby="sort-modal-title"
              aria-describedby="sort-modal-description"
            >
              <Box
                className={`view-modal-filter ${classes.modalFilter}`}>
                <Box className="filter-content">
                  <Box className="filter-wrapper">
                    <Box className="filter-heading" sx={{display:'flex',justifyContent:'space-between',}}>
                    <Typography className="filter-reset" style={{color:'black'}}>
                          {
                            this.translateChat(
                              `${configJSON.chatConversationsTxt}`
                            )
                          }            
                            </Typography>
                      <Box className="heading-center">
                      
                        <Typography className="filter-reset">
                          {
                            this.translateChat(
                              `${configJSON.reset}`
                            )
                          }            
                            </Typography>
                        <Box onClick={this.closeSortModal} data-test-id="closeSortModal" >
                          <img src={close} alt="close" />
                        </Box>
                      </Box>
                    </Box>
                    <Typography className="filters">
                                              {
                            this.translateChat(
                              `${configJSON.filters}`
                            )
                            }  
                    </Typography>
                    <Typography className="filters-activity">
                      {
                        this.translateChat(
                          `${configJSON.adjust_activity_log_filters}`
                        )
                      }  
                       </Typography>
                    <Typography className="filters-assigned" style={{marginTop:'70px'}}>
                    {
                        this.translateChat(
                          `${configJSON.assignedtothisproject}`
                        )
                      } 
                    </Typography>
                    <Box className="search-wrapper">
                      <Box className="search-box">
                        <CustomSearchDropdown
                          classes={classes}
                          label={this.translateChat(`${configJSON.search_project_name}`)}
                          value={this.state.projectDetailName}
                          changeHandler={this.projectHandler}
                          searchChangeHAndler={this.projectmultiSelectSearchChange}
                          searchValue={this.state.projectDetailSearchVal}
                          dropdownListing={this.displayproject}
                          selectName="projectname"
                          searchValueName="projectnameSearchVal"
                          itemValueKey="label"
                          itemLabelKey="label"
                          data-test-id="project-name-dropdown"
                        />
                      </Box>
                    </Box>
                    <Typography className="filters-team">
                      
                      {this.translateChat(
                            `${configJSON.Team}`
                          )} 
                    </Typography>
                    <Box className="search-wrapper">
                      <Box className="search-box">
                        <CustomSearchDropdown
                          classes={classes}
                          label={this.translateChat(`${configJSON.search_project_name}`)}
                          value={this.state.teamDetailName}
                          changeHandler={this.teamHandler}
                          searchChangeHAndler={this.teammultiSelectSearchChange}
                          searchValue={this.state.teamDetailSearchVal}
                          dropdownListing={this.displayteam}
                          selectName="projectname"
                          searchValueName="projectnameSearchVal"
                          itemValueKey="label"
                          itemLabelKey="label"
                          data-test-id="project-name-dropdown"
                        />
                      </Box>
                    </Box>
                    <Box className="atatchments-container">
                      <Typography className="atatchments">
                      {this.translateChat(
                            `${configJSON.message_contains_attachments}`
                          )}                         
                      </Typography>
                      <Switch  color="default" checked={this.state.attachMedia} onChange={this.handleAttachMedia} />
                    </Box>
                    <Button className="button-filter" onClick={this.handleChatFilterData} data-test-id="apply-filter-chat">
                    {this.translateChat(
                            `${configJSON.ApplyFilters}`
                          )}</Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        </Box>
        <Box className="sort-container">
          <Box className="conversation-container">
          <Typography className="conversion-count-text">
            {this.state.chatRoomList?.length}           
          </Typography>
          <Typography className="conversion-count-text1">
          {this.state.chatRoomList?.length>1 ? this.translateChat(`${configJSON.chatConversationsTxt}`) : this.translateChat(`${configJSON.chatConversationTxt}`)}
          </Typography>
          </Box>
          <FormControl className="select-control">
            <Select
              displayEmpty
              inputProps={{ IconComponent: () => null }}
              MenuProps={{
                anchorOrigin: { horizontal: "left", vertical: "bottom" },
                transformOrigin: { horizontal: "left", vertical: "top" },
                classes: { paper: classes.dropdownStyle },
                getContentAnchorEl: null,
              }}
              value={this.state.chatRoomSortBy}
              name="chatRoomSortBy"
              onChange={this.handleAllInputChange}
              defaultValue={this.translateChat(`${configJSON.sortByTxt}`)}
              renderValue={(value) => (
                <Box>
                  {value}
                  <ExpandMoreIcon />
                </Box>
              )}
              data-test-id="change-sort-chat"
            >
              <MenuItem value={this.translateChat(`${configJSON.recentText}`)}>
                {this.translateChat(`${configJSON.recentText}`)}
              </MenuItem>
              <MenuItem value={this.translateChat(`${configJSON.unreadText}`)}>
                {this.translateChat(`${configJSON.unreadText}`)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    );
  }
      renderMessages = (item: any) => (
        <Box className="time-content">
          <Typography className="chat-time chatTimeContainer">
            {item.attributes.last_message.data &&
              formatChatDate(item.attributes.last_message.data.attributes.created_at)}
          </Typography>
          <Box className="chat-count">
            <Typography className="chat-count-position">
              {item.attributes.unread_count}
            </Typography>
          </Box>
    
        </Box>
      )
  renderChatItems() {   
    const sortedChatList = this.state.chatRoomList.sort((a: Chat, b: Chat) => {
      const lastMessageA = a.attributes.last_message?.data;
      const lastMessageB = b.attributes.last_message?.data;
      if (!lastMessageA && !lastMessageB) {
        return new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime();
      }
      if (!lastMessageA) return -1;
      if (!lastMessageB) return 1;
      const unreadCountDiff = b.attributes.unread_count - a.attributes.unread_count;
      if (unreadCountDiff) return unreadCountDiff;
      const lastMessageTimeA = new Date(lastMessageA.attributes.created_at).getTime();
      const lastMessageTimeB = new Date(lastMessageB.attributes.created_at).getTime();
      return lastMessageTimeB - lastMessageTimeA;
    });
    return (
      <>
      <Box style={{height: "570px", overflowY: "auto", overscrollBehaviorY:"contain"}}>
        {this.state.chatRoomList.length===0 ? (
        <Typography className="support-sub-txt">                  
          {this.translateChat(`${configJSON.noChatsFoundMsg}`)}
         </Typography>
           ) : (
         sortedChatList.map((item: Chat) => {
          const nameParts = item.attributes.name.split(" ");
          const initials = nameParts
            .map((part: string) => part.charAt(0).toUpperCase())
            .join("");
            let displayName = item.attributes.name;
            if (displayName.startsWith("Etoh")) {
              displayName = displayName.replace("Etoh", "EtOH");
            }
          return (
            <Box
              className={
                this.state.messageId === item.id
                  ? "etoh-header-chat-active"
                  : "etoh-header-chat"
              }
              key={item.id}
              data-test-id={`getAllMessage-${item.id}`}
              onClick={() => this.getAllMessage(item, this.state.page)}
            >
              {renderContent(item.attributes.chat_type, initials)}
              <Box className="etoh-content">
                <Typography className="support-txt">
                {displayName}
                </Typography>
                <Typography className="support-sub-txt textTestforchatmessage sent number_Of_attachment">
                {ResolveLastMessage(item)}

                </Typography>
              </Box>
              {this.renderMessages(item)}
            </Box>
             );
          })
        )}
        </Box>
      </>
    );
  }

  renderMedia2( item : any) {
    if (Array.isArray(item.attributes.media_file) &&
    item.attributes.media_file.length > 0 &&
    item.attributes.media_type.startsWith('image') ) {
      return (
        <Typography className="help-message"     
        data-test-id="image-button"
        onClick={() => this.handleClickOpen(item.attributes.media_file)}> 
        <img
          src={item.attributes.media_file[item.attributes.media_file.length - 1]?.url}
          alt="Thumbnail"
          style={{
            width: '218px',
            height: '212px',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
        />
        </Typography>
      ) 
    }else{
    return  renderMedia(item.attributes.media_file, item.attributes.media_type, this.fullScreenImgModelOpen)
    }
  }

  renderChatMessage = (item: ChatMessagesResponsess, isOutgoingMessage: boolean, isDeleted: boolean, isLastMessage: boolean) => {
    const { open, selectedImage } = this.state;
  
    return (
      <>
        {isDeleted ? (
          <Box
            className={
              isOutgoingMessage ? "chat-text-content-sender" : "chat-text-content-reciever"
            }
          >
            <Box className="right-content">
              <Typography className="delete-message">Message deleted.</Typography>
            </Box>
          </Box>
        ) : (
          <Box
            className={
              isOutgoingMessage
                ? "chat-text-content-sender"
                : "chat-text-content-reciever"
            }
          >
            <Box className="right-content">
            <Box className="right-inner-content right-inner-content-modify" style={{ marginRight:!item.attributes.media_file? "25px":"" }}>
               
               {item.attributes.media_file ? (
                   <div>
                     {this.renderMedia2(item)}
                   </div>
                 ):  <Typography className="help-message">          

                 {item.attributes.message}
               </Typography>}
              </Box>
             
                  
                <div className="option-wrapper option-wrapper-modify">
                  <div className="select-menu">
                    <button
                      className="menuItem-delete"
                      aria-describedby="delete-message-popper"
                      type="button"
                      onClick={() => this.openDeleteMessage(item.id)}
                      data-test-id="open-delete-message">
                      <img src={menuIcon} alt="Full view" />
                    </button>
                    {this.state.deleteMessage === item.id && (
                      <div className="option-menu">
                        <button
                          className="delete-btn-popover-text"
                          onClick={() => this.opendeleteMessageModal(item.id)}
                          data-test-id="open-delete-confirm-message"
                        >
                          <img src={deleteIcon} alt="Delete-Message-Icon" />
                          Delete message
                        </button>
                      </div>
                    )}
                  </div>
                </div>
             
              <Dialog open={open} onClose={this.handleClose}>
                <div onClick={this.handleClose}></div>
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Full view"
                    style={{ width: '100%', height: 'auto', display: selectedImage ? 'block' : 'none' }}
                  />
                )}
              </Dialog>
              <Typography className="tick-time tick-time-modify">
                {isLastMessage && (
                  <img
                    className="img"
                    src={doubleTickGray}
                    alt="doubleTickGray"
                  />
                )}
                {formatLiveChatTime(item.attributes.created_at)}
              </Typography>
            </Box>
          </Box>
        )}
      </>
    )
  }


  
  renderChatMessages() {
    const { allSinglePersonChat, groupedMessages, itemData, open, selectedImage } = this.state;
    let displayName = itemData?.attributes?.name || '';
    if (displayName.startsWith("Etoh")) {
       displayName = displayName.replace("Etoh", "EtOH");
     }
    return (
      <>
        {allSinglePersonChat?.length > 0 ? (
          <Box className="etoh-header">
            {renderContent(
              itemData.attributes.chat_type,
              itemData.attributes.name.split(" ").map((part: string) => part.charAt(0).toUpperCase())
            )}
            <Box className="etoh-content">
              <Typography className="support-txt">
               {displayName} 
              </Typography>
              <Typography className="support-sub-txt">
                {itemData.attributes?.receiver?.email}
                {itemData.attributes.chat_type === "etoh_shop_support" && this.translateChat(`${configJSON.ethoChatSubMsg}`)}
              </Typography>
            </Box>
            <Box className="info-content">
              <Typography className="info-content-img">
                <img src={information} alt="double-tick"></img>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className="default-chat-content">
            <Typography className="support-txt-default">
              {this.translateChat(`${configJSON.welcomeTxt}`)}
            </Typography>
          </Box>
        )}
        <Box
          id="parentChatID"
          style={{ maxHeight: 590, width: "95%", overflow: "auto", position: "absolute", bottom: 110, right: 0 }}
       >
          {allSinglePersonChat && allSinglePersonChat.length > 0 && (
            <InfiniteScroll
              data-test-id="InfiniteScroll"
              dataLength={allSinglePersonChat.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasNextPage ? this.state.hasNextPage : false}
              loader={
                <Box className="loader" key="loader">
                  <Typography>
                    {this.translateChat(`${configJSON.loadingTxt}`)}
                  </Typography>
                </Box>
              }
              scrollableTarget="parentChatID"
            >
              {groupedMessages.map((group: GroupedMessages) => {        
                        
                return (
                  <Box key={group.date} className="scroll-content">
                    <Typography className="date-message">
                      {group.date}
                    </Typography>
                    {group.messages.map((item,index) => {
                       const senderData = item.attributes.sender?.data;
                       const currentUserId = this.state.currentUserId;   
                       const isOutgoingMessage = senderData?.id === currentUserId;
                       const isDeleted = item.attributes.deleted_at !== null;
                       
                       const isLastMessage = groupedMessages[groupedMessages.length-1]
                       const last_messages=isLastMessage.messages;
                       let lm=index==last_messages.length-1
                       
                      return (
                        <div key={item.id}>
{this.renderChatMessage(item,isOutgoingMessage,isDeleted,lm)}
                         
                        </div>
                      );
                    })}
                    <div ref={this.LiveChatEndRef} />
                  </Box>
                );
              })}
            </InfiniteScroll>
          )}
        </Box>
        <Box className="send-messge-area">
          <ChatImagePreview
            handleCloseAttachment={this.handleCloseAttachment}
            previews={this.state.previews}
            filesUploaded={this.state.filesUploaded}
            translateChat={this.translateChat}
            chatTxtMessage={this.state.chatTxtMessage}
            handleAllInputChange={this.handleAllInputChange}
            onBrowse={this.onBrowse}
            handleKeyDown={this.handleKeyDown}
            formRef={this.formRef}
            fileRef={this.fileRef}
            handleSubmitMessage={this.handleSubmitMessage}
            createExternalAndInternalChatMessgaeEvent={
              this.createExternalAndInternalChatMessgaeEvent
            }
            chatType={itemData.attributes.chat_type}
            handleOnDrop={this.handleOnDrop}
            messageId={this.state.messageId}
            data-test-id="ChatImagePreview"
          />
        </Box>
      </>
    );
  }
  renderHeader() {
    const { classes } = this.props;
    return (
      <Box className={classes.secondaryHeader}>
        <Box className="container">
          <Box
            onClick={this.handleBackBtn}
            data-test-id="handleBackBtn"
            className="image-wrapper"
          >
            <Box title="Back">
              <img
                className="back-img"
                alt="Arrow Back Icon"
                src={backArrowIcon}
              />
              {this.translateChat("Back")}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;
    const { subscribedUserData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <Box className={`wrapper ${classes.wrapper} ${classes.profileWrapper}`}>
          {this.renderHeader()}
          <Box className={`inner-wrapper ${classes.innerWrapper}`}>
            <Box className={classes.profileInnerWrapper}>
              <Box className="main-heading">
                <Box className="back-btn-wrap" onClick={this.handleBackBtn}>
                  <img alt="Arrow Back Icon" src={backArrowIcon} />
                </Box>
                <Typography className="heading" variant="h1">
                  {this.translateChat("Live Chat")}
                </Typography>
                <Box
                  className="profile-menu"
                  onClick={this.toggleProfileDrawer}
                  data-test-id="toggleProfileDrawer"
                >
                  <img src={menu} alt="menu" />
                </Box>
              </Box>
              <Box className={classes.drawerWrapper}>
                <Box className={classes.drawer}>
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                      root: classes.drawerRootWrapper,
                    }}
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? this.state.isDrawerOpened : true}
                  >
                    <CustomProfileSidebar
                      t={this.translateChat}
                      toggleMenu={this.closeDrawer}
                      title="Live Chat"
                      data-test-id="toggle-close"
                    />
                  </Drawer>
                </Box>
                {this.state.chatRoomAllList?.length === 0 
                  ? this.renderNoChatsView()
                  : this.renderChatsList()}
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.openNewChat && (         
          <NewChatModalWeb openModal={this.state.openNewChat} closeModal={this.closeNewChat} navigation={""} classes={classes}/>
        )}
        {this.state.isdeleteModalopen && (
          <ConfirmActionModal
            isOpen={this.state.isdeleteModalopen}
            handleClose={this.CloseDeleteModal}
            modalConfirmAction={this.deleteMessage}
            modalMessage={`Are you sure you want to delete this message?`}
            confirmBtnTxt={"Delete"}
            modalHeading={"Delete Message"}
            cancelBtnTxt={"Cancel"}
            data-testid="confirmOption"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(liveChatCustomStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(LiveChat2)
);
// Customizable Area End
