// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Input,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Switch,
  Popover,
  TextField,
  Checkbox,
  Drawer,
  Modal,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  CardMedia,
  FormHelperText,
  TablePagination,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FileDrop } from "react-file-drop";
import {
  attachment,
  calendarRange,
  sortVariant,
  viewColumn,
  minimizeGray,
  closeGray,
  uploadImg,
  checkBoxFilled,
  checkBoxEmpty,
  menu,
  checkGreen,
  minimizeDirectEmail,
  noEmailCampaigns,
  fileIcon,
  checked,
} from "./assets";
import { Link } from "react-router-dom";
import { withStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess'; // Import an "expanded" icon, if available
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import DirectEmailsHomePageController, {
  Props,
  configJSON,
} from "./DirectEmailsHomePageController.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import CustomMarketingSidebar from "../../../components/src/CustomMarketingSidebar.web";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { customStyles } from "../../../components/src/DirectEmailsHelper.web";
import { AssociatedProductsWeb } from "../../LeadManagement/src/AssociatedProducts.web";
import WebLoader from "../../../components/src/WebLoader.web";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ProductViewModal from "../../../components/src/ProductViewModal.web";
import ConnectBrevoAPI from "../../../components/src/ConnectBrevoKey.web";
import moment from "moment";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import ClearIcon from "@material-ui/icons/Clear";
import { showLettersFromName, extractItemDetails } from "../../../components/src/ReusableFunctions";
import ProductCardsSummary from "../../../components/src/ProductCardsSummary.web";
import ImageModal from "../../../components/src/DirectEmailsImageView.web";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SyncBrevoScreen from "../../../components/src/SyncBrevoScreen.web";

export class DirectEmailsHomePage extends DirectEmailsHomePageController {
  constructor(props: Props) {
    super(props);
  }

 htmlToText = (html:any) => {
    html = html.replace(/<br\s*\/?>/gi, '\n');
    html = html.replace(/<\/p>/gi, '\n').replace(/<p[^>]*>/gi, '');
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  

  renderEmailList = () => {
   if(this.state.isDraft){
   return  this.renderDraftsList()
   }else{
    return this.renderSentEmailList()
   }
  };
  showNameCell = (mail:any) => {
    const contact_ids =  mail.attributes.to_contact_ids;
    const groups = mail.attributes.to_group_ids;
    return (
      <TableCell className="name-cell" scope="row">
                        <Box className="name-col">
                        {contact_ids.length > 0 ? (
                          <>
                            <Box className="profile-name">
                              {showLettersFromName(contact_ids[0].full_name)}
                            </Box>
                            {contact_ids[0].full_name}
                          </>
                        ) : groups.length > 0 ? (
                          <>
                            <Box className="profile-name">
                              {showLettersFromName(groups[0].name)}
                            </Box>
                            {groups[0].name}
                          </>
                        ) : (
                          <>
                          <Box className="profile-name">
                            {showLettersFromName(mail.attributes.to[0].name)}
                          </Box>
                          {mail.attributes.to[0].name}
                          </>
                        )}
                      {(contact_ids.length > 1 || groups.length > 1) && <>
                        <Box className="assignee-bubble" data-test-id="assignee-bubble-id">
                          +
                          {contact_ids.length +
                            groups.length -
                            1}
                          <Box className="text-detail">
                            <List>
                              {contact_ids.slice(1).map((recipient:any) => (
                                <ListItem key={recipient.email}>
                                  <Typography className="title">{recipient.full_name}</Typography>
                                  <Typography className="desc">{recipient.email}</Typography>
                                </ListItem>
                              ))}
                             
                              {(contact_ids.length === 0
                                ? groups.slice(1)
                                : groups
                              ).map((group:any) => (
                                <ListItem key={group.id}>
                                  <Typography className="title">{group.name}</Typography>
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </Box>
                        </>
                       }
                        </Box>
                      </TableCell>
    )
  }
  showRecipientsDetails = (viewEmailDetails: any) => {
    const { to_contact_ids: toContactIds, to_group_ids: groups, sender, created_at, to } = viewEmailDetails;
  
    // Helper function to render the expandable list
    const renderExpandableList = (contacts: any[], groups: any[]) => (
      <Box className="expand-more">
        <Box className="icon-wrapper">
          <ExpandMoreIcon className="expand-more-icon" />
          <ExpandLessIcon className="expand-less-icon" />
        </Box>
        <Box className="text-sub-detail">
          <List>
            {contacts.map((contact: any) => (
              <ListItem key={contact.email}>
                <Typography className="title">{contact.full_name}</Typography>
                <Typography className="desc">{contact.email}</Typography>
              </ListItem>
            ))}
            {groups.map((group: any) => (
              <ListItem key={group.id}>
                <Typography className="title">{group.name}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    );

    const renderDefaultLists = (to:any) => (
      <Box className="expand-more">
        <Box className="icon-wrapper">
          <ExpandMoreIcon className="expand-more-icon" />
          <ExpandLessIcon className="expand-less-icon" />
        </Box>
        <Box className="text-sub-detail">
          <List>
            {to.map((contact: any) => (
              <ListItem key={contact.email}>
                <Typography className="title">{contact.name}</Typography>
                <Typography className="desc">{contact.email}</Typography>
              </ListItem>
            ))}
          
          </List>
        </Box>
      </Box>
    )

    const mainRecipient =
      toContactIds.length > 0
        ? { name: toContactIds[0].full_name, email: toContactIds[0].email }
        : groups.length > 0
        ? { name: groups[0].name }
        : null;
    return (
      <>
        {mainRecipient ? (
          <Typography className="info-text">
            to {mainRecipient.name}
            <Box className="text-detail">
              <List>
                <ListItem>
                  <Typography className="title">From</Typography>
                  <Typography className="desc">
                    {sender.name}
                    <span>{sender.email}</span>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="title">To</Typography>
                  <Typography className="desc">
                    {mainRecipient.name}
                    {mainRecipient.email && (
                      <span>{mainRecipient.email}</span>
                    )}
                    {(toContactIds.length > 1 || groups.length > 1) && (
                      <Box className="assignee-bubble" data-test-id="assignee-bubble-new">
                        +{toContactIds.length + groups.length -1}
                      </Box>
                    )}
                    {(toContactIds.length > 1 || groups.length > 1) &&
                      renderExpandableList(toContactIds.slice(1), toContactIds.length > 0 ? groups : groups.slice(1))}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="title">Date</Typography>
                  <Typography className="desc">
                    {moment(created_at).format("MMM DD, YYYY hh:mm a")}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Typography>
        ) : (
          <Typography className="info-text">
          to {to[0].name}
          <Box className="text-detail">
            <List>
              <ListItem>
                <Typography className="title">From</Typography>
                <Typography className="desc">
                  {sender.name}
                  <span>{sender.email}</span>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className="title">To</Typography>
                <Typography className="desc">
                {to[0].name}
                    <span>{to[0].email}</span>
                  {to.length > 1  && (
                    <Box className="assignee-bubble">
                      +{to.length - 1}
                    </Box>
                  )}
                </Typography>
                {(to.length > 1) &&
                  renderDefaultLists(to.slice(1))}
              </ListItem>
              <ListItem>
                <Typography className="title">Date</Typography>
                <Typography className="desc">
                  {moment(created_at).format("MMM DD, YYYY hh:mm a")}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Typography>
        )}
      </>
    );
  };
  
  renderTableHeader = (isDraft:boolean) => {
    const {checkedColumns} = this.state;
    return (
      <TableHead>
        <TableRow>
          {isDraft && <TableCell></TableCell>}
          {!checkedColumns.recipients &&   <TableCell className="name-cell">{this.t(`${configJSON.recipientTxt}`)}</TableCell>}
          {!checkedColumns.subjects &&   <TableCell className="title-cell">{this.t(`${configJSON.subjectTxt}`)}</TableCell> }
          {!checkedColumns.messages &&  <TableCell className="desc-cell">{this.t(`${configJSON.messageTxt}`)}</TableCell> }
          {!checkedColumns.date && <TableCell className="date-cell">{this.t(`${configJSON.dateTxt}`)}</TableCell> }
            {!checkedColumns.attachments && <TableCell className="attachment-cell">{this.t(`${configJSON.attachmentTxt}`)}</TableCell> }
          <TableCell className="action-cell">{this.t(`${configJSON.actionsTxt}`)}</TableCell>
        </TableRow>
      </TableHead>
    );
  };
  renderSentEmailList = () => {
    const { classes } = this.props;
    const { allDirectEmails, currentPage, emailsPerPage , checkedColumns } = this.state;
    return (
      <Box className="marketing-inner">
        {this.state.isSearching && 
        <TableContainer>
        <Table
          className={`direct-emails-sent ${classes.table}`}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
         {this.renderTableHeader(false)}
         </Table>
          </TableContainer>
        }
        {allDirectEmails.length > 0 ? <TableContainer>
            <Table
              className={`direct-emails-sent ${classes.table}`}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
             {this.renderTableHeader(false)}
              <TableBody>
              {allDirectEmails.slice(currentPage * emailsPerPage, currentPage * emailsPerPage + emailsPerPage).map((mail: any) => {
                const allrecipents = mail.attributes.to;
                const plainTextContent = this.htmlToText(mail.attributes.html_content);
                  return (
                    <TableRow key={mail.id}>
                      {!checkedColumns.recipients && 
                      <> {this.showNameCell(mail)}</>
                      
                      }
                       {!checkedColumns.subjects &&
                      <TableCell><p className="Subject-cell">{mail.attributes.subject}</p></TableCell>}
                       {!checkedColumns.messages &&
                      <TableCell>
                        <p className="mail-cell">{plainTextContent}</p>
                      </TableCell>
                      }
                       {!checkedColumns.date && 
                      <TableCell>
                        {moment(mail.attributes.created_at).format(
                          "MMM DD, YYYY hh:mm a"
                        )}
                      </TableCell>
                      }
                       {!checkedColumns.attachments &&
                      <TableCell>
                        {mail.attributes.attachments.length > 0 ? (
                          <Link to="#" className="attachment-link">
                            <Box component="span" className="attach-icon">
                              <img src={attachment} alt="attachment" />
                            </Box>
                            {mail.attributes.attachments.length}
                          </Link>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      }
                      <TableCell className="action-link">
                        <Link
                          to="#"
                          title="Deactivate"
                          className="table-link"
                          onClick={() => this.viewEmailDetail(mail.id)}
                          data-test-id={`view-email-${mail.id}`}
                        >
                          {this.t(`${configJSON.viewTxt}`)}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer> : this.showEmptyEmailsBox() }
        
      </Box>
    );
  }
  renderDraftsList = () => {
    const { classes } = this.props;
    const { allDraftEmails,currentPage, emailsPerPage, checkedColumns } = this.state;
   
    return (
      <Box className="marketing-inner">
        {allDraftEmails.length > 0 ? <TableContainer>
            <Table
              className={`direct-emails-sent ${classes.table}`}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
             {this.renderTableHeader(true)}
              <TableBody>
              {allDraftEmails.slice(currentPage * emailsPerPage, currentPage * emailsPerPage + emailsPerPage).map((draftMail: any) => {
                const plainTextContent = this.htmlToText(draftMail.attributes.html_content);
                  return (
                    <TableRow key={draftMail.id}>
                      <TableCell className="draft-cell">Draft</TableCell>
                      {!checkedColumns.recipients && 
                      <TableCell className="name-cell" scope="row">
                        <Box className="name-col">
                          <Box className="profile-name">
                          {draftMail.attributes?.sender?.name 
          ? showLettersFromName(draftMail.attributes.sender.name) 
          : ""}
                          </Box>
                          {draftMail.attributes?.sender?.name || ""}
                        </Box>
                      </TableCell>}
                      {!checkedColumns.subjects && 
                      <TableCell>{draftMail.attributes?.subject || ""}</TableCell> }
                       {!checkedColumns.messages && 
                      <TableCell>
                        <Box className="desc">
                        {plainTextContent}
                        </Box>
                      </TableCell>}
                      {!checkedColumns.date && 
                      <TableCell>
                        {moment(draftMail.attributes.created_at).format(
                          "MMM DD, YYYY hh:mm a"
                        )}
                      </TableCell>}
                      {!checkedColumns.attachments && 
                      <TableCell>
                        {draftMail.attributes.attachments.length > 0 ? (
                          <Link to="#" className="attachment-link">
                            <Box component="span" className="attach-icon">
                              <img src={attachment} alt="attachment" />
                            </Box>
                            {draftMail.attributes.attachments.length}
                          </Link>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>}
                      <TableCell className="action-link">
                        <Link
                          to="#"
                          title="Deactivate"
                          className="table-link"
                          data-test-id={`edit-draft-mail-${draftMail.id}`}
                          onClick={(event:any) => this.editDraftEmail(event, draftMail.id)}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer> : this.showEmptyEmailsBox() }
        
      </Box>
    );
  } 
  showEmptyEmailsBox = () => {
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const {isSearching} = this.state;
    if(!isSearching){
      return (
        <Box className="empty-box">
            <CardMedia
              component="img"
              className="img-contact-book"
              image={noEmailCampaigns}
            />
            <Box className="box-empty-right">
              <Typography className="txt-no-contact-title">
                {this.t(`${configJSON.noEmailText}`)}
              </Typography>
              <Typography className="txt-no-contact-sub-title">
                {this.t(`${configJSON.noEmailDescription}`)}
              </Typography>
              <Button
              aria-describedby={popoverId}
                onClick={(event: any) => this.popoverHandleClick(event)}
                data-test-id="empty-btn-no-mails"
              >
                + {this.t(`${configJSON.composeEmailTxt}`)}
              </Button>
            </Box>
          </Box>
        );
    }else{
      return (
        <Box className="no-search-results">
        <SearchIcon />
        <Box className="search-txt">{this.t("No emails found")}</Box>
        </Box>
      )
     
    }
   
  }
  showEmailCountText = () => {
    const { allDirectEmails, allDraftEmails, isDraft } = this.state;
    const emailList = isDraft ? allDraftEmails : allDirectEmails;
    const emailTextKey = emailList.length === 1 ? configJSON.emailsTxtSingular : configJSON.emailsTxt;
    const emailText = this.t(emailTextKey);
  
    return (
      <Box className="email-count">
        {emailList.length} {emailText}
      </Box>
    );
  };
  
  showCreateDirectEmailButon = (classes: any) => {
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const { allDirectEmails } = this.state;
    if (this.state.isBrevoVerified ) {
      return (
        <>
        
        <Box className={classes.createTaskWrapper}>
        {allDirectEmails.length > 0 && 
          <Box className="task-actions">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              aria-describedby={popoverId}
              onClick={(event: any) => this.popoverHandleClick(event)}
              data-test-id="popover-trigger-button"
            >
              +{this.t(`${configJSON.composeTxt}`)}
            </Button>
          </Box>
        }

          <Popover
            id={popoverId}
            open={popOverOpen}
            anchorEl={this.state.popoveAnchorEl}
            onClose={this.popoverHandleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{ paper: `${classes.createTaskBox}` }}
            onKeyDown={(event) => {
              this.handleEmailEscEvent(event);
            }}
            data-test-id="direct-email-popover"
          >
            <Box className="task-heading">
              <Box className="heading-left">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.composeEmailTxt}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.composeEmailSubTxt}`)}
                </Box>
              </Box>
              <Box className="heading-right">
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={this.saveDraftEmail}
                  data-test-id="minimize-email-compose"
                >
                  <img src={minimizeGray} alt="minimizeGray" />
                </Link>
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={this.saveComposeMailasDraft}
                  data-test-id="save-draft-email"
                >
                  <img src={closeGray} alt="closeGray" />
                </Link>
              </Box>
            </Box>
            <Box className="task-description">
              <Box className="task-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      {this.state.getFromEmailList.length > 0 && (
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                          required
                        >
                          <InputLabel id="fromEmail">
                            {this.t(`${configJSON.fromemailAddressTxt}`)}
                          </InputLabel>
                          <Select
                            labelId="fromEmail"
                            id="fromEmail"
                            name="newStatus"
                            label={this.t(`${configJSON.fromemailAddressTxt}`)}
                            onChange={this.handleFromAddress}
                            value={this.state.fromEmailAddress}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyleDeals}`,
                              },
                            }}
                            data-test-id="from-email-address"
                            IconComponent={ExpandMoreIcon}
                          >
                            {this.state.getFromEmailList.map((sender: any) => {
                              return (
                                <MenuItem key={sender.id} value={sender.email}>
                                  {sender.email}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.state.createEmailError.fromEmailError !== "" && (
                          <FormHelperText className="error-select">
                            {this.state.createEmailError.fromEmailError}
                          </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Box>
                  </Box>
                  {this.showDirectEmailFields(this.handleToEmailChange,this.t(`${configJSON.toTxt}`), "autocomplete", true, this.state.selectedValues)}
                  {this.state.showCCFields && 
                    <>
                     {this.showDirectEmailFields(this.handleCCEmailChange,this.t(`${configJSON.CCTxt}`),"cc-autocomplete",false, this.state.ccEmailValues)}
                  {this.showDirectEmailFields(this.handleBCCEmailChange,this.t(`${configJSON.BCCTxt}`),"bcc-autocomplete", false, this.state.bccEmailValues)}
                    </>
                  }
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.subjectTxt}`)}
                        value={this.state.emailSubject}
                        isRequired={true}
                        name="emailSubject"
                        isDealForm={true}
                        onChange={(event: any) =>
                          this.handleComposeEmailInput(
                            "emailSubject",
                            event.target.value
                          )
                        }
                        data-test-id="email-subject"
                        errors={this.state.createEmailError.emailSubjectError}
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        isRequired={true}
                        label={this.t(`${configJSON.composeEmailTxt}`)}
                        isMultiline
                        rows={4}
                        value={this.state.composeEmailContent}
                        name="composeEmailContent"
                        isDealForm={true}
                        onChange={(event: any) =>
                          this.handleComposeEmailInput(
                            "composeEmailContent",
                            event.target.value
                          )
                        }
                        data-test-id="compose-email-content"
                        errors={this.state.createEmailError.composeEmailError}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="form-info-wrapper">
                  <Box className="inventory-wrapper">
                    <Box className="links-wrapper">
                      <Link
                        to="#"
                        className="inventory-link"
                        onClick={this.openChooseProductsModal}
                        data-test-id="openChooseProductsModal"
                      >
                        + {this.t(`${configJSON.addProductTxt}`)}
                      </Link>
                    </Box>
                    {this.state.pdfCatalogueUrl !== "" && 
                    <Box className="items-list-wrapper">
                    <Box className="items-list">
                      <Box className="items-title">
                        <Box className="primary-txt">
                          <a href={this.state.pdfCatalogueUrl} target="_blank" className="primary-txt-link">
                          {this.t(`${configJSON.productCataloguetxt}`)}
                          </a>
                          
                        </Box>
                        <Box
                          className="title-icon"
                          onClick={this.removeProductAttachments}
                          data-test-id="remove-product-summary"
                        >
                          <CloseOutlinedIcon />
                        </Box>
                      </Box>
                      <Box className="sub-txt">
                        {this.t(`${configJSON.totalAmount}`)}
                        <span>
                        : ${this.state.totalproductAmount.toFixed(2)}</span>
                      </Box>
                    </Box>
                  </Box>
                    }
                  </Box>
                </Box>
                <Box className="form-info-wrapper">
                  <FileDrop
                    onDrop={() => this.handleOnDrop}
                    onDragOver={this.dragEnter}
                    onDragLeave={this.dragLeave}
                  >
                    <Box
                      className={`${classes.fileUploadWrapper} ${
                        this.state.fileDrop && classes.fileDropDrag
                      } `}
                      onClick={this.onBrowse}
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          type="file"
                          onChange={this.handleOnDrop}
                          multiple
                          hidden
                          ref={this.fileRef}
                          data-test-id="add-email-attachment"
                        />
                      </form>
                      <Box className="file-upload-block">
                        <Box component="p" className="file-subtxt">
                          {this.t(`${configJSON.clickHereTxt}`)}
                          <Box component="span" className="browse-link">
                            {this.t(`${configJSON.browseTxt}`)}
                          </Box>
                          {this.t(`${configJSON.yourPictureTxt}`)}
                        </Box>
                        <Box className="upload-icon">
                          <img src={uploadImg} alt="uploadImg" />
                        </Box>
                        <Button
                          className={`secondary-btn ${classes.secondaryButton}`}
                        >
                          {this.t(`${configJSON.addAttachmentsTxt}`)}
                        </Button>
                      </Box>
                    </Box>
                  </FileDrop>
                  
                </Box>
                {this.state.filesUploaded.map((name: any, index: number) => (
                  <List className="attachlist-ul" key={index}>
                    <ListItem
                      disableRipple
                      className="attachlist-li"
                      role={undefined}
                      dense
                      button
                    >
                      <ListItemIcon className="attachlist-icon">
                        <img src={checkGreen} alt="checkGreen" />
                      </ListItemIcon>
                      <ListItemText
                        className="attachlist-label"
                        id="checkbox-list-label"
                        primary={name.name}
                      />
                      <ListItemSecondaryAction>
                        <Link
                          to="#"
                          className="tasklist-link link-gray"
                          onClick={() => this.removeFileUPload(index)}
                          data-test-id="remove-file-attachment"
                        >
                          <img src={closeGray} alt="closeGray" />
                        </Link>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ))}
                 {this.state.selectedProductIds && (
                  <>
                   {this.state.selectedProductIds.length > 0 && (
                     <Box className="form-info-wrapper">
                      <Box className="product-summary-wrapper">
                        <Typography>Product Summary</Typography>
                        <Link
                          to="#"
                          className="edit-items-link"
                          onClick={this.openAttachedProducts}
                          data-test-id="open-attached-products"
                        >
                          Edit Items
                        </Link>
                      </Box>
                     <ProductCardsSummary productItems={this.state.selectedProductsData} isSummaryInfo={true}data-test-id="product-cards-summary"/>
                    </Box>
                   )}
                  </>
                
            )}
              </Box>
            </Box>
            <Box className="createtask-bottom">
              <Box className="full-btn">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.createDirectEmails}
                  data-test-id="create-direct-email"
                >
                  {this.t(`${configJSON.sendNowTxt}`)}
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
        </>
      );
    }
  };
  showDirectEmailFields = (handlerFunc: any, textLabel: string, testId: string , isRequired: boolean, selectedVal:any) => {
    const {classes} = this.props;
   
    const {emailGroups, composeEmailList, showCCFields} = this.state;
    const brevoToFields = composeEmailList.concat(
      emailGroups.map((group:any) => ({
        first_name: group.name || "N/A",
        last_name: "",
        email: group.name || "N/A",
        id: group.id,
        type: "group",
      }))
    );

    return (
      <Box className="form-row">
      <Box className="form-col">
        <Autocomplete
          multiple
          className="multiselect-autocomplete"
          data-test-id={testId}
          options={brevoToFields}
          onChange={handlerFunc}
          value={selectedVal}
          disableCloseOnSelect
          ChipProps={{ deleteIcon: <CloseIcon /> }}
          popupIcon={  testId === "autocomplete" ? (
            <Box onClick={(e) => this.showCCBCCFields(e)} data-test-id="show-cc-fields">
              {showCCFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          ) : <ExpandMoreIcon className="hide-arrow" />}
          getOptionLabel={(option: any) => option.email}
          classes={{ popper: classes.autocompleteDropbox }}
          renderOption={(option: any, { selected }) => (
            <Box className="tasklist-li">
              <Box className="tasklist-left">
                <Box className="assignee-profile">
                  {option.first_name.charAt(0).toUpperCase()}
                  {option.last_name.charAt(0).toUpperCase()}
                </Box>
                <Box className="assignee-info">
                  <Box className="assignee-name">
                    {option.first_name + " " + option.last_name}
                  </Box>
                  <Box className="assignee-email">
                    {option.email}
                  </Box>
                </Box>
              </Box>
              <Box className="tasklist-icon">
                <Checkbox
                  className="checklist-check"
                  icon={
                    <img
                      src={checkBoxEmpty}
                      alt="checkBoxEmpty"
                    />
                  }
                  checkedIcon={
                    <img
                      src={checkBoxFilled}
                      alt="checkBoxFilled"
                    />
                  }
                  tabIndex={-1}
                  disableRipple
                  checked={selected}
                />
              </Box>
            </Box>
          )}
          filterOptions={(options, params) => {
            const inputValue = params.inputValue.toLowerCase();
            const filtered = options.filter(
              (option) =>
                option.first_name.toLowerCase().includes(inputValue) ||
                option.last_name.toLowerCase().includes(inputValue)   || 
                option.email.toLowerCase().includes(inputValue) 
            );
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label=""
              placeholder=""
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Box className="start-wrapper">
                    <InputAdornment position="start">
                      {textLabel}
                      {isRequired && <Box component="span" className="label-requuired" color="red">*</Box> }
                      
                    </InputAdornment>
                    <Box className="check">
                      {params.InputProps.startAdornment}
                    </Box>
                  </Box>
                ),
                endAdornment: (
                  <>
                 
                    <InputAdornment
                      className="end-icon"
                      position="end"
                    >
                      {  testId === "autocomplete" && <SearchIcon />}
                    </InputAdornment>
                    <Box>{params.InputProps.endAdornment}</Box>
                  </>
                ),
              }}
            />
          )}
          noOptionsText="No contacts"
        />
      </Box>
    </Box>
    )
  }
  directEmailFilters = (classes: any) => {
    const {isDraft,allDraftEmails, allDirectEmails, emailsPerPage,currentPage } = this.state;
    const {checkedColumns} = this.state;
      return (
        <Box className="marketing-top">
                          <Box className="top-left">
                            {this.showEmailCountText()}
                            <Box className="search-wrapper">
                              <Input
                                placeholder={this.t(`${configJSON.serachTypeTxt}`)}
                                type="search"
                                onChange={(event) =>
                                  this.handleEmailSearch(event.target.value)
                                }
                                data-test-id="search-email-input"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                                value={this.state.searchEmailTxt}
                              />
                            </Box>
                          </Box>
                          <Box className="top-right">
                            <Box className="filter-list">
                              <Box className="filter-control">
                                <CustomDatePicker
                                  placeholder={this.t(`${configJSON.dateRangeTxt}`)}
                                  t={this.t}
                                  range={true}
                                  value={this.state.emailFilterDate}
                                  currentDate=""
                                  onChange={this.handleEmailFilterDates}
                                  onOpenPickNewDate={false}
                                  data-test-id="emailFilterdates"
                                  getFilterdData={this.getDirectEmails}
                                />
                                {this.state.emailFilterDate?.length > 0 && (
                                  <ClearIcon
                                    onClick={() => this.clearAction()}
                                    className="multiple-clear"
                                    data-test-id="clearEmailFilter"
                                  />
                                )}
                              </Box>
                              <FormControl className="select-control">
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="contact-group-filter"
                                  value={this.state.selectedContact}
                                  onChange={(event: any) =>
                                    this.handleFilterContacts(event)
                                  }
                                >
                                  <MenuItem value="">
                                    {this.t(`${configJSON.toContactGroupTxt}`)}
                                  </MenuItem>
                                  {this.state.composeEmailList.length > 0 &&
                                    this.state.composeEmailList.map(
                                      (contact: any) => {
                                        return (
                                          <MenuItem value={contact.email}>
                                            {contact.first_name} {contact.last_name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Select>
                              </FormControl>
                              <Box
                                className={`switch-wrapper ${classes.switchWrapper}`}
                              >
                                <FormControl component="fieldset">
                                  <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                      value="end"
                                      control={
                                        <Switch
                                          checked={this.state.hasAttachments}
                                          onChange={this.toggleHasAttachments}
                                          data-test-id="attachment-switch"
                                        />
                                      }
                                      label={this.t(
                                        `${configJSON.hasAttachmentsTxt}`
                                      )}
                                      labelPlacement="end"
                                      data-test-id="attachment-form-control"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Box>
                              <Box className="filter-control border-hl" onClick={this.handleColumnOpenMenu} data-test-id="show-columns-list">
                                <img
                                  src={viewColumn}
                                  alt="viewColumn"
                                  className="icon"
                                />{" "}
                                {this.t(`${configJSON.columnsTxt}`)}
                              </Box>
                              <Popover
                                open={Boolean(this.state.anchorElColmun)}
                                anchorEl={this.state.anchorElColmun}
                                onClose={this.handleColumnCloseMenu}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              classes={{ paper: `${classes.popoverStyle}` }}
                              data-test-id="columns-popover"
                              >
                              <Box className="view-more-dropdown" >
                                <CustomInputWeb
                                  type="search"
                                  // value={"Search Columns Type"}
                                  label=""
                                  placeholder={this.t("Search Columns Type")}
                                  // onChange={(event: any) => handleSearch(event.target.value)}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  data-test-id="searchfield"
                                />
                                <Box className="dropdown-menu-item-column">
                                  <Typography className="dropdown-menu-item-text">                   
                                  {this.t(`${configJSON.recipientTxt}`)}
                                  </Typography>
                                  <Checkbox 
                                  className="dropdown-menu-item-checked"
                                  checked={checkedColumns.recipients} 
                                  onChange={this.handleCheckedHideColumns} 
                                  data-test-id="recipientTxt" 
                                  name="recipients" 
                                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                                  />
                                </Box>
                                <Box className="dropdown-menu-item-column">
                                  <Typography className="dropdown-menu-item-text">                   
                                  {this.t(`${configJSON.messageTxt}`)}
                                  </Typography>
                                  <Checkbox 
                                  className="dropdown-menu-item-checked"
                                  checked={checkedColumns.messages} 
                                  onChange={this.handleCheckedHideColumns}
                                  data-test-id="messageTxt" 
                                  name="messages" 
                                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                                  />
                                </Box>
                                <Box className="dropdown-menu-item-column">
                                  <Typography className="dropdown-menu-item-text">                   
                                  {this.t(`${configJSON.subjectTxt}`)}
                                  </Typography>
                                  <Checkbox 
                                  className="dropdown-menu-item-checked"
                                  checked={checkedColumns.subjects} 
                                  onChange={this.handleCheckedHideColumns}
                                  data-test-id="subjectTxt" 
                                  name="subjects" 
                                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                                  />
                                </Box>
                                <Box className="dropdown-menu-item-column">
                                  <Typography className="dropdown-menu-item-text">                   
                                  {this.t(`${configJSON.dateTxt}`)}
                                  </Typography>
                                  <Checkbox 
                                  className="dropdown-menu-item-checked"
                                  checked={checkedColumns.date} 
                                  onChange={this.handleCheckedHideColumns}
                                  data-test-id="dateTxt" 
                                  name="date" 
                                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                                  />
                                </Box>
                                <Box className="dropdown-menu-item-column">
                                  <Typography className="dropdown-menu-item-text">                   
                                  {this.t(`${configJSON.attachmentTxt}`)}
                                  </Typography>
                                  <Checkbox 
                                  className="dropdown-menu-item-checked"
                                  checked={checkedColumns.attachments} 
                                  onChange={this.handleCheckedHideColumns}
                                  data-test-id="attachmentTxt" 
                                  name="attachments" 
                                  checkedIcon={<img src={checked} alt="checkbox" />}                   
                                  />
                                </Box>
                                
                              </Box>
                            </Popover>
                              <FormControl className="select-control sortby-control">
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  onChange={(event) => this.sortEmailsType(event)}
                                  displayEmpty
                                  defaultValue={this.t(`${configJSON.sortByTxt}`)}
                                  data-test-id="sortEmails"
                                  //value={this.state.sortByValue}
                                  inputProps={{ IconComponent: () => null }}
                                  renderValue={(value) => {
                                    return (
                                      <Box>
                                        <img
                                          className="select-icon"
                                          src={sortVariant}
                                          alt="sortVariant"
                                        />
                                        {this.t(`${value}`)}
                                      </Box>
                                    );
                                  }}
                                >
                                  <MenuItem value="Sort by">
                                    {this.t(`${configJSON.sortByTxt}`)}
                                  </MenuItem>
                                  <MenuItem value="Newest to oldest">
                                    {this.t(`${configJSON.newestoold}`)}
                                  </MenuItem>
                                  <MenuItem value="Oldest to newest">
                                    {this.t(`${configJSON.oldtonew}`)}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TablePagination
                          rowsPerPageOptions={[]}
                          component="div"
                          count={isDraft ? allDraftEmails.length : allDirectEmails.length}
                          rowsPerPage={emailsPerPage}
                          page={currentPage}
                          onPageChange={this.handleEmailsPerChange}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                          data-test-id="email-pagination"
                          />
                            </Box>
                          </Box>
                        </Box>
        )
    
   
  }
  render() {
    const { classes, isMobile } = this.props;
    const {isImageView, currentIndex ,allDirectEmails, allDraftEmails, isDraft} = this.state;
    const emailList = isDraft ? allDraftEmails : allDirectEmails;
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <div style={{position: "fixed", width: "100%", zIndex: 2}}>
        <GlobalHeaderWeb />
        </div>
        <Box className={classes.marketingRoot}>
          <Box className="mobile-heading">
            <Box className="back-btn-wrap">
              <i>
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
            </Box>
            <Typography className="heading" variant="h1">
              {this.t(`${configJSON.directEmailsTxt}`)}
            </Typography>
            <Box
              className="marketing-menu"
              onClick={this.toggleMenuDrawer}
              data-test-id="marketingmenubar"
            >
              <img src={menu} alt="menu" />
            </Box>
          </Box>
          <Box className={classes.drawerWrapper}
           sx={{
            paddingTop: emailList.length === 0 ? { xs: "10%", sm: "8%", md: "6%" } : "",
           }}>
            <Box className={classes.drawer}>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                  root: classes.drawerRootWrapper,
                }}
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? this.state.isMenuDrawerOpened : true}
              >
                <CustomMarketingSidebar
                  t={this.t}
                  toggleMenu={this.closeMenuDrawer}
                  allTabsCount={this.state.marketingCount}
                />
              </Drawer>
            </Box>
            <Box className={classes.drawerContent}>
              <Box className="marketing-content">
                {this.state.isBrevoVerified ? (
                  <>
                    {this.directEmailFilters(classes)}
                    {this.renderEmailList()}
                  </>
                ) : (
                  <ConnectBrevoAPI
                    classes={classes}
                    t={this.t}
                    connectBtnHandler={this.handleConnectBrevoAccount}
                    isInputModal={this.state.isConnectingBrevoAccount}
                    closeModalHandler={this.closeBrevoInput}
                    brevoKeyVal={this.state.brevoAPIKey}
                    keyInputError={this.state.brevoAPIKeyError}
                    keyInputHandler={this.handleBrevoAPIKeyInput}
                    validateAccount={this.validateBrevoAccount}
                    data-test-id="connect-brevo-direct-emails"
                  />
                )}
                 {this.state.showSyncButton && 
                     <SyncBrevoScreen
                     classes={classes}
                     t={this.t}
                     showSyncModal={this.state.showSyncButton}
                     closeSyncModal={this.closeSyncModal}
                     syncImage={noEmailCampaigns}
                     data-test-id="syncBrevoScreen"
                   />
                  }
              </Box>
            </Box>
          </Box>
        </Box>
        {this.showCreateDirectEmailButon(classes)}
        {this.state.isSaveDraft && (
          <ConfirmActionModal
            isOpen={this.state.isSaveDraft}
            handleClose={this.handleClose}
            modalMessage={this.t(`${configJSON.draftMsgTxt}`)}
            confirmBtnTxt={this.t(`${configJSON.saveAsDraftText}`)}
            modalHeading={this.t(`${configJSON.saveAsDraftText}`)}
          />
        )}
        {this.state.chooseProductsModal && (
          <AssociatedProductsWeb
            openProductsModal={this.state.chooseProductsModal}
            closeProductsModal={this.closeChooseProductsModal}
            chosenDealProductIds={this.state.selectedProductIds}
            classes={classes}
            selectedDealProducts={this.selectedEmailProducts}
            topButtonTxt={this.t(`${configJSON.addToEmail}`)}
            headingTxt={this.t(`${configJSON.productsHeading}`)}
            headingSubTxt={this.t(`${configJSON.productsSubHeading}`)}
            data-test-id="associated-products-web"
          />
        )}
        {this.state.isViewingEmail && (
          <Modal
            open={this.state.isViewingEmail}
            onClose={this.closeViewDetailMail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.viewEmailsWrapper}>
              <Box className="product-top-header" data-test-id="back-arrow" onClick={this.closeViewDetailMail}>
                <ArrowBack/>
                <Box className="back-text">{this.t(`${configJSON.backTxt}`)}</Box>
              </Box>
              {this.state.viewEmailDetail && (
                <Box className="main-wrapper chatto-project-wrapper">
                  <Box className="title-wrapper">
                    <Box className="title-left">
                      <Typography className="title-txt">
                        {this.state.viewEmailDetail.subject}
                      </Typography>
                      <Box className="avatar-wrapper">
                        <Box className="profile-wrapper">
                          {this.state.viewEmailDetail.sender && (
                             <Box className="profile-name">{showLettersFromName(this.state.viewEmailDetail.sender.name)}</Box> 
                          )}
                          
                        </Box>
                        <Box className="profile-info">
                          <Typography>
                            {" "}
                            <span>me</span>{" "}
                            {moment(
                              this.state.viewEmailDetail.created_at
                            ).format("MMMM DD, YYYY")}
                          </Typography>
                          {this.state.viewEmailDetail.to && 
                          <>
                          {this.showRecipientsDetails(this.state.viewEmailDetail)}
                          </>
                        }
                        </Box>
                      </Box>
                    </Box>
                    <Box className="title-right">
                      <Button
                        className={`${classes.secondaryButton} blue-border`}
                        onClick={() => this.deleteDirectEmail(this.state.viewEmailDetail.id)}
                        data-test-id="delete-direct-email"
                      >
                        {this.t("Delete")}
                      </Button>
                    </Box>
                  </Box>
                  <Box className="main-content-wrapper">
                  {this.state.viewEmailDetail.html_content && (
                    <Typography
                       dangerouslySetInnerHTML={{
                          __html:
                            this.state.viewEmailDetail.html_content.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            ),
                        }}
                        />
                  )}
                  {this.state.viewEmailDetail?.attachments?.length > 0 && (
                    <Box className="attachments-grid-wrapper">
                      <Box className="attachments-title-wrapper">
                        <Typography>
                          {this.state.viewEmailDetail.attachments.length} Attachments
                        </Typography>
                      </Box>
                      <List className="attachments-list">
                      {this.state.viewEmailDetail?.attachments.map((attachment:any) => {
                        const isImage = (filename: string) => {
                          const imageExtensions = ['jpg', 'jpeg', 'png', 'svg'];
                          const fileExtension = filename.split('.').pop()?.toLowerCase();
                          return imageExtensions.includes(fileExtension || '');
                        };
                        return (
                          <ListItem className="list-item" key={attachment.id}>
                            <Box className="list-img">
                              <img 
                              src={isImage(attachment.name) ? attachment.url : fileIcon}
                              alt={isImage(attachment.name) ? attachment.name : "download"}
                              onClick={() => this.viewImageModal(attachment.id)}
                              // {...(isImage(attachment.name) && { onClick: () => this.viewImageModal(attachment.id) })}
                              data-test-id={`email-attachment-${attachment.id}`}
                              />
                            </Box>
                            <Box className="list-name">{attachment.name}</Box>
                        </ListItem>
                        )
                        })}
                      
                      </List>
                    </Box>
                  )}

                    <Box className="product-summary-grid-wrapper">
                      <Box className="product-summary-title-wrapper">
                      {this.state.viewEmailDetail?.products?.data && this.state.viewEmailDetail.products.data.length > 0 &&  
                        <>
                        <Typography>Product Summary</Typography>
                        <Box className="title-right">
                          {this.state.viewEmailDetail.total_amount !== null && 
                             <Typography>
                             {" "}
                             Total Amount: <span>${this.state.viewEmailDetail.total_amount}</span>{" "}
                           </Typography>
                          }
                         
                        </Box>
                       </> 
                      }
                      </Box>
                        {this.state.viewEmailDetail?.products?.data && 
                        <ProductCardsSummary productItems={this.state.viewEmailDetail?.products?.data} data-test-id="product-cards-summary"/>

                        }
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
        )}
        {this.state.viewingAttachedProducts && (
          <ProductViewModal
            modalOpen={this.state.viewingAttachedProducts}
            modalClose={this.closeAttachedProducts}
            selectedProductsData={this.state.selectedProductsData}
            openEdit={this.openChooseProductsModal}
            classes={classes}
            data-test-id="direct-email-view-products"
            t={this.t}
            productsQuantity={this.state.productsQuantity}
          />
        )}
        {this.state.minimizeDirectEmail && (
          <Box className={classes.btnMinimizeForm}  onClick={(event: any) => this.maximizeEmailForm(event)}
          data-test-id="maximize-email-form">
            <CardMedia
              component="img"
              src={minimizeDirectEmail}
              className="expand-icon"
             
            />
            <Typography className="create-txt">Create Email</Typography>
            <Button className="minimize-close-btn">
              <CardMedia
                component="img"
                src={closeGray}
                className="close-icon"
                onClick={this.closeFloatEmailForm}
                data-test-id="close-floating-deal"
              />
            </Button>
          </Box>
        )}
         {isImageView && <ImageModal
          isOpen={isImageView}
          viewDetails={this.state.viewEmailDetail}
          currentIndex={currentIndex}
          currentId={this.state.currentId}
          onClose={this.viewImageModalClose} 
          classes={classes}
          handleAfterChange={this.handleAfterChange}
          data-test-id="attachmentModal" 
          isEmailAttachment={true}
          /> }
          {this.state.isEmailDraft && 
            <Modal
            open={this.state.isEmailDraft}
            onClose={this.closeDraftEmailModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
          >
            <Box className={classes.modalDialogBox}>
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                Save as Draft
                </Typography>
                <Link to="#" className="close-icon" onClick={this.closeDraftEmailModal}>
                  <CloseOutlinedIcon />
                </Link>
              </Box>
              <Box className="modal-description">
                <Typography className="modal-desc">
                Do you want to save your draft?
                </Typography>
    
                <Box className="modal-footer">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Discard"
                    onClick={this.closeDraftEmailModal}
                    data-test-id="close-draft-modal"
                  >
                   Discard
                  </Button>
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Save as Draft"
                    onClick={this.createDirectEmails}
                    data-test-id="save-mail-as-draft"
                  >
                   Save as Draft
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          }
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(DirectEmailsHomePage)
);
// Customizable Area End
