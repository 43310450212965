// Customizable Area Start
import React from "react";
import { Box,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  InputAdornment,
  } from "@material-ui/core";
import CustomInputWeb from "./CustomInput.web";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import i18n from "../../web/src/utilities/i18n";

interface SearchDropdownProps {
  label?: any;
  value?:string;
  classes?:any;
  changeHandler?:any;
  searchChangeHAndler?:any;
  searchValue?:any;
  dropdownListing?:any;
  searchValueName?:string;
  selectName?:string;
  itemLabelKey?: string;  // Key for label display
  itemValueKey?: string;  // Key for value to use in MenuItem
  required?: boolean;
  onFocus?:  () => void;
  onBlur?:   () => void;
  customLabelClassname?: any;
}

const t = (key:any, variables?: Record<string, any>) => {
  return (i18n as any).t(key, { ns: "translation" , ...variables } )
}

export const CustomSearchDropdown = (props : SearchDropdownProps) => {
  const {
    label,
    value,
    classes,
    changeHandler,
    searchChangeHAndler,
    searchValue,
    dropdownListing,
    searchValueName,
    selectName,
    itemLabelKey = "name",   // Default to "name"
    itemValueKey = "id",     // Default to "id"
    required = false,
    onFocus,
    onBlur,
    customLabelClassname,
  } = props;
  
    return (
      <FormControl variant="outlined" className="select-outer">
      <InputLabel className={customLabelClassname} style={{ color: required ? '#94a3b8' : 'rgba(148, 163, 184, 0.5)' }}>
        {label}
        { required && <span style={{ color:'#e86577', marginLeft:"4px", position: "absolute", top:"-25px", right:"-18px"}}>*</span> }
      </InputLabel>
      <Select
        value={value}
        name={selectName}
        required={true}
        onChange={changeHandler}
        label={label}
        IconComponent={(props) => (
          <ExpandMoreIcon {...props} />
        )}
        data-test-id="Country"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          classes: { paper: classes.dropdownStyle },
          getContentAnchorEl: null,
        }}
      >
        <Box style={{  "position": 'sticky',
            "top": 0,
            "backgroundColor": 'white',
            "zIndex": 10,
            "padding": '8px',}}>
        <CustomInputWeb
          placeholder={t("Search here")}
          onFocus={onFocus}
          onBlur={onBlur}
          isRequired={true}
          fullWidth={true}
          autoFocus={true}
          onChange={(e) => searchChangeHAndler(e)}
          value={searchValue}
          name={searchValueName}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
          data-test-id="countries-search-input"
        />
      </Box>
        {dropdownListing().map((unit: any) => {
          return (
            <MenuItem value={unit[itemValueKey]} key={unit.id}>
              {unit[itemLabelKey]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
    )
}

// Customizable Area End