import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Drawer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ProfileSidebar from "../../../components/src/CustomProfileSidebar";
import { withStyles } from "@material-ui/core/styles";
import {
  backArrowIcon,
} from "./assets";
import { customStyles } from "../../../components/src/TimesheetManagementHelper.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import RolesPermissions2Controller, {
  Props,
  configJSON,
} from "./RolesPermissions2Controller";

export class RolesPermissions2 extends RolesPermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const { classes } = this.props;
    return (
      // Customizable Area Start
     <>
      <Box className={classes.secondaryHeader}>
              <Box className="container">
                <Box onClick={this.backToDashboard} className="image-wrapper" data-test-id="backToDashboard">
                    <Link title="Back" to="#" > 
                    <em><img alt="Arrow Back Icon" src={backArrowIcon}  /></em> 
                    {this.t("Back")}
                    </Link>
                </Box>
              </Box>
            </Box>
      <Box
         className={`wrapper ${classes.wrapper} ${classes.timesheetWrapper}`}
      >
        <Box style={{marginTop: "20px"}} className={`inner-wrapper ${classes.innerWrapper}`}>
          <Box className={classes.timesheetInnerWrapper}>
            <Box className="main-heading">
             
              <Typography className="heading" variant="h1">
                {this.t("Roles & Permissions")}
              </Typography>
              
            </Box>
            <Box className={classes.drawerWrapper}>
              <Box className={classes.drawer}>
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                    root: classes.drawerRootWrapper,
                  }}
                  variant={"permanent"}
                  open={true}
                >
                  <ProfileSidebar t={this.t} />
                </Drawer>
              </Box>
              <Box className={classes.drawerContent}>
              <Box className={`${classes.profileBlockOuter}`}>
                    <Box className="timesheet-heading-wrapper">
                      <Typography className="timesheet-title">
                        {this.t('All Roles & Permissions')}
                      </Typography>
                      <Typography className="timesheet-sub-title">
                        {this.t('Manage your members’ access.')}
                      </Typography>
                    </Box>
                </Box>
              </Box>
            </Box>
            
          </Box>
          
        </Box>
      </Box>
      </>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  RolesPermissions2
);

// Customizable Area End
