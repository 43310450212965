import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  InputLabel,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Drawer,
  FormHelperText,
  Select,
  FormControl,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "./SettingStyles.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { menu,arrowBackIcon } from "./assets";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import CustomSettingSidebar from "../../../components/src/CustomSettingSidebar.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

// Customizable Area End

import Settinggs5Controller, {
  Props,
  configJSON,
} from "./Settings5Controller.web";
import DeactivateAccountWeb from "./DeactivateAccount.web";


export class Settings5 extends Settinggs5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;
    return (
        <Box className={`wrapper ${classes.wrapper} ${classes.settingWrapper}`}>
            <style>{`
            #menu-formating ul .Mui-disabled{
               display:none;
            }
            `}</style>
            <Box className={classes.secondaryHeader}>
                <Box className="container">
                    <Box className="image-wrapper" data-test-id="backButton" onClick={this.backButtonClick}>
                        <Link to="#" title="Back" > <em><img src={arrowBackIcon} alt="Back Arrow Icon" /></em>  {this.t(`${configJSON.back}`)}</Link>
                    </Box>
                </Box>
            </Box>
            <Box className={`inner-wrapper`}>
                <Box className={classes.settingInnerWrapper}>
                    <Box className="main-heading">
                        <Box className="back-btn-wrap">
                            <i>
                                <svg
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                    className="MuiSvgIcon-root"
                                >
                                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                                </svg>
                            </i>
                        </Box>
                        <Typography variant="h1" className="heading">
                            {this.t(`${configJSON.SettingsHeading}`)}
                        </Typography>
                        <Box
                            data-testId="settingToggleMenu"
                            onClick={this.toggleSettingDrawer}
                            className="setting-menu"
                        >
                            <img alt="menu" src={menu} />
                        </Box>
                    </Box>
                    <Box className={classes.drawerWrapper}>
                        <Box className={classes.drawer}>
                            <Drawer
                                open={isMobile ? this.state.isSettingDrawerOpened : true}
                                variant={isMobile ? "temporary" : "permanent"}
                                classes={{
                                    root: classes.drawerRootWrapper,
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <CustomSettingSidebar
                                    toggleMenu={this.closeSettingDrawer}
                                    activeMenu={3}
                                    navigation={this.props.navigation}
                                    t={this.t}
                                />
                            </Drawer>
                        </Box>
                        <Box className={classes.drawerContent}>

                            <Box
                                className={`${classes.settingBlockOuter
                                    } date-number-formating-block `}
                            >
                                <Box className="setting-block-wrapper setting-form-wrapper">
                                    <Box component="form" className="form-box">
                                        <Box
                                            className={`${classes.formInfoWrapper
                                                } form-info-wrapper`}
                                            style={{ margin: "0", width: "100%" }}
                                        >
                                            <Typography className="form-title format-title">
                                                {this.t(`${configJSON.DateFormatTxt}`)}
                                            </Typography>
                                            <FormControl
                                                variant="outlined"
                                                className={`${classes.selectOutline}`}
                                            >
                                                <Select
                                                    IconComponent={ExpandMoreIcon}
                                                    displayEmpty
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: {
                                                            paper: classes.dropdownStyle,
                                                        },
                                                    }}
                                                    value={this.state.dateFormatSelect}
                                                    onChange={this.handleDateFormatSelectChange}
                                                    name="formating"
                                                    data-test-id="date-format-select"
                                                >
                                                    <MenuItem value="" disabled>DD/MM/YYYY</MenuItem>
                                                    <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                                                    <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                                                    <MenuItem value="YYYY/MM/DD">YYYY/MM/DD</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            className={`${classes.formInfoWrapper
                                                } form-info-wrapper`}
                                            style={{ margin: "0", width: "100%" }}
                                        >
                                            <Typography className="form-title format-title">
                                                {this.t(`${configJSON.NumberFormatTxt}`)}
                                            </Typography>
                                            <FormControl
                                                variant="outlined"
                                                className={`${classes.selectOutline}`}
                                            >
                                                <Select
                                                    IconComponent={ExpandMoreIcon}
                                                    displayEmpty
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: {
                                                            paper: classes.dropdownStyle,
                                                        },
                                                    }}
                                                    value={this.state.numberFormatSelect}
                                                    onChange={this.handleNumberFormatSelectChange}
                                                    name="formating"
                                                    data-test-id="number-format-select"
                                                >
                                                    <MenuItem value="" disabled>
                                                        European A: 1.234.567,89
                                                    </MenuItem>
                                                    <MenuItem value="en-US">Western: 1,234,567.89</MenuItem>
                                                    <MenuItem value="de-DE">European A: 1.234.567,89</MenuItem>
                                                    <MenuItem value="fr-FR">European B: 1 234 567,89</MenuItem>
                                                    <MenuItem value="en-IN">Indian: 12,34,567.89</MenuItem>
                                                    <MenuItem value="de-CH">Swiss: 1'234'567.89</MenuItem>
                                                    <MenuItem value="zh-CN">Chinese A: 1234567.89</MenuItem>
                                                    <MenuItem value="Chinese B en-US">Chinese B: 1 234 567.89</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box style={{ marginTop: "315px", display: "block" }}>
                                        <Button className={`${classes.CancelBtn}`} title="Cancel">
                                            {this.t(`${configJSON.txtCancel}`)}
                                        </Button>
                                        <Button
                                            className={`primary-btn ${classes.primaryButton} ${classes.updateBtn}`}
                                            title="Continue"
                                            onClick={this.continueNextStep}
                                            data-test-id="continueNextStep"
                                        >
                                            {this.t(`${configJSON.Update}`)}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(Settings5)
);
// Customizable Area End
