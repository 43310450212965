//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import { toast } from "react-toastify";
import {deleteIcon} from './assets';
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  isMobile?: boolean;
}

export interface S {
  labelHeader: string;
  filter_date: any;
  filter_escore: any[];
  selected_escore_value: any[];
  token: any;
  sortValue: any;
  ischeck: boolean;
  selecteditem: string;
  submittedquizList: any[];
  quizList: any[];
  filteredQuizList: any[];
  searchQuery: string;
  modalOpen: boolean;
  viewQuizLibrary: boolean;
  viewGradeScore: boolean;
  viewSharingModal: boolean;
  equizResultModal: boolean;
  reTakeQuizModal: boolean;
  deleteQuizModal: boolean;
  simulationQuizModal: boolean;
  fullScreenMode: boolean;
  simulateQuizId: string;
  isCreatingQuiz: boolean;
  gradeScore: string;
  createQuiz: {
    quizTitle: string;
    quizCaption: string;
  };
  createQuizError: {
    errorquizTitle: string;
    errorquizCaption: string;
  };
  getQuizSubmittedData: any[];
  getQuizFullDetails: any[];
  searchQuizValue: string;
  chooseQuizSearch: string;
  searchedQuizData: any[];
  quizLibrarySearchData: any[];
  deleteQuizId: string;
  isAscendingOrder: boolean | null;
  isAscendingOrderLibrary: boolean | null | string;
  isFilteringQuiz: boolean;
  isFilteringQuizLibrary: boolean;
  filteredDataQuiz: any;
  filteredlibraryQuiz: any;
  reTakeQuiz: {
    quizId: string;
    quizTitle: string;
  };
  anchorEl: any;
  openedPopoverId: any;
  currentPage: number;
  itemsPerPage: number;
  isDoneClicked: boolean;
  isLoading: boolean;
  openFilter: boolean;
  isScoreChecked: boolean;
}
export interface SS {
  id: any;
}

export default class EquizDasboardControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuizzesListRequestId: string = "";
  getSubmittedQuizesListAPI: string = "";
  getQuizListAPI: string = "";
  getQuizSubmittedDetailsAPI: string = "";
  getQuizFullDetailsAPI: string = "";
  getAllUserDealsRequestId: string = "";
  deleteQuizAPI: string = "";
  getFilteredQuizesAPI: string = "";
  undoActionAPICallId: string ="";
  userSessionData: any;
  userToken: any;
  private timer: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      labelHeader: "",
      sortValue: null,
      filter_date: "",
      filter_escore: ["A", "B", "C", "D", "E"],
      selected_escore_value: [],
      token: "",
      ischeck: false,
      selecteditem: "",
      submittedquizList: [],
      quizList: [],
      filteredQuizList: [],
      searchQuery: "",
      modalOpen: false,
      viewQuizLibrary: false,
      viewGradeScore: false,
      viewSharingModal: false,
      equizResultModal: false,
      simulationQuizModal: false,
      simulateQuizId: "",
      isCreatingQuiz: false,
      reTakeQuizModal: false,
      deleteQuizModal: false,
      fullScreenMode: false,
      gradeScore: "",
      createQuiz: {
        quizTitle: "",
        quizCaption: "",
      },
      createQuizError: {
        errorquizTitle: "",
        errorquizCaption: "",
      },
      getQuizSubmittedData: [],
      searchQuizValue: "",
      searchedQuizData: [],
      getQuizFullDetails: [],
      chooseQuizSearch: "",
      quizLibrarySearchData: [],
      deleteQuizId: "",
      isAscendingOrder: null,
      isAscendingOrderLibrary: null,
      isFilteringQuiz: false,
      isFilteringQuizLibrary: false,
      filteredDataQuiz: [],
      filteredlibraryQuiz: [],
      reTakeQuiz: {
        quizId: "",
        quizTitle: "",
      },
      anchorEl: null,
      openedPopoverId: null,
      currentPage: 0,
      itemsPerPage: 20,
      isDoneClicked: false,
      isLoading: false,
      openFilter: false,
      isScoreChecked: false,
    };
    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getSubmittedQuizList();
    this.getQuizList();
    this.getUserDeals();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key: any, variables?: Record<string, any>) {
    return i18n.t(key, { ns: "translation", ...variables });
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        // do errorResponse handling here or if there are some errors while calling the API, handle those errors here.
        return;
      }

      // error handling done here

      // Handle Request IDs here

      // for getting quizzes list
      if (apiRequestCallId == this.getSubmittedQuizesListAPI) {
        this.setState({
          submittedquizList: responseJson.data,
        });
        this.setState({isLoading: false})
        return;
      } else if (apiRequestCallId == this.getQuizListAPI) {
        this.setState({
          quizList: responseJson.data,
          isLoading: false,
        });
      } else if (apiRequestCallId == this.getQuizSubmittedDetailsAPI) {
        this.setState(
          { getQuizSubmittedData: responseJson.quiz.quiz.data },
          this.handleOpenViewGradeScore
        );
      } else if (apiRequestCallId == this.getQuizFullDetailsAPI) {
        this.setState({isLoading: false})
        this.setState(
          {
            getQuizFullDetails: responseJson.quiz,
            gradeScore: responseJson.quiz.quiz.data.attributes.score,
          },
          this.handleOpenEquizResultModal
        );
      } else if (apiRequestCallId == this.deleteQuizAPI) {
        if (responseJson.success === true) {
          this.handleCloseDeleteQuizModal();
          this.handleCloseEquizResultModal();
          this.getSubmittedQuizList();
          this.createToastNotificationSuccess(this.t("Quiz deleted succesfully"), deleteIcon);
        }
      } else if (apiRequestCallId == this.getFilteredQuizesAPI) {
        this.setState({isLoading: false})
        this.setState({currentPage : 0})
        if (responseJson.data) {
          this.setState({ filteredDataQuiz: responseJson.data });
        }
      }
      else if (apiRequestCallId == this.undoActionAPICallId) {
        this.getSubmittedQuizList();
      }else if(apiRequestCallId == this.getAllUserDealsRequestId){
        console.log(responseJson)
      }
    }
  }

  createToastNotificationSuccess = (toastMessage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification quiz-deleteClass">
        <div className="notification-txt">{toastMessage}</div>{" "}
        <a href="#" className="toast-link" data-test-id="undo-toast-link" onClick={this.undoQuizAPI}>
          undo
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };

  getSubmittedQuizList = () => {
    //* Making Network Request
    this.setState({isLoading:true})
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.getSubmittedQuizes;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubmittedQuizesListAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuizList = () => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
    this.setState({isLoading: true})
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.createQuizEndPoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizListAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserDeals = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllUserDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDeals
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

   

    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handle_modalClose = () => {
    localStorage.removeItem("chosequizId");
    const newState = {
      viewQuizLibrary: false,
      isCreatingQuiz: false,
      selecteditem: "",
      createQuiz: {
        quizTitle : "",
        quizCaption : "",
      },
      openFilter: false,
      isAscendingOrderLibrary: "Sort by"
    };
    this.setState(newState ,()=>{this.filterLibraryQuizes();});
  };

  handleOpenViewGradeScore = () => {
    this.setState({ viewGradeScore: true });
  };

  handleCloseGradeScoreModal = () => {
    this.setState({ viewGradeScore: false });
  };

  handleOpenSharingModal = () => {
    this.setState({ viewSharingModal: true });
  };

  handleCloseSharingModal = () => {
    this.setState({ viewSharingModal: false });
  };

  handleOpenEquizResultModal = () => {
    this.setState({ equizResultModal: true });
  };

  handleCloseEquizResultModal = () => {
    this.setState({ equizResultModal: false });
  };
  handleRetakeQuizModal = (quizId: string, quizTitle: string, quizCaption?: string) => {
    const quiz = {
      quizTitle,
      quizCaption
    };
    
    let quizToEdit = JSON.stringify(quiz);
    localStorage.setItem("quizdetails", quizToEdit);

    this.setState({
      reTakeQuiz: {
        quizId,
        quizTitle,
      },
    });
    this.setState({ reTakeQuizModal: true });
  };
  handleCloseReTakeQuizModal = () => {
    this.setState({ reTakeQuizModal: false });
  };
  retakeQuizBtnHandler = () => {
    localStorage.setItem("retakeQuizId", this.state.reTakeQuiz.quizId);
    this.props.navigation.navigate("ReTakeQuizWeb");
  };
  handleDeleteQuiizModal = (questionId: string) => {
    this.setState({ deleteQuizId: questionId });
    this.setState({ deleteQuizModal: true });
  };
  handleCloseDeleteQuizModal = () => {
    this.setState({ deleteQuizModal: false });
  };
  handleFullScreenMode = () => {
    this.setState({ fullScreenMode: true });
  };
  handlecloseFullScreen = () => {
    this.setState({ fullScreenMode: false });
  };
  handleOpenScoreMenu = (event: any, popoverId: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      openedPopoverId: popoverId,
    });
  };

  handleCloseScoreMenu = () => {
    this.setState({ openedPopoverId: null, anchorEl: null });
  };

  openFilterModalMobile = () => {
    this.setState({openFilter : true})
  }

  handleSearchClick = (event: { target: { value: string } }) => {
    this.setState(
      { searchQuizValue: event.target.value, isFilteringQuiz: true },
      () => {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.filterQuizSearch();
        }, 700); 
      }
    );
  };
  handleChooseSearchClick = (event: { target: { value: string } }) => {
    this.setState(
      { chooseQuizSearch: event.target.value, isFilteringQuizLibrary: true },
      () => {
        this.filterLibraryQuizes();
      }
    );
  };

  handleSubmittedQuizesSort = (e: { target: { value: any } }) => {
    if (e.target.value == "Sort by") {
      this.setState({ isAscendingOrder: null, isFilteringQuiz: false }, () => {
        this.filterQuizes();
      });
    } else if (e.target.value == "A-Z") {
      this.setState({ isAscendingOrder: true, isFilteringQuiz: true }, () => {
        this.filterQuizes();
      });
    } else {
      this.setState({ isAscendingOrder: false, isFilteringQuiz: true }, () => {
        this.filterQuizes();
      });
    }
  };

  handleLibraryQuizSort = (e: { target: { value: any } }) => {
    if (e.target.value == "Sort by") {
      this.setState({ isAscendingOrderLibrary: "Sort by" }, () => {
        this.filterLibraryQuizes();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscendingOrderLibrary: true }, () => {
        this.filterLibraryQuizes();
      });
    } else {
      this.setState({ isAscendingOrderLibrary: false }, () => {
        this.filterLibraryQuizes();
      });
    }
  };

  handleDateChange = (dates: any) => {
    if (dates) {
    this.setState(
      {
        filter_date: dates,
        isFilteringQuiz: true,
      },
    );
    }
  };

  handleDateReset = () => {
    this.setState(
      {
        filter_date: "",
        isFilteringQuiz: true,
      },()=>{
        this.filterQuizSearch();
      }
    );
  };

  handleScoreCheckboxes = () => {
    this.setState({isScoreChecked : !this.state.isScoreChecked})
  }
  
  handleLastWeek = () => {
    const today = new Date();
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - 7);

    const lastWeekEnd = new Date(today);

    this.setState({
      filter_date: [lastWeekStart, lastWeekEnd],
    });
  };

  handleLastMonth = () => {
    const today = new Date();
    const lastMonthStart = new Date(today);
    lastMonthStart.setMonth(today.getMonth() - 1, 1);

    const lastMonthEnd = new Date(today);
    lastMonthEnd.setDate(0); // Move to the last day of the previous month
    this.setState({
      filter_date: [lastMonthStart, lastMonthEnd],
    });
    
  };

  handleLastYear = () => {
    const today = new Date();
    const lastYearStart = new Date(today);
    lastYearStart.setFullYear(today.getFullYear() - 1, 0, 1);

    const lastYearEnd = new Date(today);
    lastYearEnd.setFullYear(today.getFullYear() - 1, 11, 31);

    this.setState({
      filter_date: [lastYearStart, lastYearEnd],
    });
  };

  scoreFilterHandler = (event: { target: { value: any } }) => {
    console.log(event.target.value)
    this.setState(
      {
        selected_escore_value: event.target.value,
        isFilteringQuiz: true,
      },
      this.filterQuizSearch
    );
  };

  filterQuizes = () => {
    const { submittedquizList } = this.state;
    let filterDataQuiz = [...submittedquizList];
    filterDataQuiz = this.sortQuizes(filterDataQuiz);
    this.setState({ filteredDataQuiz: filterDataQuiz });
  };

  filterLibraryQuizes = () => {
    const { chooseQuizSearch, quizList, isFilteringQuizLibrary } = this.state;
    let filterlibraryQuiz = quizList;
    if (isFilteringQuizLibrary) {
      if (chooseQuizSearch) {
        const searchPattern = chooseQuizSearch.replace(/[()]/g, '\\$&');
        filterlibraryQuiz = quizList.filter((quiz: any) => {
          let quizTitle = quiz.attributes.title.toLowerCase();
          if (quizTitle.search(new RegExp(searchPattern, 'i')) !== -1)
            return quizTitle;
        });
      }
    }

    filterlibraryQuiz = this.sortLibraryQuizes(filterlibraryQuiz);
    this.setState({ filteredlibraryQuiz: filterlibraryQuiz });
  };

  sortQuizes = (sortData: any) => {
    const { isAscendingOrder } = this.state;
    if (isAscendingOrder === null) {
      sortData = this.state.submittedquizList;
      return sortData;
    } else if (isAscendingOrder) {
      return sortData.sort((quiz1: any, quiz2: any) => {
        return quiz1.attributes.title.toLowerCase() <
          quiz2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    } else {
      return sortData.sort((quiz1: any, quiz2: any) => {
        return quiz1.attributes.title.toLowerCase() >
          quiz2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    }
  };

  sortLibraryQuizes = (sortData: any) => {
    const { isAscendingOrderLibrary } = this.state;
    if (isAscendingOrderLibrary === "Sort by") {
      this.getQuizList();
      return sortData; 
    } else if (isAscendingOrderLibrary) {
      return sortData.sort((quiz1: any, quiz2: any) => {
        return quiz1.attributes.title.toLowerCase() <
          quiz2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    } else {
      return sortData.sort((quiz1: any, quiz2: any) => {
        return quiz1.attributes.title.toLowerCase() >
          quiz2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    }
  };

  handleOpenSimlateQuiz = (quizId: string) => {
    this.setState({ simulateQuizId: quizId });
    this.setState({ simulationQuizModal: true, equizResultModal: false });
  };

  handleCloseSimlateQuiz = () => {
    this.setState({ simulationQuizModal: false });
  };

  handlequizLibrary = () => {
    this.setState({ viewQuizLibrary: true });
  };

  handleCreateQuiz = () => {
    this.setState({ isCreatingQuiz: true });
  };
  handleQuizClick = (quizId: string) => {
    localStorage.setItem("chosequizId", quizId);
    this.setState({ selecteditem: quizId });
  };

  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  handlePrevPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleContinueButtonClick = () => {
    this.props.navigation.navigate("EnteringQuiz");
  };

  saveQuizDetails = (key: any, value: any, objectToUpdate: any) => {
    const newState: any = { ...this.state };
    newState[objectToUpdate] = {
      ...newState[objectToUpdate],
      [key]: value,
    };
    this.setState(newState);
  };
  createQuizHandler = () => {
    let errorFlag = false;
    let quizTitleError = this.state.createQuizError.errorquizTitle;
    let quizCaptionError = this.state.createQuizError.errorquizCaption;

    const trimmedQuizTitle = this.state.createQuiz.quizTitle.trim();
    const trimmedQuizCaption = this.state.createQuiz.quizCaption.trim();

    if (trimmedQuizTitle  === "") {
      quizTitleError = "Title field is empty";
      errorFlag = true;
    }
    if (trimmedQuizCaption === "") {
      quizCaptionError = "Caption field is empty";
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({
        createQuizError: {
          errorquizTitle: quizTitleError,
          errorquizCaption: quizCaptionError,
        },
      });
      return true;
    } else {
      const updatedCreateQuiz = {
        ...this.state.createQuiz,
        quizTitle: trimmedQuizTitle,
        quizCaption: trimmedQuizCaption
      };
      let addedQuiz = JSON.stringify(updatedCreateQuiz);
      localStorage.setItem("quizdetails", addedQuiz);
      this.props.navigation.navigate("EnteringQuiz");
    }
  };

  handleQuizTitleClick = (quizId: string) => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.submitQuizEndPoint + "?quiz_id=" + quizId;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizSubmittedDetailsAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleFullDetailsClick = (quizId: string) => {
    this.setState({isLoading: true})
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.submitQuizEndPoint + "?quiz_id=" + quizId;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizFullDetailsAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteQuiz = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint =
      configJSON.deleteQuizEndPoint + "?quiz_id=" + this.state.deleteQuizId;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuizAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterQuizSearch = () => {
    const { filter_date, searchQuizValue, selected_escore_value } = this.state;

    let formattedStartDate = "";
    let formattedEndDate = "";

    if (filter_date !== null && filter_date.length > 0) {
      const startDate = new Date(filter_date[0]);
      const endDate = new Date(filter_date[1]);

      formattedStartDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
      formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
    }

    const header = {
      token: this.userToken.meta.token,
    };
    let baseURL = configJSON.quizFilterSearchEndPoint;

    let endPoint = `${baseURL}`;
    if (searchQuizValue != "") {
      endPoint += `&search[query]=${searchQuizValue}`;
    }
    if (formattedStartDate != "") {
      endPoint += `&filter[start_date]=${formattedStartDate}`;
    }
    if (formattedEndDate != "" && formattedEndDate !== "NaN-NaN-NaN") {
      endPoint += `&filter[end_date]=${formattedEndDate}`;
    }
    if (selected_escore_value.length > 0) {
      endPoint += `&filter[score]=${selected_escore_value}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilteredQuizesAPI = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({isLoading: true})

    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  undoQuizAPI = () => {
    alert("undo")
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.undoActionAPICallId = requestMessage.messageId;

    // //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoActionAPIEndPint
    );

    // //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    // //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
}
//Customizable Area End
