import React, { Component } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  Grid
} from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props {
  classes: any;
}

export interface State {
  selectedFilter: string;
}

class SalesDashboardController extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFilter: "Last 7 Days",
    };
  }

  async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedFilter: event.target.value as string });
  };

  getHeaderData = () => [
    { key: this.t(configJSON.totalSales), count: 5000, status: "96 Deals" },
    { key: this.t(configJSON.newContacts), count: 240, status: "300 Outreaches" },
  ];

  getFooterData = () => [
    { key: this.t(configJSON.initiated), count: 30, status: "500 € potential earnings" },
    { key: this.t(configJSON.proposalsSent), count: 75, status: "500 € potential earnings" },
    { key: this.t(configJSON.followUps), count: 200, status: "500 € potential earnings" },
    { key: this.t(configJSON.wonTxt), count: 15, status: "50k € earned" },
    { key: this.t(configJSON.lostTxt), count: 65, status: "500 € potential earnings" },
  ];

  data = [
    {
        "label": "Series 1",
        "data": [
            {
                "primary": "2024-10-02T00:00:00.000Z",
                "secondary": 72
            },
            {
                "primary": "2024-10-03T00:00:00.000Z",
                "secondary": 49
            },
            {
                "primary": "2024-10-04T00:00:00.000Z",
                "secondary": 70
            },
            {
                "primary": "2024-10-05T00:00:00.000Z",
                "secondary": 58
            },
            {
                "primary": "2024-10-06T00:00:00.000Z",
                "secondary": 13
            },
            {
                "primary": "2024-10-07T00:00:00.000Z",
                "secondary": 86
            },
            {
                "primary": "2024-10-08T00:00:00.000Z",
                "secondary": 76
            },
            {
                "primary": "2024-10-09T00:00:00.000Z",
                "secondary": 53
            },
            {
                "primary": "2024-10-10T00:00:00.000Z",
                "secondary": 49
            },
            {
                "primary": "2024-10-11T00:00:00.000Z",
                "secondary": 72
            }
        ]
    },

];

  tooltip = {

    render: (tooltipInfo: any) => {
        if (!tooltipInfo || !tooltipInfo.focusedDatum) {
            return null; 
        }

        const { focusedDatum } = tooltipInfo;
        const { classes } = this.props;    

        const primaryValue = focusedDatum?.originalDatum?.primary || focusedDatum.primary;
        const secondaryValue = focusedDatum?.originalDatum?.secondary || focusedDatum.secondary;
        const formattedDate = primaryValue
        ? new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(primaryValue))
        : "N/A";

        return (
            <Box className={classes.teamModuleWrapper} style={{
              position: "absolute",
              top: "-5px",
              left: "-35px",
              transform: "translateY(-100%)",
              textAlign: "center",
            }}>
            <div className= "tooltip" style={{ borderRadius:"10.51px" }}>
                <strong style={{fontFamily: "Expletus Sans", fontSize: "14px", fontWeight: 500}}>{formattedDate}</strong>
                <span style={{fontFamily: "Expletus Sans", fontSize: "18px"}}>{secondaryValue ?? "N/A"}</span>
            </div>
            <div style={{ position:"relative" }}>
              <img src={require("../assets/Polygon4.png")} style={{ position: "absolute", left:"3.4px", top: "-5px" }}/>
            </div>
            </Box>
          
        );
    },
  };


  getPrimaryAxis = () => ({
    getValue: (datum: any) => new Date(datum.primary),
  });

  getSecondaryAxes = () => [
    {
      getValue: (datum: any) => datum.secondary,
    },
  ];

  renderStatisticList = (data: any, type: any) => {
    return (
        <>
            {data.map((item: any) => (
                <Card className={type === 'header' ? "google-card" : "google-card2"} key={item.key}>
                    <CardContent className="content-card">
                        <Box className="right-content">
                            <Box className="quiz-title">
                                {item.key}
                            </Box>
                            <Box className="quiz-title2">
                                {item.count}
                            </Box>
                            <Box className="quiz-date">
                                {item.status}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </>
    );
};

}

export default SalesDashboardController;
